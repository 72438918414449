import apiService from "api/api";
import { CLIENT_ENDPOINT } from "../../clients/redux/endpoints";
import { prepareListForBackend, prepareItemsForBackend } from "./utils";
import { IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IServerResponse } from "actions/appActions";
import { IList as IBEList } from "smala-tools/dist/src/types";

const ITEMS_ENDPOINT = `items`;
const LISTS_ENDPOINT = "lists";
/**
 * Creates a list by making a POST request to api with the given parameters
 * @param {String} clientId Client ID with which the list is linked
 * @param {Object} list detail of the list
 * @returns {Promise}
 * */
export type TCreateListFunc = (
  clientId: string,
  list: IList
) => Promise<IServerResponse<{ list_id: string }>>;
export const createListApiCall: TCreateListFunc = (clientId, list) => {
  return apiService.post(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}`,
    prepareListForBackend(list)
  );
};

/**
 * Makes an api call to fetch the list of lists
 * @param {String} clientId Client ID for which we want the lists
 */
export type TFetchListsByClientResponse = { lists: IBEList[] };
export type TFetchListsByClientFunc = (
  clientId: string
) => Promise<IServerResponse<TFetchListsByClientResponse>>;
export const fetchListsByClientApiCall: TFetchListsByClientFunc = (
  clientId
) => {
  return apiService.get(`${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}`);
};

/**
 * Fetch Items by List Id and by attribute name that the duplication will be applied
 * @param {String} clientId Client Id in which we want to fetch the item
 * @param {String} attName Atribute name in which we want to filter the duplicates
 * @param {String} listId ID of the List in which we want the items
 * @returns {Promise}
 */
export type TFetchDuplicatedFunc = (
  clientId: string,
  listId: string,
  attToFilter: string
) => Promise<IServerResponse<any>>;

/**
 * Update ObjectList in client
 * @param {String} clientId Client ID for which we want the lists
 * @param {String} listId ID of the list we want to update
 * @param {Object} newList new properties of the list
 * */

export type TUpdateListFunc = (
  clientId: string,
  listId: string,
  newList: IList
) => Promise<IServerResponse<{}>>;
export const updateListApiCall: TUpdateListFunc = (
  clientId,
  listId,
  newList
) => {
  delete (newList as Partial<IList>).id;
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}`,
    prepareListForBackend(newList)
  );
};

/**
 * Delete List in client
 * @param {String} clientId Client ID for which we want the lists
 * @param {String} listId ID of the list we want to delete
 * @returns {Promise}
 * */
export type TDeleteListFunc = (
  clientId: string,
  listId: string
) => Promise<IServerResponse<{}>>;
export const deleteListApiCall: TDeleteListFunc = (clientId, listId) => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}`,
    {}
  );
};

/**
 * Archive List in client
 * @param {String} clientId Client ID for which we want the lists
 * @param {String} listId ID of the list we want to archive
 * @returns {Promise}
 * */
export type TArchiveListFunc = (
  clientId: string,
  listId: string
) => Promise<IServerResponse<{}>>;
export const archiveListApiCall: TArchiveListFunc = (clientId, listId) => {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/archive`,
    {}
  );
};

/**
 * Restore List in client
 * @param {String} clientId Client ID for which we want the lists
 * @param {String} listId ID of the list we want to restore
 * @returns {Promise}
 * */
export type TRestoreListFunc = (
  clientId: string,
  listId: string
) => Promise<IServerResponse<{}>>;
export const restoreListApiCall: TRestoreListFunc = (clientId, listId) => {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/restore`,
    {}
  );
};

/**
 * Create Items for a given List
 * @param {String} clientId Client Id in which we want to create the items
 * @param {String} listId List Id in which we want to create the items
 * @param {Array} items Values for the items (should be conform to the schema designed in the meta)
 * @returns {Promise}
 */
export type TCreateItemsFunc = (
  clientId: string,
  listId: string,
  items: IListItem[],
  listSchema: IListSchema[]
) => Promise<IServerResponse<{ id: string }[]>>;
export const createItemsApiCall: TCreateItemsFunc = (
  clientId,
  listId,
  items,
  listSchema
) => {
  return apiService.post(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}`,
    {
      items: prepareItemsForBackend(items, listSchema),
    }
  );
};

/**
 * Fetch Items by List Id
 * @param {String} clientId Client Id in which we want to fetch the item
 * @param {String} listId ID of the List in which we want the items
 * @returns {Promise}
 */
export type TFetchItemsByListFunc = (
  clientId: string,
  listId: string
) => Promise<IServerResponse<any>>;
export const fetchItemsByListApiCall: TFetchItemsByListFunc = (
  clientId,
  listId
) => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}/${ITEMS_ENDPOINT}/${listId}`
  );
};

/**
 * Update Items
 * @param {String} clientId Client Id in which we want to update the item
 * @param {Array} items Items to edit (with their id and all the new properties to change)
 * @param {String} listId ID of the list we want to update
 * @returns {Promise}
 */
export type TUpdateItemsFunc = (
  clientId: string,
  items: IListItem[],
  listId: string,
  listSchema: IListSchema[]
) => Promise<IServerResponse<any>>;
export const updateItemsApiCall: TUpdateItemsFunc = (
  clientId,
  items,
  listId,
  listSchema
) => {
  return apiService.patch(`${CLIENT_ENDPOINT}/${clientId}/${ITEMS_ENDPOINT}`, {
    list_id: listId,
    items: prepareItemsForBackend(items, listSchema),
  });
};

/**
 * Upload image
 * @param {Array} files file objects to upload
 * @param {Array} file_metas array of meta info to give to the backend for each files
 * @returns {Promise}
 */
export type TUploadFileFunc = (
  files: any,
  file_metas: any
) => Promise<IServerResponse<{ uploaded: boolean }>>;
export const uploadFileApiCall: TUploadFileFunc = (files, file_metas) => {
  return apiService.multipartUpdate(`/upload/items`, files, { file_metas });
};

/**
 * Delete Items
 * @param {String} clientId Client Id in which we want to delete the items
 * @param {String} listId List Id in which we want to delete the items
 * @param {String[]} itemIds IDs of the items we want to delete
 * @returns {Promise}
 */
export type TDeleteItemsFunc = (
  clientId: string,
  listId: string,
  itemIds: string[]
) => Promise<IServerResponse<{}>>;
export const deleteItemsApiCall: TDeleteItemsFunc = (
  clientId,
  listId,
  itemIds
) => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}`,
    {
      ids: itemIds,
    }
  );
};

/**
 * Archive Items
 * @param {String} clientId Client Id in which we want to archive the items
 * @param {String} listId List Id in which we want to archive the items
 * @param {String[]} itemIds IDs of the items we want to archive
 * @returns {Promise}
 */
export type TArchiveItemsFunc = (
  clientId: string,
  listId: string,
  itemIds: string[]
) => Promise<IServerResponse<{}>>;
export const archiveItemsApiCall: TArchiveItemsFunc = (
  clientId,
  listId,
  itemIds
) => {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/archive`,
    {
      ids: itemIds,
    }
  );
};

/**
 * Restore Items
 * @param {String} clientId Client Id in which we want to restore the items
 * @param {String} listId List Id in which we want to restore the items
 * @param {String[]} itemIds IDs of the items we want to restore
 * @returns {Promise}
 */
export type TRestoreItemsFunc = (
  clientId: string,
  listId: string,
  itemIds: string[]
) => Promise<IServerResponse<{}>>;
export const restoreItemsApiCall: TRestoreItemsFunc = (
  clientId,
  listId,
  itemIds
) => {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/restore`,
    {
      ids: itemIds,
    }
  );
};

/**
 * Assign Items
 * @param {String} clientId Client Id in which we want to do the action
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export type TAssignItemsFunc = (
  clientId: string,
  listId: string,
  links: any
) => Promise<IServerResponse<{}>>;
export const assignItemsApiCall: TAssignItemsFunc = (
  clientId,
  listId,
  links
) => {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/assign`,
    {
      links,
    }
  );
};

/**
 * Unassign Items
 * @param {String} clientId Client Id in which we want to do the action
 * @param {String} ids Id's of the new links we want
 * @returns {Promise}
 */
export function unassignItemsApiCall(
  clientId: string,
  listId: string,
  ids: any
) {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/unassign`,
    {
      ids,
    }
  );
}
/**
 * Fetch Items per list
 * @param {String} clientId Client Id we want to fetch the items from
 * @param {String} listId ID of the list we want to fetch the items from
 * @returns {Promise}
 */
export type TFetchItemsForListFunc = (
  clientId: string,
  listId: string,
  offset: number,
  query: string,
  filter_duplicates: string | undefined,
  detail: boolean | undefined,
  origin_map: boolean | undefined
) => Promise<
  IServerResponse<{
    schema: { type: string; column_name: string }[];
    items: any[];
  }>
>;
export const fetchItemsForListApiCall: TFetchItemsForListFunc = (
  clientId,
  listId,
  offset,
  query,
  filter_duplicates,
  detail,
  origin_map
) => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}`,
    {
      start_after: offset,
      query,
      filter_duplicates,
      detail,
      origin_map,
    } as any
  );
};

export type TDownloadListItemsFunc = (
  clientId: string,
  listId: string,
  query: string
) => Promise<IServerResponse<any>>;
export const downloadListItemsApiCall: TDownloadListItemsFunc = (
  clientId,
  listId,
  query
) => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}/${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/download`,
    {
      query,
    } as any
  );
};
export type TFetchListItemActivitiesResponse = { submissions: any[] };

/**
 * Fetch List Items activities
 * @param {String} clientId Client Id we want to fetch the items from
 * @param {any} query containing list item Id
 * @returns {Promise}
 */
export type TFetchListItemActivitiesFunc = (
  clientId: string,
  query: any
) => Promise<IServerResponse<TFetchListItemActivitiesResponse>>;
export const fetchListItemActivitiesApiCall: TFetchListItemActivitiesFunc = (
  clientId,
  query
) => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}/workflows/submissions`,
    query
  );
};
interface IFetchSubmissionDetailResponse {
  submission: any;
}
export const fetchSubmissionDetailApiCall = (
  workflowId: string,
  submissionId: string,
  clientId: string
): Promise<IServerResponse<IFetchSubmissionDetailResponse>> => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}/workflows/${workflowId}/submissions/${submissionId}`
  );
};
