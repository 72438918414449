import { secondaryColor, defaultColor } from "../../assets/colors";

export default (theme: any) => ({
  InputWrapper: {
    border: "#CCC solid 1px",
    "border-radius": "5px",
  },
  InputWrapperError: {
    border: "#F00 solid 1px",
    "border-radius": "5px",
  },
  InputOuterWrapper: {
    "padding-top": "5px",
    "padding-bottom": "5px",
  },
  FormControlLabel: {
    "margin-left": "auto",
    "margin-right": 0,
  },
  FormGroup: {
    width: "100%",
  },
  Typography: {
    display: "flex",
    "align-items": "center" /* align vertical */,
    "margin-left": 10,
    color: defaultColor,
  },
  WhiteTypography: {
    color: "white",
  },
  ErrorTypography: {
    display: "flex",
    "align-items": "center" /* align vertical */,
    "margin-left": 10,
    color: "#F00",
  },
  UndeterminedTypography: {
    display: "flex",
    "align-items": "center" /* align vertical */,
    "margin-left": 10,
    color: secondaryColor,
  },
  AddButton: {
    "margin-left": "auto",
    "margin-right": 0,
  },
  ChipContainer: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
  },
  chipSelected: {
    margin: theme.spacing(0.5),
    background: theme.palette.primary.light,
  },
  chipUnselected: {
    margin: theme.spacing(0.5),
  },
  undeterminedInput: {
    color: secondaryColor,
  },
  input: {
    color: `black !important`,
  },
  inputDarkMode: {
    color: "white !important",
    borderColor: "white !important",
  },
  ImageButton: {
    "margin-left": "auto",
    "margin-right": 0,
  },
  IconButtonNoLabel: {
    "padding-bottom": "24px",
  },
  ImageCloseButton: {
    float: "right",
    width: "50px",
    height: "50px",
  },
  ImageContainer: {
    width: "100%",
    background: "#EEE",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center", // align image center horizontally
  },
  Image: {
    maxWidth: "100%",
  },
  textField: {
    background: "#EEE",
  },
  smallDisplay: {
    height: 30,
  },
});
