import { ACTION_TYPE } from "../../model/application/UIActionTypes";
import {
  isArchiveTeamsDisabled,
  isDeleteTeamsDisabled,
  isRestoreTeamsDisabled,
} from "./actionVisibilityFunctions";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { getActionName } from "lang/utils";

const ActionsName = {
  downloadTeam: "downloadTeam",
  openTeamDetail: "openTeamDetail",
  openCreateTeamModal: "openCreateTeamModal",
  openEditTeamModal: "openEditTeamModal",
  openAssignMobileUsersToTeamsModal: "openAssignMobileUsersToTeamsModal",
  openAssignWebUsersToTeamsModal: "openAssignWebUsersToTeamsModal",
  openDeleteGroupModal: "openDeleteGroupModal",
  openDeleteGroupsModal: "openDeleteGroupsModal",
  openArchiveGroupModal: "openArchiveGroupModal",
  openArchiveGroupsModal: "openArchiveGroupsModal",
  restoreGroup: "restoreGroup",
  restoreGroups: "restoreGroups",
  openCreateTeamsModal: "openCreateTeamsModal",
  openUpdateTeamsModal: "openUpdateTeamsModal",
  hideArchivedTeams: "hideArchivedTeams",
  showArchivedTeams: "showArchivedTeams",
  openManageHierarchy: "openManageHierarchy",
};

enum TYPE_DISPLAY_NAME {
  DISPLAY_TEAM = "DISPLAY_TEAM",
  DISPLAY_LEVEL = "DISPLAY_LEVEL",
  DISPLAY_GROUP = "DISPLAY_GROUP",
}

const prepareActions = (
  actionsAvailable: any,
  showArchiveTeams: boolean,
  containerActions: any,
  typeDisplay: TYPE_DISPLAY_NAME,
  lang: TLang,
  isHierarchy: boolean
) => {
  const actions = [];
  const customActions = lang.containers.teams.subCategories.teams.actions;
  actions.push({
    action: containerActions[ActionsName.downloadTeam],
    label: getActionName(
      lang,
      LANG_ACTIONS.DOWNLOAD,
      SUB_CATEGORIES.TEAM,
      true,
      true
    ),
    actionType: ACTION_TYPE.DOWNLOAD,
  });
  actions.push({
    action: containerActions[ActionsName.openTeamDetail],
    label: customActions.detail,
    actionType: ACTION_TYPE.DETAIL,
  });
  if (
    ((actionsAvailable.CREATE_TEAMS &&
      typeDisplay === TYPE_DISPLAY_NAME.DISPLAY_TEAM) ||
      (actionsAvailable.CREATE_TEAMS && actionsAvailable.BULK_ACTIONS_TEAMS)) &&
    typeDisplay !== TYPE_DISPLAY_NAME.DISPLAY_LEVEL
  )
    actions.push({
      action: containerActions[ActionsName.openCreateTeamModal],
      label: getActionName(lang, LANG_ACTIONS.CREATE, SUB_CATEGORIES.TEAM),
      actionType: ACTION_TYPE.CREATE,
    });
  if (
    (actionsAvailable.EDIT_TEAMS &&
      typeDisplay === TYPE_DISPLAY_NAME.DISPLAY_TEAM) ||
    (actionsAvailable.EDIT_TEAMS && actionsAvailable.BULK_ACTIONS_TEAMS)
  ) {
    actions.push({
      action: containerActions[ActionsName.openEditTeamModal],
      label: getActionName(lang, LANG_ACTIONS.EDIT, SUB_CATEGORIES.TEAM),
      actionType: ACTION_TYPE.EDIT,
    });
  }
  if (actionsAvailable.EDIT_TEAMS && actionsAvailable.BULK_ACTIONS_TEAMS) {
    actions.push({
      action: containerActions[ActionsName.openAssignMobileUsersToTeamsModal],
      label: customActions.bulkAssignMobileUsersToTeams,
      actionType: ACTION_TYPE.OTHER,
    });
    actions.push({
      action: containerActions[ActionsName.openAssignWebUsersToTeamsModal],
      label: customActions.bulkAssignWebUsersToTeams,
      actionType: ACTION_TYPE.OTHER,
    });
    actions.push({
      action: containerActions[ActionsName.openUpdateTeamsModal],
      label: getActionName(
        lang,
        LANG_ACTIONS.EDIT,
        SUB_CATEGORIES.TEAM,
        true,
        true
      ),
      actionType: ACTION_TYPE.EDIT_MULTIPLE,
    });
  }
  if (
    (actionsAvailable.DELETE_TEAM || actionsAvailable.DELETE_TEAMS) &&
    showArchiveTeams &&
    typeDisplay === TYPE_DISPLAY_NAME.DISPLAY_TEAM
  ) {
    actions.push({
      action: containerActions[ActionsName.openDeleteGroupModal],
      label: getActionName(lang, LANG_ACTIONS.DELETE, SUB_CATEGORIES.TEAM),
      actionType: ACTION_TYPE.DELETE,
    });
    actions.push({
      action: containerActions[ActionsName.openDeleteGroupsModal],
      label: getActionName(
        lang,
        LANG_ACTIONS.DELETE,
        SUB_CATEGORIES.TEAM,
        true
      ),
      disabled: isDeleteTeamsDisabled,
      actionType: ACTION_TYPE.DELETE_MULTIPLE,
    });
  }
  if (
    ((actionsAvailable.ARCHIVE_TEAMS || actionsAvailable.ARCHIVE_TEAM) &&
      typeDisplay === TYPE_DISPLAY_NAME.DISPLAY_TEAM) ||
    ((actionsAvailable.ARCHIVE_TEAMS || actionsAvailable.ARCHIVE_TEAM) &&
      actionsAvailable.BULK_ACTIONS_TEAMS)
  ) {
    actions.push({
      action: containerActions[ActionsName.openArchiveGroupModal],
      label: getActionName(lang, LANG_ACTIONS.ARCHIVE, SUB_CATEGORIES.TEAM),
      actionType: ACTION_TYPE.ARCHIVE,
    });
    actions.push({
      action: containerActions[ActionsName.openArchiveGroupsModal],
      label: getActionName(
        lang,
        LANG_ACTIONS.ARCHIVE,
        SUB_CATEGORIES.TEAM,
        true
      ),
      disabled: isArchiveTeamsDisabled,
      actionType: ACTION_TYPE.ARCHIVE_MULTIPLE,
    });
  }
  if (
    (actionsAvailable.RESTORE_TEAM || actionsAvailable.RESTORE_TEAMS) &&
    showArchiveTeams &&
    typeDisplay === TYPE_DISPLAY_NAME.DISPLAY_TEAM
  ) {
    actions.push({
      action: containerActions[ActionsName.restoreGroup],
      label: getActionName(lang, LANG_ACTIONS.RESTORE, SUB_CATEGORIES.TEAM),
      actionType: ACTION_TYPE.RESTORE,
    });
    actions.push({
      action: containerActions[ActionsName.restoreGroups],
      label: getActionName(
        lang,
        LANG_ACTIONS.RESTORE,
        SUB_CATEGORIES.TEAM,
        true
      ),
      disabled: isRestoreTeamsDisabled,
      actionType: ACTION_TYPE.RESTORE_MULTIPLE,
    });
  }
  if (actionsAvailable.CREATE_TEAMS && actionsAvailable.BULK_ACTIONS_TEAMS)
    actions.push({
      action: containerActions[ActionsName.openCreateTeamsModal],
      label: getActionName(
        lang,
        LANG_ACTIONS.CREATE,
        SUB_CATEGORIES.TEAM,
        true,
        true
      ),
      actionType: ACTION_TYPE.CREATE_MULTIPLE,
    });
  // Hide/Show archived teams
  actions.push({
    action: showArchiveTeams
      ? containerActions[ActionsName.hideArchivedTeams]
      : containerActions[ActionsName.showArchivedTeams],
    label: `${
      showArchiveTeams ? lang.actions.hideArchive : lang.actions.displayArchive
    }`,
    actionType: ACTION_TYPE.OTHER,
  });
  if (actionsAvailable.EDIT_TEAMS_HIERARCHY && isHierarchy)
    actions.push({
      action: containerActions[ActionsName.openManageHierarchy],
      label: customActions.manageHierarchy,
      actionType: ACTION_TYPE.OTHER,
    });

  return actions;
};

export { ActionsName, TYPE_DISPLAY_NAME, prepareActions };
