import { changeSubcategorySuccessActionCreator } from "./actionCreators";
import { IDispatchAndGetState } from "store/model";

export type IChangeSubcategoryActionFunc = (
  subcategory: string
) => IDispatchAndGetState<void>;

/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "mobileuser" or "webuser"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory
) => {
  return (dispatch) => {
    return new Promise(() =>
      dispatch(changeSubcategorySuccessActionCreator(subcategory))
    );
  };
};
export interface IEnvironmentActions {
  changeSubcategoryAction: IChangeSubcategoryActionFunc;
}

const actions: IEnvironmentActions = {
  changeSubcategoryAction,
};

export default actions;
