import * as types from "../actionTypes/webUserActionTypes";
import initialState from "./initialState";
import {
  insertNewItemToArr,
  updateObjectInArray,
  removeObjectFromArray,
  removeObjectFromHash,
  convertArrayToObject,
} from "../../../../utils/reducerUtils";
import { IWebUser } from "model/entities/User";
import { IAction, IActionError } from "store/model";
import {
  IDeleteWebUsersActionSuccess,
  IDeleteWebUserActionSuccess,
  IUpdateWebUsersActionSuccess,
  IFetchWebUsersActionSuccess,
  ICreateWebUsersActionSuccess,
} from "../actionCreator/webUserActionCreator";
import { isOptimetriksRole } from "model/application/ActionCode";

export interface IWebUsersState {
  fetchingAllUsers: boolean;
  fetchingUser: boolean;
  creatingUsers: boolean;
  updatingUser: boolean;
  deletingUser: boolean;
  deletingUsers: boolean;
  lastUpdated: Date;
  user: IWebUser;
  didInvalidate: boolean;
  allUsers: IWebUser[];
  byId: { [id: string]: IWebUser };
  byEmail: {};
  errors: any[];
}

// TODO: use updateObjectInArray utility function for error actions to update the error actions instead of appending new ones
/**
 * webUsersReducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function webUsersReducer(
  state = initialState.webUsers,
  action: IAction
) {
  switch (action.type) {
    case types.CREATE_WEB_USERS_BEGIN:
      return Object.assign({}, state, {
        creatingUsers: true,
      });

    case types.CREATE_WEB_USERS_FAILURE: {
      const { type, error } = action as IActionError;
      return Object.assign({}, state, {
        creatingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...error,
          actionType: type,
        }),
      });
    }

    case types.CREATE_WEB_USERS_SUCCESS: {
      const { users } = action as ICreateWebUsersActionSuccess;

      let allUsers = state.allUsers;
      let byId = state.byId;
      (users as IWebUser[]).forEach((user) => {
        if (!byId[user.id]) {
          allUsers = insertNewItemToArr(allUsers, user);
          byId = Object.assign({}, byId, {
            [user.id]: {
              ...user,
            },
          });
        }
      });

      return {
        ...state,
        creatingUsers: false,
        allUsers,
        byId,
      };
    }

    case types.FETCH_WEB_USERS_BY_CLIENT_BEGIN:
      return {
        ...state,
        fetchingAllUsers: true,
        fetchingUser: false,
        creatingUser: false,
        updatingUser: false,
        deletingUser: false,
      };

    case types.FETCH_WEB_USERS_BY_CLIENT_FAILURE:
      return {
        ...state,
        fetchingAllUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.FETCH_WEB_USERS_BY_CLIENT_SUCCESS: {
      let { users, currentRight } = action as IFetchWebUsersActionSuccess;
      // if the current user is not an optimetriks user, hide all the "optimetriks_admin" users
      if (!isOptimetriksRole(currentRight)) {
        users = (users as IWebUser[]).filter((u) => !isOptimetriksRole(u.role));
      }
      return {
        ...state,
        fetchingAllUsers: false,
        allUsers: users,
        byId: convertArrayToObject(state.byId, users, "id"),
      };
    }

    case types.UPDATE_WEB_USERS_BEGIN:
      return {
        ...state,
        updatingUser: true,
      };

    case types.UPDATE_WEB_USERS_SUCCESS: {
      const { users } = action as IUpdateWebUsersActionSuccess;
      const allUsers = state.allUsers.map((u) => {
        const webUsr = users.find((uu) => uu.id === u.id);
        if (webUsr) {
          return { ...u, ...webUsr };
        } else return u;
      });
      const byId = state.byId;
      allUsers.forEach((u) => {
        byId[u.id] = u;
      });

      return {
        ...state,
        updatingUser: false,
        allUsers,
        byId,
      };
    }

    case types.UPDATE_WEB_USERS_FAILURE:
      return {
        ...state,
        updatingUser: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.DELETE_WEB_USER_BEGIN:
      return {
        ...state,
        deletingUser: true,
      };

    case types.DELETE_WEB_USER_SUCCESS: {
      const { id } = action as IDeleteWebUserActionSuccess;
      return {
        ...state,
        deletingUser: false,
        allUsers: removeObjectFromArray(state.allUsers, id),
        byId: removeObjectFromHash(state.byId, id),
      };
    }

    case types.DELETE_WEB_USER_FAILURE:
      return {
        ...state,
        deletingUser: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.DELETE_WEB_USERS_BEGIN:
      return {
        ...state,
        deletingUsers: true,
      };

    case types.DELETE_WEB_USERS_SUCCESS: {
      const { ids } = action as IDeleteWebUsersActionSuccess;
      const allUsers = state.allUsers.filter((u) => !ids.includes(u.id));
      return {
        ...state,
        deletingUsers: false,
        allUsers,
      };
    }

    case types.DELETE_WEB_USERS_FAILURE:
      return {
        ...state,
        deletingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.CLEAR_DATA: {
      return initialState.webUsers;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState.webUsers,
      };

    default:
      return state;
  }
}
