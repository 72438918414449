import { ITransformation } from "model/entities/Dashboard";
import { ITransformation as IBETransformation } from "smala-tools/dist/src/types";

const prepareTransformationForBackend = (
  originalTransformation: ITransformation
): IBETransformation => {
  const transformation: Partial<ITransformation> = JSON.parse(
    JSON.stringify(originalTransformation)
  );
  Object.keys(transformation).forEach((att) => {
    if (transformation[att] === "") delete transformation[att];
  });
  delete transformation.id;
  transformation.destination_columns = transformation.destination_columns?.map(
    (c) => ({
      column_name: c.column_name,
      column_type: c.column_type,
    })
  );
  if (!transformation.destination_columns)
    transformation.destination_columns = [];
  delete transformation.loading;
  delete transformation["indexes"];
  if (transformation.integrations) {
    transformation.integrations.forEach((int) => delete int["index"]);
  }
  return transformation as IBETransformation;
};

const prepareTransformationsForFrontend = (
  originalTransformations: IBETransformation[]
): ITransformation[] => {
  return originalTransformations.map((t: any) => {
    delete t.last_update;
    delete t.last_exec;
    if (t.integrations) {
      t.integrations = t.integrations.map(
        (integration: any, index: number) => ({ ...integration, index })
      );
    }
    return t;
  });
};

export { prepareTransformationForBackend, prepareTransformationsForFrontend };
