/**
 * @notes: reducer reducer
 */

import * as types from "./actionTypes";
import initialState from "./initialState";
import {
  updateErrorsList,
  insertNewItemToArr,
} from "../../../utils/reducerUtils2";
import { prepareDashboardsForFrontend, cleanKpis } from "./utils";
import { IDashboard } from "model/entities/Dashboard";
import { IAction } from "store/model";
import * as actionCreators from "./actionCreators";

export interface DashboardsState {
  subCategorySelected: string;
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isComputing: boolean;
  selectedDashboard: IDashboard | null;
  dashboards: IDashboard[];
  errors: any[];
}

/**
 * Dashboards reducer takes current state and action and returns a new state for the dashboards object in redux store
 * @param state initial state of dashboards
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_DASHBOARD_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_DASHBOARD_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_DASHBOARD_SUCCESS: {
      const {
        dashboard,
      } = action as actionCreators.ICreateDashboardSuccessAction;

      dashboard.query = {};

      return {
        ...state,
        isCreating: false,
        dashboards: insertNewItemToArr(state.dashboards, dashboard),
      };
    }

    case types.UPDATE_DASHBOARD_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_DASHBOARD_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_DASHBOARD_SUCCESS: {
      const {
        dashboard,
      } = action as actionCreators.IUpdateDashboardSuccessAction;

      const dashboards = state.dashboards.map((d) =>
        d.id === dashboard.id ? dashboard : d
      );

      return {
        ...state,
        isUpdating: false,
        dashboards,
      };
    }

    case types.DELETE_DASHBOARD_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_DASHBOARD_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_DASHBOARD_SUCCESS: {
      const { id } = action as actionCreators.IDeleteDashboardSuccessAction;

      const dashboards = state.dashboards.filter((d) => d.id !== id);

      return {
        ...state,
        isDeleting: false,
        dashboards,
        selectedDashboard: null,
      };
    }

    case types.ARCHIVE_DASHBOARDS_BEGIN:
      return {
        ...state,
        isArchiving: true,
      };

    case types.ARCHIVE_DASHBOARDS_FAILURE: {
      return {
        ...state,
        isArchiving: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.ARCHIVE_DASHBOARDS_SUCCESS: {
      const { ids } = action as actionCreators.IArchiveDashboardsSuccessAction;

      const dashboards = state.dashboards.map((d) => {
        if (ids.includes(d.id)) {
          d.actif = false;
        }
        return d;
      });

      return {
        ...state,
        isArchiving: false,
        dashboards,
        selectedDashboard: null,
      };
    }

    case types.RESTORE_DASHBOARDS_BEGIN:
      return {
        ...state,
        isRestoring: true,
      };

    case types.RESTORE_DASHBOARDS_FAILURE: {
      return {
        ...state,
        isRestoring: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.RESTORE_DASHBOARDS_SUCCESS: {
      const { ids } = action as actionCreators.IRestoreDashboardsSuccessAction;

      const dashboards = state.dashboards.map((d) => {
        if (ids.includes(d.id)) {
          d.actif = true;
        }
        return d;
      });

      return {
        ...state,
        isRestoring: false,
        dashboards,
        selectedDashboard: null,
      };
    }

    case types.FETCH_ALL_DASHBOARDS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_DASHBOARDS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_DASHBOARDS_SUCCESS: {
      let {
        dashboards: beDashboards,
      } = action as actionCreators.IFetchDashboardsSuccessAction;
      beDashboards = beDashboards.filter((d) => (d.type as any) !== "POWERBI");
      const dashboards = prepareDashboardsForFrontend(beDashboards);
      return {
        ...state,
        isFetchingAll: false,
        dashboards,
      };
    }

    case types.COMPUTE_DASHBOARD_BEGIN: {
      return {
        ...state,
        isComputing: true,
      };
    }

    case types.COMPUTE_DASHBOARD_FAILURE: {
      return {
        ...state,
        isComputing: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.COMPUTE_DASHBOARD_SUCCESS: {
      const {
        kpis: ks,
        dashboardId,
        query,
      } = action as actionCreators.IComputeDashboardSuccessAction;
      const kpis = cleanKpis(dashboardId, ks);
      const dashboard = state.dashboards.filter((d) => d.id === dashboardId)[0];
      const dashboardsWithData = state.dashboards.map((d) => {
        if (d.id === dashboardId) {
          d.query = query;
          const kpisWitData = dashboard.kpis.map((kpi) => {
            const kpiWithData = kpis.filter((k) => k.id === kpi.id)[0];
            if (kpiWithData) {
              let data = kpiWithData.data;
              if (kpiWithData.hasOwnProperty("data")) {
                if (Array.isArray(data)) {
                  data = data.map((d) => {
                    if (d.hasOwnProperty("data")) {
                      // if there is a data attribute, check if it is an array. Otherwise, cast it to string,
                      // and change its name to avoid any confusion with the "data" reserved-world for the kpi structure.
                      if (!Array.isArray(d.data)) {
                        d._data = JSON.stringify(d.data);
                        delete d.data;
                      }
                    }
                    return d;
                  });
                }
                return {
                  ...kpi,
                  data,
                };
              } else {
                return kpi;
              }
            } else {
              return kpi;
            }
          });
          return {
            ...d,
            kpis: kpisWitData,
          };
        } else {
          return d;
        }
      });

      return {
        ...state,
        isComputing: false,
        dashboards: dashboardsWithData,
      };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const {
        subcategory,
      } = action as actionCreators.IChangeSubcategorySuccessAction;
      return {
        ...state,
        subCategorySelected: subcategory,
      };
    }

    default:
      return state;
  }
}
