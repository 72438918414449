import React, { Fragment } from "react";
import App from "./containers/app/App";
import DevTools from "./store/DevTools";

const Root = () => {
  return (
    <Fragment>
      <App />
      <DevTools />
    </Fragment>
  );
};

export default Root;
