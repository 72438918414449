import initialState from "./initialState";
import * as types from "./actionTypes";
import { IAction } from "store/model";
import { IChangeSubcategorySuccessActionCreator } from "./actionCreator";

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as IChangeSubcategorySuccessActionCreator;
      return {
        ...state,
        subCategorySelected: subcategory,
      };
    }
    default:
      return state;
  }
}
