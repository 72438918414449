/**
 * @notes: Authentication Action types
 */

export const UPDATE_SMALA_TOKEN = "UPDATE_SMALA_TOKEN";

// login action types
export const LOGIN_REQUEST_BEGIN = "LOGIN_REQUEST_BEGIN";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";

// logout action types
export const LOGOUT_REQUEST_BEGIN = "LOGOUT_REQUEST_BEGIN";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const LOGOUT_REQUEST_FAILURE = "LOGOUT_REQUEST_FAILURE";

// recover password action types
export const RECOVER_PASSWORD_BEGIN = "RECOVER_PASSWORD_BEGIN";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";

// change password action types
export const CHANGE_PASSWORD_BEGIN = "CHANGE_PASSWORD_BEGIN";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const CHANGE_LANG_SUCCES = "CHANGE_LANG_SUCCESS";

export const SET_ACTIONS_AVAILABLE_SUCCESS = "SET_ACTIONS_AVAILABLE_SUCCESS";

export const GET_LAST_VERSION_SUCCESS = "GET_LAST_VERSION_SUCCESS";
