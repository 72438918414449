import { IDashboard } from "model/entities/Dashboard";
import { createSelector } from "reselect";
import { customSelectorCreator } from "../../../selectors/appSelector";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/constants";
import { getSelectedClient } from "../../clients/redux/selectors";
import { getAllWorkflows } from "../../workflows/redux/selectors";
import IRootState from "store/model";
import { IList } from "model/entities/List";
import { IClient } from "model/entities/Client";
import IPlace from "model/entities/Place";
import IWorkflow from "model/entities/Workflow";
import { allDashboardsSelector } from "containers/dashboards/redux/selectors";

/**
 * Selected sub-category
 * lists
 */
export const getSelectedSubCategory = (state: IRootState) =>
  state.lists.subCategorySelected;

/**
 * Get all lists from redux store
 * @param {Object} state
 * @returns {Array} Array of lists objects
 */
const getAllLists = (state: IRootState) => state.lists.allLists;

/**
 * Get list by id
 * @param {Object} state Redux store state
 * @returns {Object}
 */
export const getListById = (state: IRootState) => state.lists.byId;

/**
 * Gets the progress state of fetching lists for a given client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingListsForClient = (state: IRootState) =>
  state.lists.fetchingListsForClient;

/**
 * Gets the progress state of fetching items for a given list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingItemsForList = (state: IRootState) =>
  state.lists.isFetchingItems;

/**
 * Gets the progress state of fetching single item
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingOneItem = (state: IRootState) =>
  state.lists.isFetchingOneItem;
/**
 * Gets the progress state of fetching list item activities
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingListItemActivities = (state: IRootState) =>
  state.lists.isFetchingOneItemActivity;
/**
 * Gets the progress state of fetching one list item submission
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingSubmission = (state: IRootState) =>
  state.lists.isFetchListItemSubmission;

/** Lists selector. Selects the lists from store*/
export const listsSelector = createSelector(getAllLists, (lists) => lists);

/**
 * gets the recently selected list
 * @param {Object} state Redux store state
 * @returns {Object}
 */
const getSelectedList = (state: IRootState) => state.lists.selectedList;

/**
 * Selector to get the most recently selected list
 * @param {Object} state
 * @returns {Object}
 */
export const selectedListSelector = createSelector(
  getSelectedList,
  (list) => list
);

/**
 * Selector to get all lists
 * @param {Object} state Redux store state
 * @returns {Array} array of lists
 */
export const allListsSelector = customSelectorCreator(
  getAllLists,
  (allLists: IList[]) => allLists
);

/**
 * Composed selector that uses @link {selectedListSelector} to compose the list data for display
 * This will use the currently selected list to display the item information as intended
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const selectedListItemsComposedSelector = createSelector(
  selectedListSelector,
  (selectedList) => {
    if (selectedList) {
      return selectedList.items;
    } else {
      return [];
    }
  }
);
/**
 * Composed Selector to get dashboards linked to a place list
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const placeLinkedDashboardsSelector = customSelectorCreator(
  [allDashboardsSelector],
  (dashboards: IDashboard[]) => {
    return dashboards.filter((d) => d.list_id === "place");
  }
);
/**
 * Composed Selector to get lists by the given client account
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const listsComposedSelector = customSelectorCreator(
  [getAllLists, getSelectedClient, getAllWorkflows],
  (lists: IList[], client: IClient, workflows: IWorkflow[]) => {
    if (client) {
      const mapListIdWorkflows: any = {};
      workflows
        .filter((w) => w.actif)
        .forEach((workflow) => {
          workflow.questionnaire.smala_list_ids.forEach((listId) => {
            mapListIdWorkflows[listId] = mapListIdWorkflows[listId] || [];
            mapListIdWorkflows[listId].push({
              id: workflow.id,
              name: workflow.name,
            });
          });
        });
      // remove the "nplet" lists
      lists = lists.filter((l) => {
        return (
          l.schema.filter((att) => att.column_tag === "_nplet_value").length ===
          0
        );
      });
      // remove the "task" lists
      lists = lists.filter((l) => {
        if (l.task_meta) {
          return false;
        }
        return true;
      });
      if (!lists) {
        return [];
      }
      return lists.map((l) => {
        let created_on = moment(l.created_at).format(DATE_FORMAT);
        const schema = l.schema.filter((a) => !a.deprecated);
        schema.sort((a, b) => a.index - b.index);

        return {
          ...l,
          schema,
          created_on,
          workflows: mapListIdWorkflows[l.id] || [],
        };
      });
    } else {
      return [];
    }
  }
);

/**
 * Composed Selector to get lists by the given client account
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const placeComposedSelector = customSelectorCreator(
  [getAllLists, getSelectedClient],
  (lists: IList[], client: IClient): IList<IPlace> | undefined => {
    if (client && lists) {
      // get only the "place" lists
      const place = lists.find((l) => l.list_type === "PLACE");
      if (place) {
        // additionnal cleaning
        //let created_on = moment(place.created_at).format(DATE_FORMAT);
        place.schema = place.schema.filter((a) => !a.deprecated);
        //place.created_on = created_on;
        return place as IList<IPlace>;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
);

export const getDeprecatedAttributeTags = customSelectorCreator(
  [getAllLists, getSelectedClient],
  (lists: IList[], client: IClient): { [listId: string]: string[] } => {
    // remove the "nplet" lists
    lists = lists.filter((l) => {
      return (
        l.schema.filter((att) => att.column_tag === "_nplet_value").length === 0
      );
    });
    // remove the "task" lists
    lists = lists.filter((l) => {
      if (l.task_meta) {
        return false;
      }
      return true;
    });
    if (!lists) {
      return {};
    }
    if (client) {
      if (!lists) {
        return {};
      }
      const result = {};
      lists.forEach(({ id, schema }) => {
        result[id] = schema
          .filter((attr) => attr.deprecated)
          .map((attr) => attr.column_tag);
      });
      return result;
    } else {
      return {};
    }
  }
);

// Status

/**
 * Gets the state of creating a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingList = (state: IRootState) =>
  state.lists.isCreatingList;

/**
 * Gets the state of assigning a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsAssignItems = (state: IRootState) =>
  state.lists.isAssigningItems;

/**
 * Gets the state of unassigning a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUnassignItems = (state: IRootState) =>
  state.lists.isUnassigningItems;

/**
 * Gets the state of finding the duplicates of a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFindDuplicatesItems = (state: IRootState) =>
  state.lists.isFindDuplicatesItems;

/**
 * Gets the state of deleting a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingList = (state: IRootState) =>
  state.lists.isDeletingList;

/**
 * Gets the state of downloading a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDownloadingList = (state: IRootState) =>
  state.lists.isDownloadingFile;

/**
 * Gets the state of updating a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingList = (state: IRootState) =>
  state.lists.isUpdatingList;

/**
 * Gets the state of archiving a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsArchivingList = (state: IRootState) =>
  state.lists.isArchivingList;

/**
 * Gets the state of restoring a list
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRestoringList = (state: IRootState) =>
  state.lists.isRestoringList;

/**
 * Gets the state of creating an item
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingItems = (state: IRootState) =>
  state.lists.isCreatingItems;

/**
 * Gets the state of deleting items
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingItems = (state: IRootState) =>
  state.lists.isDeletingItems;

/**
 * Gets the state of updating an item
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingItems = (state: IRootState) =>
  state.lists.isUpdatingItems;

/**
 * Gets the state of archiving items
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsArchivingItems = (state: IRootState) =>
  state.lists.isArchivingItems;

/**
 * Gets the state of restoring items
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRestoringItems = (state: IRootState) =>
  state.lists.isRestoringItems;

/**
 * Gets the state of uploading a file
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUploadingFile = (state: IRootState) =>
  state.lists.isUploadingFile;
