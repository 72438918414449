import * as types from "./actionTypes";
import { IAction, IActionError } from "store/model";
import { IDocument } from "model/entities/Document";

export interface IChangeSubcategorySuccessActionCreator extends IAction {
  subcategory: string;
  selectedFolder: string;
}
export function fetchDocumentsBeginAction(): IAction {
  return { type: types.FETCH_DOCUMENTS_BEGIN };
}
export function fetchDocumentsFailureAction(error: any): IActionError {
  return { type: types.FETCH_DOCUMENTS_FAILURE, error };
}
export interface IFetchDocumentsSuccessAction extends IAction {
  clientId: string;
  documents: IDocument[];
}
export const fetchDocumentsSuccessAction = (
  clientId: string,
  documents: IDocument[]
): IFetchDocumentsSuccessAction => ({
  type: types.FETCH_DOCUMENTS_SUCCESS,
  clientId,
  documents,
});

export function uploadDocumentsBeginAction(): IAction {
  return { type: types.UPLOAD_DOCUMENTS_BEGIN };
}
export function uploadDocumentsFailureAction(error: any): IActionError {
  return { type: types.UPLOAD_DOCUMENTS_FAILURE, error };
}
export function uploadDocumentsuccessAction(): IAction {
  return { type: types.UPLOAD_DOCUMENTS_SUCCESS };
}

export function deleteDocumentsBeginAction(): IAction {
  return { type: types.DELETE_DOCUMENTS_BEGIN };
}
export function deleteDocumentsFailureAction(error: any): IActionError {
  return { type: types.DELETE_DOCUMENTS_FAILURE, error };
}
export function deleteDocumentsuccessAction(): IAction {
  return { type: types.DELETE_DOCUMENTS_SUCCESS };
}

/**
 * @param {Object} subcategory Can be either index or client or optimetriks
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: string,
  selectedFolder: string
): IChangeSubcategorySuccessActionCreator {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
    selectedFolder,
  };
}
