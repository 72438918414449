/**
 * @notes: Route configurations. These are the routes used in the application. This will be used in the sidebar.
 * But in general will provide an easier access for what routes are in the application and can thus be used where needed
 */
import {
  HOME_ROUTE,
  DASHBOARD_ROUTE,
  CLIENTS_ROUTE,
  TEAMS_ROUTE,
  USERS_ROUTE,
  WORKFLOWS_ROUTE,
  LISTS_ROUTE,
  PLACES_ROUTE,
  PICTURES_ROUTE,
  DOCUMENTS_ROUTE,
  ENVIRONMENT_ROUTE,
} from "./constants";
import { ACTION_TYPE, OBJECT_CATEGORY } from "model/application/ActionCode";
import TLang from "model/application/Lang";

interface IRoute {
  path: string;
  title: string;
  icon: string;
}

export const getRoutes = (
  actionsAvailable: any,
  lang: TLang,
  modulesClient: string[] = []
) => {
  const routes: IRoute[] = [];
  if (!actionsAvailable) return routes;
  routes.push({
    path: HOME_ROUTE,
    title: lang.mainLayout.sidebar.home,
    icon: "home",
  });
  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DASHBOARD}S`]) {
    routes.push({
      path: DASHBOARD_ROUTE,
      title: lang.mainLayout.sidebar.dashboards,
      icon: "dashboard",
    });
  }
  if (
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.MOB_USER}S`] ||
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.WEB_USER}S`]
  ) {
    routes.push({
      path: USERS_ROUTE,
      title: lang.mainLayout.sidebar.users,
      icon: "person",
    });
  }
  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.TEAM}S`]) {
    routes.push({
      path: TEAMS_ROUTE,
      title: lang.mainLayout.sidebar.teams,
      icon: "group_work",
    });
  }
  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.WORKFLOW}S`]) {
    routes.push({
      path: WORKFLOWS_ROUTE,
      title: lang.mainLayout.sidebar.workflows,
      icon: "work",
    });
  }
  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.LIST}S`]) {
    routes.push({
      path: LISTS_ROUTE,
      title: lang.mainLayout.sidebar.lists,
      icon: "list",
    });
    if (
      actionsAvailable[
        `${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.CLIENT_PICTURE}S`
      ]
    ) {
      routes.push({
        path: PICTURES_ROUTE,
        title: lang.mainLayout.sidebar.pictures,
        icon: "photo_camera",
      });
    }
  }
  if (
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.LIST}S`] &&
    modulesClient.includes("GEO_CHECK_IN")
  ) {
    routes.push({
      path: PLACES_ROUTE,
      title: lang.mainLayout.sidebar.places,
      icon: "place",
    });
  }
  if (actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DOCUMENT}S`]) {
    routes.push({
      path: DOCUMENTS_ROUTE,
      title: lang.mainLayout.sidebar.documents,
      icon: "assignment",
    });
  }
  if (
    actionsAvailable[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.TRANSFORMATION}S`]
  ) {
    routes.push({
      path: ENVIRONMENT_ROUTE,
      title: lang.mainLayout.sidebar.environments,
      icon: "environment",
    });
  }
  if (actionsAvailable[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.CLIENT}`]) {
    routes.push({
      path: CLIENTS_ROUTE,
      title: lang.mainLayout.sidebar.clients,
      icon: "supervisor_account",
    });
  }
  return routes;
};
