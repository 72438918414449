import initialState from "./initialState";
import * as types from "../actionTypes/userActionTypes";
import { IWebUsersState } from "./webUsersReducer";
import { IMobileUsersState } from "./mobileUsersReducer";
import { IAction } from "store/model";
import { combineReducers } from "redux";
import webUsers from "./webUsersReducer";
import mobileUsers from "./mobileUsersReducer";

export interface IUsersState {
  subCategorySelected: "mobileuser" | "webuser";
  mobileUsers: IMobileUsersState;
  webUsers: IWebUsersState;
}

export function reducer(
  state = initialState.subCategorySelected,
  action: IAction
) {
  switch (action.type) {
    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as any;
      return subcategory;
    }
    default:
      return state;
  }
}

export default combineReducers({
  subCategorySelected: reducer,
  webUsers,
  mobileUsers,
});
