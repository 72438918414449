export default (theme: any) => ({
  "@global": {
    a: {
      "&:hover": {
        textDecoration: "none",
      },
    },
    ".speedometer": {
      display: "block",
      margin: "0 auto",
      "& g:nth-child(3)": {
        "& text": {
          transform: "translate(0px, -40px)",
        },
      },
      "& g:nth-child(4)": {
        display: "none",
      },
    },
    ".ps__rail-y": {
      // Prefect scrollbar. Should be above everything
      zIndex: 1000,
    },
    ".ps__rail-x": {
      // Prefect scrollbar. Should be above everything
      zIndex: 1000,
    },
    ".MuiTableSortLabel-icon": {
      color: "white !important",
    },
    ".MuiButtonBase-root": {
      zIndex: "1000",
    },
    ".MuiFab-primary": {
      color: theme.palette.primary.main,
      background: "transparent",
      boxShadow: "none",
      "&:hover": {
        background: "#DDD",
      },
    },
  },
  MainContainer: {
    background: "#EEE",
    height: "calc(100vh - 64px)", // remove the size of the navbar
    width: "calc(100vw - 60px)", // remove the size of the sidebar
    padding: "20px",
    margin: "auto",
    float: "right",
    overflowX: "hidden",
    overflowY: "auto",
  },
});
