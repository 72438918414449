import React from "react";
import { NavLink } from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from "@material-ui/core";
import styles from "./styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DashboardIcon from "@material-ui/icons/BarChart";
import UserIcon from "@material-ui/icons/SupervisorAccount";
import TeamIcon from "@material-ui/icons/GroupWork";
import WorkflowIcon from "@material-ui/icons/Work";
import ListIcon from "@material-ui/icons/List";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import PlaceIcon from "@material-ui/icons/Place";
import ClientIcon from "@material-ui/icons/Business";
import UnknownIcon from "@material-ui/icons/Help";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StorageIcon from "@material-ui/icons/Storage";
const useStyles = makeStyles(styles as any);

export interface ISidebarItemProps {
  activeRoute: string;
  path: string;
  icon: string;
  title: string;
  open: boolean;
  changeSubCategoryFunction: (id: string) => void;
  subCategories: any[];
  selectedSubCategory: string;
  disabled: boolean;
}

/**
 * SidebarItem stateless component. This represents a single Sidebar Item component on Sidebar
 * @param {Object} Properties object with path, icon and title
 */
export const SidebarItem: React.FunctionComponent<ISidebarItemProps> = ({
  activeRoute,
  path,
  icon,
  title,
  open,
  changeSubCategoryFunction,
  selectedSubCategory,
  subCategories,
  disabled,
}: ISidebarItemProps) => {
  // bind the style
  const classes: any = useStyles();

  let Icon = InboxIcon;
  switch (icon) {
    case "dashboard":
      Icon = DashboardIcon;
      break;
    case "environment":
      Icon = StorageIcon;
      break;
    case "person":
      Icon = UserIcon;
      break;
    case "group_work":
      Icon = TeamIcon;
      break;
    case "work":
      Icon = WorkflowIcon;
      break;
    case "list":
      Icon = ListIcon;
      break;
    case "photo_camera":
      Icon = PhotoIcon;
      break;
    case "place":
      Icon = PlaceIcon;
      break;
    case "supervisor_account":
      Icon = ClientIcon;
      break;
    case "assignment":
      Icon = AssignmentIcon;
      break;
    default:
      Icon = UnknownIcon;
  }

  return (
    <ExpansionPanel
      expanded={open && activeRoute ? true : false}
      classes={{
        root: activeRoute
          ? classes.ExpansionPanelRootActive
          : classes.ExpansionPanelRoot,
      }}
    >
      <NavLink
        to={path}
        activeClassName="active"
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
          }
        }}
      >
        <ExpansionPanelSummary
          classes={{
            root: classes.ExpansionPanelSummaryRoot,
            content: classes.ExpansionPanelSummaryContent,
          }}
          style={{ cursor: disabled ? "default" : "pointer" }}
        >
          <ListItem
            button
            disabled={disabled}
            key={title}
            onClick={
              subCategories[0]
                ? () => changeSubCategoryFunction(subCategories[0].id)
                : title === "Documents"
                ? () => changeSubCategoryFunction("index")
                : () => {}
            }
          >
            <ListItemIcon
              className={
                activeRoute
                  ? classes.SidebarItemIconActive
                  : classes.SidebarItemIcon
              }
            >
              <Icon />
            </ListItemIcon>
            <ListItemText
              className={
                activeRoute
                  ? classes.SidebarItemTextActive
                  : classes.SidebarItemText
              }
              primary={title}
            />
          </ListItem>
        </ExpansionPanelSummary>
      </NavLink>
      {open && activeRoute
        ? subCategories.map((cat) => {
            return (
              <ExpansionPanelDetails
                onClick={() => changeSubCategoryFunction(cat.id)}
                key={cat.name}
                classes={
                  {
                    root: classes.ExpansionPanelDetailsRoot,
                    content: classes.ExpansionPanelDetailsContent,
                  } as any
                }
              >
                <ListItem button disabled={disabled} key={cat.name}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <ListItemText
                    className={
                      selectedSubCategory === cat.id
                        ? classes.SidebarItemTextActive
                        : classes.SidebarItemText
                    }
                    primary={cat.name}
                  />
                </ListItem>
              </ExpansionPanelDetails>
            );
          })
        : null}
    </ExpansionPanel>
  );
};

export default SidebarItem;
