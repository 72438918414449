import IRootState from "store/model";

export const getDocuments = (state: IRootState) => state.documents.allDocuments;

export const getIsFetchingDocuments = (state: IRootState) =>
  state.documents.isFetchingDocuments;

export const getIsUploadingDocuments = (state: IRootState) =>
  state.documents.isUploadingDocuments;

export const getSelectedDocuments = (state: IRootState) =>
  state.documents.subCategorySelected;

export const getSelectedSubCategory = (state: IRootState) =>
  state.documents.subCategorySelected;

export const getFolder = (state: IRootState) => state.documents.selectedFolder;
