/**
 * @notes: Stream action types
 */
export const CREATE_STREAM_BEGIN = "CREATE_STREAM_BEGIN";
export const CREATE_STREAM_SUCCESS = "CREATE_STREAM_SUCCESS";
export const CREATE_STREAM_FAILURE = "CREATE_STREAM_FAILURE";

export const UPDATE_STREAM_BEGIN = "UPDATE_STREAM_BEGIN";
export const UPDATE_STREAM_SUCCESS = "UPDATE_STREAM_SUCCESS";
export const UPDATE_STREAM_FAILURE = "UPDATE_STREAM_FAILURE";

export const FETCH_ALL_STREAMS_BEGIN = "FETCH_ALL_STREAMS_BEGIN";
export const FETCH_ALL_STREAMS_SUCCESS = "FETCH_ALL_STREAMS_SUCCESS";
export const FETCH_ALL_STREAMS_FAILURE = "FETCH_ALL_STREAMS_FAILURE";

export const DELETE_STREAM_BEGIN = "DELETE_STREAM_BEGIN";
export const DELETE_STREAM_SUCCESS = "DELETE_STREAM_SUCCESS";
export const DELETE_STREAM_FAILURE = "DELETE_STREAM_FAILURE";

export const USE_STREAM_BEGIN = "USE_STREAM_BEGIN";
export const USE_STREAM_SUCCESS = "USE_STREAM_SUCCESS";
export const USE_STREAM_FAILURE = "USE_STREAM_FAILURE";

export const CLEAR_DATA = "CLEAR_DATA";
