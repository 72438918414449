import apiService from "../../../../api/api";
import { CLIENT_ENDPOINT } from "../../../clients/redux/endpoints";
import { IWebUser } from "model/entities/User";
import { IWebUser as IBEWebUser } from "smala-tools/dist/src/types/users";
import { IServerResponse } from "actions/appActions";
import { prepareWebUserForBackend } from "../utils";

export const WEB_USER_ENDPOINT = "/web/user";
export const WEB_USERS_ENDPOINT = "/web/users";

export type TCreateWebUsersFunc = (
  users: IWebUser[],
  clientId: string
) => Promise<IServerResponse<{ id: string; email: string }[]>>;
export const createWebUsersApiCall: TCreateWebUsersFunc = (
  users,
  client_id
) => {
  return apiService.post(`${WEB_USERS_ENDPOINT}`, {
    users: users.map((u) => {
      return prepareWebUserForBackend(u);
    }),
    client_id,
  });
};

export type TResetWebUsersPasswordFunc = (
  userEmail: string
) => Promise<IServerResponse<{ new_password: string }>>;
export const resetWebUsersPasswordApiCall: TResetWebUsersPasswordFunc = (
  email
) => {
  return apiService.post(`${WEB_USER_ENDPOINT}/resetpassword`, {
    email,
  });
};

export type TFetchWebUsersFunc = (
  clientId: string
) => Promise<IServerResponse<IBEWebUser[]>>;
export const fetchWebUsersApiCall: TFetchWebUsersFunc = (clientId) => {
  return apiService.get(`${CLIENT_ENDPOINT}/${clientId}${WEB_USERS_ENDPOINT}`);
};

export type TDeleteWebUserFunc = (
  userId: string
) => Promise<IServerResponse<{}>>;
export const deleteWebUserApiCall: TDeleteWebUserFunc = (userId) => {
  return apiService.delete(`${WEB_USER_ENDPOINT}/${userId}`);
};

export type TDeleteWebUsersFunc = (
  userIds: string[]
) => Promise<IServerResponse<{}>>;
export const deleteWebUsersApiCall: TDeleteWebUsersFunc = (userIds) => {
  return apiService.delete(`${WEB_USERS_ENDPOINT}`, {
    ids: userIds,
  });
};

export type TEditWebUserFunc = (
  id: string,
  first_name: string,
  middle_name: string | undefined,
  last_name: string,
  email: string,
  clients: string[] | undefined,
  role: string,
  business_role: string | undefined
) => Promise<IServerResponse<{}>>;
export const updateWebUserApiCall: TEditWebUserFunc = (
  id,
  first_name,
  middle_name,
  last_name,
  email,
  clients,
  role,
  business_role
) => {
  const user = prepareWebUserForBackend({
    first_name,
    middle_name,
    last_name,
    email,
    clients,
    role,
    business_role,
  } as IWebUser);
  return apiService.patch(`${WEB_USER_ENDPOINT}/${id}`, user);
};
export type TEditWebUsersFunc = (
  webUsers: IWebUser[],
  clientId: string
) => Promise<IServerResponse<IBEWebUser[]>>;
export const updateWebUsersApiCall: TEditWebUsersFunc = (
  webUsers,
  clientId
) => {
  const body = {
    users: webUsers.map((u) => prepareWebUserForBackend(u)),
  };
  return apiService.patch(`${WEB_USERS_ENDPOINT}`, body);
};
