import { ACTION_CODE, IAccessRightProfile } from "smala-tools/dist/src/types";

enum OPTIMETRIKS_ROLES {
  SUPERADMIN = "superadmin",
  ADMIN = "optimetriks_admin",
  OPERATION = "optimetriks_operations",
}

const isOptimetriksRole = (role: any) => {
  return Object.values(OPTIMETRIKS_ROLES).includes(role);
};

enum OBJECT_CATEGORY {
  MOB_USER = "MOB_USER",
  WEB_USER = "WEB_USER",
  LIST = "LIST",
  ITEM = "ITEM",
  CLIENT = "CLIENT",
  TEAM = "TEAM",
  CLIENT_PICTURE = "CLIENT_PICTURE",
  CLIENT_STRIPE_PORTAL = "CLIENT_STRIPE_PORTAL",
  DASHBOARD = "DASHBOARD",
  WORKFLOW = "WORKFLOW",
  SUBMISSION = "SUBMISSION",
  DOCUMENT = "DOCUMENT",
  STREAM = "STREAM",
  TRANSFORMATION = "TRANSFORMATION",
  EMAILGENERATION = "EMAILGENERATION",
}

enum ACTION_TYPE {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE",
  ARCHIVE = "ARCHIVE",
  RESTORE = "RESTORE",
  FETCH = "FETCH",
  RUN = "RUN",
  DOWNLOAD = "DOWNLOAD",
  ASSIGN_MOBILE_USERS_TO = "ASSIGN_MOBILE_USERS_TO",
  ASSIGN_TEAMS_TO = "ASSIGN_TEAMS_TO",
  UNASSIGN_TEAMS_TO = "UNASSIGN_TEAMS_TO",
  UPLOAD_FILE_FOR = "UPLOAD_FILE_FOR",
  LICENSE = "LICENSE",
  UNLICENSE = "UNLICENSE",
  BULK_ACTIONS = "BULK_ACTIONS",
  VIEW_BILLING = "VIEW_BILLING",
  REPLICATE = "REPLICATE",
}

const actionTypePerCategory = (objectCategory: string) => {
  switch (objectCategory) {
    case OBJECT_CATEGORY.TEAM: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.ARCHIVE,
        ACTION_TYPE.RESTORE,
        ACTION_TYPE.BULK_ACTIONS,
      ];
    }
    case OBJECT_CATEGORY.MOB_USER: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.LICENSE,
        ACTION_TYPE.UNLICENSE,
        ACTION_TYPE.BULK_ACTIONS,
      ];
    }
    case OBJECT_CATEGORY.WEB_USER: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.BULK_ACTIONS,
      ];
    }
    case OBJECT_CATEGORY.LIST: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.RESTORE,
        ACTION_TYPE.ARCHIVE,
      ];
    }
    case OBJECT_CATEGORY.ITEM: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.RESTORE,
        ACTION_TYPE.ARCHIVE,
        ACTION_TYPE.BULK_ACTIONS,
        ACTION_TYPE.DOWNLOAD,
      ];
    }
    case OBJECT_CATEGORY.WORKFLOW: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.RESTORE,
        ACTION_TYPE.ARCHIVE,
      ];
    }
    case OBJECT_CATEGORY.SUBMISSION: {
      return [ACTION_TYPE.FETCH, ACTION_TYPE.EDIT, ACTION_TYPE.DELETE];
    }
    case OBJECT_CATEGORY.DASHBOARD: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.ARCHIVE,
        ACTION_TYPE.RESTORE,
        ACTION_TYPE.RUN,
      ];
    }
    case OBJECT_CATEGORY.STREAM: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.RUN,
      ];
    }
    case OBJECT_CATEGORY.TRANSFORMATION: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
      ];
    }
    case OBJECT_CATEGORY.EMAILGENERATION: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
      ];
    }
    case OBJECT_CATEGORY.CLIENT_PICTURE: {
      return [ACTION_TYPE.FETCH];
    }
    case OBJECT_CATEGORY.DOCUMENT: {
      return [ACTION_TYPE.FETCH, ACTION_TYPE.CREATE, ACTION_TYPE.DELETE];
    }
    case OBJECT_CATEGORY.CLIENT: {
      return [
        ACTION_TYPE.FETCH,
        ACTION_TYPE.CREATE,
        ACTION_TYPE.DELETE,
        ACTION_TYPE.EDIT,
        ACTION_TYPE.RESTORE,
        ACTION_TYPE.ARCHIVE,
      ];
    }
    case OBJECT_CATEGORY.CLIENT_STRIPE_PORTAL: {
      return [ACTION_TYPE.VIEW_BILLING];
    }
    default: {
      return [];
    }
  }
};

const getActionCodesFromCategory = (objectCategory: string) => {
  const actionTypes = actionTypePerCategory(objectCategory);
  const result = [];
  actionTypes.forEach((actionType) => {
    result.push(
      `${actionType}_${objectCategory}${
        actionType === ACTION_TYPE.FETCH || actionType === ACTION_TYPE.DOWNLOAD
          ? "S"
          : ""
      }`
    );
  });
  if (objectCategory === OBJECT_CATEGORY.DASHBOARD) {
    result.push("GET_USER_REPORT");
    result.push("EXPORT_CLIENT_DATA");
  }
  return result;
};

const defaultProfileWithNoRights = () => {
  const result = {};
  // set all the possible actions to false
  for (let action in ACTION_CODE) {
    result[ACTION_CODE[action]] = false;
  }
  // set to true actions which should always be true
  result[ACTION_CODE.Login] = true;
  result[ACTION_CODE.Logout] = true;
  result[ACTION_CODE.PasswordRequest] = true;
  result[ACTION_CODE.FetchClient] = true;
  result[ACTION_CODE.GetLicenseStatus] = true;
  return result;
};

const defaultProfile = (): IAccessRightProfile[] => {
  // prepare viewer actions
  const viewerActions = defaultProfileWithNoRights();
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.MOB_USER}S`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.WEB_USER}S`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.TEAM}S`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.WORKFLOW}S`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.SUBMISSION}S`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.LIST}S`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.ITEM}S`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DASHBOARD}S`] = true;
  viewerActions[`${ACTION_TYPE.RUN}_${OBJECT_CATEGORY.DASHBOARD}`] = true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.CLIENT_PICTURE}S`] =
    true;
  viewerActions[`${ACTION_TYPE.FETCH}_${OBJECT_CATEGORY.DOCUMENT}S`] = true;
  // prepare editor actions
  const editorActions = defaultProfileWithNoRights();
  for (let category in OBJECT_CATEGORY) {
    if (OBJECT_CATEGORY[category] !== OBJECT_CATEGORY.CLIENT) {
      const actionsForTheCategory = actionTypePerCategory(
        OBJECT_CATEGORY[category]
      );
      for (let action of actionsForTheCategory) {
        editorActions[`${action}_${OBJECT_CATEGORY[category]}`] = true;
        editorActions[`${action}_${OBJECT_CATEGORY[category]}S`] = true;
      }
    }
  }
  // remove the actions the editor cannot do

  // MOB USERS
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.MOB_USER}`] =
    false;
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.MOB_USER}S`] =
    false;
  // WEB USERS
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.WEB_USER}`] =
    false;
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.WEB_USER}S`] =
    false;
  // TEAMS
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.TEAM}`] = false;
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.TEAM}S`] = false;
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.TEAM}`] = false;
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.TEAM}S`] = false;
  editorActions["EDIT_TEAMS_HIERARCHY"] = false;
  // ITEMS
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.ITEM}`] = false;
  editorActions[`${ACTION_TYPE.BULK_ACTIONS}_${OBJECT_CATEGORY.ITEM}S`] = false;
  // WORKFLOWS
  editorActions[`${ACTION_TYPE.ARCHIVE}_${OBJECT_CATEGORY.WORKFLOW}`] = false;
  editorActions[`${ACTION_TYPE.ARCHIVE}_${OBJECT_CATEGORY.WORKFLOW}S`] = false;
  editorActions[`${ACTION_TYPE.RESTORE}_${OBJECT_CATEGORY.WORKFLOW}`] = false;
  editorActions[`${ACTION_TYPE.RESTORE}_${OBJECT_CATEGORY.WORKFLOW}S`] = false;
  editorActions[`${ACTION_TYPE.EDIT}_${OBJECT_CATEGORY.WORKFLOW}`] = false;
  editorActions[`${ACTION_TYPE.EDIT}_${OBJECT_CATEGORY.WORKFLOW}S`] = false;
  editorActions[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.WORKFLOW}`] = false;
  editorActions[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.WORKFLOW}S`] = false;
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.WORKFLOW}`] = false;
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.WORKFLOW}S`] = false;
  // LISTS
  editorActions[`${ACTION_TYPE.ARCHIVE}_${OBJECT_CATEGORY.LIST}`] = false;
  editorActions[`${ACTION_TYPE.ARCHIVE}_${OBJECT_CATEGORY.LIST}S`] = false;
  editorActions[`${ACTION_TYPE.RESTORE}_${OBJECT_CATEGORY.LIST}`] = false;
  editorActions[`${ACTION_TYPE.RESTORE}_${OBJECT_CATEGORY.LIST}S`] = false;
  editorActions[`${ACTION_TYPE.EDIT}_${OBJECT_CATEGORY.LIST}`] = false;
  editorActions[`${ACTION_TYPE.EDIT}_${OBJECT_CATEGORY.LIST}S`] = false;
  editorActions[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.LIST}`] = false;
  editorActions[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.LIST}S`] = false;
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.LIST}`] = false;
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.LIST}S`] = false;
  // DASHBOARDS
  editorActions[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.DASHBOARD}`] = false;
  editorActions[`${ACTION_TYPE.CREATE}_${OBJECT_CATEGORY.DASHBOARD}S`] = false;
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.DASHBOARD}`] = false;
  editorActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.DASHBOARD}S`] = false;
  editorActions[`${ACTION_TYPE.EDIT}_${OBJECT_CATEGORY.DASHBOARD}`] = false;
  editorActions[`${ACTION_TYPE.EDIT}_${OBJECT_CATEGORY.DASHBOARD}S`] = false;
  editorActions[`${ACTION_TYPE.ARCHIVE}_${OBJECT_CATEGORY.DASHBOARD}`] = false;
  editorActions[`${ACTION_TYPE.ARCHIVE}_${OBJECT_CATEGORY.DASHBOARD}S`] = false;
  editorActions[`${ACTION_TYPE.RESTORE}_${OBJECT_CATEGORY.DASHBOARD}`] = false;
  editorActions[`${ACTION_TYPE.RESTORE}_${OBJECT_CATEGORY.DASHBOARD}S`] = false;
  editorActions["EXPORT_CLIENT_DATA"] = false;
  editorActions["GET_USER_REPORT"] = false;
  //STRIPE BILLING
  editorActions[
    `${ACTION_TYPE.VIEW_BILLING}_${OBJECT_CATEGORY.CLIENT_STRIPE_PORTAL}`
  ] = false;

  // prepare admin actions
  const adminActions = defaultProfileWithNoRights();
  for (let category in OBJECT_CATEGORY) {
    if (OBJECT_CATEGORY[category] !== OBJECT_CATEGORY.CLIENT) {
      const actionsForTheCategory = actionTypePerCategory(
        OBJECT_CATEGORY[category]
      );
      for (let action of actionsForTheCategory) {
        adminActions[`${action}_${OBJECT_CATEGORY[category]}`] = true;
        adminActions[`${action}_${OBJECT_CATEGORY[category]}S`] = true;
      }
    }
  }
  adminActions["EXPORT_CLIENT_DATA"] = true;
  adminActions["GET_USER_REPORT"] = true;
  adminActions["EDIT_TEAMS_HIERARCHY"] = true;
  const fullAccessActions = JSON.parse(JSON.stringify(adminActions));

  // remove the actions the admin cannot do
  adminActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.LIST}`] = false;
  adminActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.WORKFLOW}`] = false;
  adminActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.LIST}S`] = false;
  adminActions[`${ACTION_TYPE.DELETE}_${OBJECT_CATEGORY.WORKFLOW}S`] = false;
  adminActions[
    `${ACTION_TYPE.VIEW_BILLING}_${OBJECT_CATEGORY.CLIENT_STRIPE_PORTAL}`
  ] = false;
  adminActions["EDIT_TEAMS_HIERARCHY"] = false;
  return [
    {
      name: "viewer",
      actions: viewerActions,
      access_all_resources: false,
    },
    {
      name: "editor",
      actions: editorActions,
      access_all_resources: false,
    },
    {
      name: "admin",
      actions: adminActions,
      access_all_resources: true,
    },
    {
      name: "full_access",
      actions: fullAccessActions,
      access_all_resources: true,
    },
  ];
};

const getActionsAvailable = (profiles: IAccessRightProfile[], role: string) => {
  if (role === "superadmin") {
    // prepare super admin actions
    // set all the possible actions to true
    const adminActions = {};
    for (let action in ACTION_CODE) {
      adminActions[ACTION_CODE[action]] = true;
    }
    adminActions[ACTION_CODE.ExportClientData] = false;
    adminActions["ACCESS_ALL_RESOURCES"] = true;
    Object.keys(OBJECT_CATEGORY).forEach(
      (o) => (adminActions[`BULK_ACTIONS_${o}S`] = true)
    );
    return adminActions;
  } else if (["optimetriks_admin", "optimetriks_operations"].includes(role)) {
    // set all the possible actions to true except couple
    const adminActions = {};
    for (let action in ACTION_CODE) {
      adminActions[ACTION_CODE[action]] = true;
    }
    adminActions[ACTION_CODE.DeleteClient] = false;
    adminActions[ACTION_CODE.DeleteWorkflow] = false;
    adminActions[ACTION_CODE.DeleteList] = false;
    adminActions["ACCESS_ALL_RESOURCES"] = true;
    Object.keys(OBJECT_CATEGORY).forEach(
      (o) => (adminActions[`BULK_ACTIONS_${o}S`] = true)
    );
    return adminActions;
  } else {
    const profile = profiles.filter((profile) => role === profile.name)[0];
    return {
      ...profile.actions,
      ACCESS_ALL_RESOURCES: profile.hasOwnProperty("access_all_resources")
        ? profile.access_all_resources
        : true,
    };
  }
};

export {
  ACTION_CODE,
  OBJECT_CATEGORY,
  ACTION_TYPE,
  OPTIMETRIKS_ROLES,
  isOptimetriksRole,
  getActionCodesFromCategory,
  defaultProfile,
  getActionsAvailable,
};
