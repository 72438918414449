import { changeSubcategorySuccessActionCreator } from "./actionCreator";
import { changeSubcategorySuccessActionCreator as listChangeSubcategorySuccessActionCreator } from "containers/lists/redux/actionCreators";
import { Dispatch } from "redux";

/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "map" or "list"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function changeSubcategoryAction(subcategory: any): any {
  return (dispatch: Dispatch) => {
    if (subcategory === "place") {
      dispatch(listChangeSubcategorySuccessActionCreator(subcategory));
    }
    dispatch(changeSubcategorySuccessActionCreator(subcategory));
  };
}
