import React from "react";
import { UserContext, ClientContext } from "../../containers/app/AppProviders";
import CustomSelect from "../Select/CustomSelect";
import CustomMoreButton, {
  IMoreButtonOptions,
} from "../Buttons/CustomMoreButton";
import { AccountCircle } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/HelpOutline";
import CustomButton from "../Buttons/CustomButton";
import { ACTION_TYPE } from "../../model/application/UIActionTypes";
import { Box, makeStyles } from "@material-ui/core";
import styles from "./styles";
import CustomTooltip from "../Tooltip/CustomTooltip";
import TLang from "model/application/Lang";
import { IOption } from "model/application/components";
import { IClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";
import { isOptimetriksRole } from "model/application/ActionCode";

interface INavBarLinksProps {
  privilege: string;
  handleLogout: () => void;
  handleOpenChangePassword: () => void;
  handleGetUserDB: () => void;
  handleClientChange: () => void;
  handleLangChange: () => void;
  handleStripeSessionFetch: () => string;
  clientOptions: IOption[];
  selectedClient: IClient;
  refreshClient: () => void;
  selectedLanguage?: "en" | "fr" | "es";
  appNotifications: any[];
  showAppNotification: () => void;
  lang: TLang;
  signedInUser: ISignedInUser;
  actionsAvailable: any;
}

const useStyles = makeStyles(styles as any);

export const NavBarLinks: React.FunctionComponent<INavBarLinksProps> = ({
  privilege,
  handleLogout,
  handleOpenChangePassword,
  handleGetUserDB,
  handleClientChange,
  handleLangChange,
  clientOptions = [],
  refreshClient,
  selectedLanguage = "en",
  appNotifications,
  showAppNotification,
  lang,
  signedInUser,
  selectedClient,
  handleStripeSessionFetch,
  actionsAvailable,
}) => {
  const classes = useStyles();
  const optionsInMoreButton: IMoreButtonOptions[] = [
    { action: handleLogout, label: lang.mainLayout.logOutButton },
    {
      action: handleOpenChangePassword,
      label: lang.mainLayout.changePasswordButton,
    },
    {
      action: () =>
        window.open(
          "https://play.google.com/store/apps/details?id=com.optimetriks.smala&hl=en",
          "_blank"
        ),
      label: lang.mainLayout.goToFPDownloadButton,
    },
  ];
  const helpButtonOptions: IMoreButtonOptions[] = [
    {
      action: () => window.open("https://help.fieldproapp.com", "_blank"),
      label: lang.mainLayout.goToHelpCenterButton,
    },
    {
      action: () =>
        window.open(
          "https://optimetriks.atlassian.net/servicedesk/customer/portal/6/group/-1",
          "_blank"
        ),
      label: lang.mainLayout.goToContactSupportButton,
    },
    {
      action: () =>
        window.open(
          "https://optimetriks.atlassian.net/servicedesk/customer/user/requests?page=1&status=open",
          "_blank"
        ),
      label: lang.mainLayout.goToViewSupportRequestsButton,
    },
  ];
  if (isOptimetriksRole(privilege)) {
    optionsInMoreButton.push({
      action: handleGetUserDB,
      label: lang.mainLayout.getClientUsersDbButton,
    });
  }
  return (
    <UserContext.Consumer>
      {() => (
        <ClientContext.Consumer>
          {(client) => (
            <div className={classes.navLinks}>
              {/** Refresh page */}
              <Box display={{ xs: "none", sm: "block" }}>
                <div className={classes.actionWrapper}>
                  <CustomTooltip title={lang.mainLayout.forceSyncButton}>
                    <CustomButton
                      type={ACTION_TYPE.REFRESH}
                      onClick={refreshClient}
                    />
                  </CustomTooltip>
                  {selectedClient.stripe_customer_id &&
                  (actionsAvailable.VIEW_BILLING_CLIENT_STRIPE_PORTAL ||
                    actionsAvailable.ACCESS_ALL_RESOURCES) ? (
                    <CustomTooltip
                      title={lang.mainLayout.goToStripePortalButton}
                    >
                      <CustomButton
                        type={ACTION_TYPE.STRIPE_PORTAL}
                        onClick={async () => {
                          const sessionLink = await handleStripeSessionFetch();
                          sessionLink && window.open(sessionLink, "_self");
                        }}
                      />
                    </CustomTooltip>
                  ) : null}
                  <CustomTooltip title={lang.mainLayout.documentationButton}>
                    <CustomMoreButton
                      icon={<HelpIcon aria-label="HelpIcon" />}
                      options={helpButtonOptions}
                    />
                  </CustomTooltip>
                  <CustomTooltip title="Setting options">
                    <CustomMoreButton
                      icon={<AccountCircle />}
                      options={optionsInMoreButton}
                    />
                  </CustomTooltip>
                  <CustomTooltip title="Notifications">
                    <CustomButton
                      type={ACTION_TYPE.NOTIFICATION}
                      onClick={showAppNotification}
                      badgeContent={appNotifications.length}
                    />
                  </CustomTooltip>
                </div>
              </Box>
              {/* Select language */}
              <CustomSelect
                title={""}
                name={"languageSelect"}
                smallDisplay={true}
                isFlagSelection={true}
                options={[
                  { key: "en", label: "gb" },
                  { key: "fr", label: "fra" },
                  { key: "es", label: "es" },
                ]}
                value={selectedLanguage}
                onChange={handleLangChange}
              />

              {/* Client switch */}

              {clientOptions.length > 1 ? (
                <CustomSelect
                  title={lang.mainLayout.selectedClient}
                  error={false}
                  smallDisplay={true}
                  name={"clientSelect"}
                  value={client.id}
                  onChange={handleClientChange}
                  options={clientOptions.sort((a, b) =>
                    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                  )}
                />
              ) : null}
            </div>
          )}
        </ClientContext.Consumer>
      )}
    </UserContext.Consumer>
  );
};

export default NavBarLinks;
