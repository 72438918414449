if (process.env.REACT_APP_ENV === "production") {
  module.exports = require("./configureStore.prod");
} else if (
  process.env.REACT_APP_ENV === "staging" ||
  process.env.REACT_APP_ENV === "staging_dev"
) {
  module.exports = require("./configureStore.staging");
} else {
  module.exports = require("./configureStore.dev");
}
