import { NOTIFICATION_LEVEL_INFO } from "./../../../notifications/constants";
import { fetchMobUserSubmissionDetailApiCall } from "./../api/mobileUserApi";
import {
  fetchMobileUsersBeginActionCreator,
  fetchMobileUsersFailureActionCreator,
  fetchMobileUsersSuccessActionCreator,
  createMobileUsersBeginActionCreator,
  createMobileUsersFailureActionCreator,
  createMobileUsersSuccessActionCreator,
  deleteMobileUserBeginActionCreator,
  deleteMobileUserFailureActionCreator,
  deleteMobileUserSuccessActionCreator,
  deleteMobileUsersBeginActionCreator,
  deleteMobileUsersSuccessActionCreator,
  updateMobileUserBeginActionCreator,
  updateMobileUserFailureActionCreator,
  updateMobileUserSuccessActionCreator,
  licenseMobileUserBeginActionCreator,
  licenseMobileUserSuccessActionCreator,
  licenseMobileUserFailureActionCreator,
  unlicenseMobileUserBeginActionCreator,
  unlicenseMobileUserSuccessActionCreator,
  unlicenseMobileUserFailureActionCreator,
  bulkLicenseMobileUsersBeginActionCreator,
  bulkLicenseMobileUsersSuccessActionCreator,
  bulkLicenseMobileUsersFailureActionCreator,
  bulkUnlicenseMobileUsersBeginActionCreator,
  bulkUnlicenseMobileUsersSuccessActionCreator,
  bulkUnlicenseMobileUsersFailureActionCreator,
  uploadFileBeginAction,
  uploadFileSuccessAction,
  uploadFileFailureAction,
  unlockLoginFailureActionCreator,
  fetchMobUserSubmissionsFailureActionCreator,
  fetchMobUserSubmissionsBeginActionCreator,
  fetchMobUserSubmissionsSuccessActionCreator,
  fetchMobUserSubmissionDetailFailureActionCreator,
  fetchMobUserSubmissionDetailSuccessActionCreator,
  fetchMobUserSubmissionDetailBeginActionCreator,
} from "../actionCreator/mobileUserActionCreator";
import { removeUsersFromAllTeamsSuccessAction } from "../../../teams/redux/actionCreators";
import {
  createMobileUsersApiCall,
  removeMobileUserFromClientApiCall,
  removeMobileUsersFromClientApiCall,
  fetchMobileUsersApiCall,
  updateMobileUserApiCall,
  licenseMobileUserApiCall,
  unlicenseMobileUserApiCall,
  bulkLicenseMobileUsersApiCall,
  bulkUnlicenseMobileUsersApiCall,
  uploadFileApiCall,
  updateMobileUsersApiCall,
  unlockLoginApiCall,
  fetchMobUserSubmissionsApiCall,
} from "../api/mobileUserApi";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../../actionCreators/ajaxActionCreator";
import * as notificationTypes from "../../../notifications/actionTypes";
import * as levels from "../../../notifications/constants";
import { createNotificationActionCreator } from "../../../notifications/actionCreator";
import { getSelectedClient } from "../../../clients/redux/selectors";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../../actions/appActions";
import { getLang } from "../../../authentication/redux/selector";
import * as lang from "../../../../lang";
import { getSuccessNotificationMessage } from "../../../../lang/utils";
import { IMobileUser } from "model/entities/User";
import { IDispatchAndGetState } from "store/model";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import * as notificationLevels from "../../../notifications/constants";
interface IMobileUserWithIID extends IMobileUser {
  iid: string;
}

export type ICreateMobileUserActionFunc = (
  users: IMobileUserWithIID[]
) => IDispatchAndGetState<any>;
export const createMobileUsersAction: ICreateMobileUserActionFunc = (users) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the id of the user who is creating this user. this won't be passed in to the given POST request
    // this will instead be passed to the redux store
    // also get the created_at date time

    dispatch(ajaxRequestAction());
    dispatch(createMobileUsersBeginActionCreator());

    return new Promise((resolve, reject) => {
      createMobileUsersApiCall(users, getSelectedClient(getState()).id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          // create list of users with id
          const usersWithId = users.map((u) => {
            return {
              ...u,
              id: data.filter((d) => d.iid === u.iid)[0].id,
              licensed: false,
            };
          });
          dispatch(createMobileUsersSuccessActionCreator(usersWithId));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.MOBILE_USER,
                users.length,
                true
              )
            )
          );
          resolve(data);
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "CreateMobileUsersError",
            err,
            createMobileUsersFailureActionCreator,
            currLang
          );
          reject(err);
        });
    });
  };
};

export type IFetchMobileUserActionFunc = (
  clientId: string
) => IDispatchAndGetState<void>;
export const fetchMobileUsersAction: IFetchMobileUserActionFunc = (
  clientId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchMobileUsersBeginActionCreator());

    return fetchMobileUsersApiCall(clientId)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(fetchMobileUsersSuccessActionCreator(data));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchMobileUsersError",
          error,
          fetchMobileUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type IUploadFileActionFunc = (
  clientId: string,
  files: any[]
) => IDispatchAndGetState<{
  uploaded: boolean;
  files: {
    filename: string;
    url: string;
  }[];
}>;
export const uploadFileMobileUserAction: IUploadFileActionFunc = (
  clientId,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginAction());
    const fileMetas = { filetype: "MOBILE_USER" };

    return new Promise(function (resolve, reject) {
      uploadFileApiCall(clientId, files, fileMetas)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessAction());
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.MOBILE_USER,
                ""
              )
            )
          );
          resolve(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureAction,
            currLang
          );
          reject("Something went wrong");
        });
    });
  };
};

export type IUpdateMobileUserActionFunc = (
  mobileUser: IMobileUser
) => IDispatchAndGetState<void>;
export const updateMobileUserAction: IUpdateMobileUserActionFunc = (
  mobileUser
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateMobileUserBeginActionCreator());

    return new Promise((resolve, reject) => {
      updateMobileUserApiCall(mobileUser, getSelectedClient(getState()).id)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(updateMobileUserSuccessActionCreator([mobileUser]));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.MOBILE_USER,
                `${mobileUser.first_name} ${mobileUser.last_name}`
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateMobileUserError",
            error,
            updateMobileUserFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export type IUpdateMobileUsersActionFunc = (
  mobileUsers: IMobileUser[]
) => IDispatchAndGetState<void>;
export const updateMobileUsersAction: IUpdateMobileUsersActionFunc = (
  mobileUsers
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateMobileUserBeginActionCreator());

    return new Promise((resolve, reject) => {
      updateMobileUsersApiCall(mobileUsers, getSelectedClient(getState()).id)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(updateMobileUserSuccessActionCreator(mobileUsers));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.MOBILE_USER,
                mobileUsers.length,
                true
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateMobileUserError",
            error,
            updateMobileUserFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export type IDeleteMobileUserActionFunc = (
  id: string
) => IDispatchAndGetState<void>;
export const deleteMobileUserAction: IDeleteMobileUserActionFunc = (id) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteMobileUserBeginActionCreator());

    return removeMobileUserFromClientApiCall(
      id,
      getSelectedClient(getState()).id
    )
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteMobileUserSuccessActionCreator(id));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.MOBILE_USER
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteMobileUserError",
          error,
          deleteMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IDeleteMobileUsersActionFunc = (
  ids: string[]
) => IDispatchAndGetState<void>;
export const deleteMobileUsersAction: IDeleteMobileUsersActionFunc = (ids) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteMobileUsersBeginActionCreator());

    return removeMobileUsersFromClientApiCall(
      ids,
      getSelectedClient(getState()).id
    )
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteMobileUsersSuccessActionCreator(ids));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.MOBILE_USER,
              ids.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteMobileUsersError",
          error,
          deleteMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IBulkLicenseMobileUsersActionFunc = (
  userIds: string[],
  clientId?: string
) => IDispatchAndGetState<void>;

export const bulkLicenseMobileUsersAction: IBulkLicenseMobileUsersActionFunc = (
  userIds,
  clientId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(bulkLicenseMobileUsersBeginActionCreator());

    // api call to link app user
    return bulkLicenseMobileUsersApiCall(client_id, userIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(bulkLicenseMobileUsersSuccessActionCreator(userIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.LICENSE,
              SUB_CATEGORIES.MOBILE_USER,
              userIds.length,
              true
            )
          )
        );
      })
      .catch((err) => {
        treatErrorNotification(
          dispatch,
          "BulkLicenseMobileUsersError",
          err,
          bulkLicenseMobileUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type IBulkUnlicenseMobileUsersActionFunc = (
  userIds: string[],
  clientId?: string
) => IDispatchAndGetState<void>;
export const bulkUnlicenseMobileUsersAction: IBulkUnlicenseMobileUsersActionFunc = (
  userIds,
  clientId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(bulkUnlicenseMobileUsersBeginActionCreator());

    // api call to link app user
    return bulkUnlicenseMobileUsersApiCall(client_id, userIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(removeUsersFromAllTeamsSuccessAction(client_id, userIds));
        dispatch(bulkUnlicenseMobileUsersSuccessActionCreator(userIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.UNLICENSE,
              SUB_CATEGORIES.MOBILE_USER,
              userIds.length,
              true
            )
          )
        );
      })
      .catch((err) => {
        treatErrorNotification(
          dispatch,
          "BulkUnlicenseMobileUsersError",
          err,
          bulkUnlicenseMobileUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type ILicenseMobileUserActionFunc = (
  user: IMobileUser,
  clientId?: string
) => IDispatchAndGetState<void>;
export const licenseMobileUserAction: ILicenseMobileUserActionFunc = (
  user,
  clientId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());

    // eslint-disable-next-line no-unused-vars
    const client_id = clientId || client.id;
    dispatch(ajaxRequestAction());
    dispatch(licenseMobileUserBeginActionCreator());

    // api call to link app user
    return licenseMobileUserApiCall(client_id, user.id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(licenseMobileUserSuccessActionCreator(user));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.LICENSE,
              SUB_CATEGORIES.MOBILE_USER,
              `${user.first_name} ${user.last_name}`
            )
          )
        );
      })
      .catch((err) => {
        treatErrorNotification(
          dispatch,
          "LicenseMobileUserError",
          err,
          licenseMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IUnlicenseMobileUserActionFunc = (
  user: IMobileUser,
  clientId?: string
) => IDispatchAndGetState<void>;
export const unLicenseMobileUserAction: IUnlicenseMobileUserActionFunc = (
  user,
  clientId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());

    // eslint-disable-next-line no-unused-vars
    const client_id = clientId || client.id;
    dispatch(ajaxRequestAction());
    dispatch(unlicenseMobileUserBeginActionCreator());

    // api call to link app user
    return unlicenseMobileUserApiCall(client_id, user.id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(removeUsersFromAllTeamsSuccessAction(client_id, [user.id]));
        dispatch(unlicenseMobileUserSuccessActionCreator(user));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.UNLICENSE,
              SUB_CATEGORIES.MOBILE_USER,
              `${user.first_name} ${user.last_name}`
            )
          )
        );
      })
      .catch((err) => {
        treatErrorNotification(
          dispatch,
          "UnlicenseMobileUserError",
          err,
          unlicenseMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IUnlockLoginFunc = (
  mobileUserId: string
) => IDispatchAndGetState<void>;
export const unlockLoginAction: IUnlockLoginFunc = (mobileUserId) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    return new Promise((resolve, reject) => {
      unlockLoginApiCall(mobileUserId)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          resolve();
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "UnlockMobileUserTokenError",
            err,
            unlockLoginFailureActionCreator,
            currLang
          );
          reject(err);
        });
    });
  };
};
export type IFetchMobUserSubmissionDetailActionFunc = (
  workflowId: string,
  submissionId: string
) => IDispatchAndGetState<any>;
/**
 * Action to fetch mobile user submissions
 * @param {String} workflowId Id of the workflow where we want the submissions
 * @param {String} submissionId Id of the submission where we want to fetch
 * @returns {Function}
 */
export const fetchMobUserSubmissionDetailAction: IFetchMobUserSubmissionDetailActionFunc = (
  workflowId,
  submissionId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const clientId = client.id;
    dispatch(ajaxRequestAction());
    dispatch(fetchMobUserSubmissionDetailBeginActionCreator());
    return new Promise((res, rej) => {
      fetchMobUserSubmissionDetailApiCall(workflowId, submissionId, clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchMobUserSubmissionDetailSuccessActionCreator(data.submission)
          );
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.FETCH,
                SUB_CATEGORIES.SUBMISSION,
                data.submission._id,
                true
              )
            )
          );
          res(data.submission);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchSubmissionError",
            error,
            fetchMobUserSubmissionDetailFailureActionCreator,
            currLang
          );
          rej(error);
        });
    });
  };
};
export type IFetchMobUserSubmissionsActionFunc = (
  query: any
) => IDispatchAndGetState<any>;
/**
 * Action to fetch mobile user submissions
 * @param {any} query containing Id of the user we want his/her submissions
 * @returns {Function}
 */
export const fetchMobUserSubmissionsAction: IFetchMobUserSubmissionsActionFunc = (
  query
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const clientId = client.id;
    dispatch(ajaxRequestAction());
    dispatch(fetchMobUserSubmissionsBeginActionCreator());
    return new Promise((res, rej) => {
      fetchMobUserSubmissionsApiCall(clientId, query)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchMobUserSubmissionsSuccessActionCreator(data.submissions)
          );
          dispatch(
            createNotificationActionCreator(
              data.submissions.length > 0
                ? notificationTypes.NOTIFICATION_SUCCESS
                : notificationTypes.NOTIFICATION_WARN,
              data.submissions.length > 0
                ? notificationLevels.NOTIFICATION_LEVEL_SUCCESS
                : notificationLevels.NOTIFICATION_LEVEL_WARNING,
              data.submissions.length > 0
                ? getSuccessNotificationMessage(
                    currLang,
                    LANG_ACTIONS.FETCH,
                    SUB_CATEGORIES.SUBMISSION,
                    data.submissions.length,
                    true
                  )
                : currLang.notifications.successNotifications
                    .mobUserNoSubmissionsMessage
            )
          );
          res(data.submissions);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchSubmissionError",
            error,
            fetchMobUserSubmissionsFailureActionCreator,
            currLang
          );
          rej(error);
        });
    });
  };
};
export interface IMobileUserActions {
  createMobileUsersAction: ICreateMobileUserActionFunc;
}

const actions: IMobileUserActions = {
  createMobileUsersAction,
};

export default actions;
