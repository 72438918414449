import {
  createClientBeginActionCreator,
  createClientFailureActionCreator,
  createClientSuccessActionCreator,
  deleteClientBeginActionCreator,
  deleteClientFailureActionCreator,
  deleteClientSuccessActionCreator,
  replicateClientBeginActionCreator,
  replicateClientFailureActionCreator,
  replicateClientSuccessActionCreator,
  fetchAllClientsFailureActionCreator,
  fetchAllClientsBeginActionCreator,
  fetchAllClientsSuccessActionCreator,
  fetchClientFailureActionCreator,
  fetchClientBeginActionCreator,
  fetchClientSuccessActionCreator,
  updateClientBeginActionCreator,
  updateClientFailureActionCreator,
  updateClientSuccessActionCreator,
  setClientLiveBeginActionCreator,
  setClientLiveFailureActionCreator,
  setClientLiveSuccessActionCreator,
  archiveClientBeginActionCreator,
  archiveClientFailureActionCreator,
  archiveClientSuccessActionCreator,
  restoreClientBeginActionCreator,
  restoreClientFailureActionCreator,
  restoreClientSuccessActionCreator,
  selectClientActionCreator,
  uploadFileBeginActionCreator,
  uploadFileSuccessActionCreator,
  uploadFileFailureActionCreator,
  fetchTeamsByClientBeginActionCreator,
  fetchTeamsByClientFailureActionCreator,
  fetchTeamsByClientSuccessActionCreator,
  fetchStripeSessionLinkBeginActionCreator,
  fetchStripeSessionLinkSuccessActionCreator,
  fetchStripeSessionLinkFailureActionCreator,
  fetchAdditionalInfosBeginActionCreator,
  fetchAdditionalInfosSuccessActionCreator,
  fetchAdditionalInfosFailureActionCreator,
  fetchTransfosByClientBeginActionCreator,
  fetchTransfosByClientSuccessActionCreator,
  fetchTransfosByClientFailureActionCreator,
} from "./actionCreators";
import {
  deleteClientApiCall,
  createClientApiCall,
  replicateClientApiCall,
  fetchAllClientsApiCall,
  fetchClientApiCall,
  updateClientApiCall,
  setClientLiveApiCall,
  archiveClientApiCall,
  restoreClientApiCall,
  uploadFileApiCall,
  getDBAccessCall,
  TCreateClientResponse,
  TReplicateClientResponse,
  TFetchClientResponse,
  TFetchAllClientsResponse,
  TUploadFileResponse,
  getStripeSessionLinkApiCall,
  fetchAdditionalInfosApiCall,
} from "./api";
import apiService from "../../../api/api";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../actionCreators/ajaxActionCreator";
import { createNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationTypes from "../../notifications/actionTypes";
import * as notificationLevels from "../../notifications/constants";
import {
  clientsByIdSelector,
  getIsFetchingAllClients,
  getSelectedClient,
} from "./selectors";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../actions/appActions";
import { defaultProfile } from "model/application/ActionCode";
import { clearListDataAction } from "../../lists/redux/actionCreators";
import { clearDashboardDataAction } from "../../dashboards/redux/actionCreators";
import { clearTeamDataAction } from "../../teams/redux/actionCreators";
import { fetchTeamsForClientApiCall } from "../../teams/redux/api";
import { clearMobileUserDataAction } from "../../usermgt/redux/actionCreator/mobileUserActionCreator";
import { clearWebUserDataActionCreator } from "../../usermgt/redux/actionCreator/webUserActionCreator";
import { clearWorkflowDataAction } from "../../workflows/redux/actionCreators";
import { getLang, getSignedInUser } from "../../authentication/redux/selector";
import * as lang from "../../../lang";
import {
  getLangObject,
  getSuccessNotificationMessage,
} from "../../../lang/utils";
import { IClient } from "model/entities/Client";
import { ITransformation } from "model/entities/Dashboard";
import { IDispatchAndGetState } from "store/model";
import ITeam from "model/entities/Team";
import { Dispatch } from "redux";
import { prepareClientForFrontend } from "./utils";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IReplicateClientAttributes } from "../components/modals/ReplicateClientModal";
import { fetchTransformationsApiCall } from "containers/environments/redux/transformations/api";

/**
 * Create Client Action dispatches action creators to redux store and makes api calls to create a license
 * @param {String} name Name of Client
 * @param {String} description Description of client
 * @param {String} logo Logo image filename
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function createClientAction(
  newClient: IClient
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createClientBeginActionCreator());

    return new Promise(function (resolve, reject) {
      createClientApiCall(newClient)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TCreateClientResponse>(
              serverResponse
            );
          const { id, dbname } = data;
          const client = {
            ...newClient,
            id,
            dbname,
            use_dedicated_db: true,
            access_right_profiles:
              (newClient as any).profiles &&
              (newClient as any).profiles.length !== 0
                ? (newClient as any).profiles
                : defaultProfile(),
            actif: true,
          };

          dispatch(ajaxSuccessAction());
          dispatch(createClientSuccessActionCreator(client));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.CLIENT,
                newClient.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateClientError",
            error,
            createClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Fetch a single Client Action. This dispatches action creators to redux store and makes api calls to
 * fetch a single license by id
 * @param {String} id Id of the Client to fetch
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function fetchClientAction(id: string): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchClientBeginActionCreator());

    return fetchClientApiCall(id)
      .then((serverResponse) => {
        const data =
          extractDataAndCheckErrorStatus<TFetchClientResponse>(serverResponse);
        const { client } = data;
        const formattedClient = prepareClientForFrontend(client);
        dispatch(ajaxSuccessAction());
        apiService.setClientId(client.id);
        dispatch(fetchClientSuccessActionCreator(formattedClient));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchClientError",
          error,
          fetchClientFailureActionCreator,
          currLang
        );
      });
  };
}
/**
 * Replicate Client Action dispatches action creators to redux store and makes api call to duplicate client
 * @param {String} id Id of Client to be replicated
 * @param {String} name Name of client to be created
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function replicateClientAction(
  oldClient: IClient,
  attributes: IReplicateClientAttributes
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(replicateClientBeginActionCreator());
    return new Promise(function (resolve, reject) {
      replicateClientApiCall(oldClient.id, attributes)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TReplicateClientResponse>(
              serverResponse
            );
          const { id, dbname } = data;
          const client = {
            ...oldClient,
            id,
            dbname,
            name: attributes.new_client_name,
            use_dedicated_db: true,
            access_right_profiles:
              (oldClient as any).profiles &&
              (oldClient as any).profiles.length !== 0
                ? (oldClient as any).profiles
                : defaultProfile(),
            active: true,
          };
          dispatch(ajaxSuccessAction());
          dispatch(replicateClientSuccessActionCreator(client));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.REPLICATE,
                SUB_CATEGORIES.CLIENT,
                oldClient.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "ReplicateClientError",
            error,
            replicateClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}
export function reloadIfNeededAction(
  selectId: string
): IDispatchAndGetState<IClient[]> {
  return (dispatch, getState) => {
    const signedInUser = getSignedInUser(getState());
    const selectedClient = getSelectedClient(getState());
    const isFetchingClients = getIsFetchingAllClients(getState());
    let realSelectedClient = signedInUser.isAuthenticated
      ? selectedClient && selectedClient.access_right_profiles
        ? selectedClient
        : null
      : null;
    if (!realSelectedClient && isFetchingClients) {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(fetchAllClientsBeginActionCreator());
      // clear all the persistant data from the store
      dispatch(clearListDataAction());
      dispatch(clearDashboardDataAction());
      dispatch(clearTeamDataAction());
      dispatch(clearMobileUserDataAction());
      dispatch(clearWebUserDataActionCreator());
      dispatch(clearWorkflowDataAction());

      return new Promise(function (resolve, reject) {
        fetchAllClientsApiCall()
          .then((serverResponse) => {
            const data =
              extractDataAndCheckErrorStatus<TFetchAllClientsResponse>(
                serverResponse
              );
            const { clients } = data;
            const formattedClients = clients.map((c) =>
              prepareClientForFrontend(c)
            );
            dispatch(ajaxSuccessAction());
            dispatch(
              fetchAllClientsSuccessActionCreator(formattedClients, selectId)
            );
            resolve(formattedClients);
          })
          .catch((error) => {
            treatErrorNotification(
              dispatch,
              "FetchAllClientsError",
              error,
              fetchAllClientsFailureActionCreator,
              currLang
            );
            reject(error);
          });
      });
    }
    return new Promise(function (resolve, reject) {});
  };
}

/**
 * Fetch all Clients Action dispatches action creators to redux store and makes api calls to fetch all
 * client accounts
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function fetchAllClientsAction(
  selectId?: string
): IDispatchAndGetState<IClient[]> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchAllClientsBeginActionCreator());
    // clear all the persistant data from the store
    dispatch(clearListDataAction());
    dispatch(clearDashboardDataAction());
    dispatch(clearTeamDataAction());
    dispatch(clearMobileUserDataAction());
    dispatch(clearWebUserDataActionCreator());
    dispatch(clearWorkflowDataAction());

    return new Promise(function (resolve, reject) {
      fetchAllClientsApiCall()
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TFetchAllClientsResponse>(
              serverResponse
            );
          const { clients } = data;
          const formattedClients = clients.map((c) =>
            prepareClientForFrontend(c)
          );
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllClientsSuccessActionCreator(formattedClients, selectId)
          );
          resolve(formattedClients);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllClientsError",
            error,
            fetchAllClientsFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

export function fetchTeamsByClient(
  idClient: string
): IDispatchAndGetState<ITeam[]> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchTeamsByClientBeginActionCreator());

    return new Promise(function (resolve, reject) {
      fetchTeamsForClientApiCall(idClient)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus<any>(serverResponse);
          const { teams } = data;

          dispatch(ajaxSuccessAction());
          dispatch(fetchTeamsByClientSuccessActionCreator(teams));
          resolve(teams);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchTeamsByClientError",
            error,
            fetchTeamsByClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}
export function fetchTransfosByClient(
  idClient: string
): IDispatchAndGetState<ITransformation[]> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchTransfosByClientBeginActionCreator());

    return new Promise(function (resolve, reject) {
      fetchTransformationsApiCall(idClient)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus<any>(serverResponse);
          const { transformations } = data;

          dispatch(ajaxSuccessAction());
          dispatch(fetchTransfosByClientSuccessActionCreator(transformations));
          resolve(transformations);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchTransfosByClientError",
            error,
            fetchTransfosByClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Updates a given Client Action dispatches action creators to redux store and makes api calls to fetch all license
 * @param {String} id Id of client to update
 * @param {Object} updatedClient details of the updated client
 * */
export function updateClientAction(
  id: string,
  updatedClient: IClient
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateClientBeginActionCreator());

    const client = clientsByIdSelector(getState())[id];

    return new Promise(function (resolve, reject) {
      updateClientApiCall(id, updatedClient)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          const clientForReducer = {
            ...client,
            ...updatedClient,
          };

          dispatch(ajaxSuccessAction());
          dispatch(updateClientSuccessActionCreator(clientForReducer));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT,
                clientForReducer.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateClientError",
            error,
            updateClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Updates a given Client Action dispatches action creators to redux store and makes api calls to fetch all license
 * @param {String} id Id of client to update
 * */
export function setClientLiveAction(id: string): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(setClientLiveBeginActionCreator());

    const client = clientsByIdSelector(getState())[id];

    return new Promise(function (resolve, reject) {
      setClientLiveApiCall(id)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          const status: "live" | "pilot" | undefined = "live";
          const clientForReducer = {
            ...client,
            status,
          };

          dispatch(ajaxSuccessAction());
          dispatch(setClientLiveSuccessActionCreator(clientForReducer));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT,
                clientForReducer.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "SetClientLiveError",
            error,
            setClientLiveFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Delete Client Action dispatches action creators to redux store and makes api calls to delete a client by id
 * @param {String} id Client id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function deleteClientAction(
  id: string,
  name: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteClientBeginActionCreator());

    return deleteClientApiCall(id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteClientSuccessActionCreator(id));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.CLIENT,
              name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteClientError",
          error,
          deleteClientFailureActionCreator,
          currLang
        );
      });
  };
}

/**
 * Archive client action
 * @param {String} clientId
 * @returns {Function}
 */
export function archiveClientAction(
  clientId: string,
  name: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveClientBeginActionCreator());
    return archiveClientApiCall(clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveClientSuccessActionCreator(clientId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.CLIENT,
              name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveClientError",
          error,
          archiveClientFailureActionCreator,
          currLang
        );
      });
  };
}

/**
 * Restore client action
 * @param {String} clientId
 * @returns {Function}
 */
export function restoreClientAction(
  clientId: string,
  name: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreClientBeginActionCreator());
    return restoreClientApiCall(clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreClientSuccessActionCreator(clientId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.CLIENT,
              name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreClientError",
          error,
          restoreClientFailureActionCreator,
          currLang
        );
      });
  };
}

export function uploadFileAction(
  file: any,
  fileName: string
): IDispatchAndGetState<{ filename: string; url: string }[]> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginActionCreator());

    return new Promise((resolve, reject) => {
      uploadFileApiCall(file, fileName)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TUploadFileResponse>(serverResponse);
          const { urls } = data;
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessActionCreator(data as any));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.CLIENT
              )
            )
          );
          resolve(urls);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Get the username and password for each databases
 * @param {String} clientId the id of the client for which we want to get the postgres db readonly user
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function getDBAccessAction(clientId: string): IDispatchAndGetState<any> {
  return (dispatch) => {
    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      getDBAccessCall(clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

/**
 * Select client action for dispatching action creator to select a current client from Clients
 * Table. This is used to fetch Licenses (Mobile and Web) for the given client
 * @param {String} id Client id
 * @returns {Function} Redux Thunk function with dispatch and getState
 */
export function selectClientAction(id: string): any {
  return (dispatch: Dispatch, getState: any) => {
    const client = clientsByIdSelector(getState())[id];
    dispatch(selectClientActionCreator(client));
  };
}
export type IFetchStripeSessionLinkActionFunc =
  () => IDispatchAndGetState<String>;
/**
 * Action to fetch stripe customer session link
 * @returns {Function}
 */
export const fetchStripeSessionLinkAction: IFetchStripeSessionLinkActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      const client = getSelectedClient(getState());
      const clientId = client.id;
      dispatch(ajaxRequestAction());
      dispatch(fetchStripeSessionLinkBeginActionCreator());
      return new Promise((res, rej) => {
        getStripeSessionLinkApiCall(clientId)
          .then((serverResponse) => {
            const data = extractDataAndCheckErrorStatus(serverResponse);
            dispatch(ajaxSuccessAction());
            dispatch(
              fetchStripeSessionLinkSuccessActionCreator(data.session_link)
            );
            res(data.session_link);
          })
          .catch((error) => {
            treatErrorNotification(
              dispatch,
              "FetchStripeSessionLinkError",
              error,
              fetchStripeSessionLinkFailureActionCreator,
              currLang
            );
          });
      });
    };
  };

export type IFetchAdditionalInfosActionFunc = (
  clientId?: string
) => IDispatchAndGetState<String>;

export const fetchAdditionalInfosAction: IFetchAdditionalInfosActionFunc = (
  clientId
) => {
  return (dispatch, getState) => {
    const currLang = getLangObject();
    dispatch(ajaxRequestAction());
    dispatch(fetchAdditionalInfosBeginActionCreator());
    return new Promise((res, rej) => {
      fetchAdditionalInfosApiCall(clientId || getSelectedClient(getState()).id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          let { owners, dashboards, documents } = data;
          owners = owners.map((o: any) => {
            return {
              ...o,
              first_name: o.name,
              id: o.email,
            };
          });
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAdditionalInfosSuccessActionCreator(
              owners,
              dashboards,
              documents
            )
          );
          res({ owners, dashboards, documents } as any);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAdditionalInfosActionError",
            error,
            fetchAdditionalInfosFailureActionCreator,
            currLang
          );
        });
    });
  };
};
