import {
  createWorkflowApiCall,
  fetchAllWorkflowsApiCall,
  deleteWorkflowApiCall,
  updateWorkflowApiCall,
  archiveWorkflowApiCall,
  restoreWorkflowApiCall,
  fetchSubmissionsApiCall,
  fetchSubmissionDetailApiCall,
  updateSubmissionApiCall,
  uploadFileApiCall,
  fetchWorkflowVersionsApiCall,
  restoreOldWorkflowApiCall,
  IUploadFileForWorkflowResponse,
  deleteSubmissionApiCall,
} from "./api";
import {
  createWorkflowBeginActionCreator,
  createWorkflowFailureActionCreator,
  createWorkflowSuccessActionCreator,
  fetchAllWorkflowsBeginActionCreator,
  fetchAllWorkflowsFailureActionCreator,
  fetchAllWorkflowsSuccessActionCreator,
  updateWorkflowBeginActionCreator,
  updateWorkflowFailureActionCreator,
  updateWorkflowSuccessActionCreator,
  deleteWorkflowBeginActionCreator,
  deleteWorkflowFailureActionCreator,
  deleteWorkflowSuccessActionCreator,
  archiveWorkflowBeginActionCreator,
  archiveWorkflowFailureActionCreator,
  archiveWorkflowSuccessActionCreator,
  restoreWorkflowBeginActionCreator,
  restoreWorkflowFailureActionCreator,
  restoreWorkflowSuccessActionCreator,
  updateSubmissionBeginActionCreator,
  updateSubmissionSuccessActionCreator,
  updateSubmissionFailureActionCreator,
  fetchSubmissionBeginActionCreator,
  fetchSubmissionSuccessActionCreator,
  fetchSubmissionFailureActionCreator,
  resetSubmissionSelectionAction,
  uploadFileBeginAction,
  uploadFileSuccessAction,
  fetchWorkflowVersionsBeginAction,
  fetchWorkflowVersionsSuccessAction,
  fetchWorkflowVersionsFailureAction,
  restoreOldWorkflowSuccessAction,
  restoreOldWorkflowFailureAction,
  restoreOldWorkflowBeginAction,
  changeSubcategorySuccessActionCreator,
  fetchSubmissionsBeginActionCreator,
  fetchSubmissionsSuccessActionCreator,
  fetchSubmissionsFailureActionCreator,
  deleteSubmissionBeginActionCreator,
  deleteSubmissionSuccessActionCreator,
  deleteSubmissionFailureActionCreator,
} from "./actionCreators";
import {
  createListSuccessAction,
  updateListSuccessAction,
  uploadFileFailureAction,
} from "../../lists/redux/actionCreators";
import { removeWorkflowsFromAllTeamsSuccessAction } from "../../teams/redux/actionCreators";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../actionCreators/ajaxActionCreator";
import { createNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationTypes from "../../notifications/actionTypes";
import * as notificationLevels from "../../notifications/constants";
import {
  getSelectedClient,
  clientsByIdSelector,
} from "../../clients/redux/selectors";
import { allListsSelector } from "../../lists/redux/selectors";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../actions/appActions";
import { getLang } from "../../authentication/redux/selector";
import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import { IDispatchAndGetState } from "store/model";
import { IWorkflowGroup } from "model/entities/Workflow";
import { Dispatch } from "redux";
import { prepareListsForFrontend } from "containers/lists/redux/utils";
import { prepareWorkflowsForFrontend } from "containers/workflows/redux/utils";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";

/**
 * Action to create a workflow with the given name and payload. The payload is a parsed JSON
 * with the question teams
 * @param {Object} primary Primary workflow to create. Can be either a normal workflow, or a generator (in that case, there is a secondary workflow also)
 * @param {Object} secondary Secondary workflow to create. It is the task workflow attached to the generator
 * @param {Object} link The object used for the setup between the primary and the secondary workflows.
 * @param {Array} files Array of files details with the url returned from the backend
 * @returns {Function}
 */
export const createWorkflowAction: ICreateWorkflowActionFunc = (
  workflowGroup,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const selectedClient = getSelectedClient(getState());
    const client = clientsByIdSelector(getState())[selectedClient.id];
    const client_id = client.id;

    dispatch(ajaxRequestAction());
    dispatch(createWorkflowBeginActionCreator());
    return createWorkflowApiCall(workflowGroup, client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const {
          primary_integrations,
          secondary_integrations,
          primary_id,
          secondary_id,
          task_list,
        } = data;

        if (secondary_id) {
          workflowGroup.primary.workflow_task_id = secondary_id;
          workflowGroup.primary.list_task_id = task_list.id;
        }
        if (primary_integrations) {
          workflowGroup.primary.integrations = primary_integrations;
        }
        if (workflowGroup.secondary && secondary_integrations) {
          workflowGroup.secondary.integrations = secondary_integrations;
        }
        dispatch(ajaxSuccessAction());
        workflowGroup.primary.id = primary_id;
        if (workflowGroup.secondary) workflowGroup.secondary.id = secondary_id;
        dispatch(createWorkflowSuccessActionCreator(workflowGroup, files));
        if (task_list)
          dispatch(
            createListSuccessAction(
              client_id,
              prepareListsForFrontend([task_list])[0]
            )
          );
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.WORKFLOW,
              workflowGroup.primary.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateWorkflowError",
          error,
          createWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch workflows by client id
 * @param {String} clientId client id where we want to fetch the workflows from
 * @returns {Function}
 */
export const fetchWorkflowsByClientAction: IFetchAllWorkflowsActionFunc = (
  clientId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;
    dispatch(ajaxRequestAction());
    dispatch(fetchAllWorkflowsBeginActionCreator());

    return fetchAllWorkflowsApiCall(client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { workflows } = data;
        dispatch(ajaxSuccessAction());
        dispatch(
          fetchAllWorkflowsSuccessActionCreator(
            prepareWorkflowsForFrontend(workflows)
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchWorkflowsByClientError",
          error,
          fetchAllWorkflowsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update workflow
 * @param {Object} primary Primary workflow to create. Can be either a normal workflow, or a generator (in that case, there is a secondary workflow also)
 * @param {Object} secondary Secondary workflow to create. It is the task workflow attached to the generator
 * @param {Object} link The object used for the setup between the primary and the secondary workflows.
 * @param {Array} files Array of files details with the url returned from the backend
 * @returns {Function}
 */
export const updateWorkflowAction: IUpdateWorkflowActionFunc = (
  workflowGroup,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const selectedClient = getSelectedClient(getState());
    const lists = allListsSelector(getState());
    const client = clientsByIdSelector(getState())[selectedClient.id];
    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(updateWorkflowBeginActionCreator());

    return updateWorkflowApiCall(workflowGroup, clientId)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const {
          task_list,
          secondary_id,
          primary_integrations,
          secondary_integrations,
        } = data;
        dispatch(ajaxSuccessAction());
        workflowGroup.primary.integrations = primary_integrations;
        if (workflowGroup.secondary) {
          workflowGroup.primary.list_task_id = task_list.id;
          workflowGroup.primary.workflow_task_id = secondary_id;
          workflowGroup.secondary.id = secondary_id;
          workflowGroup.secondary.integrations = secondary_integrations;
          if (lists.find((l) => l.id === task_list.id)) {
            dispatch(
              updateListSuccessAction(prepareListsForFrontend([task_list])[0])
            );
          } else {
            dispatch(
              createListSuccessAction(
                clientId,
                prepareListsForFrontend([task_list])[0]
              )
            );
          }
        }
        dispatch(updateWorkflowSuccessActionCreator(workflowGroup, files));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.WORKFLOW,
              workflowGroup.primary.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateWorkflowError",
          error,
          updateWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete workflow action
 * @param {String} workflowId,ju2sqlgln6se
 * @param {String} workflowAttachedId
 * @returns {Function}
 */
export const deleteWorkflowAction: IDeleteWorkflowActionFunc = (
  workflowId,
  workflowAttachedId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteWorkflowBeginActionCreator());
    const selectedClient = getSelectedClient(getState());
    const client = clientsByIdSelector(getState())[selectedClient.id];
    const clientId = client.id;
    return deleteWorkflowApiCall(workflowId, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          removeWorkflowsFromAllTeamsSuccessAction(clientId, [workflowId])
        );
        dispatch(
          deleteWorkflowSuccessActionCreator(workflowId, workflowAttachedId)
        );
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.WORKFLOW,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteWorkflowError",
          error,
          deleteWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Archive workflow action
 * @param {String} workflowId
 * @param {String} workflowAttachedId (Optionnal)
 * @returns {Function}
 */
export const archiveWorkflowAction: IArchiveWorkflowActionFunc = (
  workflowId,
  workflowAttachedId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveWorkflowBeginActionCreator());
    const selectedClient = getSelectedClient(getState());
    const client = clientsByIdSelector(getState())[selectedClient.id];
    const clientId = client.id;
    return archiveWorkflowApiCall(workflowId, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          removeWorkflowsFromAllTeamsSuccessAction(clientId, [workflowId])
        );
        dispatch(
          archiveWorkflowSuccessActionCreator(workflowId, workflowAttachedId)
        );
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.WORKFLOW,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveWorkflowError",
          error,
          archiveWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Restore workflow action
 * @param {String} workflowId
 * @returns {Function}
 */
export const restoreWorkflowAction: IRestoreWorkflowActionFunc = (
  workflowId,
  workflowAttachedId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreWorkflowBeginActionCreator());
    const client = getSelectedClient(getState());
    const clientId = client.id;
    return restoreWorkflowApiCall(workflowId, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          restoreWorkflowSuccessActionCreator(workflowId, workflowAttachedId)
        );
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.WORKFLOW,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreWorkflowError",
          error,
          restoreWorkflowFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch submissions
 * @param {String} workflowId Id of the workflow where we want the submissions
 * @param {String} submissionId Id of the submission where we want to fetch
 * @returns {Function}
 */
export const fetchSubmissionDetailAction: IFetchSubmissionDetailActionFunc = (
  workflowId,
  submissionId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(fetchSubmissionBeginActionCreator());

    return new Promise((res, rej) => {
      fetchSubmissionDetailApiCall(workflowId, submissionId, clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(fetchSubmissionSuccessActionCreator(data.submission));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.FETCH,
                SUB_CATEGORIES.SUBMISSION,
                data.submission._id,
                true
              )
            )
          );
          res(data.submission);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchSubmissionError",
            error,
            fetchSubmissionFailureActionCreator,
            currLang
          );
          rej(error);
        });
    });
  };
};

/**
 * Action to fetch submissions
 * @param {String} workflowId Id of the workflow where we want the submissions
 * @param {Timestamp} startAt Starting date for the submissions
 * @param {Timestamp} endAt Ending date for the submissions
 * @returns {Function}
 */
export const fetchSubmissionsAction: IFetchSubmissionsActionFunc = (
  workflowId,
  filters
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(fetchSubmissionsBeginActionCreator());

    return fetchSubmissionsApiCall(
      workflowId,
      filters,
      client.meta_hierarchy_dependencies,
      clientId
    )
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(fetchSubmissionsSuccessActionCreator(data.submissions));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.FETCH,
              SUB_CATEGORIES.SUBMISSION,
              data.submissions.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchSubmissionError",
          error,
          fetchSubmissionsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch submissions
 * @param {String} workflowId Id of the workflow where we want the submissions
 * @param {String} submissionId Id of the submission where we want to fetch
 * @returns {Function}
 */
export const deleteSubmissionAction: IDeleteSubmissionActionFunc = (
  workflowId,
  submissionId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(deleteSubmissionBeginActionCreator());

    return new Promise((res, rej) => {
      deleteSubmissionApiCall(workflowId, submissionId, clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(deleteSubmissionSuccessActionCreator(submissionId));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.DELETE,
                SUB_CATEGORIES.SUBMISSION,
                submissionId,
                true
              )
            )
          );
          res(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "DeleteSubmissionError",
            error,
            deleteSubmissionFailureActionCreator,
            currLang
          );
          rej(error);
        });
    });
  };
};

export function resetSubmissions() {
  return (dispatch: Dispatch) => {
    dispatch(resetSubmissionSelectionAction());
  };
}

/**
 * Update submission
 * @param {Object} submission New values for the submission
 * @returns {Function}
 */
export const updateSubmissionAction: IUpdateSubmissionActionFunc = (
  submission
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const selectedClient = getSelectedClient(getState());
    const client = clientsByIdSelector(getState())[selectedClient.id];
    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(updateSubmissionBeginActionCreator());

    return updateSubmissionApiCall(
      submission,
      submission._workflow_id,
      clientId
    )
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateSubmissionSuccessActionCreator(submission));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.SUBMISSION,
              submission._id
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateSubmissionError",
          error,
          updateSubmissionFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Upload file
 * @param {Array} files Array of files ({file:Object,fileName:String,workflowId:String})
 * @returns {Function}
 */
export const uploadFileAction: IUploadFileActionFunc = (files) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginAction());

    return new Promise((res, rej) => {
      uploadFileApiCall(files)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessAction());
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.PICTURE,
                files.length,
                true
              )
            )
          );
          res(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureAction,
            currLang
          );
          rej(Error("Something went wrong"));
        });
    });
  };
};

export const fetchWorkflowVersionsAction: IFetchWorkflowVersionsActionFunc = (
  workflowId
) => {
  return (dispatch, getState) => {
    const client = getSelectedClient(getState());
    const currLang = lang[getLang(getState())];

    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(fetchWorkflowVersionsBeginAction());
    return fetchWorkflowVersionsApiCall(workflowId, clientId)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(fetchWorkflowVersionsSuccessAction(data.versions));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchWorkflowVersions",
          error,
          fetchWorkflowVersionsFailureAction,
          currLang
        );
      });
  };
};

export const restoreWorkflowVersionAction: IRestoreWorkflowVersionActionFunc = (
  workflowId,
  versionId
) => {
  return (dispatch, getState) => {
    const client = getSelectedClient(getState());
    const currLang = lang[getLang(getState())];

    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(restoreOldWorkflowBeginAction());
    return restoreOldWorkflowApiCall(clientId, workflowId, versionId)
      .then((serverResponse) => {
        const { primary, secondary } =
          extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          restoreOldWorkflowSuccessAction(
            prepareWorkflowsForFrontend([primary])[0],
            secondary ? prepareWorkflowsForFrontend([secondary])[0] : undefined
          )
        );
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.WORKFLOW,
              primary.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreWorkflowVersion",
          error,
          restoreOldWorkflowFailureAction,
          currLang
        );
      });
  };
};

export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory
) => {
  return (dispatch, getState) => {
    dispatch(changeSubcategorySuccessActionCreator(subcategory));
    return Promise.resolve();
  };
};

export type ICreateWorkflowActionFunc = (
  workflowGroup: IWorkflowGroup,
  files: any[]
) => IDispatchAndGetState<void>;
export type IUpdateWorkflowActionFunc = (
  workflow: IWorkflowGroup,
  files: any[]
) => IDispatchAndGetState<any>;
export type IFetchAllWorkflowsActionFunc = (
  clientId?: string
) => IDispatchAndGetState<void>;
export type IDeleteWorkflowActionFunc = (
  workflowId: string,
  workflowName: string
) => IDispatchAndGetState<void>;
export type IArchiveWorkflowActionFunc = (
  workflowId: string,
  workflowAttachedId: string
) => IDispatchAndGetState<void>;
export type IRestoreWorkflowActionFunc = (
  workflowId: string,
  workflowAttachedId: string
) => IDispatchAndGetState<void>;
export type IChangeSubcategoryActionFunc = (
  subcategory: string
) => IDispatchAndGetState<void>;
export type IRestoreWorkflowVersionActionFunc = (
  workflowId: string,
  versionId: string
) => IDispatchAndGetState<void>;
export type IFetchWorkflowVersionsActionFunc = (
  workflowId: string
) => IDispatchAndGetState<void>;
export type IUploadFileActionFunc = (
  files: any[]
) => IDispatchAndGetState<IUploadFileForWorkflowResponse>;
export type IFetchSubmissionsActionFunc = (
  workflowId: string,
  filters: any[]
) => IDispatchAndGetState<void>;
export type IFetchSubmissionDetailActionFunc = (
  workflowId: string,
  submissionId: string
) => IDispatchAndGetState<any>;
export type IUpdateSubmissionActionFunc = (
  submission: any
) => IDispatchAndGetState<void>;
export type IDeleteSubmissionActionFunc = (
  workflowId: string,
  submissionId: string
) => IDispatchAndGetState<any>;

export interface IWorkflowActions {
  createWorkflowAction: ICreateWorkflowActionFunc;
  fetchWorkflowsByClientAction: IFetchAllWorkflowsActionFunc;
  updateWorkflowAction: IUpdateWorkflowActionFunc;
  deleteWorkflowAction: IDeleteWorkflowActionFunc;
  archiveWorkflowAction: IArchiveWorkflowActionFunc;
  restoreWorkflowAction: IRestoreWorkflowActionFunc;
  changeSubcategoryAction: IChangeSubcategoryActionFunc;
}

const actions: IWorkflowActions = {
  createWorkflowAction,
  updateWorkflowAction,
  fetchWorkflowsByClientAction,
  deleteWorkflowAction,
  archiveWorkflowAction,
  restoreWorkflowAction,
  changeSubcategoryAction,
};

export default actions;
