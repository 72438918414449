import * as types from "./actionTypes";
import { IAction, IActionError, IBeginAction } from "store/model";
import { IDashboard } from "model/entities/Dashboard";
import { IDashboard as IBEDashboard } from "smala-tools/dist/src/types/dashboards";

export interface ICreateDashboardSuccessAction extends IAction {
  dashboard: IDashboard;
}
export interface IUpdateDashboardSuccessAction extends IAction {
  dashboard: IDashboard;
}
export interface IDeleteDashboardSuccessAction extends IAction {
  id: string;
}
export interface IArchiveDashboardsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreDashboardsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchDashboardsSuccessAction extends IAction {
  dashboards: IBEDashboard[];
}
export interface IComputeDashboardSuccessAction extends IAction {
  dashboardId: string;
  kpis: any[]; // FIXME replace "any" with a more precise type
  query: any;
}
export interface IClearDashboardDataSuccessAction extends IAction {}
export interface IChangeSubcategorySuccessAction extends IAction {
  subcategory: string;
}

/**
 * Create dashboard begin action creator
 * This creates an action informing redux store that the creation process for a dashboard has just began
 * @returns {Object}
 */
export function createDashboardBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_DASHBOARD_BEGIN,
  };
}

/**
 * Failure Action for creating a dashboard instructing that the creation process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createDashboardFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_DASHBOARD_FAILURE,
    error,
  };
}

/**
 * Success action for creating a dashboard
 * @param {Object} dashboard Dashboard object,
 * @returns {Object}
 */
export const createDashboardSuccessActionCreator = (
  dashboard: IDashboard
): ICreateDashboardSuccessAction => {
  return {
    type: types.CREATE_DASHBOARD_SUCCESS,
    dashboard,
  };
};

/**
 * Edit dashboard begin action creator
 * This creates an action informing redux store that the creation process for a dashboard has just began
 * @returns {Object}
 */
export function updateDashboardBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_DASHBOARD_BEGIN,
  };
}

/**
 * Failure Action for editing a dashboard instructing that the edition process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateDashboardFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_DASHBOARD_FAILURE,
    error,
  };
}

/**
 * Success action for editing a dashboard
 * @param {Object} dashboard Dashboard object,
 * @returns {Object}
 */
export function updateDashboardSuccessActionCreator(
  dashboard: IDashboard
): IUpdateDashboardSuccessAction {
  return {
    type: types.UPDATE_DASHBOARD_SUCCESS,
    dashboard,
  };
}

/**
 * Delete dashboard begin action creator
 * This creates an action informing redux store that the creation process for a dashboard has just began
 * @returns {Object}
 */
export function deleteDashboardBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_DASHBOARD_BEGIN,
  };
}

/**
 * Failure Action for creating a dashboard instructing that the creation process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteDashboardFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_DASHBOARD_FAILURE,
    error,
  };
}

/**
 * Success action for creating a dashboard
 * @param {Object} dashboardId Dashboard object,
 * @returns {Object}
 */
export function deleteDashboardSuccessActionCreator(
  dashboardId: string
): IDeleteDashboardSuccessAction {
  return {
    type: types.DELETE_DASHBOARD_SUCCESS,
    id: dashboardId,
  };
}

/**
 * Archive dashboard begin action creator
 * This creates an action informing redux store that the archive process for a dashboard has just began
 * @returns {Object}
 */
export function archiveDashboardsBeginActionCreator(): IBeginAction {
  return {
    type: types.ARCHIVE_DASHBOARDS_BEGIN,
  };
}

/**
 * Failure Action for creating a dashboard instructing that the archive process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function archiveDashboardsFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.ARCHIVE_DASHBOARDS_FAILURE,
    error,
  };
}

/**
 * Success action for archiving dashboards
 * @param {Array} dashboardIds Dashboard ids to archive,
 * @returns {Object}
 */
export function archiveDashboardsSuccessActionCreator(
  dashboardIds: string[]
): IArchiveDashboardsSuccessAction {
  return {
    type: types.ARCHIVE_DASHBOARDS_SUCCESS,
    ids: dashboardIds,
  };
}

/**
 * Restore dashboard begin action creator
 * This creates an action informing redux store that the restore process for a dashboard has just began
 * @returns {Object}
 */
export function restoreDashboardsBeginActionCreator(): IBeginAction {
  return {
    type: types.RESTORE_DASHBOARDS_BEGIN,
  };
}

/**
 * Failure Action for creating a dashboard instructing that the restore process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function restoreDashboardsFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.RESTORE_DASHBOARDS_FAILURE,
    error,
  };
}

/**
 * Success action for restoring dashboards
 * @param {Array} dashboardIds Dashboard ids to restore,
 * @returns {Object}
 */
export function restoreDashboardsSuccessActionCreator(
  dashboardIds: string[]
): IArchiveDashboardsSuccessAction {
  return {
    type: types.RESTORE_DASHBOARDS_SUCCESS,
    ids: dashboardIds,
  };
}

/**
 * begin action when there is a request to fetch all dashboards
 * @returns {Object}
 * */
export const fetchAllDashboardsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_DASHBOARDS_BEGIN,
});

/**
 * Failure action for fetching all dashboards. This action is dispatched when there is an error fetching all
 * dashboards
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllDashboardsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_DASHBOARDS_FAILURE,
  error,
});

/**
 * Fetch all Dashboards action creator
 * @param {Array} dashboards an array of dashboard objects
 * @returns {Object}
 * */
export const fetchAllDashboardsSuccessActionCreator = (
  dashboards: IBEDashboard[]
): IFetchDashboardsSuccessAction => ({
  type: types.FETCH_ALL_DASHBOARDS_SUCCESS,
  dashboards,
});

/**
 * Process dashboard begin action process
 * This process an action informing redux store that the  process for a dashboard has just began
 * @returns {Object}
 */
export function computeDashboardBeginActionCreator(): IBeginAction {
  return {
    type: types.COMPUTE_DASHBOARD_BEGIN,
  };
}

/**
 * Failure Action for processind a dashboard instructing that the run  process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function computeDashboardFailureActionCreator(error: any): IActionError {
  return {
    type: types.COMPUTE_DASHBOARD_FAILURE,
    error,
  };
}

/**
 * Success action for processing a dashboard
 * @param {Object} kpis kpis data,
 * @returns {Object}
 */
export function computeDashboardSuccessActionCreator(
  kpis: any[],
  dashboardId: string,
  query: any
): IComputeDashboardSuccessAction {
  return {
    type: types.COMPUTE_DASHBOARD_SUCCESS,
    kpis,
    dashboardId,
    query,
  };
}

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearDashboardDataAction(): IClearDashboardDataSuccessAction {
  return {
    type: types.CLEAR_DATA,
  };
}

/**
 * @param {Object} subcategory Can be either the summary or a dashboard
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: string
): IChangeSubcategorySuccessAction {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}
