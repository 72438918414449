/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import apiService from "../../../../api/api";
import { prepareTransformationForBackend } from "./utils";
import { ITransformation } from "model/entities/Dashboard";
import { IServerResponse } from "actions/appActions";
import { ITransformation as IBETransformation } from "smala-tools/dist/src/types";

export const TRANSFORMATIONS_ENDPOINT = `transformations`;
export const CLIENTS_ENDPOINT = `/clients`;

// TRANSFORMATIONS

export type TCreateTransformationFunc = (
  transformation: ITransformation
) => Promise<IServerResponse<ICreateTransformationResponse>>;
export interface ICreateTransformationResponse {
  id: string;
  integrations: any[];
}

export type TUpdateTransformationFunc = (
  transformation: ITransformation
) => Promise<IServerResponse<IUpdateTransformationResponse>>;
export interface IUpdateTransformationResponse {
  integrations: any[];
}

export type TDeleteTransformationFunc = (
  transformation_id: string
) => Promise<IServerResponse<IDeleteTransformationResponse>>;
export interface IDeleteTransformationResponse {}

export type TFetchTransformationsFunc = (
  client_id: string
) => Promise<IServerResponse<IFetchTransformationsResponse>>;
export interface IFetchTransformationsResponse {
  transformations: IBETransformation[];
}

/**
 * Creates a new transformation
 * @param {Object} transformation Transformation details
 */
export const createTransformationApiCall: TCreateTransformationFunc = (
  transformation
) => {
  const payload = prepareTransformationForBackend(transformation);
  return apiService.post(`${TRANSFORMATIONS_ENDPOINT}`, payload);
};

/**
 * Update a targetted transformation
 * @param {Object} transformation Detail of the new transformation
 */
export const updateTransformationApiCall: TUpdateTransformationFunc = (
  transformation
) => {
  const payload = prepareTransformationForBackend(transformation);
  return apiService.patch(
    `${TRANSFORMATIONS_ENDPOINT}/${transformation.id}`,
    payload
  );
};

/**
 * Delete transformation API call makes a POST request to delete a single transformation
 * @param {String} transformation Id of the transformation to delete
 * @return {Promise}
 * */
export const deleteTransformationApiCall: TDeleteTransformationFunc = (
  transformation_id
) => {
  return apiService.delete(
    `${TRANSFORMATIONS_ENDPOINT}/${transformation_id}`,
    {}
  );
};

/**
 * Api call to fetch all
 */
export const fetchTransformationsApiCall: TFetchTransformationsFunc = (
  clientId: string
) => {
  return apiService.get(
    `${CLIENTS_ENDPOINT}/${clientId}/${TRANSFORMATIONS_ENDPOINT}`
  );
};
