import { BUSINESS_ERROR } from "../model/application/Api";

class CustomBusinessError extends Error {
  status;
  errorStatus;
  name;
  value;
  isBusinessError = true;
  response;

  constructor(message, errorStatus, name, value) {
    super(message);
    this.status = 200;
    this.errorStatus = errorStatus;
    this.name = name ? name : "InternalError";
    if (value) this.value = value;
    this.response = {
      data: {
        data: {
          error: {
            message: value,
          },
        },
      },
    };
  }
}

export class PartialFailureBusinessError extends CustomBusinessError {
  constructor(message, value) {
    super(
      `Partial error: ${message}`,
      BUSINESS_ERROR.PARTIAL_SUCCESS,
      "PartialFailureError",
      value
    );
  }
}

export class NotFoundBusinessError extends CustomBusinessError {
  constructor(message, value) {
    super(
      `Not found error: ${message}`,
      BUSINESS_ERROR.NOT_FOUND,
      "NotFoundError",
      value
    );
  }
}

export class NotAllowedBusinessError extends CustomBusinessError {
  constructor(message, value) {
    super(
      `Not allowed error: ${message}`,
      BUSINESS_ERROR.NOT_ALLOWED,
      "NotAllowedError",
      value
    );
  }
}

export class AlreadyExistsBusinessError extends CustomBusinessError {
  constructor(message, value) {
    super(
      `Already exists error: ${message}`,
      BUSINESS_ERROR.ALREADY_EXISTS,
      "AlreadyExistsError",
      value
    );
  }
}

export class RunInBackgroundBusinessError extends CustomBusinessError {
  constructor() {
    super(
      `Run in background`,
      BUSINESS_ERROR.RUN_IN_BACKGROUND,
      "RunInBackgroundError",
      null
    );
  }
}
export class NotUpToDateBusinessError extends CustomBusinessError {
  constructor(message, value) {
    super(
      `Not up to date error: ${message}`,
      BUSINESS_ERROR.NOT_UP_TO_DATE,
      "NotUpToDateError",
      value
    );
  }
}

export class AuthentificationBusinessError extends CustomBusinessError {
  constructor(message, value) {
    super(
      `Authentication error: ${message}`,
      BUSINESS_ERROR.AUTHENTICATION,
      "AuthentificationError",
      value
    );
  }
}

export class QuotaExceededBusinessError extends CustomBusinessError {
  constructor(value) {
    super(
      `Quota exceeded. Try later.`,
      BUSINESS_ERROR.QUOTA_EXCEEDED,
      "QuotaExceededError",
      value
    );
  }
}

export class PayloadTooLargeBusinessError extends CustomBusinessError {
  constructor(value) {
    super(
      `Payload too large. Try to restrict your query.`,
      BUSINESS_ERROR.PAYLOAD_TOO_LARGE,
      "PayloadTooLargeBusinessError",
      value
    );
  }
}

export class TimeOutBusinessError extends CustomBusinessError {
  constructor(message, value) {
    super(
      `Timeout error: ${message}`,
      BUSINESS_ERROR.TIMEOUT,
      "TimeOutBusinessError",
      value
    );
  }
}
