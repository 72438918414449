/**
 * Creates a notification action creator. This will be dispatched to the Redux store and allow for notification
 * messaging. This is used to create notifications for new user additions (web/app)
 * @param {Object} user User Object
 * @return {Object} Object with type of action and the userName
 * */

import { IAction } from "store/model";

export interface INotification {
  id?: string;
  level: string;
  message: string;
  detailedMessage?: string;
}
export interface INotificationActionCreator extends IAction {
  notification: INotification;
}

/**
 * Creates a notification action
 * @param {String} notificationType Notification type
 * @param {String} message Message to display in notification
 * @param {String} level string telling the level of notification to create
 * @returns {Object}
 */
export function createNotificationActionCreator(
  notificationType: string,
  level: string,
  message: string,
  detailedMessage?: string
): INotificationActionCreator {
  return {
    type: notificationType,
    notification: {
      level,
      message,
      detailedMessage,
    },
  };
}
