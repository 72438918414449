import React, { Component, createRef } from "react";
import { object, arrayOf, func, string, array } from "prop-types";
import NavbarLinks from "./NavbarLinks";
import { getRoutes } from "../../config/routes";
import {
  Typography,
  Toolbar,
  AppBar,
  withStyles,
  Box,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import styles from "./styles";
import "react-perfect-scrollbar/dist/css/styles.css";
/**
 * Navbar stateless component
 */
export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarExists: true,
      color: "transparent",
    };

    this.sidebarToggle = createRef();
  }

  /**
   * Gets the active route from the Location object passed in
   * */
  getActiveRoute() {
    let activeRoute = "";
    const { location, actionsAvailable, lang } = this.props;
    const callback = (route) => {
      if (route.path === location.pathname) {
        activeRoute = route.title.toUpperCase();
      }
    };

    getRoutes(actionsAvailable, lang).forEach(callback);

    return activeRoute;
  }

  /**
   * Toggles the Navigation dropdown menu
   * */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      color: isOpen ? "transparent" : "white",
    });
    this.setState({
      isOpen: !isOpen,
    });
  };

  dropdownToggle = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        dropDownOpen: !prevState.dropDownOpen,
      };
    });
  };

  /**Handles the opening and closing of sidebar */
  handleOpenSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    // referencing from ref
    this.sidebarToggle.current &&
      this.sidebarToggle.current.classList.toggle("toggled");
  };

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    const { isOpen } = this.state;
    this.setState({
      color: window.innerWidth < 993 && isOpen ? "white" : "transparent",
    });
  };

  render() {
    const {
      classes,
      onLogoutClick,
      onOpenChangePasswordClick,
      onGetUserDBClick,
      notificationItems,
      onClientChange,
      onLangChange,
      clientOptions,
      selectedClient,
      refreshClient,
      selectedLanguage,
      privilege,
      appNotifications,
      showAppNotification,
      lang,
      signedInUser,
      onOpenStripePortalClick,
      actionsAvailable,
    } = this.props;

    return (
      <AppBar position="static" color="inherit" style={{ paddingLeft: "50px" }}>
        <Toolbar>
          <Box display={{ xs: "none", sm: "block" }}>
            <Typography variant="h5">{this.getActiveRoute()}</Typography>
            {this.getActiveRoute() === "HOME" ? (
              <Typography variant="subtitle1">
                App latest update:{" "}
                {process.env.REACT_APP_SMALA_VERSION
                  ? process.env.REACT_APP_SMALA_VERSION.split(
                      /(Release_staging_|Release_develop_|Release_preprod_|Release_master_)/
                    )[2]
                  : ""}
              </Typography>
            ) : null}
          </Box>
          <Box display={{ xs: "block", sm: "none" }}>
            <Menu onClick={this.handleOpenSidebar} />
          </Box>
          <div className={classes.grow} />
          <NavbarLinks
            privilege={privilege}
            notificationItems={notificationItems}
            handleLogout={onLogoutClick}
            handleOpenChangePassword={onOpenChangePasswordClick}
            handleGetUserDB={onGetUserDBClick}
            handleClientChange={onClientChange}
            handleLangChange={onLangChange}
            clientOptions={clientOptions}
            selectedClient={selectedClient}
            refreshClient={refreshClient}
            selectedLanguage={selectedLanguage}
            appNotifications={appNotifications}
            showAppNotification={showAppNotification}
            lang={lang}
            signedInUser={signedInUser}
            handleStripeSessionFetch={onOpenStripePortalClick}
            actionsAvailable={actionsAvailable}
          />
        </Toolbar>
      </AppBar>
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }

  /**
   * Is invoked immediately an update occurs on the DOM. In this case a check is made on the window's width
   * to check if it is less than the 993 threshold and check if the location pathnam has changed.
   * If so, toggle the class list on the sidebarToggle. This is using refs to make the update
   * @param {Object} prevProps Previous Props before the update
   * @param {Object} prevState Previous State before the update
   */
  componentDidUpdate(prevProps, prevState) {
    const { location, history } = prevProps;
    if (
      window.innerWidth < 993 &&
      history.location.pathname !== location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current &&
        this.sidebarToggle.current.classList.toggle("toggled");
    }
  }
}

/**
 * Prop validation
 */
Navbar.propTypes = {
  classes: object,
  privilege: string,
  location: object,
  history: object,
  notificationItems: arrayOf(object),
  clientOptions: arrayOf(object),
  onLogoutClick: func.isRequired,
  onOpenChangePasswordClick: func.isRequired,
  onGetUserDBClick: func,
  onClientChange: func,
  onLangChange: func,
  selectedClient: object,
  actionsAvailable: object,
  refreshClient: func,
  selectedLanguage: string,
  onOpenStripePortalClick: func,
  appNotifications: array,
  showAppNotification: func,
  lang: object,
  drawerItems: func,
  signedInUser: object,
};

export default withStyles(styles)(Navbar);
