import React from "react";
import { CircularProgress, Card, makeStyles } from "@material-ui/core";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

export const ContentLoader: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Card className={classes.customTableWrapper} style={{ height: "100%" }}>
      <div className={classes.LoadingTextContent}>Loading...</div>
      <div className={classes.contentLoaderWrapper}>
        <CircularProgress color={"inherit"} />
      </div>
    </Card>
  );
};

export default ContentLoader;
