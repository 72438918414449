import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import styles from "./styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import HelpIcon from "@material-ui/icons/HelpOutline";
import WarningIcon from "@material-ui/icons/ErrorOutline";
import MoreIcon from "@material-ui/icons/More";
import { Fab } from "@material-ui/core";

const useStyles = makeStyles(styles as any);

interface ICustomTooltipWrapperProps extends Omit<TooltipProps, "title"> {
  errorMessage?: string;
  title?: string;
  inCell?: boolean;
  cellOverflowed?: boolean;
}

export const CustomTooltipWrapper: React.FunctionComponent<ICustomTooltipWrapperProps> =
  ({ errorMessage, children, title, placement, inCell, cellOverflowed }) => {
    const classes = useStyles();

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const getContent = () => {
      return (
        <div>
          <div
            className={
              (title || (errorMessage && errorMessage.length)) &&
              !cellOverflowed
                ? classes.Content
                : ""
            }
          >
            {cellOverflowed ? (
              <div className={classes.CellContentOverflowed}>{children}</div>
            ) : (
              <div>{children}</div>
            )}
          </div>
          <div>
            <Fab
              className={
                inCell ? classes.TooltipButtonCell : classes.TooltipButton
              }
              onMouseEnter={(e) => {
                e.stopPropagation();
                setTooltipOpen(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setTooltipOpen(false);
              }}
              style={{ zIndex: 1 }}
            >
              {errorMessage && errorMessage.length && (
                <WarningIcon className={classes.TooltipButtonIconWithError} />
              )}
              {!inCell && title && (!errorMessage || !errorMessage.length) && (
                <HelpIcon className={classes.TooltipButtonIcon} />
              )}
              {inCell &&
                title &&
                cellOverflowed &&
                (!errorMessage || !errorMessage.length) && (
                  <MoreIcon className={classes.TooltipButtonIcon} />
                )}
            </Fab>
          </div>
        </div>
      );
    };

    return (
      <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
        <Tooltip
          classes={{ tooltip: classes.Tooltip }}
          title={
            <>
              {
                <div
                  dangerouslySetInnerHTML={{ __html: title as string }}
                ></div>
              }
              {<div className={classes.ErrorMessage}>{errorMessage}</div>}
            </>
          }
          placement={placement ? placement : "bottom-end"}
          /*         PopperProps={{
          disablePortal: true,
        }} */
          onMouseLeave={() => setTooltipOpen(false)}
          open={tooltipOpen}
          disableFocusListener
          disableTouchListener
        >
          {getContent()}
        </Tooltip>
      </ClickAwayListener>
    );
  };

export default CustomTooltipWrapper;
