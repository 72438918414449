import initialState from "./initialState";
import * as types from "./actionTypes";
import {
  insertNewItemToArr,
  convertArrayToObject,
  extractIdsFromArray,
  updateObjectInArray,
  removeObjectFromArray,
  removeObjectFromHash,
  removeItem,
  updateErrorsList,
} from "../../../utils/reducerUtils2";
import uniq from "lodash/uniq";
import moment from "moment";
import { DATE_FORMAT_FULL_DATE } from "../../../utils/constants";
import {
  IWorkflow,
  IWorkflowVersion,
  ISubmission,
} from "model/entities/Workflow";
import { IAction, IActionError } from "store/model";
import {
  IFetchAllWorkflowsSuccessActionCreator,
  ICreateWorkflowSuccessActionCreator,
  IUpdateWorkflowSuccessActionCreator,
  IDeleteWorkflowSuccessActionCreator,
  IArchiveWorkflowSuccessActionCreator,
  IRestoreWorkflowSuccessActionCreator,
  IFetchSubmissionSuccessActionCreator,
  IFetchSubmissionsSuccessActionCreator,
  IUpdateSubmissionSuccessActionCreator,
  IFetchWorkflowVersionsSuccessAction,
  IRestoreOldWorkflowSuccessAction,
  IChangeSubcategorySuccessActionCreator,
  IDeleteSubmissionSuccessActionCreator,
} from "containers/workflows/redux/actionCreators";

export interface WorkflowsState {
  subCategorySelected: string;
  isAttaching: boolean;
  isFetchingAll: boolean;
  isDeleting: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isFetchingSubmissionDetail: boolean;
  isFetchingSubmissions: boolean;
  isUpdatingSubmission: boolean;
  isUploadingFile: boolean;
  isFetchingWorkflowVersions: boolean;
  isRestoringWorkflowVersion: boolean;
  allWorkflows: IWorkflow[];
  workflowVersions: IWorkflowVersion[];
  selectedSubmission?: ISubmission;
  allSubmissions: ISubmission[];
  byId: { [id: string]: IWorkflow };
  allIds: string[];
  errors: any[];
  lastUpdated: Date;
}

/**
 * Reducer for workflows, handles updating the state of the store in regards to workflows object
 * This will have an initial state that will be updated on every action that is dispatched. if the action
 * is not handled by this reducer, then the initial state will be returned.
 * @param {Object} state Redux store state
 * @param {Object} action Action object
 */
export default function workflowReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_WORKFLOW_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_WORKFLOW_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.CREATE_WORKFLOW_SUCCESS: {
      const {
        workflowGroup: { primary, secondary },
        files,
      } = action as ICreateWorkflowSuccessActionCreator;

      // first replace the url in the workflows
      for (let file of files) {
        if (file.file.split("/")[2] === primary.picture_url) {
          primary.picture_url = file.url;
        } else if (
          secondary &&
          file.file.split("/")[2] === secondary.picture_url
        ) {
          secondary.picture_url = file.url;
        }
      }

      const newById = {
        ...state.byId,
        [primary.id]: primary,
      };
      if (secondary && secondary.id) newById[secondary.id] = secondary;
      let newAllWorkflows = insertNewItemToArr(state.allWorkflows, primary);
      if (secondary && secondary.id)
        newAllWorkflows = insertNewItemToArr(newAllWorkflows, secondary);
      const ids = extractIdsFromArray(newAllWorkflows);
      return {
        ...state,
        isCreating: false,
        allWorkflows: newAllWorkflows,
        byId: newById,
        allIds: uniq(state.allIds.concat(ids)),
        lastUpdated: Date.now(),
      };
    }

    case types.FETCH_ALL_WORKFLOWS_BEGIN:
      return {
        ...state,
        isFetchingAll: true,
        isUpdating: false,
        isArchiving: false,
        isCreating: false,
        isDeleting: false,
        isRestoring: false,
      };

    case types.FETCH_ALL_WORKFLOWS_FAILURE:
      return {
        ...state,
        isFetchingAll: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_ALL_WORKFLOWS_SUCCESS: {
      const { workflows } = action as IFetchAllWorkflowsSuccessActionCreator;
      const ids = extractIdsFromArray(workflows);
      return {
        ...state,
        isFetchingAll: false,
        allWorkflows: workflows,
        byId: convertArrayToObject({}, workflows, "id"),
        allIds: uniq(state.allIds.concat(ids)),
        lastUpdated: moment().format(DATE_FORMAT_FULL_DATE),
      };
    }

    case types.UPDATE_WORKFLOW_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_WORKFLOW_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.UPDATE_WORKFLOW_SUCCESS: {
      const {
        workflowGroup: { primary, secondary },
        files,
      } = action as IUpdateWorkflowSuccessActionCreator;

      // first replace the url in the workflows
      for (let file of files) {
        if (file.file.split("/")[2] === primary.picture_url) {
          primary.picture_url = file.url;
        } else if (
          secondary &&
          file.file.split("/")[2] === secondary.picture_url
        ) {
          secondary.picture_url = file.url;
        }
      }

      const newById = {
        ...state.byId,
        [primary.id]: primary,
      };
      let newAllWorkflows = updateObjectInArray(state.allWorkflows, primary);
      if (secondary && secondary.id) {
        newAllWorkflows = newAllWorkflows.find((w) => w.id === secondary.id)
          ? updateObjectInArray(newAllWorkflows, secondary)
          : insertNewItemToArr(newAllWorkflows, secondary);
        newById[secondary.id] = secondary;
      }

      return {
        ...state,
        isUpdating: false,
        allWorkflows: newAllWorkflows,
        byId: newById,
      };
    }

    case types.DELETE_WORKFLOW_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_WORKFLOW_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.DELETE_WORKFLOW_SUCCESS: {
      const {
        workflowId,
        workflowAttachedId,
      } = action as IDeleteWorkflowSuccessActionCreator;
      const workflow = state.allWorkflows.find((w) => w.id === workflowId);
      let allWorkflows = removeObjectFromArray(state.allWorkflows, workflowId);

      if (workflowAttachedId) {
        allWorkflows = removeObjectFromArray(allWorkflows, workflowAttachedId);
      }
      const byId = removeObjectFromHash(state.byId, workflowId);
      const allIds = removeItem(state.allIds, workflow);

      return {
        ...state,
        isDeleting: false,
        allWorkflows,
        byId,
        allIds,
      };
    }

    case types.ARCHIVE_WORKFLOW_BEGIN:
      return {
        ...state,
        isArchiving: true,
      };

    case types.ARCHIVE_WORKFLOW_FAILURE:
      return {
        ...state,
        isArchiving: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.ARCHIVE_WORKFLOW_SUCCESS: {
      const {
        workflowId,
        workflowAttachedId,
      } = action as IArchiveWorkflowSuccessActionCreator;
      let workflow = state.byId[workflowId];
      workflow.actif = false;

      let allWorkflows = updateObjectInArray(state.allWorkflows, workflow);
      let byId = {
        ...state.byId,
        [workflow.id]: workflow,
      };
      if (workflowAttachedId) {
        let workflowAttached = state.byId[workflowAttachedId];
        workflowAttached.actif = false;
        allWorkflows = updateObjectInArray(allWorkflows, workflowAttached);
        byId[workflowAttached.id] = workflowAttached;
      }

      return {
        ...state,
        isArchiving: false,
        allWorkflows,
        byId,
      };
    }

    case types.RESTORE_WORKFLOW_BEGIN:
      return {
        ...state,
        isRestoring: true,
      };

    case types.RESTORE_WORKFLOW_FAILURE:
      return {
        ...state,
        isRestoring: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.RESTORE_WORKFLOW_SUCCESS: {
      const {
        workflowId,
        workflowAttachedId,
      } = action as IRestoreWorkflowSuccessActionCreator;
      let workflow = state.byId[workflowId];
      workflow.actif = true;
      let allWorkflows = updateObjectInArray(state.allWorkflows, workflow);
      let byId = {
        ...state.byId,
        [workflow.id]: workflow,
      };

      if (workflowAttachedId) {
        let workflowAttached = state.byId[workflowAttachedId];
        workflowAttached.actif = true;
        allWorkflows = updateObjectInArray(allWorkflows, workflowAttached);
        byId[workflowAttached.id] = workflowAttached;
      }

      return {
        ...state,
        isRestoring: false,
        allWorkflows,
        byId,
      };
    }

    case types.FETCH_SUBMISSION_DETAIL_BEGIN:
      return {
        ...state,
        isFetchingSubmissionDetail: true,
      };

    case types.FETCH_SUBMISSION_DETAIL_FAILURE:
      return {
        ...state,
        isFetchingSubmissionDetail: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_SUBMISSION_DETAIL_SUCCESS: {
      const { submission } = action as any;
      return {
        ...state,
        selectedSubmission: submission,
        isFetchingSubmissionDetail: false,
      };
    }

    case types.DELETE_SUBMISSION_BEGIN:
      return {
        ...state,
        isDeletingSubmission: true,
      };

    case types.DELETE_SUBMISSION_FAILURE:
      return {
        ...state,
        isDeletingSubmission: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.DELETE_SUBMISSION_SUCCESS: {
      const { submissionId } = action as IDeleteSubmissionSuccessActionCreator;
      return {
        ...state,
        allSubmissions: state.allSubmissions.filter(
          (s) => s._id !== submissionId
        ),
        isDeletingSubmission: false,
      };
    }

    case types.FETCH_SUBMISSIONS_BEGIN:
      return {
        ...state,
        isFetchingSubmissions: true,
      };

    case types.FETCH_SUBMISSIONS_FAILURE:
      return {
        ...state,
        isFetchingSubmissions: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.FETCH_SUBMISSIONS_SUCCESS: {
      const { submissions } = action as IFetchSubmissionsSuccessActionCreator;

      return {
        ...state,
        isFetchingSubmissions: false,
        allSubmissions: submissions,
      };
    }

    case types.UPDATE_SUBMISSION_BEGIN:
      return {
        ...state,
        isUpdatingSubmission: true,
      };

    case types.UPDATE_SUBMISSION_FAILURE:
      return {
        ...state,
        isUpdatingSubmission: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.UPDATE_SUBMISSION_SUCCESS: {
      const { submission } = action as IUpdateSubmissionSuccessActionCreator;
      let { allSubmissions } = state;
      allSubmissions = allSubmissions.map((s) => {
        if (s._id === submission._id) {
          return submission;
        }
        return s;
      });

      return {
        ...state,
        isUpdatingSubmission: false,
        selectedSubmission: submission,
        allSubmissions,
      };
    }

    case types.RESET_SUBMISSION_SELECTION: {
      return {
        ...state,
        allSubmissions: [],
      };
    }

    case types.UPLOAD_FILE_BEGIN:
      return {
        ...state,
        isUploadingFile: true,
      };

    case types.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploadingFile: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isUploadingFile: false,
      };
    }

    case types.FETCH_WORKFLOW_VERSIONS_BEGIN: {
      return {
        ...state,
        isFetchingWorkflowVersions: true,
      };
    }

    case types.FETCH_WORKFLOW_VERSIONS_FAILURE: {
      return {
        ...state,
        isFetchingWorkflowVersions: false,
      };
    }

    case types.FETCH_WORKFLOW_VERSIONS_SUCCESS: {
      const { versions } = action as IFetchWorkflowVersionsSuccessAction;
      return {
        ...state,
        isFetchingWorkflowVersions: false,
        workflowVersions: versions,
      };
    }

    case types.RESTORE_OLD_WORKFLOWS_BEGIN: {
      return {
        ...state,
        isRestoringWorkflowVersion: true,
      };
    }

    case types.RESTORE_OLD_WORKFLOWS_FAILURE: {
      return {
        ...state,
        isRestoringWorkflowVersion: false,
      };
    }

    case types.RESTORE_OLD_WORKFLOWS_SUCCESS: {
      const { primary, secondary } = action as IRestoreOldWorkflowSuccessAction;

      const newById = {
        ...state.byId,
        [primary.id]: primary,
      };
      let newAllWorkflows = updateObjectInArray(state.allWorkflows, primary);
      if (secondary && secondary.id) {
        newAllWorkflows = newAllWorkflows.find((w) => w.id === secondary.id)
          ? updateObjectInArray(newAllWorkflows, secondary)
          : insertNewItemToArr(newAllWorkflows, secondary);
        newById[secondary.id] = secondary;
      }

      return {
        ...state,
        isRestoringWorkflowVersion: false,
        allWorkflows: newAllWorkflows,
        byId: newById,
      };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as IChangeSubcategorySuccessActionCreator;
      return {
        ...state,
        subCategorySelected: subcategory,
      };
    }

    default:
      return state;
  }
}
