import { IClient } from "model/entities/Client";
import {
  CLIENT_MODULE,
  IAccessRightProfile,
  IClient as IBEClient,
} from "smala-tools/dist/src/types";
import { clone } from "utils/utils";

const prepareClientForFrontend = (client: IBEClient): IClient => {
  let result: any = clone(client);

  // put meta_hierarchy_dependencies to the correct format if need be
  if (
    !client.meta_hierarchy_dependencies ||
    Array.isArray(client.meta_hierarchy_dependencies)
  ) {
    if (!result.levels) result.levels = [];
    result.meta_hierarchy_dependencies = {};
    (client.levels ? client.levels : []).forEach((e: string, idx: number) => {
      result.meta_hierarchy_dependencies[`level_${idx}`] = {
        level_type_name: e,
        level_type_number: (client.meta_hierarchy_dependencies as any[])
          ?.map((h) => h.level_name)
          .includes(e)
          ? idx
          : -1,
      };
    });
    result.hierarchy_dependencies = client.hierarchy_dependencies
      ? client.hierarchy_dependencies
      : [];
    result.hierarchy_dependencies = result.hierarchy_dependencies.map(
      (h: any) => ({
        level_type_number: h.level_number,
        level_name: h.group_name,
        level_father: h.group_father,
      })
    );
    delete result.levels;
  }
  if (!client.hierarchy_dependencies) result.hierarchy_dependencies = [];
  if (client.transformations_order)
    result.transformations_order = client.transformations_order.split(",");
  delete result.license_status; // FIXME: I don't know why there is this attribute, it should not be here...
  delete result.workflows; // FIXME: I don't know why there is this attribute, it should not be here...
  delete result.use_dedicated_db; // FIXME: no longer in use
  // add actif:true if no "actif" attribute
  if (!result.hasOwnProperty("actif")) {
    result["actif"] = true;
  }
  // add max download to 50 if this property is not defined
  if (!result.hasOwnProperty("max_downloads")) {
    result.max_downloads = 50;
  }
  // add modules property if this property is not defined
  if (!result.hasOwnProperty("modules")) {
    result.modules = [];
  }
  // add default value for modules
  if (!result.modules) {
    result.modules = [];
  }
  // change the name of the property "app_user_licenses" in "total_licenses"
  result.total_licenses = result.app_user_licenses;
  delete result.app_user_licenses;
  // flatten attributes for Geo log module config
  if (result.geo_check_in_config) {
    result.geo_check_in_config_computation_query =
      result.geo_check_in_config.computation_query;
    result.geo_check_in_config_computation_scale =
      result.geo_check_in_config.computation_scale;
    result.geo_check_in_config_last_visit_threshold =
      result.geo_check_in_config.last_visit_threshold;
    result.geo_check_in_config_optional_check_in =
      result.geo_check_in_config.optional_check_in;
  }
  delete result.geo_check_in_config;
  // flatten attributes for redshift configs
  if (result.redshift_connection) {
    result.redshift_host = result.redshift_connection.host;
    result.redshift_port = result.redshift_connection.port;
    result.redshift_user = result.redshift_connection.user;
    result.redshift_dbname = result.redshift_connection.dbname;
    result.redshift_password = result.redshift_connection.password;
  }
  delete result.redshift_connection;
  //flatten attributes for big query configs
  if (result.bigquery_connection) {
    result.bg_dataset_name = result.bigquery_connection.dataset_name;
    result.bg_client_email = result.bigquery_connection.client_email;
    result.bg_private_key = result.bigquery_connection.private_key;
  }
  delete result.bigquery_connection;
  // set values which are not set
  if (!result.teams_with_app_chat) result.teams_with_app_chat = [];
  if (!result.gps_tracking_days)
    result.gps_tracking_days = ["mo", "tu", "we", "th", "fr"];
  // remove all the "old" "level_x" attributes
  Object.keys(result).forEach((k) => {
    if (k.includes("level_")) delete result[k];
  });
  // clean dirty backend values
  delete result.levels;
  delete result.workflowsInClient;
  delete result.migration_hash;
  // clean other backend values we don't use in frontend
  delete result.archived_at;

  return result;
};

const prepareClientForBackend = (client: IClient): IBEClient => {
  const result: any = clone(client);

  // correct profiles
  result.access_right_profiles = prepareProfilesForBackend(
    result.access_right_profiles
  );

  // remove the attributes not asked by the backend
  delete result.id;
  result.app_user_licenses = result.total_licenses;
  delete result.total_licenses;
  delete result.use_dedicated_db;
  delete result.status;

  // remove the config not set
  if (result.time_slots_stop === "") delete result.time_slots_stop;
  if (result.time_slots_start === "") delete result.time_slots_start;
  if (result.support_api_key === "") delete result.support_api_key;
  if (result.max_downloads === "") delete result.max_downloads;
  if (result.logo === "") delete result.logo;
  if (result.old_sensible_data_password === "")
    delete result.old_sensible_data_password;
  if (result.new_sensible_data_password === "")
    delete result.new_sensible_data_password;
  if (result.transformations_order)
    result.transformations_order = result.transformations_order.join(",");
  if (!result.transformations_order) delete result.transformations_order;
  if (!result.operations_owner) delete result.operations_owner;
  if (!result.sales_owner) delete result.sales_owner;

  // put geo check in configs inside the geo_check_in_config object
  if (result.modules.includes(CLIENT_MODULE.GEO_CHECK_IN)) {
    result.geo_check_in_config = {
      computation_query: result.geo_check_in_config_computation_query
        ? result.geo_check_in_config_computation_query
        : "SELECT 1",
      computation_scale: result.geo_check_in_config_computation_scale
        ? result.geo_check_in_config_computation_scale
        : "LINEAR",
      last_visit_threshold: result.geo_check_in_config_last_visit_threshold
        ? result.geo_check_in_config_last_visit_threshold
        : 7,
      optional_check_in: result.geo_check_in_config_optional_check_in
        ? result.geo_check_in_config_optional_check_in
        : false,
    };
    result.geo_checkin_distance = result.geo_checkin_distance || 20;
  } else {
    delete result.geo_check_in_config;
    delete result.geo_checkin_distance;
  }
  delete result.geo_check_in_config_computation_query;
  delete result.geo_check_in_config_computation_scale;
  delete result.geo_check_in_config_last_visit_threshold;
  delete result.geo_check_in_config_optional_check_in;

  // remove the "active" property (it's a v3 property)
  delete result.active;

  // put redshift configs inside the redshift_connection object
  if (
    result.redshift_host &&
    result.redshift_port &&
    result.redshift_user &&
    result.redshift_password &&
    result.redshift_dbname
  ) {
    result.redshift_connection = {
      host: result.redshift_host,
      port: result.redshift_port,
      user: result.redshift_user,
      password: result.redshift_password,
      dbname: result.redshift_dbname,
    };
  }
  delete result.redshift_dbname;
  delete result.redshift_password;
  delete result.redshift_user;
  delete result.redshift_port;
  delete result.redshift_host;

  // put redshift configs inside the redshift_connection object
  /* result.bg_dataset_name = result.bigquery_connection.dataset_name;
    result.bg_client_email = result.bigquery_connection.client_email;
    result.bg_private_key = result.bigquery_connection.private_key; */
  if (
    result.bg_dataset_name &&
    result.bg_client_email &&
    result.bg_private_key
  ) {
    const cleanedKey = result.bg_private_key.replace(/\\n/g, "\n");
    result.bigquery_connection = {
      dataset_name: result.bg_dataset_name,
      client_email: result.bg_client_email,
      private_key: cleanedKey,
    };
  }
  delete result.bg_dataset_name;
  delete result.bg_client_email;
  delete result.bg_private_key;
  // remove quota download status attributes
  delete result.downloads_quota_period;
  delete result.downloads_count;
  // remove the hierarchy graph (it is another endpoint managing it)
  delete result.hierarchy_dependencies;
  // remove the profile attribute (is used only for displaying/hiding features on the frontend)
  delete result.profile;
  // delete the version
  delete result.version;

  return result;
};

const prepareProfilesForBackend = (
  profiles: IAccessRightProfile[]
): IAccessRightProfile[] => {
  // add "s" to all actions in profile (except the "FETCH" actions: should already be set correctly)
  profiles = profiles.map((p) => {
    //delete p.key;
    if (p.hasOwnProperty("lockChip")) delete p["lockChip"];
    Object.keys(p.actions).forEach((act) => {
      if (act[act.length - 1] !== "S" && !act.startsWith("FETCH")) {
        p.actions[`${act}S`] = p.actions[act];
      }
    });
    // add "Assign teams to mobile users" if "EDIT_TEAMS"
    if (p.actions["EDIT_TEAMS"]) {
      p.actions["ASSIGN_MOBILE_USERS_TO_TEAMS"] = true;
      p.actions["ASSIGN_WEB_USERS_TO_TEAMS"] = true;
    }
    delete p["index"];
    return p;
  });
  return profiles;
};

export { prepareClientForFrontend, prepareClientForBackend };
