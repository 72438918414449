import { ITableOptimizationState } from "./tableOptimizations/reducer";
import initialState from "./initialState";
import * as types from "./actionTypes";
import { ITransformationState } from "./transformations/reducer";
import { IEmailGenerationState } from "./emailGenerations/reducer";
import { IAction } from "store/model";
import { combineReducers } from "redux";
import emailGenerations from "./emailGenerations/reducer";
import transformations from "./transformations/reducer";
import streams from "./streams/reducer";
import dbSchema from "./dbSchema/reducer";
import tableOptimizations from "./tableOptimizations/reducer";
import { IDbSchemaState } from "./dbSchema/reducer";
import { IStreamState } from "./streams/reducer";

export interface IEnvironmentState {
  subCategorySelected:
    | "index"
    | "emailGeneration"
    | "transformation"
    | "stream"
    | "tableOptimization";
  transformations: ITransformationState;
  emailGenerations: IEmailGenerationState;
  dbSchema: IDbSchemaState;
  streams: IStreamState;
  tableOptimizations: ITableOptimizationState;
}

export function reducer(
  state = initialState.subCategorySelected,
  action: IAction
) {
  switch (action.type) {
    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as any;
      return subcategory;
    }
    default:
      return state;
  }
}

export default combineReducers({
  subCategorySelected: reducer,
  emailGenerations,
  transformations,
  streams,
  dbSchema,
  tableOptimizations,
});
