export enum ENTITIES {
  CLIENT = "clients",
  DASHBOARD = "dashboards",
  LIST = "lists",
  TEAM = "teams",
  USER = "users",
  WORKFLOW = "workflows",
  PICTURE = "pictures",
  DOCUMENT = "documents",
  ENVIRONMENT = "environments",
}

export enum SUB_CATEGORIES {
  CLIENT = "clients",
  DASHBOARD = "dashboards",
  LIST = "lists",
  ITEM = "items",
  TEAM = "teams",
  GROUP = "groups",
  MOBILE_USER = "mobileUsers",
  WEB_USER = "webUsers",
  WORKFLOW = "workflows",
  SUBMISSION = "submissions",
  PICTURE = "pictures",
  DOCUMENT = "documents",
  TRANSFORMATION = "transformations",
  STREAM = "streams",
  NOTIFICATION = "notifications",
  TABLEOPTIMIZATION = "tableOptimizations",
}

// all the custom actions where cannot be generalise (all the "standard" actions are in lang.actions)
export type TCustomDirectActionLabel = {
  select: string;
  bulkLicense: string;
  bulkUnlicense: string;
  bulkEditWithCSV: string;
  fetchRawData: string;
  detail: string;
  bulkAssignMobileUsersToTeams: string;
  bulkAssignWebUsersToTeams: string;
  findDuplicates: string;
  openDetailPanel: string;
  openItemActivity: string;
  manageHierarchy: string;
  restoreVersion: string;
};

export enum ERROR_LIST {
  EMPTY = "empty",
  TOO_LONG = "tooLong",
  ALPHANUMERIC = "alphanumeric",
  ALREADY_IN_USE = "alreadyInUse",
  OPTIONS_MISSING = "optionsMissing",
  WRONG_FORMAT = "wrongFormat",
  IMPOSSIBLE_TO_FIND = "impossibleToFind",
  MAXIMUM = "maximum",
  MINIMUM = "minimum",
  OPTION_FORBIDDEN_CHARACTERS = "optionLabelForbiddenCharacters",
  EMAIL_CONTAIN_UPPERCASE = "emailContainUppercase"
}

export type TInputAttributeLang = {
  title: string;
  description: string; // FIXME: check if it is in the right place
  tooltip: string;
  options: {
    [optionName: string]: string;
  };
  customErrorMessages: {
    [errorMessage: string]: string;
  };
  templates: any;
  createEditModal: TCreateEditModalLang;
  selectModal: TSelectModalLang;
  warningOnRemove: TConfirmModalLang;
  warningMessage: TConfirmModalLang;
};

export type TConfirmModalLang = {
  title?: string;
  description?: string;
};

export type TSelectModalLang = {
  title: string;
  description?: string;
};

export type TCreateEditModalLang = {
  createTitle: string;
  editTitle: string;
  title?: string;
  description?: string;
} & {
  [inputAttName: string]: TInputAttributeLang;
};
export type TGpsTrackingLang = {
  showMatchingRoutes: string;
  hideMatchingRoutes: string;
  checkIn: string;
  checkOut: string;
  submitted: string;
  viewActivity: string;
  viewCustomer: string;
} & {
  [inputAttName: string]: TInputAttributeLang;
};

export type TLockModalLang = {
  title: string;
  description: string;
} & {
  [inputAttName: string]: TInputAttributeLang;
};

export type TArchiveDeleteModalLang = {
  title: string;
  description: string;
  inputName: TInputAttributeLang;
  warningModal: TConfirmModalLang;
};

export const CLIENT_CREATE_EDIT_MODAL = {
  INPUT_NAME: "inputName",
  INPUT_GO_LIVE: "inputGoLive",
  INPUT_LOCK_PASSWORD: "inputLockPassword",
  INPUT_DESCRIPTION: "inputDescription",
  INPUT_LICENCE_NB: "inputLicenceNb",
  INPUT_LOGO: "inputLogo",
  INPUT_TIMESLOT_START: "inputTimeSlotsStart",
  INPUT_TIMESLOT_STOP: "inputTimeSlotsStop",
  INPUT_MAX_DOWNLOAD: "inputMaxDownload",
  INPUT_HIERARCHY: "inputHierarchy",
  INPUT_SUPPORT_API_KEY: "inputSupportAPIKey",
  INPUT_LEVEL_TYPE: "inputLevelType",
  INPUT_PROFILE: "inputProfile",
  INPUT_MODULE: "inputModule",
  INPUT_SELECT_TEAM_LABEL: "inputSelectTeamLabel",
  INPUT_LABEL_TYPE: "inputLabelType",
  INPUT_TEAMS_APP_CHAT: "inputTeamsAppChat",
  INPUT_TRACKING_DAYS: "inputTrackingDays",
  INPUT_GEO_CHECKIN_CONFIG_COMPUTATION_SCALE:
    "inputGeoCheckInConfigComputationScale",
  INPUT_GEO_CHECKIN_CONFIG_LAST_VISIT_THRESHOLD:
    "inputGeoCheckInConfigLastVisitThreshold",
  INPUT_GEO_CHECKIN_CONFIG_OPTIONNAL_CHECKIN:
    "inputGeoCheckInConfigOptionalCheckIn",
  INPUT_GEO_CHECKIN_CONFIG_COMPUTATION_QUERY:
    "inputGeoCheckInConfigComputationQuery",
  INPUT_DEFAULT_DATE_FILTER: "inputDefaultDateFilter",
  INPUT_GROUP: "inputGroup",
  INPUT_BILLING_OWNER_MAIL: "inputBillingOwnerMail",
  INPUT_BILLING_OWNER_NAME: "inputBillingOwnerName",
  INPUT_LANGUAGE: "inputLanguage",
  INPUT_INVOICED_BY: "inputInvoicedBy",
  INPUT_CATEGORY: "inputCategory",
  INPUT_COUNTRY: "inputCountry",
  INPUT_PHONE_REGEX: "inputPhoneRegex",
  INPUT_MAGIC_NUMBER: "inputMagicNumber",
};

export const DASHBOARD_CREATE_EDIT_MODAL = {
  INPUT_TYPE: "inputType",
  INPUT_TITLE: "inputTitle",
  INPUT_DESCRIPTION: "inputDescription",
  INPUT_CHART: "inputChart",
  INPUT_DB_TYPE: "inputDBType",
  INPUT_HOST: "inputHost",
  INPUT_PORT: "inputPort",
  INPUT_DB_NAME: "inputDBName",
  INPUT_USER: "inputUser",
  INPUT_PASSWORD: "inputPassword",
  INPUT_LIST_ID: "inputListId",
  INPUT_SCOPE: "inputScope",
  INPUT_TEAM: "inputTeam",
};

export const LIST_CREATE_EDIT_MODAL = {
  INPUT_TYPE: "inputType",
  INPUT_NAME: "inputName",
  INPUT_DESCRIPTION: "inputDescription",
  INPUT_TITLE_IN_CARD: "inputTitleInCard",
  INPUT_PICTURE_IN_CARD: "inputPictureInCard",
  INPUT_IMPORTANT_ATTRIBUTE: "inputImportantAttribute",
  INPUT_FILTRABLE_ATTRIBUTE: "inputFiltrableAttribute",
  INPUT_SCOPE: "inputScope",
  INPUT_ACCESS_RIGHT_FOR_MOBILE_USER: "inputAccessRightForMobileUser",
  INPUT_ACCESS_RIGHT_FOR_WEB_USER: "inputAccessRightForWebUser",
  INPUT_SELECT_TEAM_LABEL: "inputSelectTeamLabel",
  INPUT_ATTRIBUTE: "inputAttribute",
  INPUT_LIST_TYPE: "inputListType",
  INPUT_KEY_ATTRIBUTE_CATEGORY: "inputKeyAttributeCategory",
};

export const ITEM_CREATE_EDIT_MODAL = {
  INPUT_OWNER: "inputOwner",
};

export enum LANG_GENERIC_TERMS {
  YES = "yes",
  NO = "no",
  BY = "by",
}

export enum LANG_ACTIONS {
  CREATE = "create",
  EDIT = "edit",
  ARCHIVE = "archive",
  RESTORE = "restore",
  DOWNLOAD = "download",
  DELETE = "delete",
  FETCH = "fetch",
  UPLOAD = "upload",
  LICENSE = "license",
  UNLICENSE = "unlicense",
  ASSIGN = "assign",
  UNASSIGN = "unassign",
  DETAIL = "detail",
  REPLICATE = "replicate",
}

export enum LANG_COMPONENTS {
  TABLE = "table",
  SEARCH = "search",
  ERROR = "errors",
  EXPANSION_PANEL = "expansionPanel",
  FILTERS = "filters",
  INPUT_ERRORS = "inputErrors",
  INPUT_CONDITION = "inputCondition",
  INPUT_OPERATION = "inputOperation",
  MAPS = "maps",
  DOWNLOADS = "downloads",
}

export enum LANG_ACTION_NOTIFICATIONS {
  CREATE = "create",
  EDIT = "edit",
  DELETE = "delete",
  ARCHIVE = "archive",
  RESTORE = "restore",
  DOWNLOAD = "download",
  FETCH = "fetch",
  LICENSE = "license",
  UNLICENSE = "unlicense",
  UPLOAD = "upload",
  OTHER_ACTION = "otherAction",
  RESETTING_PASSWORD = "resettingPassword",
}

type TLangGenericTerms = {
  [key in LANG_GENERIC_TERMS]: string;
};

type TLangActions = {
  [key in LANG_ACTIONS]: string;
};

type TLangComponents = {
  inputErrors: { [key in ERROR_LIST]: string };
  filters: any;
  maps: any;
  search: string;
  table: any;
  expansionPanel: any;
  inputCondition: TInputAttributeLang;
  inputOperation: TInputAttributeLang;
  errors: any;
  downloads: any;
};

type TLangMainLayout = {
  selectedClient: string;
  sidebar: {
    summary: string;
    home: string;
    users: string;
    mobile_users: string;
    web_users: string;
    dashboards: string;
    clients: string;
    list: string;
    lists: string;
    places: string;
    map: string;
    pictures: string;
    documents: string;
    workflows: string;
    teams: string;
    database: string;
    environments: string;
  };
  // buttons
  logOutButton: string;
  forceSyncButton: string;
  documentationButton: string;
  giveFeedbackButton: string;
  goToFPDownloadButton: string;
  goToHelpCenterButton: string;
  goToContactSupportButton: string;
  goToViewSupportRequestsButton: string;
  getClientUsersDbButton: string;
  editProfileButton: string;
  changePasswordButton: string;
  goToStripePortalButton: string;
  // modals
  editProfileModal: any;
  changePasswordModal: any;
};

type TLangInProgressNotifications = {
  [key in LANG_ACTION_NOTIFICATIONS]: string;
};

type TLangSuccessNotifications = {
  default: string;
  defaultPlural: string;
  downloadList: string;
  filterDuplicates: string;
  mobUserNoSubmissionsMessage: string;
};

type TLangErrorNotifications = {
  default: string;
  internalError: string;
  validationError: string;
  networkError: string;
};

type TLangNotifications = {
  inProgressNotifications: TLangInProgressNotifications;
  successNotifications: TLangSuccessNotifications;
  errorNotifications: TLangErrorNotifications;
};

type TLangModal = {
  confirm: string;
  cancel: string;
  save: string;
  add: string;
  create: string;
  delete: string;
  archive: string;
  edit: string;
  proceed: string;
  upload: string;
  apply: string;
  select: string;
  recover: string;
  selectAll: string;
  deselectAll: string;
  validationText: string;
  dataLossMessage: string;
  confirmationMessage: string;
  downloadWIP: string;
  uploadWIP: string;
  loadWIPModalTitle: string;
  loadWIPModalDescription: string;
  warningTitle: string;
  createEditOption: {
    createTitle: string;
    editTitle: string;
    inputKey: TInputAttributeLang;
    inputLabel: TInputAttributeLang;
  };
  confirmCloseModal: {
    title: string;
    description: string;
  };
  lockModal: TLockModalLang;
  uploadCSVMessage: {
    message: string;
    errorMessage: string;
  };
  archiveDeleteMultiple: {
    title: string;
    description: string;
  };
  archiveDelete: {
    title: string;
    description: string;
  };
  archiveMessage: string;
  deleteMessage: string;
  bulk: {
    warningOnConfirm: {
      description: string;
      title: string;
    };
    mainModal: {
      title: string;
      description: string;
    };
  };
};

type TLangLoginScreen = {
  passwordLostModal: TCreateEditModalLang;
  passwordLostMessageModal: TCreateEditModalLang;
  passwordLostButton: string;
  inputEmail: TInputAttributeLang;
  inputPassword: TInputAttributeLang;
};

type TLangDefaultScreen = {
  subject: string;
  subjects: string;
  actions: TCustomDirectActionLabel;
  columns: any;
  createEditModal?: TCreateEditModalLang;
  archiveModal?: TArchiveDeleteModalLang;
  deleteModal?: TArchiveDeleteModalLang;
  replicateModal?: TCreateEditModalLang & { replicateTitle: string };
  customNotifications: any;
};

export type TLang = {
  genericTerms: TLangGenericTerms;
  actions: {
    singleGenericAction: string;
    bulkGenericAction: string;
    bulkGenericActionFromCSV: string;
    runInBackgroundMessage: string;
    hideArchive: string;
    displayArchive: string;
  } & TLangActions;
  components: TLangComponents;
  mainLayout: TLangMainLayout;
  notifications: TLangNotifications;
  modal: TLangModal;
  containers: {
    login: {
      subCategories: {
        login: TLangLoginScreen;
      };
    };
    clients: {
      subCategories: {
        clients: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          deleteModal: TArchiveDeleteModalLang;
          setClientLiveModal: TConfirmModalLang;
        };
      };
    };
    users: {
      subCategories: {
        mobileUsers: TLangDefaultScreen & {
          deleteModal: TArchiveDeleteModalLang;
          userAlreadyInTeamModal: any;
          maxLicencesModal: any;
          createEditModal: TCreateEditModalLang;
        };
        webUsers: TLangDefaultScreen & {
          deleteModal: TArchiveDeleteModalLang;
          createEditModal: TCreateEditModalLang;
        };
      };
      deleteModal: TArchiveDeleteModalLang;
    };
    teams: {
      subCategories: {
        teams: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          bulkCreateModal: TCreateEditModalLang;
          manageHierarchyModal: TCreateEditModalLang;
        };
        groups: TLangDefaultScreen & {
          deleteGroupModal: TArchiveDeleteModalLang;
          archiveGroupModal: TArchiveDeleteModalLang;
          deleteGroupsModal: TArchiveDeleteModalLang;
          archiveGroupsModal: TArchiveDeleteModalLang;
        };
      };
    };
    lists: {
      subCategories: {
        lists: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          archiveModal: TArchiveDeleteModalLang;
          warningModal: any;
          bulkAddOptionsFromCSV: any;
        };
        items: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          deleteModal: TArchiveDeleteModalLang;
          bulkEditModal: any;
          createEditFindDuplicateModal: TCreateEditModalLang;
        };
      };
    };
    dashboards: {
      subCategories: {
        folders: TLangDefaultScreen;
        dashboards: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          gpsTracking: TGpsTrackingLang;
        };
      };
    };
    database: {
      subCategories: {
        schema: TLangDefaultScreen & {
          detailTableView: string;
          simpleTableView: string;
          direction: string;
        };
      };
    };
    workflows: {
      subCategories: {
        workflows: TLangDefaultScreen & {
          revertWorkflowVersionModal: TCreateEditModalLang;
          createEditModal: TCreateEditModalLang;
          createEditTagModal: TCreateEditModalLang;
        };
        submissions: TLangDefaultScreen;
      };
    };
    documents: {
      subCategories: {
        documents: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          deleteModal: TArchiveDeleteModalLang;
        };
      };
    };
    pictures: {
      subCategories: {
        pictures: TLangDefaultScreen & {
          galleryModal: any;
          downloadModal: any;
        };
      };
    };
    environments: {
      subCategories: {
        tableOptimizations: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        transformations: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        streams: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        notifications: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
      };
    };
  };
};

export default TLang;
