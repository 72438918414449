import ITable from "model/entities/Database";
import { IAction } from "store/model";
import { updateErrorsList } from "utils/reducerUtils2";
import { IFetchTablesForClientSuccessAction } from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IDbSchemaState {
  allTables: ITable[];
  isFetchingTablesForClient: boolean;
  errors: any[];
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_TABLES_BEGIN:
      return { ...state, isFetchingTablesForClient: true };
    case types.FETCH_TABLES_SUCCESS:
      return {
        ...state,
        allTables: (action as IFetchTablesForClientSuccessAction).tables,
        isFetchingTablesForClient: false,
      };
    case types.FETCH_TABLES_FAILURE: {
      return {
        ...state,
        isFetchingTablesForClient: false,
        errors: updateErrorsList(state, action),
      };
    }
    default:
      return state;
  }
}
