import { secondaryColor } from "../../assets/colors";

export default () => ({
  DialogActions: {
    "margin-right": 20,
    "margin-bottom": 20,
    // height: "100%",
  },
  FilterComponent: {
    position: "absolute",
    top: 0,
    background: "white",
    height: "300px",
    width: "100%",
    padding: "10px",
    "z-index": 1001,
    "box-shadow": "5px 10px 18px #888888",
  },
  DashboardToolbox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  DashboardTitle: {
    position: "absolute",
    top: 0,
    width: "100%",
    "z-index": 1000,
    "pointer-events": "none",
  },
  DashboardContent: {
    padding: 0,
  },
  DashboardCloseButton: {
    position: "absolute",
    bottom: 5,
    right: 5,
    background: secondaryColor,
    "z-index": 1001,
  },
});
