import thunk from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { routerMiddleware } from "react-router-redux";
import { history } from "./historyConfig";
import { createLogger } from "redux-logger";
//import { reduxSearch } from "redux-search";

// TODO: set logger options:
// refer to https://github.com/evgenyrodionov/redux-logger
const logger = createLogger({
  duration: true,
  diff: true,
});

// TODO: configurations for redux search
// ref :https://github.com/bvaughn/redux-search/blob/master/docs/README.md
// const configureReduxSearch = reduxSearch({
// 	resourceIndexes: {
// 		users: ["name", "id"],
// 		clients: ["name", "id"],
// 		licenses: ["name", "id"]
// 	}
// 	// resourceSelector: (resourceName, state) => {
// 	// 	// eslint-disable-next-line
// 	// 	console.log("state", state);
// 	// 	return state.users.appUsers.get(resourceName);
// 	// }
// });

export const prodMiddleware = [thunk, routerMiddleware(history)];

// middleware to use based on the staging environment, this will be used when the staging environment
// will be in development (run locally), but targetting a staging server
export const devMiddleware = prodMiddleware.concat([
  reduxImmutableStateInvariant(),
  logger,
]);
