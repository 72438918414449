export default (theme: any) => ({
  ChipContainer: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
  },
  chipSelected: {
    margin: theme.spacing(0.5),
    background: theme.palette.primary.light,
  },
  chipUnselected: {
    margin: theme.spacing(0.5),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 150,
  },
  formControlFlag: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 64,
  },
  flagDisplay: {
    width: "64px",
    marginLeft: "10px",
  },
  undeterminedInput: {
    color: theme.palette.secondary.main,
  },
  input: {
    color: theme.palette.default.main,
  },
  select: {
    textOverflow: "clip", //isFlagSelection ? "clip" : "ellipsis"
  },
});
