export default {
  ajax: {
    isFetching: false,
    callsInProgress: 0,
    mustLogOut: false,
    errors: [],
  },
  app: {
    isFirstLogin: true,
    notifications: [],
    warningModal: {
      title: "",
      description: "",
      isOpen: false
    },
  }
};
