/**
 * @notes: reducer reducer
 */

import * as types from "./actionTypes";
import initialState from "./initialState";
import {
  updateErrorsList,
  insertNewItemToArr,
} from "../../../../utils/reducerUtils2";
import { IStream } from "model/entities/Dashboard";
import { IAction } from "store/model";
import * as actionCreators from "./actionCreators";
import { prepareStreamsForFrontend } from "./utils";

export interface IStreamState {
  subCategorySelected: string;
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isComputing: boolean;
  selectedStream: IStream | null;
  streams: IStream[];
  errors: any[];
}

/**
 * Dashboards reducer takes current state and action and returns a new state for the dashboards object in redux store
 * @param state initial state of dashboards
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_STREAM_BEGIN:
      return {
        ...state,
        isCreatingStream: true,
      };

    case types.CREATE_STREAM_FAILURE:
      return {
        ...state,
        isCreatingStream: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_STREAM_SUCCESS: {
      const { stream } = action as actionCreators.ICreateStreamSuccessAction;

      return {
        ...state,
        isCreatingStream: false,
        streams: insertNewItemToArr(state.streams, stream),
      };
    }

    case types.UPDATE_STREAM_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_STREAM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_STREAM_SUCCESS: {
      const { stream } = action as actionCreators.IUpdateStreamSuccessAction;

      const streams = state.streams.map((s) =>
        s.id === stream.id ? stream : s
      );

      return {
        ...state,
        isUpdating: false,
        streams,
      };
    }

    case types.DELETE_STREAM_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_STREAM_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_STREAM_SUCCESS: {
      const { id } = action as actionCreators.IDeleteStreamSuccessAction;

      const streams = state.streams.filter((s) => s.id !== id);

      return {
        ...state,
        isDeleting: false,
        streams,
      };
    }

    case types.FETCH_ALL_STREAMS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_STREAMS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_STREAMS_SUCCESS: {
      let { streams } = action as actionCreators.IFetchStreamsSuccessAction;
      return {
        ...state,
        isFetchingAll: false,
        streams: prepareStreamsForFrontend(streams),
      };
    }

    default:
      return state;
  }
}
