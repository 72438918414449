import {
  successColor,
  warningColor,
  infoColor,
  errorColor,
  yellow,
  lightgray,
} from "../../assets/colors";

export default (theme: any) => ({
  BackButton: {
    float: "left",
    margin: "5px",
    marginTop: "15px",
  },
  ButtonContainer: {
    padding: "5px",
    zIndex: "1000",
  },
  ActionFab: {
    margin: "5px",
    zIndex: "1000",
  },
  ButtonContainerNoPadding: {
    zIndex: "1000",
  },
  MoreButton: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    marginLeft: "5px",
    marginRight: "5px",
    zIndex: "1000",
  },
  LoginButton: {
    float: "left",
    height: "36px",
    width: "122px",
    "box-shadow": "none",
    border: "0px solid !important",
    "&:hover": {
      background: theme.palette.secondary.main,
      opacity: ".5",
    },
    borderRadius: "4px",
    background: theme.palette.secondary.main,
  },
  LoginButtonLabel: {
    width: "58px",
    height: "20px",
    fontsize: "16px",
    fontWeight: "500",
    lineHeight: "1.25",
    letterSpacing: "-0.25",
    color: "#fefefe",
  },
  TableActionButton: {
    float: "right",
    height: "40px",
    width: "40px",
    "box-shadow": "none",
    border: "0px solid !important",
    "&:hover": {
      border: "1px solid !important",
      background: "#FFF",
    },
    background: "transparent",
    transform: "scale(0.8)",
  },
  TableDetailActionButton: {
    margin: "4px 8px",
    maxHeight: 28,
  },
  SUCCESSColor: {
    color: successColor,
  },
  REPLICATEColor: {
    color: lightgray,
  },
  LOGINColor: {
    color: yellow,
  },
  WARNINGColor: {
    color: warningColor,
  },
  INFOColor: {
    color: infoColor,
  },
  ERRORColor: {
    color: errorColor,
  },
  DarkButton: {
    color: "white",
    background: "#0000",
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
  },
  ShowActivityButton: {
    "box-shadow": "none",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
    background: theme.palette.secondary.main,
  },
  ShowActivityButtonLabel: {
    color: "#fefefe",
  },
  ShowTimeLineActivityLabel: {
    color: theme.palette.secondary.main,
    fontSize: "12px",
    padding: 0,
    margin: 0,
    textTransform: "none",
  },
  ShowTimeLineActivityButton: {
    "box-shadow": "none",
    "&:hover": {
      background: "none",
    },
    padding: 0,
    margin: 0,
    minHeight: 0,
    minWidth: 0,
  },
});
