import { IOption } from "model/application/components";
import { IEmailGeneration } from "model/entities/Dashboard";
import { IEmailGeneration as IBEEmailGeneration } from "smala-tools/dist/src/types";
import { clone } from "utils/utils";

const prepareEmailGenerationForBackend = (
  originalEmailGeneration: IEmailGeneration,
  isCreation: boolean = true
): IBEEmailGeneration => {
  const emailGeneration = JSON.parse(JSON.stringify(originalEmailGeneration));
  Object.keys(emailGeneration).forEach((att) => {
    if (emailGeneration[att] === "" && att !== "last_sent")
      delete emailGeneration[att];
  });
  if (emailGeneration.web_users)
    emailGeneration.web_users = emailGeneration.web_users.map(
      (e: any) => e.key
    );
  delete emailGeneration.id;
  if (isCreation) {
    emailGeneration.last_sent = "";
  }
  return emailGeneration;
};
const prepareEmailGenerationsForFrontend = (
  originalEmailGenerations: IBEEmailGeneration[]
): IEmailGeneration[] => {
  let emailGenerations = clone(originalEmailGenerations);
  let frontEmailGenerations: IEmailGeneration[] = [];
  emailGenerations.forEach((d: IBEEmailGeneration) => {
    let emailGeneration = EmailGenerationBackToFront(d);
    frontEmailGenerations.push(emailGeneration);
  });
  return frontEmailGenerations;
};
const EmailGenerationBackToFront = (
  originalEmailGeneration: IBEEmailGeneration
): IEmailGeneration => {
  delete (originalEmailGeneration as any)?.wgeneratedEmailb_users;
  const email = {
    ...originalEmailGeneration,
    web_users:
      originalEmailGeneration.web_users?.map(
        (e) => ({ key: e, label: e } as IOption)
      ) || [],
    mobile_user_roles:
      originalEmailGeneration.mobile_user_roles?.map(
        (e) => ({ key: e, label: e } as IOption)
      ) || [],
  };
  return email;
};

export { prepareEmailGenerationsForFrontend, prepareEmailGenerationForBackend };
