import apiService from "api/api";
import { CLIENT_ENDPOINT } from "../../clients/redux/endpoints";
import { IServerResponse } from "actions/appActions";
import { DOCUMENT_SCOPE, IDocument } from "model/entities/Document";

const DOCUMENTS_ENDPOINT = "documents";

export const fetchDocumentsForClientApiCall = (
  clientId: string
): Promise<IServerResponse<IDocument[]>> => {
  return apiService.get(`${CLIENT_ENDPOINT}/${clientId}/${DOCUMENTS_ENDPOINT}`);
};

export type UploadDocumentsFunc = (
  clientId: string,
  document: Blob,
  fileName: string,
  teams: string[],
  scope: DOCUMENT_SCOPE,
  folder: string
) => Promise<IServerResponse<any>>;
export const uploadDocumentsRequest: UploadDocumentsFunc = (
  clientId,
  documents,
  fileName,
  teams,
  scope,
  folder
) => {
  return apiService.multipartUpdate(
    `${CLIENT_ENDPOINT}/${clientId}/${DOCUMENTS_ENDPOINT}`,
    [{ file: documents, filename: fileName }],
    { file_metas: { name: fileName, teams, scope, folder } }
  );
};

export type DeleteDocumentsFunc = (
  clientId: string,
  documentId: string
) => Promise<IServerResponse<any>>;
export const deleteDocumentsRequest: DeleteDocumentsFunc = (
  clientId,
  documentId
) => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${clientId}/${DOCUMENTS_ENDPOINT}/${documentId}`,
    {}
  );
};
