import * as types from "./actionTypes";
import initialState from "./initialState";
import IPicture from "model/entities/Picture";
import { IAction } from "store/model";
import { IFetchPicturesSuccessAction } from "./actionCreators";
import { updateErrorsList } from "utils/reducerUtils2";

export interface PicturesState {
  allPictures: IPicture[];
  totalPictures: number;
  isFetchingPictures: boolean;
  errors: any[];
}

const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case types.FETCH_PICTURE_BEGIN:
    case types.DOWNLOAD_PICTURE_BEGIN:
      return {
        ...state,
        allPictures: [],
        isFetchingPictures: true,
      };

    case types.FETCH_PICTURE_SUCCESS:
      return {
        ...state,
        totalPictures: (action as IFetchPicturesSuccessAction).totalPictures,
        allPictures: (action as IFetchPicturesSuccessAction).pictures,
        isFetchingPictures: false,
      };
    case types.DOWNLOAD_PICTURE_SUCCESS:
      return {
        ...state,
        isFetchingPictures: false,
      };

    case types.FETCH_PICTURE_FAILURE:
    case types.DOWNLOAD_PICTURE_FAILURE:
      return {
        ...state,
        errors: updateErrorsList(state, action),
        isFetchingPictures: false,
      };

    default:
      return state;
  }
};

export default reducer;
