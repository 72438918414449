import {
  loginRequestBeginAction,
  loginRequestFailureAction,
  loginRequestSuccessAction,
  logoutRequestBeginAction,
  logoutErrorAction,
  logoutSuccessAction,
  recoverPasswordBeginAction,
  recoverPasswordFailureAction,
  recoverPasswordSuccessAction,
  setActionsAvailableSuccessAction,
  changeLanguageSuccessAction,
  changePasswordBeginAction,
  changePasswordSuccessAction,
  changePasswordFailureAction,
  checkLastVersionActionSuccess,
} from "./actionCreator";
import {
  loginUserApiCall,
  logoutUserApiCall,
  recoverPasswordApiCall,
  changePasswordApiCall,
  checkUpdateApiCall,
} from "./authApi";
import { getLastCheckVersion } from "./selector";
import {
  ajaxErrorAction,
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../actionCreators/ajaxActionCreator";
import { push } from "react-router-redux";
import apiService from "../../../api/api";
import { LOGIN_ROUTE, HOME_ROUTE } from "../../../config/constants";
import { firstAppLoginAction } from "../../../actionCreators/appActionCreator";
import { createNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationTypes from "../../notifications/actionTypes";
import * as notificationLevels from "../../notifications/constants";
import { extractDataAndCheckErrorStatus } from "../../../actions/appActions";
import { selectClientActionCreator } from "../../clients/redux/actionCreators";
import { Dispatch } from "redux";
import { IDispatchAndGetState } from "store/model";

/**
 * Login Action which will be exposed to the Authentication container
 * This will be used to login the user given the user email and the user password
 * @param {String} userEmail User email
 * @param {String} userPassword Users's password
 * @param {String} token The token returned by reCAPTCHA
 * @param {Boolean} keepSignedIn, Whether to keep the user signed in
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function loginAction(
  userEmail: string,
  userPassword: string,
  keepSignedIn = true
) {
  return (dispatch: Dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(loginRequestBeginAction());
    return loginUserApiCall(userEmail, userPassword)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { token, id, created_at } = data;
        dispatch(ajaxSuccessAction());
        dispatch(
          loginRequestSuccessAction(
            token,
            id,
            created_at,
            userEmail,
            keepSignedIn
          )
        );
        apiService.setClientId(data.client);
        dispatch(push(HOME_ROUTE));
      })
      .catch((err) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(loginRequestFailureAction(err));
      });
  };
}

/**
 * Logs out a given user given their id
 * dispatches actions to redux store facilitating the process
 * */
export function logoutAction() {
  apiService.setClientId("");
  apiService.setToken("");
  return (dispatch: Dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(logoutRequestBeginAction());

    return logoutUserApiCall()
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(logoutSuccessAction());
        dispatch(selectClientActionCreator(undefined));
        dispatch(push(LOGIN_ROUTE));
        // set this to true when the user logs out, this will ensure that the
        // isFirstLogin value is set to true for displaying welcom message
        dispatch(firstAppLoginAction(true));
      })
      .catch((error) => {
        dispatch(ajaxErrorAction(error, "LogoutError"));
        dispatch(logoutErrorAction(error));
        dispatch(push(LOGIN_ROUTE));
      });
  };
}

/**
 * Password Recovery Action which will be exposed to the Authentication container
 * This will be used to reset the password for the email provided
 * @param {String} userEmail User email
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function recoverPasswordAction(userEmail: string) {
  return (dispatch: Dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(recoverPasswordBeginAction());
    return recoverPasswordApiCall(userEmail)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(recoverPasswordSuccessAction());
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            `Succesfully recover the password`
          )
        );
      })
      .catch((err) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(recoverPasswordFailureAction(err));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_ERROR,
            notificationLevels.NOTIFICATION_LEVEL_ERROR,
            `Failed to recover the password`
          )
        );
      });
  };
}

/**
 * Edit profile Action which will be exposed to the Authentication container
 * This will be used to edit profile informations (such as password)
 * @param {String} password New password
 * @param {String} oldPassword Old password
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function changePasswordAction(password: string, oldPassword: string) {
  return (dispatch: Dispatch) => {
    dispatch(ajaxRequestAction());
    dispatch(changePasswordBeginAction());
    return changePasswordApiCall(password, oldPassword)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(changePasswordSuccessAction());
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            `Succesfully changed password`
          )
        );
      })
      .catch((err) => {
        dispatch(ajaxErrorAction(err, err.message));
        dispatch(changePasswordFailureAction(err));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_ERROR,
            notificationLevels.NOTIFICATION_LEVEL_ERROR,
            `Failed to change the password`
          )
        );
      });
  };
}

export function changeLanguage(lang: string) {
  return (dispatch: Dispatch) => {
    dispatch(changeLanguageSuccessAction(lang));
  };
}

export function setActionsAvailableAction(actionsAvailable: any) {
  return (dispatch: Dispatch) => {
    dispatch(setActionsAvailableSuccessAction(actionsAvailable));
  };
}

/**
 * Check update Action
 * This will be used to login the user given the user email and the user password
 * @param {String} version
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function checkUpdateAction(
  version: string
): IDispatchAndGetState<boolean> {
  return (dispatch, getState) => {
    dispatch(ajaxRequestAction());
    return new Promise(function (resolve, reject) {
      // A bit dirty fix to ensure this endpoint is not spammed too much in case of infinity loop (otherwise it'll block my gitlab account...)
      if (
        new Date(getLastCheckVersion(getState())).getTime() >
        new Date(Date.now() - 5000).getTime()
      ) {
        reject(`Not allowed to spam this endpoint`);
      } else {
        checkUpdateApiCall(version)
          .then((serverResponse) => {
            dispatch(checkLastVersionActionSuccess());
            const data = extractDataAndCheckErrorStatus(serverResponse);
            const { has_update } = data;
            resolve(has_update);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };
}
