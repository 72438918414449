import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../../actionCreators/ajaxActionCreator";
import {
  createTableOptimizationApiCall,
  deleteTableOptimizationApiCall,
  fetchTableOptimizationsApiCall,
} from "./api";
import { createNotificationActionCreator } from "../../../notifications/actionCreator";
import * as notificationTypes from "../../../notifications/actionTypes";
import * as levels from "../../../notifications/constants";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../../actions/appActions";
import { getLang } from "../../../authentication/redux/selector";
import * as lang from "../../../../lang";
import { getSuccessNotificationMessage } from "../../../../lang/utils";
import { ITableOptimization } from "model/entities/TableOptimization";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import {
  createTableOptimizationBeginActionCreator,
  createTableOptimizationFailureActionCreator,
  createTableOptimizationSuccessActionCreator,
  deleteTableOptimizationBeginActionCreator,
  deleteTableOptimizationFailureActionCreator,
  deleteTableOptimizationSuccessActionCreator,
  fetchAllTableOptimizationsBeginActionCreator,
  fetchAllTableOptimizationsFailureActionCreator,
  fetchAllTableOptimizationsSuccessActionCreator,
} from "./actionCreators";
import { IDispatchAndGetState } from "store/model";

/**
 * Create TableOptimization action which creates an tableOptimization for embedding
 * @param {String} id primary_key the tableOptimization in the tables
 * @param {String} title the name of the table optimization
 * @param {String} target_table the name of the table for which we want the optimization
 * @param {String} optimization_type type optimization VALUE between PARTITION and INDEX
 * @param {String} dimension The dimension on which we want to apply the optimization: the name of the column
 * @param {String} dimension_type the type of the column (between TIMESTAMP and TEXT)
 * @returns {Function}
 */
export const createTableOptimizationAction: ICreateTableOptimizationActionFunc =
  (tableOptimization: ITableOptimization, clientId = undefined) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(createTableOptimizationBeginActionCreator());

      return createTableOptimizationApiCall(tableOptimization)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            createTableOptimizationSuccessActionCreator(tableOptimization)
          );
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.TABLEOPTIMIZATION
              )
            )
          );
        })

        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateTableOptimization",
            error,
            createTableOptimizationFailureActionCreator,
            currLang
          );
        });
    };
  };
/**
 * Action to fetch allTableOptimizations
 * @returns {Function}
 */
export const fetchAllTableOptimizationsAction: TFetchAllTableOptimizationsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAllTableOptimizationsBeginActionCreator());
      return fetchTableOptimizationsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { table_optimizations } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllTableOptimizationsSuccessActionCreator(table_optimizations)
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllTableOptimizationsError",
            error,
            fetchAllTableOptimizationsFailureActionCreator,
            currLang
          );
        });
    };
  };
/**
 * Delete TableOptimization Action dispatches action creators to redux store and makes api calls to delete a tableOptimization by id
 * @param {String} tableOptimization_id TableOptimization id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteTableOptimizationAction: TDeleteTableOptimizationActionFunc =
  (tableOptimizationId) => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(deleteTableOptimizationBeginActionCreator());
      return deleteTableOptimizationApiCall(tableOptimizationId)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            deleteTableOptimizationSuccessActionCreator(tableOptimizationId)
          );
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.DELETE,
                SUB_CATEGORIES.TABLEOPTIMIZATION
              )
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "DeleteTableOptimizationError",
            error,
            deleteTableOptimizationFailureActionCreator,
            currLang
          );
        });
    };
  };

export type ICreateTableOptimizationActionFunc = (
  tableOptimization: ITableOptimization,
  clientId?: string
) => IDispatchAndGetState<void>;
export type TFetchAllTableOptimizationsActionFunc =
  () => IDispatchAndGetState<void>;
export type TDeleteTableOptimizationActionFunc = (
  tableOptimizationId: string
) => IDispatchAndGetState<void>;

export interface ITableOptimizationActions {
  createTableOptimizationAction: ICreateTableOptimizationActionFunc;
  fetchAllTableOptimizationsAction: TFetchAllTableOptimizationsActionFunc;
  deleteTableOptimizationAction: TDeleteTableOptimizationActionFunc;
}

const actions: ITableOptimizationActions = {
  createTableOptimizationAction: createTableOptimizationAction,
  fetchAllTableOptimizationsAction: fetchAllTableOptimizationsAction,
  deleteTableOptimizationAction: deleteTableOptimizationAction,
};

export default actions;
