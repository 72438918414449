import React, { Component, Fragment } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/NavBar/Navbar";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../actions/appActions";
import * as authActions from "../containers/authentication/redux/actions";
import * as clientActions from "../containers/clients/redux/actions";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  selectClientAction,
  fetchClientAction,
  fetchStripeSessionLinkAction,
} from "../containers/clients/redux/actions";
import { changeLanguage } from "../containers/authentication/redux/actions";
import {
  getIsLoggingOut,
  tokenSelector,
  getLang,
  getPrivilege,
} from "../containers/authentication/redux/selector";
import {
  allClientsSelector,
  clientOptionsSelector,
  getIsFetchingAllClients,
  getSelectedClient,
} from "../containers/clients/redux/selectors";
import { fetchMobileUsersAction } from "../containers/usermgt/redux/actions/mobileUserActions";
import { fetchWebUsersAction } from "../containers/usermgt/redux/actions/webUserActions";
import { fetchAllDashboardsAction } from "../containers/dashboards/redux/actions";
import NotificationsContainer from "../containers/notifications/NotificationsContainer";
import { allNotificationsSelector } from "../containers/notifications/selectors";
import { fetchWorkflowsByClientAction } from "../containers/workflows/redux/actions";
import { fetchTeamsForClientAction } from "../containers/teams/redux/actions";
import { fetchListsForClientAction } from "../containers/lists/redux/actions";
import { fetchAllTableOptimizationsAction } from "containers/environments/redux/tableOptimizations/actions";
import * as alllang from "../lang";
import { SnackbarProvider } from "notistack";
import CustomDialogCreateEdit from "../components/Dialog/CustomDialogCreateEdit";
import ChangePasswordModal from "./components/modals/ChangePasswordModal";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import {
  getAppNotifications,
  getWarningModalParamsSelector,
} from "selectors/appSelector";
import { appCleanNotification } from "actionCreators/appActionCreator";
import AppNotifications from "components/AppNotifications";
import { fetchDocumentsForClientAction } from "containers/documents/redux/actions";
import { IClient } from "model/entities/Client";
import { IOption } from "model/application/components";
import IRootState from "store/model";
import { getErrorMessagesForPassword } from "./components/modals/passwordBuilderUtils";
import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import * as appActions from "actions/appActions";
import TLang from "model/application/Lang";
import { WarningModalContext } from "containers/app/AppProviders";
import { ISignedInUser } from "model/entities/User";
import { fetchAllEmailGenerationsAction } from "containers/environments/redux/emailGenerations/actions";
import { fetchAllTransformationsAction } from "containers/environments/redux/transformations/actions";
import { fetchAllStreamsAction } from "containers/environments/redux/streams/actions";
import {
  fetchTablesForClientAction,
  TFetchTablesForClientActionFunc,
} from "containers/environments/redux/dbSchema/actions";
import { getActionsAvailable } from "model/application/ActionCode";

interface IMainLayoutProps extends RouteComponentProps {
  children: any;
  actions: any;
  authActions: any;
  clientActions: any;
  fetchAllClients: () => void;
  fetchProfiles: () => void;
  selectClient: (id: string) => void;
  fetchClient: (clientId: string) => void;
  fetchMobileUsers: (clientId: string) => void;
  fetchWebUsers: (clientId: string) => void;
  fetchWorkflows: (clientId: string) => void;
  fetchTeams: (clientId: string) => void;
  fetchLists: (clientId: string) => void;
  fetchDashboards: (clientId: string) => void;
  fetchTables: TFetchTablesForClientActionFunc;
  fetchTransformations: (clientId: string) => void;
  fetchStreams: (clientId: string) => void;
  fetchEmailGenerations: (clientId: string) => void;
  fetchDocuments: (clientId: string) => void;
  fetchDbSchema: (clientId: string) => void;
  fetchTableOptimizations: (clientId: string) => void;
  setActionsAvailable: (actions: any) => void;
  changeLanguage: (lang: string) => void;
  cleanNotification: () => void;
  fetchStripeSessionLink: () => void;
  notifications: any[];
  appNotifications: any[];
  token: string | null;
  selectedClient: IClient;
  actionsAvailable: any;
  isLoggingOut: boolean;
  location: any;
  history: any;
  cookies: any;
  clients: IClient[];
  clientOptions: IOption[];
  isFetchingClients: boolean;
  refreshClient: () => void;
  route: string;
  privilege: string;
  lang: string;
  classes: any;
  warningModalParams: IWarningModalParams;
  appActions: any;
  forceUpdate: () => any;
  signedInUser: ISignedInUser;
}
interface IMainLayoutStates {
  token: string;
  isLoggingOut: boolean;
  selectedClient: IClient;
  isWarningOpen: boolean;
  // change password modal
  isChangePasswordOpen: boolean;
  // lang
  currLang: TLang;
  disabledSidebar: boolean;
  showAppNotifications: boolean;
  lang: string;
}

interface IWarningModalParams {
  description: string;
  title: string;
  isOpen: boolean;
  onConfirm?: () => any;
}

/**
 * Think of the Main Layout as the HOC of the application. The function of this layout is to allow
 * wrapping the routes in a nested function. This, in this case, is used for the entire application
 * This layout has access to the Redux Store
 * */
export class MainLayout extends Component<IMainLayoutProps, IMainLayoutStates> {
  constructor(props: IMainLayoutProps) {
    super(props);

    this.state = {
      token: "",
      isLoggingOut: false,
      selectedClient: props.clients[0],
      isChangePasswordOpen: false,
      isWarningOpen: false,
      // lang
      currLang: alllang[props.lang],
      disabledSidebar: true,
      showAppNotifications: false,
      lang: props.lang,
    };

    this.handleClientChange(props.selectedClient.id);
  }
  /**
   * Handle logout function. This logs out the current user from the dashboard
   * */
  handleLogout = () => {
    const { authActions, cookies, token } = this.props;

    authActions.logoutAction(token);
    cookies.set("token", null);
  };

  /**
   * Handle open change password modal. This will open the modal to change password
   */
  openChangePassword = () => {
    this.setState({
      isChangePasswordOpen: true,
    });
  };

  /**
   * Handle close change password modal.
   */
  closeChangePassword = () => {
    this.setState({
      isChangePasswordOpen: false,
    });
  };

  confirmChangePassword = (attributes: {
    newPassword: string;
    oldPassword: string;
  }) => {
    const { authActions } = this.props;
    authActions.changePasswordAction(
      attributes.newPassword,
      attributes.oldPassword
    );
    this.closeChangePassword();
  };

  handleGetUserDB = () => {
    const { clientActions, selectedClient } = this.props;
    clientActions.getDBAccessAction(selectedClient.id).then((client: any) => {
      this.context.openWarningModal({
        title: `User DB Credentials`,
        description: `client id: ${selectedClient.id} ==> username: ${client.username}, password: ${client.password}`,
      });
    });
  };

  /**
   * Handles client change from the Select option. This will trigger a fetch of teams for a particular
   * client
   * @param {String} selectedClient Id of client
   */
  handleClientChange = (selectedClient: string) => {
    const {
      fetchClient,
      fetchDashboards,
      fetchTables,
      fetchTransformations,
      fetchStreams,
      fetchEmailGenerations,
      fetchTableOptimizations,
      fetchLists,
      fetchWorkflows,
      fetchTeams,
      fetchMobileUsers,
      fetchWebUsers,
      cleanNotification,
      fetchDocuments,
      actionsAvailable,
      forceUpdate,
    } = this.props;

    this.setState({ disabledSidebar: true });

    cleanNotification();

    // select client
    Promise.resolve(fetchClient(selectedClient)).then(() => {
      const promiseArr = [];
      const profiles = this.props.selectedClient.access_right_profiles;
      const actions: any = getActionsAvailable(
        profiles,
        this.props.selectedClient.profile
      );
      this.props.setActionsAvailable(actions);
      // fetch dashboards from the given client
      if (actions.FETCH_DASHBOARDS)
        promiseArr.push(fetchDashboards(selectedClient));
      // fetch db_schema from the given client
      if (actions.GET_DB_SCHEMA)
        promiseArr.push(fetchTables(selectedClient, {}));
      // fetch email generations from the given client
      if (actions.FETCH_EMAILGENERATIONS)
        promiseArr.push(fetchEmailGenerations(selectedClient));
      if (actions.FETCH_TABLE_OPTIMIZATIONS)
        promiseArr.push(fetchTableOptimizations(selectedClient));
      // fetch transformations from the given client
      if (actions.FETCH_TRANSFORMATIONS)
        promiseArr.push(fetchTransformations(selectedClient));
      // fetch streams from the given client
      if (actions.FETCH_STREAMS) promiseArr.push(fetchStreams(selectedClient));
      // fetch users from the given client
      if (actions.FETCH_MOB_USERS)
        promiseArr.push(fetchMobileUsers(selectedClient));
      // fetch users from the given  client
      if (actions.FETCH_WEB_USERS)
        promiseArr.push(fetchWebUsers(selectedClient));
      // fetch lists for the given client
      if (actions.FETCH_LISTS) promiseArr.push(fetchLists(selectedClient));
      // fetch workflows for the given client
      if (actions.FETCH_WORKFLOWS)
        promiseArr.push(fetchWorkflows(selectedClient));
      // fetch teams for the given client
      if (actions.FETCH_TEAMS) promiseArr.push(fetchTeams(selectedClient));
      // fetch documents for the given client
      if (actions.FETCH_DOCUMENTS)
        promiseArr.push(fetchDocuments(selectedClient));

      Promise.all([promiseArr]).then(() => {
        this.setState({ disabledSidebar: false });
        forceUpdate();
      });
    });
  };

  handleLangChange = (lang: string) => {
    const { changeLanguage } = this.props;
    changeLanguage(lang.toLowerCase());
  };

  /**
   * Make any necessary updates when we receive props. These props will ideally come from the Redux Store
   * @param {Object} nextProps Next Properties received from the Redux Store state using the mapStateToProps function
   * */
  static getDerivedStateFromProps(
    nextProps: IMainLayoutProps,
    prevState: IMainLayoutStates
  ) {
    const { token, isLoggingOut, lang } = nextProps;

    if (
      token !== prevState.token ||
      isLoggingOut !== prevState.isLoggingOut ||
      lang !== prevState.lang
    ) {
      return {
        token,
        isLoggingOut,
        currLang: alllang[lang],
        lang,
      };
    } else {
      return null;
    }
  }

  render() {
    const {
      isChangePasswordOpen,
      currLang,
      lang,
      disabledSidebar,
      showAppNotifications,
    } = this.state;
    const {
      notifications,
      location,
      history,
      clientOptions,
      children,
      actionsAvailable,
      selectedClient,
      refreshClient,
      privilege,
      appNotifications,
      classes,
      signedInUser,
      fetchStripeSessionLink,
    } = this.props;
    return (
      <div id="MainLayout">
        <Fragment>
          <SnackbarProvider style={{ pointerEvents: "all" }}>
            <NotificationsContainer lang={currLang} />
            <Sidebar
              actionsAvailable={actionsAvailable}
              location={location}
              lang={currLang}
              disabledSidebar={disabledSidebar}
            />
            <Navbar
              privilege={privilege}
              location={location}
              history={history}
              notificationItems={notifications}
              onLogoutClick={this.handleLogout}
              onOpenChangePasswordClick={this.openChangePassword}
              onGetUserDBClick={this.handleGetUserDB}
              onClientChange={this.handleClientChange}
              onLangChange={this.handleLangChange}
              selectedClient={selectedClient}
              actionsAvailable={actionsAvailable}
              clientOptions={clientOptions}
              refreshClient={refreshClient}
              selectedLanguage={lang}
              appNotifications={appNotifications}
              showAppNotification={() =>
                this.setState({ showAppNotifications: !showAppNotifications })
              }
              lang={currLang}
              signedInUser={signedInUser}
              onOpenStripePortalClick={fetchStripeSessionLink}
            />
            {showAppNotifications && appNotifications.length > 0 && (
              <AppNotifications
                appNotifications={appNotifications}
                onClickAway={() =>
                  this.setState({ showAppNotifications: false })
                }
              />
            )}
            <div className={classes.MainContainer}>{children}</div>
          </SnackbarProvider>
          {this.displayWarningModal()}
          {this.displayChangePasswordModal(isChangePasswordOpen, currLang)}
        </Fragment>
      </div>
    );
  }

  displayWarningModal = () => {
    const {
      warningModalParams: { description, title, isOpen, onConfirm },
    } = this.props;
    const { currLang } = this.state;
    let formattedTitle = title;
    if (!title) {
      if (onConfirm) {
        formattedTitle = currLang.modal.confirmCloseModal.title;
      } else {
        formattedTitle = currLang.modal.warningTitle;
      }
    }
    return (
      <CustomDialogWarning
        isOpen={isOpen}
        onConfirmAction={() => {
          if (onConfirm) onConfirm();
          this.props.actions.closeWarningModal();
        }}
        rootLang={this.state.currLang}
        lang={{
          title: formattedTitle,
          description,
        }}
        onClose={() => {
          this.props.actions.closeWarningModal();
        }}
        singleButton={!onConfirm}
      />
    );
  };

  displayChangePasswordModal = (display: boolean, lang: TLang) => {
    return (
      <CustomDialogCreateEdit
        additionnalProps={{}}
        isEditOpen={display}
        CreateEditModal={ChangePasswordModal}
        editModalTitle={lang.mainLayout.changePasswordModal.title}
        createModalTitle={lang.mainLayout.changePasswordModal.title}
        validate={getErrorMessagesForPassword}
        attributes={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        onClose={this.closeChangePassword}
        onEditElement={this.confirmChangePassword}
        closeOnSave={true}
        lang={lang}
      />
    );
  };

  /**
   * When the component is mounted, we want to update the document class by toggling the navigation class
   * @param prevProps Previous props before the update
   * @param prevState Previous state before the update of the Component
   * */
  componentDidUpdate(
    prevProps: IMainLayoutProps,
    prevState: IMainLayoutStates
  ) {
    if (
      window.innerWidth < 993 &&
      prevProps.history.location.pathname !== prevProps.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
}

/**
 * maps the state of the redux store to the DashboardContainer props
 * @param {Object} state of redux store
 * @param {Object} ownProps DashboardContainer properties
 * @returns {Object} new state of redux store
 */
function mapStateToProps(state: IRootState) {
  return {
    token: tokenSelector(state),
    privilege: getPrivilege(state),
    clients: allClientsSelector(state),
    clientOptions: clientOptionsSelector(state),
    isLoggingOut: getIsLoggingOut(state),
    notifications: allNotificationsSelector(state),
    appNotifications: getAppNotifications(state),
    warningModalParams: getWarningModalParamsSelector(state),
    isFetchingClients: getIsFetchingAllClients(state),
    selectedClient: getSelectedClient(state),
    lang: getLang(state),
  };
}

/**
 * maps dispatch actions to props in this container
 * component
 * @param {Object} dispatch
 * @returns {Object} actions object
 */
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    fetchTeams: bindActionCreators(fetchTeamsForClientAction, dispatch),
    fetchLists: bindActionCreators(fetchListsForClientAction, dispatch),
    fetchWebUsers: bindActionCreators(fetchWebUsersAction, dispatch),
    fetchMobileUsers: bindActionCreators(fetchMobileUsersAction, dispatch),
    fetchWorkflows: bindActionCreators(fetchWorkflowsByClientAction, dispatch),
    fetchDashboards: bindActionCreators(fetchAllDashboardsAction, dispatch),
    fetchTables: bindActionCreators(fetchTablesForClientAction, dispatch),
    fetchEmailGenerations: bindActionCreators(
      fetchAllEmailGenerationsAction,
      dispatch
    ),
    fetchTransformations: bindActionCreators(
      fetchAllTransformationsAction,
      dispatch
    ),
    fetchStreams: bindActionCreators(fetchAllStreamsAction, dispatch),
    selectClient: bindActionCreators(selectClientAction, dispatch),
    fetchClient: bindActionCreators(fetchClientAction, dispatch),
    fetchDocuments: bindActionCreators(fetchDocumentsForClientAction, dispatch),
    changeLanguage: bindActionCreators(changeLanguage, dispatch),
    cleanNotification: () => dispatch(appCleanNotification()),
    fetchTableOptimizations: bindActionCreators(
      fetchAllTableOptimizationsAction,
      dispatch
    ),
    fetchStripeSessionLink: bindActionCreators(
      fetchStripeSessionLinkAction,
      dispatch
    ),
    setActionsAvailable: bindActionCreators(
      authActions.setActionsAvailableAction,
      dispatch
    ),
  };
}

MainLayout.contextType = WarningModalContext;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles as any)(MainLayout))
);
