import * as types from "./actionTypes";
import { IBeginAction, IActionError, IAction } from "store/model";
import ITeam from "model/entities/Team";

/**
 * Action creator return the type of action that informs reducer a team is being created. This will normally
 * enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createTeamBeginAction(): IBeginAction {
  return {
    type: types.CREATE_TEAM_BEGIN,
  };
}

/**
 * Team failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createTeamFailureAction(error: any): IActionError {
  return {
    type: types.CREATE_TEAM_FAILURE,
    error,
  };
}

export interface ICreateTeamSuccessAction extends IAction {
  clientId: string;
  team: ITeam;
}
/**
 * Team create success action is used to inform reducer of successful ajax request
 * @param {String} clientId Client Id this newly created team belongs to
 * @param {Object} team Object with team information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createTeamSuccessAction(
  clientId: string,
  team: ITeam
): ICreateTeamSuccessAction {
  return {
    type: types.CREATE_TEAM_SUCCESS,
    clientId,
    team,
  };
}

/**
 * Action creator return the type of action that informs reducer a team is being created. This will normally
 * enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createTeamsBeginAction(): IBeginAction {
  return {
    type: types.CREATE_TEAMS_BEGIN,
  };
}

/**
 * Team failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createTeamsFailureAction(error: any): IActionError {
  return {
    type: types.CREATE_TEAMS_FAILURE,
    error,
  };
}

export interface ICreateTeamsSuccessAction extends IAction {
  clientId: string;
  teams: ITeam[];
}

/**
 * Team create success action is used to inform reducer of successful ajax request
 * @param {String} clientId Client Id this newly created team belongs to
 * @param {Object} teams Object with team information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createTeamsSuccessAction(
  clientId: string,
  teams: ITeam[]
): ICreateTeamsSuccessAction {
  return {
    type: types.CREATE_TEAMS_SUCCESS,
    clientId,
    teams,
  };
}

/**
 * Fetch All teams begin action. is used to dispatch action to the redux store a fetch all team request is being
 * requested.
 * @return {Object} Object with type of action
 * */
export function fetchTeamsForClientBeginAction(): IBeginAction {
  return {
    type: types.FETCH_TEAMS_FOR_CLIENT_BEGIN,
  };
}

/**
 * Fetch All teams Failure action dispatched when there was an error fetching all teams. This will take in the
 * error object that will be used for logging.
 * @param {Object} error Error object with information of the failure
 * @return {Object} Object with type of action and the error object
 * */
export function fetchTeamsForClientFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_TEAMS_FOR_CLIENT_FAILURE,
    error,
  };
}

export interface IFetchTeamsForClientSuccessAction extends IAction {
  clientId: string;
  teams: ITeam[];
}

/**
 * Fetch all teams success action, which is dispatched when there was a successful retrieval of teams from the API
 * or redux store. This dispatches the client id and the array of team objects
 * @param {String} clientId Client Id
 * @param {Array} teams An array of team objects
 * */
export function fetchTeamsForClientSuccessAction(
  clientId: string,
  teams: ITeam[]
): IFetchTeamsForClientSuccessAction {
  return {
    type: types.FETCH_TEAMS_FOR_CLIENT_SUCCESS,
    clientId,
    teams,
  };
}

/**
 * Update team action used to inform reducer of failed ajax request to update a team
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateTeamsFailureAction(error: any): IActionError {
  return {
    type: types.UPDATE_TEAMS_FAILURE,
    error,
  };
}

export interface IUpdateTeamsSuccessAction extends IAction {
  teams: ITeam[];
}

/**
 * Success action for edit teams
 * @param {Array} teams Array of teams to edit
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateTeamsSuccessAction(
  teams: ITeam[]
): IUpdateTeamsSuccessAction {
  return {
    type: types.UPDATE_TEAMS_SUCCESS,
    teams,
  };
}

/**
 * Update team action used to inform reducer of failed ajax request to update a team
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateTeamsBeginAction(): IBeginAction {
  return {
    type: types.UPDATE_TEAMS_BEGIN,
  };
}

/**
 * Delete teams action used to inform reducer of beginning ajax request to delete teams
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteTeamsBeginAction(): IBeginAction {
  return {
    type: types.DELETE_TEAMS_BEGIN,
  };
}

export interface IDeleteTeamsSuccessAction extends IAction {
  ids: string[];
}

/**
 * Delete teams action used to inform reducer of successful ajax request to delete teams
 * @param {String} ids Ids of the teams to delete
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteTeamsSuccessAction(
  ids: string[]
): IDeleteTeamsSuccessAction {
  return {
    type: types.DELETE_TEAMS_SUCCESS,
    ids,
  };
}

/**
 * Delete teams action used to inform reducer of successful ajax request to delete teams
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteTeamsFailureAction(error: any): IActionError {
  return {
    type: types.DELETE_TEAMS_FAILURE,
    error,
  };
}

/**
 * Archive teams action used to inform reducer of beginning ajax request to archive teams
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveTeamsBeginAction(): IBeginAction {
  return {
    type: types.ARCHIVE_TEAMS_BEGIN,
  };
}

export interface IArchiveTeamsSuccessAction extends IAction {
  ids: string[];
}

/**
 * Archive teams action used to inform reducer of successful ajax request to archive teams
 * @param {String} ids Ids of the teams to archive
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveTeamsSuccessAction(
  ids: string[]
): IArchiveTeamsSuccessAction {
  return {
    type: types.ARCHIVE_TEAMS_SUCCESS,
    ids,
  };
}

/**
 * Archive teams action used to inform reducer of successful ajax request to archive teams
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveTeamsFailureAction(error: any): IActionError {
  return {
    type: types.ARCHIVE_TEAMS_FAILURE,
    error,
  };
}

/**
 * Restore teams action used to inform reducer of beginning ajax request to restore teams
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreTeamsBeginAction(): IBeginAction {
  return {
    type: types.RESTORE_TEAMS_BEGIN,
  };
}

export interface IRestoreTeamsSuccessAction extends IAction {
  ids: string[];
}

/**
 * Restore teams action used to inform reducer of successful ajax request to restore teams
 * @param {String} ids Ids of the teams to archive
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreTeamsSuccessAction(
  ids: string[]
): IRestoreTeamsSuccessAction {
  return {
    type: types.RESTORE_TEAMS_SUCCESS,
    ids,
  };
}

/**
 * Restore teams action used to inform reducer of successful ajax request to restore teams
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreTeamsFailureAction(error: any): IActionError {
  return {
    type: types.RESTORE_TEAMS_FAILURE,
    error,
  };
}

export interface ISelectTeamActionCreator extends IAction {
  selectedTeam?: ITeam;
}

/**
 * Action creator that allows the selection of a team from the store when a user selects on from the table
 * @param selectedTeam The selected Team
 * @return {Object}
 */
export const selectTeamActionCreator = (
  selectedTeam?: ITeam
): ISelectTeamActionCreator => ({
  type: types.SELECT_TEAM,
  selectedTeam,
});

export interface IRemoveUsersFromAllTeamsSuccessAction extends IAction {
  clientId: string;
  userIds: string[];
}

/**
 * Delete user(s) from all team success action creator is dispatched when there is a successful deletion of a
 * user (or several users) from all the teams where he belongs
 * @param {String} clientId Client Id team belongs to
 * @param {Array} userIds Array of user ids to be removed from team
 * @returns {Object} Object with action type and payload
 */
export function removeUsersFromAllTeamsSuccessAction(
  clientId: string,
  userIds: string[]
): IRemoveUsersFromAllTeamsSuccessAction {
  return {
    type: types.REMOVE_USERS_FROM_ALL_TEAMS_SUCCESS,
    clientId,
    userIds,
  };
}

export interface IRemoveWorkflowsFromAllTeamsSuccessAction extends IAction {
  clientId: string;
  workflows: string[];
}

/**
 * Delete user(s) from all team success action creator is dispatched when there is a successful deletion of a
 * workflow from all the teams where he belongs
 * @param {String} clientId Client Id workflow belongs to
 * @param {Array} workflows Array of workflow to be removed from teams
 * @returns {Object} Object with action type and payload
 */
export function removeWorkflowsFromAllTeamsSuccessAction(
  clientId: string,
  workflows: string[]
): IRemoveWorkflowsFromAllTeamsSuccessAction {
  return {
    type: types.REMOVE_WORKFLOWS_FROM_ALL_TEAMS_SUCCESS,
    clientId,
    workflows,
  };
}

/**
 * begin action creator that returns an action object indicating the linking of mobile users and teams
 * has began
 * @returns {Object}
 * */
export function assignMobileUsersToTeamsBeginAction(): IBeginAction {
  return {
    type: types.ASSIGN_MOBILE_USERS_TO_TEAMS_BEGIN,
  };
}

export interface IAssignMobileUsersToTeamsSuccessAction extends IAction {
  links: any;
}

/**
 * begin action creator that returns an action object indicating the linking of web users and teams
 * has began
 * @returns {Object}
 * */
export function assignWebUsersToTeamsBeginAction(): IBeginAction {
  return {
    type: types.ASSIGN_WEB_USERS_TO_TEAMS_BEGIN,
  };
}

export interface IAssignWebUsersToTeamsSuccessAction extends IAction {
  links: any;
}

/**
 * Success action creator that returns an action object
 * @param {Array} links Array of new links
 * @returns {Object}
 * */
export function assignMobileUsersToTeamsSuccessAction(
  links: any
): IAssignMobileUsersToTeamsSuccessAction {
  return {
    type: types.ASSIGN_MOBILE_USERS_TO_TEAMS_SUCCESS,
    links,
  };
}

/**
 * Success action creator that returns an action object
 * @param {Array} links Array of new links
 * @returns {Object}
 * */
export function assignWebUsersToTeamsSuccessAction(
  links: any
): IAssignWebUsersToTeamsSuccessAction {
  return {
    type: types.ASSIGN_WEB_USERS_TO_TEAMS_SUCCESS,
    links,
  };
}

/**
 * action creator that returns an action object with
 * @param {Object} error Error Object
 * @returns {Object}
 * */
export function assignMobileUsersToTeamsFailureAction(
  error: any
): IActionError {
  return {
    type: types.ASSIGN_MOBILE_USERS_TO_TEAMS_FAILURE,
    error,
  };
}

/**
 * action creator that returns an action object with
 * @param {Object} error Error Object
 * @returns {Object}
 * */
export function assignWebUsersToTeamsFailureAction(error: any): IActionError {
  return {
    type: types.ASSIGN_WEB_USERS_TO_TEAMS_FAILURE,
    error,
  };
}

export function updateHierarchyBeginAction(): IBeginAction {
  return {
    type: types.UPDATE_HIERARCHY_BEGIN,
  };
}

export interface IUpdateHierarchySuccessAction extends IAction {
  teams: ITeam[];
}

export function updateHierarchySuccessAction(
  teams: ITeam[]
): IUpdateHierarchySuccessAction {
  return {
    type: types.UPDATE_HIERARCHY_SUCCESS,
    teams,
  };
}

export function updateHierarchyFailureAction(error: any): IActionError {
  return {
    type: types.UPDATE_HIERARCHY_FAILURE,
    error,
  };
}

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearTeamDataAction(): IAction {
  return {
    type: types.CLEAR_DATA,
  };
}

export interface IChangeSubcategorySuccessActionCreator extends IAction {
  subcategory: any;
}

/**
 * @param {Object} subcategory Can be either the list of teams or the list of a level
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: any
): IChangeSubcategorySuccessActionCreator {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}
