import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../../actionCreators/ajaxActionCreator";
import {
  createTransformationApiCall,
  deleteTransformationApiCall,
  fetchTransformationsApiCall,
  updateTransformationApiCall,
} from "./api";
import { createNotificationActionCreator } from "../../../notifications/actionCreator";
import * as notificationTypes from "../../../notifications/actionTypes";
import * as levels from "../../../notifications/constants";
import {
  createTransformationBeginActionCreator,
  createTransformationFailureActionCreator,
  createTransformationSuccessActionCreator,
  deleteTransformationBeginActionCreator,
  deleteTransformationFailureActionCreator,
  deleteTransformationSuccessActionCreator,
  fetchAllTransformationsBeginActionCreator,
  fetchAllTransformationsFailureActionCreator,
  fetchAllTransformationsSuccessActionCreator,
  updateTransformationBeginActionCreator,
  updateTransformationFailureActionCreator,
  updateTransformationSuccessActionCreator,
} from "./actionCreators";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../../actions/appActions";
import { getLang } from "../../../authentication/redux/selector";
import * as lang from "../../../../lang";
import { getSuccessNotificationMessage } from "../../../../lang/utils";
import {
  ITransformation,
  TRANSFORMATION_STATUS,
} from "model/entities/Dashboard";
import { IDispatchAndGetState } from "store/model";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";

// TRANSFORMATIONS

/**
 * Create Transformation action which creates a transformation for embedding
 *   title: string;
  query: string;
  primary_key: string;
  delta_time: number;
  transformation_aggregation_period: string;
  historical_load_days: number;
  indexes: string;
 * @param {String} title Title of the transformation
 * @param {String} query Query for the transformation
 * @param {String} primary_key Primary key of the transformation
 * @param {Number} delta_time Delta time of transformations in minutes
 * @param {Number} transformation_aggregation_period Transformation aggregation period (between "instant", "hourly", "daily", "weekly" and "monthly")
 * @param {Number} historical_load_days Historical load days of transformations in days
 * @param {String} indexes Indexes of the transformation
 * @returns {Function}
 */
export const createTransformationAction: ICreateTransformationActionFunc = (
  transformation: ITransformation
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createTransformationBeginActionCreator());

    return createTransformationApiCall(transformation)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id, integrations } = data;
        const transformationWithId = {
          ...transformation,
          id: id,
          integrations: integrations,
        };
        dispatch(ajaxSuccessAction());
        dispatch(
          createTransformationSuccessActionCreator(transformationWithId)
        );
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.TRANSFORMATION
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateTransformation",
          error,
          createTransformationFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update Transformation action which creates a transformation for embedding
 * @param {Object} transformation Transformation object to edit
 * @returns {Function}
 */
export const updateTransformationAction: TUpdateTransformationActionFunc = (
  transformation
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateTransformationBeginActionCreator());

    return updateTransformationApiCall({ ...transformation })
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(
          updateTransformationSuccessActionCreator({
            ...transformation,
            integrations: data.integrations,
            status: transformation.last_update
              ? transformation.status
              : transformation.freeze
              ? TRANSFORMATION_STATUS.FROZEN
              : TRANSFORMATION_STATUS.VALIDATION,
          })
        );

        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.TRANSFORMATION,
              transformation.title
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateTransformation",
          error,
          updateTransformationFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch allTransformations
 * @returns {Function}
 */
export const fetchAllTransformationsAction: TFetchAllTransformationsActionFunc =
  (clientId: string) => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAllTransformationsBeginActionCreator());

      return fetchTransformationsApiCall(clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { transformations } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllTransformationsSuccessActionCreator(transformations)
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllTransformationsError",
            error,
            fetchAllTransformationsFailureActionCreator,
            currLang
          );
        });
    };
  };

/**
 * Delete Transformation Action dispatches action creators to redux store and makes api calls to delete a license by id
 * @param {String} transformation_id Transformation id to delete
 * @param {String} transformation_name Transformation name to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteTransformationAction: TDeleteTransformationActionFunc = (
  transformationId,
  transformationName
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteTransformationBeginActionCreator());

    return deleteTransformationApiCall(transformationId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteTransformationSuccessActionCreator(transformationId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.TRANSFORMATION,
              transformationName
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteTransformationError",
          error,
          deleteTransformationFailureActionCreator,
          currLang
        );
      });
  };
};

export type ICreateTransformationActionFunc = (
  transformation: ITransformation
) => IDispatchAndGetState<void>;
export type TUpdateTransformationActionFunc = (
  transformation: ITransformation
) => IDispatchAndGetState<any>;
export type TFetchAllTransformationsActionFunc = (
  clientId: string
) => IDispatchAndGetState<void>;
export type TDeleteTransformationActionFunc = (
  transformationId: string,
  transformationName: string
) => IDispatchAndGetState<void>;

export interface ITransformationActions {
  createTransformationAction: ICreateTransformationActionFunc;
  updateTransformationAction: TUpdateTransformationActionFunc;
  fetchAllTransformationsAction: TFetchAllTransformationsActionFunc;
  deleteTransformationAction: TDeleteTransformationActionFunc;
}

const actions: ITransformationActions = {
  createTransformationAction: createTransformationAction,
  updateTransformationAction: updateTransformationAction,
  fetchAllTransformationsAction: fetchAllTransformationsAction,
  deleteTransformationAction: deleteTransformationAction,
};

export default actions;
