import {
  IMobileUser as IBEMobileUser,
  IWebUser as IBEWebUser,
} from "smala-tools/dist/src/types";
import { IMobileUser, IWebUser } from "model/entities/User";

const prepareMobileUserForFrontend = (
  mobileUser: IBEMobileUser
): IMobileUser => {
  const result: IMobileUser = { ...(mobileUser as any) } as IMobileUser;
  return result;
};

const prepareMobileUserForBackend = (
  mobileUser: IMobileUser
): IBEMobileUser => {
  const result: IBEMobileUser = { ...(mobileUser as any) } as IBEMobileUser;
  Object.keys(result).forEach((att) => {
    if (result[att] === "" || result[att] === null) {
      delete result[att];
    }
  });
  return result;
};

const prepareWebUserForFrontend = (webUser: IBEWebUser): IWebUser => {
  const result: IWebUser = { ...(webUser as any) } as IWebUser;
  return result;
};

const prepareWebUserForBackend = (webUser: IWebUser): IBEWebUser => {
  const result: IBEWebUser = { ...(webUser as any) } as IBEWebUser;
  Object.keys(result).forEach((att) => {
    if (result[att] === "" || result[att] === null) {
      delete result[att];
    }
  });
  return result;
};

export {
  prepareMobileUserForFrontend,
  prepareMobileUserForBackend,
  prepareWebUserForFrontend,
  prepareWebUserForBackend,
};
