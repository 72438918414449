import apiService from "../../../../api/api";
import { CLIENT_ENDPOINT } from "../../../clients/redux/endpoints";
import { prepareMobileUserForBackend } from "containers/usermgt/redux/utils";
import { IMobileUser } from "model/entities/User";
import { IMobileUser as IBEMobileUser } from "smala-tools/dist/src/types/users";
import { IServerResponse } from "actions/appActions";

export const APP_USERS_ENDPOINT = "/mobileusers";
export const APP_USER_ENDPOINT = "/mobileuser";

export type TCreateMobileUsersFunc = (
  users: IMobileUser[],
  clientId: string
) => Promise<IServerResponse<{ id: string; iid: string }[]>>;
export const createMobileUsersApiCall: TCreateMobileUsersFunc = (
  users: IMobileUser[],
  clientId: string
) => {
  return apiService.post(`/clients/${clientId}${APP_USERS_ENDPOINT}`, {
    users: users.map((user) => prepareMobileUserForBackend(user)),
  });
};

export type TFetchMobileUsersFunc = (
  clientId: string
) => Promise<IServerResponse<IBEMobileUser[]>>;
export const fetchMobileUsersApiCall: TFetchMobileUsersFunc = (
  clientId: string
) => {
  return apiService.get(`${CLIENT_ENDPOINT}/${clientId}${APP_USERS_ENDPOINT}`);
};

export type TDeleteMobileUserFunc = (
  userId: string,
  clientId: string
) => Promise<IServerResponse<{ deleted: boolean }>>;
export const removeMobileUserFromClientApiCall: TDeleteMobileUserFunc = (
  userId: string,
  clientId: string
) => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${clientId}${APP_USER_ENDPOINT}/${userId}`
  );
};

export type TDeleteMobileUsersFunc = (
  userIds: string[],
  clientId: string
) => Promise<IServerResponse<{}>>;
export const removeMobileUsersFromClientApiCall: TDeleteMobileUsersFunc = (
  userIds: string[],
  clientId: string
) => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${clientId}${APP_USERS_ENDPOINT}`,
    {
      ids: userIds,
    }
  );
};

export type TUpdateMobileUserFunc = (
  mobileUser: IMobileUser,
  clientId: string
) => Promise<IServerResponse<{ success: boolean }>>;
export const updateMobileUserApiCall: TUpdateMobileUserFunc = (
  mobileUser: IMobileUser,
  clientId: string
) => {
  // remove id from the body
  const preparedMobileUser = prepareMobileUserForBackend(mobileUser);
  const { id, ...body } = preparedMobileUser;
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}${APP_USER_ENDPOINT}/${mobileUser.id}`,
    body
  );
};

export type TUpdateMobileUsersFunc = (
  mobileUsers: IMobileUser[],
  clientId: string
) => Promise<IServerResponse<{ success: boolean }>>;
export const updateMobileUsersApiCall: TUpdateMobileUsersFunc = (
  mobileUsers: IMobileUser[],
  clientId: string
) => {
  const body = {
    users: mobileUsers.map((u) => prepareMobileUserForBackend(u)),
  };
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}${APP_USERS_ENDPOINT}`,
    body
  );
};

export type TLicenseMobileUserFunc = (
  clientId: string,
  userId: string
) => Promise<IServerResponse<{ success: boolean }>>;
export const licenseMobileUserApiCall: TLicenseMobileUserFunc = (
  clientId: string,
  userId: string
) => {
  return apiService.post(`${CLIENT_ENDPOINT}/${clientId}/license`, {
    user_id: userId,
  });
};

export type TUnLicenseMobileUserFunc = (
  clientId: string,
  userId: string
) => Promise<IServerResponse<{ deleted: boolean }>>;
export const unlicenseMobileUserApiCall: TUnLicenseMobileUserFunc = (
  clientId: string,
  userId: string
) => {
  return apiService.delete(`${CLIENT_ENDPOINT}/${clientId}/license/${userId}`);
};

export type TBulkLicenseMobileUserFunc = (
  clientId: string,
  userIds: string[]
) => Promise<IServerResponse<{ success: boolean }>>;
export const bulkLicenseMobileUsersApiCall: TBulkLicenseMobileUserFunc = (
  clientId: string,
  userIds: string[]
) => {
  return apiService.post(`${CLIENT_ENDPOINT}/${clientId}/licenses`, {
    mobile_users: userIds,
  });
};

export type TBulkUnLicenseMobileUserFunc = (
  clientId: string,
  userIds: string[]
) => Promise<IServerResponse<{ deleted: boolean }>>;
export const bulkUnlicenseMobileUsersApiCall: TBulkUnLicenseMobileUserFunc = (
  clientId: string,
  userIds: string[]
) => {
  return apiService.delete(`${CLIENT_ENDPOINT}/${clientId}/licenses`, {
    mobile_users: userIds,
  });
};

export type TUploadFileFunc = (
  clientId: string,
  files: any[],
  fileMetas: any
) => Promise<
  IServerResponse<{
    uploaded: boolean;
    files: { filename: string; url: string }[];
  }>
>;
export const uploadFileApiCall: TUploadFileFunc = (
  clientId: string,
  files: any[],
  fileMetas: any
) => {
  return apiService.multipartUpdate(`/clients/${clientId}/files`, files, {
    file_metas: fileMetas,
  });
};

export type TUnlockLoginFunc = (
  mobileUserId: string
) => Promise<IServerResponse<{ [s: string]: string }>>;
export const unlockLoginApiCall: TUnlockLoginFunc = (mobileUserId) => {
  return apiService.post(`/mobileuser/${mobileUserId}/unlock`, {});
};
interface IFetchMobUserSubmissionDetailResponse {
  submission: any;
}

export const fetchMobUserSubmissionDetailApiCall = (
  workflowId: string,
  submissionId: string,
  clientId: string
): Promise<IServerResponse<IFetchMobUserSubmissionDetailResponse>> => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}/workflows/${workflowId}/submissions/${submissionId}`
  );
};
interface IFetchMobUserSubmissionsResponse {
  submissions: any[];
}

export const fetchMobUserSubmissionsApiCall = (
  clientId: string,
  query: any
): Promise<IServerResponse<IFetchMobUserSubmissionsResponse>> => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}/workflows/submissions`,
    query
  );
};
