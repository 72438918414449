import { IClient } from "../../../model/entities/Client";

/**
 * Return client that match the id or the first active client of a list of clients
 * @param {Array} clients Array of all possible clients
 * @param {Object} selectId string of the id of a client
 * @returns {Object} A client or undefined in the case that no client is active
 * */
export const firstClientActive = (clients: IClient[], selectId?: string) => {
  let selectedClient = clients
    .filter((c) => c.id === selectId)
    .find((client) => !client.hasOwnProperty("actif") || client.actif);

  return selectedClient
    ? selectedClient
    : clients.find((client) => !client.hasOwnProperty("actif") || client.actif);
};
