import { IEmailGenerationState } from "./reducer";

const initialState: IEmailGenerationState = {
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isComputing: false,
  selectedEmailGeneration: null,
  emailGenerations: [],
  errors: [],
};

export default initialState;
