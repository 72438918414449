import { createSelector } from "reselect";
import { customSelectorCreator } from "../../../selectors/appSelector";
import IRootState from "store/model";
import {
  IDashboard,
  DASHBOARD_SCOPE,
  DASHBOARD_TYPE,
} from "model/entities/Dashboard";
import { teamsComposedSelector } from "containers/teams/redux/selectors";
import { ITeamSelector } from "model/entities/Team";
import { isOptimetriksRole } from "model/application/ActionCode";
import {
  getActionsAvailableSelector,
  getPrivilege,
  userBusinessRole,
} from "containers/authentication/redux/selector";

/**
 * Selected sub-category
 * dashboards
 */
export const getSelectedSubCategory = (state: IRootState) =>
  state.dashboards.subCategorySelected;

/**
 * Gets the state of fetching dashboards
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingAllDashboards = (state: IRootState) =>
  state.dashboards.isFetchingAll;

/**
 * Gets the state of computing dashboards
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsComputingDashboards = (state: IRootState) =>
  state.dashboards.isComputing;

/**
 * Gets the status of creating a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsCreatingDashboard = (state: IRootState) =>
  state.dashboards.isCreating;

/**
 * Gets the status of updating a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsUpdatingDashboard = (state: IRootState) =>
  state.dashboards.isUpdating;

/**
 * Gets the status of deleting a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsDeletingDashboard = (state: IRootState) =>
  state.dashboards.isDeleting;

/**
 * Gets the status of archiving a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsArchivingDashboard = (state: IRootState) =>
  state.dashboards.isArchiving;

/**
 * Gets the status of restoring a dashboard
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsRestoringDashboard = (state: IRootState) =>
  state.dashboards.isRestoring;

/**
 * Gets the currenly viewing dashboard
 * @param {Object} state
 * @returns {String}
 */
export const getSelectedDashboard = (state: IRootState) =>
  state.dashboards.selectedDashboard;

/**
 * Gets all dashboards from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
const getAllDashboards = (state: IRootState) => state.dashboards.dashboards;

/**
 * Selector to get all Dashboards from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allDashboardsSelector = customSelectorCreator(
  [getAllDashboards],
  (allDashboards: IDashboard[]) => {
    // map all the dashboards
    return allDashboards;
  }
);

export const dashboardsComposedSelector = createSelector(
  [
    getAllDashboards,
    teamsComposedSelector,
    getActionsAvailableSelector,
    userBusinessRole,
    getPrivilege,
  ],
  (
    dashboards,
    teams: ITeamSelector[],
    actions,
    business_role,
    user_role
  ): IDashboard[] => {
    return dashboards
      .filter(
        (dashboard) =>
          dashboard.type !== DASHBOARD_TYPE.MOBILE || actions["EDIT_DASHBOARD"]
      )
      .filter(
        (dashboard) =>
          !dashboard.hasOwnProperty("scope") ||
          !dashboard.hasOwnProperty("web_user_roles") ||
          dashboard.scope !== DASHBOARD_SCOPE.GLOBAL ||
          (dashboard.scope === DASHBOARD_SCOPE.GLOBAL &&
            dashboard.web_user_roles &&
            dashboard.web_user_roles.length === 0) ||
          (dashboard.scope === DASHBOARD_SCOPE.GLOBAL &&
            dashboard.web_user_roles &&
            dashboard.web_user_roles.length !== 0 &&
            (dashboard.web_user_roles.includes(business_role) ||
              isOptimetriksRole(user_role)))
      )
      .map((dashboard) => {
        dashboard.actif =
          !dashboard.hasOwnProperty("actif") ||
          dashboard.actif === undefined ||
          dashboard.actif;

        // link teams to dashboards
        dashboard.teams = [];
        teams.forEach((team: ITeamSelector) => {
          if (team.dashboards.map((o) => o.key).includes(dashboard.id)) {
            dashboard.teams!.push(team.id);
          }
        });

        return dashboard;
      });
  }
);

export const dashboardsWithTeamScopeComposedSelector = createSelector(
  [dashboardsComposedSelector],
  (dashboards) => dashboards.filter((d) => d.scope === DASHBOARD_SCOPE.TEAM)
);
