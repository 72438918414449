export default () => ({
  grow: {
    flexGrow: 1.8,
  },
  navLinks: {
    display: "flex",
    "justify-content": "space-around",
    "align-items": "center",
    flex: "1",
    "flex-direction": "row-reverse",
  },
  actionWrapper: {
    display: "flex",
    "justify-content": "space-around",
  },
});
