// main colors
export const primaryColor =
  process.env.REACT_APP_ENV === "production"
    ? "#124E5D"
    : process.env.REACT_APP_ENV === "development"
    ? "#A72894"
    : "#00A9EC";
export const secondaryColor = "#FFBD55";
export const cancelColor = "#CCC";
export const defaultColor = "#000";
export const defaultBackground = "#FFF";
export const successColor = "#00AC6A";
export const warningColor = "#FFB236";
export const infoColor = "#2CA8FF";
export const errorColor = "#EF472F";
// other colors (used for dashboards mainly)
export const darkteal = "#1e434a";
export const lightred = "#fe938b";
export const blue = "#19548f";
export const yellow = "#febd55";
export const darkgray = "#6c6c6c";
export const lightpurple = "#bb7ab4";
export const lightblue = "#639faa";
export const lightgray = "#c6c6c6";
// Extended colors (dashboards)
export const orange = "#ff5722";
export const teal = "#4d7a85";
export const bluegray = "#87a5ad";
export const lightbluegray = "#c3d2d6";

export const black = "#222222";
export const red = "#EF472F";
export const deepblue = "#111B34";
