import {
  fetchWebUsersBeginActionCreator,
  fetchWebUsersFailureActionCreator,
  fetchWebUsersSuccessActionCreator,
  deleteWebUserBeginActionCreator,
  deleteWebUserFailureActionCreator,
  deleteWebUserSuccessActionCreator,
  updateWebUsersBeginActionCreator,
  updateWebUsersFailureActionCreator,
  updateWebUsersSuccessActionCreator,
  createWebUsersSuccessActionCreator,
  createWebUsersFailureActionCreator,
  createWebUsersBeginActionCreator,
  deleteWebUsersSuccessActionCreator,
  deleteWebUsersFailureActionCreator,
  deleteWebUsersBeginActionCreator,
  resetPasswordFailureActionCreator,
} from "../actionCreator/webUserActionCreator";
import {
  deleteWebUserApiCall,
  fetchWebUsersApiCall,
  updateWebUserApiCall,
  createWebUsersApiCall,
  deleteWebUsersApiCall,
  resetWebUsersPasswordApiCall,
  updateWebUsersApiCall,
} from "../api/webUserApi";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../../actionCreators/ajaxActionCreator";
import * as notificationTypes from "../../../notifications/actionTypes";
import * as levels from "../../../notifications/constants";
import { createNotificationActionCreator } from "../../../notifications/actionCreator";
import { getSelectedClient } from "../../../clients/redux/selectors";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../../actions/appActions";
import { getLang, getPrivilege } from "../../../authentication/redux/selector";
import * as lang from "../../../../lang";
import { getSuccessNotificationMessage } from "../../../../lang/utils";
import { IDispatchAndGetState } from "store/model";
import { IWebUser } from "model/entities/User";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { Dispatch } from "redux";

export type IFetchWebUsersActionFunc = (
  clientId: string,
  promise?: boolean
) => any;
export const fetchWebUsersAction: IFetchWebUsersActionFunc = (
  clientId,
  promise = undefined
) => {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];
    const privilege = getPrivilege(getState());
    dispatch(ajaxRequestAction());
    dispatch(fetchWebUsersBeginActionCreator());

    if (promise) {
      return new Promise((resolve, reject) => {
        fetchWebUsersApiCall(clientId)
          .then((serverResponse) => {
            const data = extractDataAndCheckErrorStatus(serverResponse);
            dispatch(ajaxSuccessAction());
            dispatch(fetchWebUsersSuccessActionCreator(data, privilege));
            resolve(data);
          })
          .catch((error) => {
            treatErrorNotification(
              dispatch,
              "FetchWebUsersError",
              error,
              fetchWebUsersFailureActionCreator,
              currLang
            );
            reject(error);
          });
      });
    } else {
      return fetchWebUsersApiCall(clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(fetchWebUsersSuccessActionCreator(data, privilege));
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchWebUsersError",
            error,
            fetchWebUsersFailureActionCreator,
            currLang
          );
        });
    }
  };
};

export type IUpdateWebUserActionFunc = (
  userId: string,
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  clients: string[],
  role: string,
  business_role: string
) => IDispatchAndGetState<void>;
export const updateWebUserAction: IUpdateWebUserActionFunc = (
  userId,
  firstName,
  middleName,
  lastName,
  email,
  clients,
  role,
  business_role
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateWebUsersBeginActionCreator());

    return updateWebUserApiCall(
      userId,
      firstName,
      middleName,
      lastName,
      email,
      clients,
      role,
      business_role
    )
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        let user = {
          id: userId,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email,
          role,
          clients,
          business_role,
        };
        dispatch(ajaxSuccessAction());
        dispatch(updateWebUsersSuccessActionCreator([user]));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.WEB_USER,
              `${firstName} ${lastName}`
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateWebUsersError",
          error,
          updateWebUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type IDeleteWebUserActionFunc = (
  userId: string
) => IDispatchAndGetState<void>;
export const deleteWebUserAction: IDeleteWebUserActionFunc = (userId) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteWebUserBeginActionCreator());

    return deleteWebUserApiCall(userId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteWebUserSuccessActionCreator(userId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.WEB_USER,
              ""
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteWebUserError",
          error,
          deleteWebUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IDeleteWebUsersActionFunc = (
  userIds: string[]
) => IDispatchAndGetState<void>;
export const deleteWebUsersAction: IDeleteWebUsersActionFunc = (userIds) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteWebUsersBeginActionCreator());

    return deleteWebUsersApiCall(userIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteWebUsersSuccessActionCreator(userIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.WEB_USER,
              userIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteWebUsersError",
          error,
          deleteWebUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type ICreateWebUsersActionFunc = (
  users: IWebUser[]
) => IDispatchAndGetState<any>;
export const createWebUsersAction: ICreateWebUsersActionFunc = (users) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the id of the user who is creating this user. this won't be passed in to the given POST request
    // this will instead be passed to the redux store
    // also get the created_at date time

    dispatch(ajaxRequestAction());
    dispatch(createWebUsersBeginActionCreator());
    return new Promise((resolve, reject) => {
      createWebUsersApiCall(users, getSelectedClient(getState()).id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          // create list of users with id
          users = users.map((u) => {
            return {
              ...u,
              id: data.filter((d) => d.email === u.email)[0].id,
            };
          });
          dispatch(createWebUsersSuccessActionCreator(users));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.WEB_USER,
                users.length,
                true
              )
            )
          );
          resolve(data);
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "CreateWebUsersError",
            err,
            createWebUsersFailureActionCreator,
            currLang
          );
          reject(err);
        });
    });
  };
};
export type IUpdateWebUsersActionFunc = (
  webUsers: IWebUser[]
) => IDispatchAndGetState<void>;
export const updateWebUsersAction: IUpdateWebUsersActionFunc = (webUsers) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateWebUsersBeginActionCreator());

    return new Promise((resolve, reject) => {
      updateWebUsersApiCall(webUsers, getSelectedClient(getState()).id)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(updateWebUsersSuccessActionCreator(webUsers));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.WEB_USER,
                webUsers.length,
                true
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateWebUsersError",
            error,
            updateWebUsersFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export type IResetUserPasswordActionFunc = (
  userEmail: string
) => IDispatchAndGetState<{ email: string; password: string }>;
export const resetUserPasswordAction: IResetUserPasswordActionFunc = (
  userEmail
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise((resolve, reject) => {
      resetWebUsersPasswordApiCall(userEmail)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          resolve({ email: userEmail, password: data.new_password });
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "ResetWebUsersPasswordError",
            err,
            resetPasswordFailureActionCreator,
            currLang
          );
          reject();
        });
    });
  };
};
