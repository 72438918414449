import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import UploadIcon from "@material-ui/icons/CloudUpload";
import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "../Buttons/CustomButton";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import styles from "./styles";
import CustomDialogWarning from "./CustomDialogWarning";
import CustomTooltip from "../Tooltip/CustomTooltip";
import TLang, { TInputAttributeLang } from "model/application/Lang";

type TSize = false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;

interface ICustomDialogFormProps {
  children?: any;
  title: string;
  langlabel?: { [att: string]: TInputAttributeLang };
  description?: string;
  isOpen?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConfirmAction?: () => void;
  confirmBtnText?: string;
  isDisabled?: boolean;
  size: TSize;
  onDownloadWIP?: () => void;
  onUploadWIP?: (file: any) => void;
  classes: any;
  lang: TLang;
}

interface ICustomDialogFormState {
  isUploadOpen: boolean;
  isWarningModalOpen: boolean;
}

/**
 * Component for displaying a dialog with a form (for add/edit objects)
 */
export class CustomDialogForm extends React.Component<
  ICustomDialogFormProps,
  ICustomDialogFormState
> {
  public static defaultProps = {
    size: "md" as TSize,
    saveWorkInProgress: false,
    onCancel: () => true,
    isOpen: false,
    isDisabled: false,
    confirmBtnText: "Confirm",
  };

  constructor(props: ICustomDialogFormProps) {
    super(props);
    this.state = {
      isUploadOpen: false,
      isWarningModalOpen: false,
    };
  }

  handleCancel = () => {
    const { onClose, onCancel } = this.props;
    if (onCancel) onCancel();
    onClose();
  };

  handleConfirmAction = () => {
    const { onConfirmAction, langlabel } = this.props;
    if (langlabel && langlabel.warningOnConfirm) {
      this.setState({
        isWarningModalOpen: true,
      });
    } else if (onConfirmAction) {
      onConfirmAction();
    }
  };

  handleConfirmAfterWarning = () => {
    const { onConfirmAction } = this.props;
    this.setState({
      isWarningModalOpen: false,
    });
    if (onConfirmAction) onConfirmAction();
  };

  render() {
    const {
      title,
      isOpen,
      onClose,
      onConfirmAction,
      confirmBtnText,
      description,
      children,
      isDisabled,
      classes,
      onDownloadWIP,
      onUploadWIP,
      size,
      langlabel,
      lang,
    } = this.props;
    const { isUploadOpen, isWarningModalOpen } = this.state;

    return (
      <div>
        <Dialog
          style={{ paddingLeft: "60px" }}
          maxWidth={size}
          fullWidth
          fullScreen={size === "xl"}
          open={isOpen ? true : false}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
            {children}
          </DialogContent>
          <DialogActions
            className={classes.DialogActions}
            style={size === "xl" ? { justifyContent: "flex-start" } : {}}
          >
            <CustomButton
              onClick={this.handleCancel}
              type={BUTTON_TYPES_OUTSIDE_TABLE.CANCEL}
            >
              {lang.modal.cancel}
            </CustomButton>
            {onDownloadWIP ? (
              <CustomTooltip title={lang.modal.downloadWIP}>
                <CustomButton
                  onClick={onDownloadWIP}
                  disabled={false}
                  type={BUTTON_TYPES_OUTSIDE_TABLE.WARNING}
                >
                  <DownloadIcon />
                </CustomButton>
              </CustomTooltip>
            ) : null}
            {onUploadWIP ? (
              <CustomTooltip title={lang.modal.uploadWIP}>
                <CustomButton
                  onClick={() => this.setState({ isUploadOpen: true })}
                  disabled={false}
                  type={BUTTON_TYPES_OUTSIDE_TABLE.WARNING}
                >
                  <UploadIcon />
                </CustomButton>
              </CustomTooltip>
            ) : null}
            {onConfirmAction ? (
              <CustomButton
                onClick={this.handleConfirmAction}
                disabled={isDisabled}
                type={BUTTON_TYPES_OUTSIDE_TABLE.ACTION}
              >
                {confirmBtnText}
              </CustomButton>
            ) : null}
          </DialogActions>

          <CustomDialogForm
            isOpen={isUploadOpen}
            title={lang.modal.loadWIPModalTitle}
            onConfirmAction={() => true}
            onClose={() => this.setState({ isUploadOpen: false })}
            confirmBtnText={lang.modal.upload}
            classes={classes}
            lang={lang}
          >
            <FilePond //ref={ref => this.pond = ref}
              allowMultiple={false}
              labelIdle={lang.modal.loadWIPModalDescription}
              onupdatefiles={(fileItems) => {
                if (fileItems[0]) {
                  this.handleFileInput(fileItems[0].file);
                }
              }}
            ></FilePond>
          </CustomDialogForm>
        </Dialog>
        {langlabel && langlabel.warningOnConfirm ? (
          <CustomDialogWarning
            isOpen={isWarningModalOpen}
            onClose={() => this.setState({ isWarningModalOpen: false })}
            onConfirmAction={this.handleConfirmAfterWarning}
            lang={{
              title: langlabel.warningOnConfirm.title,
              description: langlabel.warningOnConfirm.description,
            }}
            rootLang={lang}
          />
        ) : null}
      </div>
    );
  }

  handleFileInput = (resumeWorkFile: any) => {
    const { onUploadWIP } = this.props;
    try {
      // create blob url
      const blobUrl = window.URL.createObjectURL(resumeWorkFile);
      fetch(blobUrl)
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          if (onUploadWIP) onUploadWIP(JSON.parse(data));
          this.setState({ isUploadOpen: false });
        });
    } catch (error) {
      this.setState({ isUploadOpen: false });
    }
  };
}

export default withStyles(styles as any)(CustomDialogForm);
