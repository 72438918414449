import React from "react";
import {
  makeStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  ListItemAvatar
} from "@material-ui/core";
import IAppNotification, {
  LevelNotification
} from "model/application/Notification";
import { Warning } from "@material-ui/icons";
import { warningColor } from "assets/colors";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "600px",
    top: "65px",
    right: "10px",
    padding: "10px 10px",
    overflow: "auto",
    maxHeight: "300px",
    zIndex: 10
  },
  blocIcon: {
    textAlign: "center",
    alignSelf: "center"
  },
  warningIcon: {
    color: warningColor,
    fontSize: "30px"
  },
  labelNotification: {
    padding: "10px 10px"
  }
});

interface IAppNotificationsProps {
  appNotifications: IAppNotification[];
  onClickAway: () => void;
}

const AppNotifications: React.FunctionComponent<IAppNotificationsProps> = ({
  appNotifications,
  onClickAway
}) => {
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Paper className={classes.root}>
        <List>
          {appNotifications
            .map<React.ReactNode>(n => (
              <ListItem alignItems="flex-start">
                {n.level === LevelNotification.Warning && (
                  <ListItemAvatar className={classes.blocIcon}>
                    <Warning className={classes.warningIcon} />
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={n.label}
                  className={classes.labelNotification}
                />
              </ListItem>
            ))
            .reduce((prev, curr) => [prev, <hr />, curr])}
        </List>
      </Paper>
    </ClickAwayListener>
  );
};

export default AppNotifications;
