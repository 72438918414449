import * as types from "./actionTypes";
import { IAction, IActionError, IBeginAction } from "store/model";
import { IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";

/**
 * Action creator return the type of action that informs reducer a list is being created. This will normally
 * enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createListBeginAction(): IBeginAction {
  return { type: types.CREATE_LIST_BEGIN };
}

/**
 * List failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createListFailureAction(error: any): IActionError {
  return { type: types.CREATE_LIST_FAILURE, error };
}

export interface ICreateListSuccessAction extends IAction {
  clientId: string;
  list: IList;
}

/**
 * List create success action is used to inform reducer of successful ajax request
 * @param {String} clientId Client Id this newly created List belongs to
 * @param {Object} list Object with List information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createListSuccessAction(
  clientId: string,
  list: IList
): ICreateListSuccessAction {
  return { type: types.CREATE_LIST_SUCCESS, clientId, list };
}

export interface ICreateItemsSuccessAction extends IAction {
  listId: string;
  items: IListItem[];
  listSchema: IListSchema[];
}

/**
 * Item creation success action is used to inform reducer of successful ajax request for creating a new item for a
 * given list in a client. will return an action with the type and payload
 * @param {String} listId List id of the newly inserted items
 * @param {Array} items List of items to insert in the list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createItemsSuccessAction(
  listId: string,
  items: IListItem[],
  listSchema: IListSchema[]
): ICreateItemsSuccessAction {
  return {
    type: types.CREATE_ITEMS_SUCCESS,
    listId,
    items,
    listSchema,
  };
}

/**
 * Action creator return the type of action that informs reducer an item is being created for a list
 * this will normally enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createItemsBeginAction(): IBeginAction {
  return { type: types.CREATE_ITEMS_BEGIN };
}

/**
 * Object failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createItemsFailureAction(error: any): IActionError {
  return { type: types.CREATE_ITEMS_FAILURE, error };
}

/**
 * Fetch All lists begin action. is used to dispatch action to the redux store a fetch all list request is being
 * requested.
 * @return {Object} Object with type of action
 * */
export function fetchListsForClientBeginAction(): IBeginAction {
  return { type: types.FETCH_LISTS_FOR_CLIENT_BEGIN };
}

/**
 * Fetch All List Failure action dispatched when there was an error fetching all lists. This will take in the
 * error object that will be used for logging.
 * @param {Object} error Error object with information of the failure
 * @return {Object} Object with type of action and the error object
 * */
export function fetchListsForClientFailureAction(error: any): IActionError {
  return { type: types.FETCH_LISTS_FOR_CLIENT_FAILURE, error };
}

export interface IFetchListsForClientSuccessAction extends IAction {
  type: string;
  clientId: string;
  lists: IList[];
}

/**
 * Fetch all lists success action, which is dispatched when there was a successful retrieval of lists from the API
 * or redux store. This dispatches the client id and the array of list objects
 * @param {String} clientId Client Id
 * @param {Array} lists An array of list objects
 * @return {Object} Object Object with type of action and the array of list objects
 * */
export function fetchListsForClientSuccessAction(
  clientId: string,
  lists: IList[]
): IFetchListsForClientSuccessAction {
  return {
    type: types.FETCH_LISTS_FOR_CLIENT_SUCCESS,
    clientId,
    lists,
  };
}

/**
 * Action creator for setting the flag when fetching items for a given list
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function fetchItemsForListBeginAction(detail?: boolean): IBeginAction {
  return {
    type: detail ? types.FETCH_ITEM_BEGIN : types.FETCH_ITEMS_BEGIN,
  };
}

export interface IFetchListByIdSuccessAction extends IAction {
  list: IList;
}

export interface IFetchItemsForListSuccessAction extends IAction {
  listId: string;
  items: IListItem[];
  offset: number;
  eraseExisting: boolean;
  listSchema: IListSchema[];
  query?: any;
}
export interface IFetchListItemActivitiesSuccessAction extends IAction {
  listId: string;
  query: any;
}
/**
 * Action creator dispatched when there is a successful fetch of items for a given list
 * @param {String} listId List Id we are fetching items for
 * @param {Array} items Array of list items
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function fetchItemsForListSuccessAction(
  listId: string,
  items: IListItem[],
  offset: number,
  eraseExisting: boolean,
  listSchema: IListSchema[],
  query?: any
): IFetchItemsForListSuccessAction {
  return {
    type: types.FETCH_ITEMS_SUCCESS,
    listId,
    items,
    offset,
    eraseExisting,
    listSchema,
    query,
  };
}

/**
 * Action creator for setting the flag when fetching items for a given list
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function fetchItemsForListFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_ITEMS_FAILURE,
    error,
  };
}
/**
 * Action creator for setting the flag when fetching list item activities
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function fetchListItemsActivityBeginAction(): IBeginAction {
  return { type: types.FETCH_ITEM_ACTIVITY_BEGIN };
}
/**
 * Action creator for setting the flag when fetching list item activities
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function fetchListItemsActivityFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_ITEM_ACTIVITY_FAILURE,
    error,
  };
}

export function fetchListItemsActivitySuccessAction(
  listId: string,
  query?: any
): IFetchListItemActivitiesSuccessAction {
  return {
    type: types.FETCH_ITEM_ACTIVITY_SUCCESS,
    listId,
    query,
  };
}
/**
 * Creates fetch submission action when a submission is being fetched
 * @returns {Object}
 */
export function fetchSubmissionBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_SUBMISSION_DETAIL_BEGIN,
  };
}
/**
 * Creates an fetch submission failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchSubmissionFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_SUBMISSION_DETAIL_FAILURE,
    error,
  };
}
export interface IFetchSubmissionSuccessActionCreator extends IAction {
  submission: any;
}
/**
 * creates a success action when we have successfully fetched submissions, pass in the fetched submissions
 * @param {Array} submissions new submissions fetched
 * @returns {Object}
 */
export function fetchSubmissionSuccessActionCreator(
  submission: any
): IFetchSubmissionSuccessActionCreator {
  return {
    type: types.FETCH_SUBMISSION_DETAIL_SUCCESS,
    submission,
  };
}
/**
 * Update List action used to inform reducer of failed ajax request to update a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateListFailureAction(error: any): IActionError {
  return { type: types.UPDATE_LIST_FAILURE, error };
}

export interface IUpdateListSuccessAction extends IAction {
  list: IList;
  attrVisibility?: boolean;
}

/**
 * Update List action used to inform reducer of failed ajax request to update a list
 * @param {Object} list Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateListSuccessAction(
  list: IList,
  attrVisibility?: boolean
): IUpdateListSuccessAction {
  return { type: types.UPDATE_LIST_SUCCESS, list, attrVisibility };
}

/**
 * Update list action used to inform reducer of failed ajax request to update a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateListBeginAction(): IBeginAction {
  return { type: types.UPDATE_LIST_BEGIN };
}

/**
 * Delete List action used to inform reducer of beginning ajax request to delete a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteListBeginAction(): IBeginAction {
  return { type: types.DELETE_LIST_BEGIN };
}

export interface IDeleteListSuccessAction extends IAction {
  id: string;
}

/**
 * Delete List action used to inform reducer of successful ajax request to delete a list
 * @param {String} id Id of the list to delete
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteListSuccessAction(id: string): IDeleteListSuccessAction {
  return { type: types.DELETE_LIST_SUCCESS, id };
}

/**
 * Delete List action used to inform reducer of successful ajax request to delete a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteListFailureAction(error: any): IActionError {
  return { type: types.DELETE_LIST_FAILURE, error };
}

/**
 * Archive List action used to inform reducer of beginning ajax request to archive a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveListBeginAction(): IBeginAction {
  return { type: types.ARCHIVE_LIST_BEGIN };
}

export interface IArchiveListSuccessAction extends IAction {
  id: string;
}

/**
 * Archive List action used to inform reducer of successful ajax request to archive a list
 * @param {String} id Id of the list to archive
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveListSuccessAction(
  id: string
): IArchiveListSuccessAction {
  return { type: types.ARCHIVE_LIST_SUCCESS, id };
}

/**
 * Archive List action used to inform reducer of successful ajax request to archive a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveListFailureAction(error: any): IActionError {
  return { type: types.ARCHIVE_LIST_FAILURE, error };
}

/**
 * Restore List action used to inform reducer of beginning ajax request to restore a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreListBeginAction(): IBeginAction {
  return { type: types.RESTORE_LIST_BEGIN };
}

export interface IRestoreListSuccessAction extends IAction {
  id: string;
}

/**
 * Restore List action used to inform reducer of successful ajax request to restore a list
 * @param {String} id Id of the list to restore
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreListSuccessAction(
  id: string
): IRestoreListSuccessAction {
  return { type: types.RESTORE_LIST_SUCCESS, id };
}

/**
 * Restore List action used to inform reducer of successful ajax request to restore a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreListFailureAction(error: any): IActionError {
  return { type: types.RESTORE_LIST_FAILURE, error };
}

/**Action creator that returns an action type stating that item deletion has just commenced*/
export function deleteItemsBeginAction(): IBeginAction {
  return {
    type: types.DELETE_ITEMS_BEGIN,
  };
}

export interface IDeleteItemsSuccessAction extends IAction {
  listId: string;
  ids: string[];
}

/**
 * Delete items from list success action creator is dispatched when there is a successful deletion of a
 * item from a list
 * @param {String} listId List id the items belongs to
 * @param {String} ids Ids of the deleted items
 * @returns {Object} Object with action type and payload
 */
export function deleteItemsSuccessAction(
  listId: string,
  ids: string[]
): IDeleteItemsSuccessAction {
  return {
    type: types.DELETE_ITEMS_SUCCESS,
    listId,
    ids,
  };
}

/**
 * Action creator returning the schema of the list with the new filters
 * was a failure in deleting items from a list.
 * @param {Object} error Error Object
 * @returns {Object} Object with type of action and error objet
 */
export function deleteItemsFailureAction(error: any): IActionError {
  return {
    type: types.DELETE_ITEMS_FAILURE,
    error,
  };
}

/**Action creator that returns an action type stating a object from a list archive has just commenced*/
export function archiveItemsBeginAction(): IBeginAction {
  return {
    type: types.ARCHIVE_ITEMS_BEGIN,
  };
}

export interface IArchiveItemsSuccessAction extends IAction {
  listId: string;
  ids: string[];
}

/**
 * Archive items from list success action creator is dispatched when there is a successful archive an
 * items from a list
 * @param {String} listId List id the items belongs to
 * @param {String} ids Ids of the archived items
 * @returns {Object} Object with action type and payload
 */
export function archiveItemsSuccessAction(
  listId: string,
  ids: string[]
): IArchiveItemsSuccessAction {
  return {
    type: types.ARCHIVE_ITEMS_SUCCESS,
    listId,
    ids,
  };
}

/**
 * Action creator returning the schema of the list with the new filters
 * was a failure in archiving items from a list.
 * @param {Object} error Error Object
 * @returns {Object} Object with type of action and error objet
 */
export function archiveItemsFailureAction(error: any): IActionError {
  return {
    type: types.ARCHIVE_ITEMS_FAILURE,
    error,
  };
}

/**Action creator that returns an action type stating a restoration of items has just commenced*/
export function restoreItemsBeginAction(): IBeginAction {
  return {
    type: types.RESTORE_ITEMS_BEGIN,
  };
}

export interface IRestoreItemsSuccessAction extends IAction {
  listId: string;
  ids: string[];
}

/**
 * Restore items from list success action creator is dispatched when there is a successful restore an
 * item from a list
 * @param {String} listId List id the items belongs to
 * @param {String} ids Ids of the restored items
 * @returns {Object} Object with action type and payload
 */
export function restoreItemsSuccessAction(
  listId: string,
  ids: string[]
): IRestoreItemsSuccessAction {
  return {
    type: types.RESTORE_ITEMS_SUCCESS,
    listId,
    ids,
  };
}

/**
 * Action creator returning the schema of the list with the new filters
 * was a failure in restoring items from a list.
 * @param {Object} error Error Object
 * @returns {Object} Object with type of action and error objet
 */
export function restoreItemsFailureAction(error: any): IActionError {
  return {
    type: types.RESTORE_ITEMS_FAILURE,
    error,
  };
}

/**
 * Update Item action used to inform reducer of failed ajax request to update an item
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateItemsFailureAction(error: any): IActionError {
  return { type: types.UPDATE_ITEMS_FAILURE, error };
}

export interface IUpdateItemsSuccessAction extends IAction {
  items: IListItem[];
  listId: string;
}

/**
 * Update Item action used to inform reducer of failed ajax request to update an item
 * @param {Array} items Array of items updated
 * @param {String} listId The id of the list where the items are updated
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateItemsSuccessAction(
  items: IListItem[],
  listId: string
): IUpdateItemsSuccessAction {
  return { type: types.UPDATE_ITEMS_SUCCESS, items, listId };
}

/**
 * Update item action used to inform reducer of failed ajax request to update an item
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateItemsBeginAction(): IBeginAction {
  return { type: types.UPDATE_ITEMS_BEGIN };
}

/**
 * Assign Item action used to inform reducer of failed ajax request to assign a item
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function assignItemsFailureAction(error: any): IActionError {
  return { type: types.ASSIGN_ITEMS_FAILURE, error };
}

export function unassignItemsFailureAction(error: any): IActionError {
  return { type: types.UNASSIGN_ITEMS_FAILURE, error };
}

export interface IUnassignItemsSuccessAction extends IAction {
  links: any;
}

export interface IAssignItemsSuccessAction extends IAction {
  links: any;
}

/**
 * Assign Item action used to inform reducer of failed ajax request to assign an item
 * @param {Array} links Array of new links
 * @return {Object} Object with type of action for reducer to handle
 * */
export function assignItemsSuccessAction(
  links: any
): IAssignItemsSuccessAction {
  return { type: types.ASSIGN_ITEMS_SUCCESS, links };
}

/**
 * Unassign Item action used to inform reducer of failed ajax request to assign an item
 * @param {Array} links Array of id's to be removed
 * @return {Object} Object with type of action for reducer to handle
 * */
export function unassignItemsSuccessAction(
  links: string[]
): IUnassignItemsSuccessAction {
  return { type: types.UNASSIGN_ITEMS_SUCCESS, links };
}
/**
 * Assign item action used to inform reducer of failed ajax request to assign an item
 * @return {Object} Object with type of action for reducer to handle
 * */
export function assignItemsBeginAction(): IBeginAction {
  return { type: types.ASSIGN_ITEMS_BEGIN };
}

/**
 * Unassign item action used to inform reducer of failed ajax request to assign an item
 * @return {Object} Object with type of action for reducer to handle
 * */
export function unassignItemsBeginAction(): IBeginAction {
  return { type: types.UNASSIGN_ITEMS_BEGIN };
}

/**
 * Action creator for setting the flag when uploading a file
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function uploadFileBeginAction(): IBeginAction {
  return {
    type: types.UPLOAD_FILE_BEGIN,
  };
}

/**
 * Action creator dispatched when there is a successful upload of file
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function uploadFileSuccessAction(): IAction {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
  };
}

/**
 * Action creator for setting the flag when uploading a file has failed
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function downloadListItemsFailureAction(error: any): IActionError {
  return {
    type: types.DOWNLOAD_LIST_ITEM_FAILURE,
    error,
  };
}

/**
 * Action creator for setting the flag when uploading a file
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function downloadListItemsBeginAction(): IBeginAction {
  return {
    type: types.DOWNLOAD_LIST_ITEM_BEGIN,
  };
}

/**
 * Action creator dispatched when there is a successful upload of file
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function downloadListItemsSuccessAction(): IAction {
  return {
    type: types.DOWNLOAD_LIST_ITEM_SUCCESS,
  };
}

/**
 * Action creator for setting the flag when uploading a file has failed
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function uploadFileFailureAction(error: any): IActionError {
  return {
    type: types.UPLOAD_FILE_FAILURE,
    error,
  };
}

export interface ISelectListActionCreator extends IAction {
  selectedList: IList;
}

/**
 * Action creator that allows the selection of a list from the store when a user selects on from the table
 * @param selectedList The selected List
 * @return {Object}
 */
export const selectListActionCreator = (
  selectedList: IList
): ISelectListActionCreator => ({
  type: types.SELECT_LIST,
  selectedList,
});

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearListDataAction(): IAction {
  return {
    type: types.CLEAR_DATA,
  };
}

export interface IChangeSubcategorySuccessActionCreator extends IAction {
  subcategory: string;
}

/**
 * @param {Object} subcategory Can be either the summary or a list
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: string
): IChangeSubcategorySuccessActionCreator {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}
