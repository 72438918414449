import * as types from "./actionTypes";
import { IBeginAction, IActionError, IAction } from "store/model";
import IWorkflow, { IWorkflowGroup } from "model/entities/Workflow";

/**
 * Action creator dispatched when creating a workflow action is started
 * @returns {Object}
 */
export function createWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_WORKFLOW_BEGIN,
  };
}

/**
 * Failure/Error action creator when errors are caught when creating a workflow
 * @param {Object} error
 * @returns {Object}
 */
export function createWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_WORKFLOW_FAILURE,
    error,
  };
}

export interface ICreateWorkflowSuccessActionCreator extends IAction {
  workflowGroup: IWorkflowGroup;
  files: any[];
}

export const createWorkflowSuccessActionCreator = (
  workflowGroup: IWorkflowGroup,
  files: any[]
): ICreateWorkflowSuccessActionCreator => {
  return {
    type: types.CREATE_WORKFLOW_SUCCESS,
    workflowGroup,
    files,
  };
};

/**
 * Fetch all workflows begin action creator
 * @returns {Object}
 */
export function fetchAllWorkflowsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_ALL_WORKFLOWS_BEGIN,
  };
}

/**
 * Fetch all workflows failure action
 * @param {Object} error
 * @returns {Object}
 */
export function fetchAllWorkflowsFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.FETCH_ALL_WORKFLOWS_FAILURE,
    error,
  };
}

export interface IFetchAllWorkflowsSuccessActionCreator extends IAction {
  workflows: IWorkflow[];
}

/**
 * Fetch all workflow success action
 * @param {Array} workflows
 * @returns {Object}
 */
export function fetchAllWorkflowsSuccessActionCreator(
  workflows: IWorkflow[]
): IFetchAllWorkflowsSuccessActionCreator {
  return {
    type: types.FETCH_ALL_WORKFLOWS_SUCCESS,
    workflows,
  };
}

/**
 * Creates update workflow action when a workflow is being updated
 * @returns {Object}
 */
export function updateWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an update workflow failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_WORKFLOW_FAILURE,
    error,
  };
}

export interface IUpdateWorkflowSuccessActionCreator extends IAction {
  workflowGroup: IWorkflowGroup;
  files: any[];
}

/**
 * creates a success action when we have successfully updated a workflow, pass in the updated workflow
 * @param {Object} primary Updated primary workflow object (either a default or a generator workflow)
 * @param {Object} secondary Updated secondary workflow object (task workflow, can be null)
 * @returns {Object}
 */
export function updateWorkflowSuccessActionCreator(
  workflowGroup: IWorkflowGroup,
  files: any[]
): IUpdateWorkflowSuccessActionCreator {
  return {
    type: types.UPDATE_WORKFLOW_SUCCESS,
    workflowGroup,
    files,
  };
}

/**
 * Creates an action when we have started the deletion process for deleting a workflow
 * @returns {Object}
 */
export function deleteWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when deleting an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function deleteWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_WORKFLOW_FAILURE,
    error,
  };
}

export interface IDeleteWorkflowSuccessActionCreator extends IAction {
  workflowId: string;
  workflowAttachedId: string;
}

/**
 * Creates success action when we have successfully deleted a workflow
 * @param {String} workflowId
 * @returns {Object}
 */
export function deleteWorkflowSuccessActionCreator(
  workflowId: string,
  workflowAttachedId: string
): IDeleteWorkflowSuccessActionCreator {
  return {
    type: types.DELETE_WORKFLOW_SUCCESS,
    workflowId,
    workflowAttachedId,
  };
}

/**
 * Creates an action when we have started the archive process for archiving a workflow
 * @returns {Object}
 */
export function archiveWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.ARCHIVE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when archiving an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function archiveWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.ARCHIVE_WORKFLOW_FAILURE,
    error,
  };
}

export interface IArchiveWorkflowSuccessActionCreator extends IAction {
  workflowId: string;
  workflowAttachedId: string;
}

/**
 * Creates success action when we have successfully archive a workflow
 * @param {String} workflowId
 * @returns {Object}
 */
export function archiveWorkflowSuccessActionCreator(
  workflowId: string,
  workflowAttachedId: string
): IArchiveWorkflowSuccessActionCreator {
  return {
    type: types.ARCHIVE_WORKFLOW_SUCCESS,
    workflowId,
    workflowAttachedId,
  };
}

/**
 * Creates an action when we have started the restore process for restoring a workflow
 * @returns {Object}
 */
export function restoreWorkflowBeginActionCreator(): IBeginAction {
  return {
    type: types.RESTORE_WORKFLOW_BEGIN,
  };
}

/**
 * Creates an error action object if we encounter and error when restoring an workflow
 * @param {Object} error Error object
 * @returns {Object}
 */
export function restoreWorkflowFailureActionCreator(error: any): IActionError {
  return {
    type: types.RESTORE_WORKFLOW_FAILURE,
    error,
  };
}

export interface IRestoreWorkflowSuccessActionCreator extends IAction {
  workflowId: string;
  workflowAttachedId: string;
}

/**
 * Creates success action when we have successfully restore a workflow
 * @param {String} workflowId
 * @returns {Object}
 */
export function restoreWorkflowSuccessActionCreator(
  workflowId: string,
  workflowAttachedId: string
): IRestoreWorkflowSuccessActionCreator {
  return {
    type: types.RESTORE_WORKFLOW_SUCCESS,
    workflowId,
    workflowAttachedId,
  };
}

/**
 * Creates fetch submission action when a submission is being fetched
 * @returns {Object}
 */
export function fetchSubmissionBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_SUBMISSION_DETAIL_BEGIN,
  };
}

/**
 * Creates an fetch submission failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchSubmissionFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_SUBMISSION_DETAIL_FAILURE,
    error,
  };
}

export interface IFetchSubmissionSuccessActionCreator extends IAction {
  submission: any;
}

/**
 * creates a success action when we have successfully fetched submissions, pass in the fetched submissions
 * @param {Array} submissions new submissions fetched
 * @returns {Object}
 */
export function fetchSubmissionSuccessActionCreator(
  submission: any
): IFetchSubmissionSuccessActionCreator {
  return {
    type: types.FETCH_SUBMISSION_DETAIL_SUCCESS,
    submission,
  };
}

/**
 * Creates delete submission action when a submission is being deleted
 * @returns {Object}
 */
export function deleteSubmissionBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_SUBMISSION_BEGIN,
  };
}

/**
 * Creates an delete submission failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteSubmissionFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_SUBMISSION_FAILURE,
    error,
  };
}

export interface IDeleteSubmissionSuccessActionCreator extends IAction {
  submissionId: string;
}

/**
 * creates a success action when we have successfully deleted submissions, pass in the deleted submissions
 * @returns {Object}
 */
export function deleteSubmissionSuccessActionCreator(
  submissionId: string
): IDeleteSubmissionSuccessActionCreator {
  return {
    type: types.DELETE_SUBMISSION_SUCCESS,
    submissionId,
  };
}

/**
 * Creates fetch submission action when a submission is being fetched
 * @returns {Object}
 */
export function fetchSubmissionsBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_SUBMISSIONS_BEGIN,
  };
}

/**
 * Creates an fetch submission failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function fetchSubmissionsFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_SUBMISSIONS_FAILURE,
    error,
  };
}

export interface IFetchSubmissionsSuccessActionCreator extends IAction {
  submissions: any[];
}

/**
 * creates a success action when we have successfully fetched submissions, pass in the fetched submissions
 * @param {Array} submissions new submissions fetched
 * @returns {Object}
 */
export function fetchSubmissionsSuccessActionCreator(
  submissions: any[]
): IFetchSubmissionsSuccessActionCreator {
  return {
    type: types.FETCH_SUBMISSIONS_SUCCESS,
    submissions,
  };
}

/**
 * Creates update submission action when a submission is being updated
 * @returns {Object}
 */
export function updateSubmissionBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_SUBMISSION_BEGIN,
  };
}

/**
 * Creates an update submission failure action
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateSubmissionFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_SUBMISSION_FAILURE,
    error,
  };
}

export interface IUpdateSubmissionSuccessActionCreator extends IAction {
  submission: any;
}

/**
 * creates a success action when we have successfully updated a submission, pass in the updated submission
 * @param {Object} submission new submission updated
 * @returns {Object}
 */
export function updateSubmissionSuccessActionCreator(
  submission: any
): IUpdateSubmissionSuccessActionCreator {
  return {
    type: types.UPDATE_SUBMISSION_SUCCESS,
    submission,
  };
}

/**
 * creates a reset submission selection action
 * @returns {Object}
 */
export function resetSubmissionSelectionAction(): IAction {
  return {
    type: types.RESET_SUBMISSION_SELECTION,
  };
}

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearWorkflowDataAction(): IAction {
  return {
    type: types.CLEAR_DATA,
  };
}

/**
 * Action creator for setting the flag when uploading a file
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function uploadFileBeginAction(): IBeginAction {
  return {
    type: types.UPLOAD_FILE_BEGIN,
  };
}

/**
 * Action creator dispatched when there is a successful upload of file
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function uploadFileSuccessAction(): IAction {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
  };
}

/**
 * Action creator for setting the flag when uploading a file has failed
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function uploadFileFailureAction(error: any): IActionError {
  return {
    type: types.UPLOAD_FILE_FAILURE,
    error,
  };
}

/**
 * Action creator for fetching workflow's versions
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function fetchWorkflowVersionsBeginAction(): IBeginAction {
  return {
    type: types.FETCH_WORKFLOW_VERSIONS_BEGIN,
  };
}

export interface IFetchWorkflowVersionsSuccessAction extends IAction {
  versions: any;
}

/**
 * Action creator dispatched when there is a successful fetching workflow's versions
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function fetchWorkflowVersionsSuccessAction(
  versions: any
): IFetchWorkflowVersionsSuccessAction {
  return {
    type: types.FETCH_WORKFLOW_VERSIONS_SUCCESS,
    versions,
  };
}

/**
 * Action creator for setting the flag when fetching workflow's versions
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function fetchWorkflowVersionsFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_WORKFLOW_VERSIONS_FAILURE,
    error,
  };
}

/**
 * Action creator for restoring workflow by id client
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function restoreOldWorkflowBeginAction(): IBeginAction {
  return {
    type: types.RESTORE_OLD_WORKFLOWS_BEGIN,
  };
}

export interface IRestoreOldWorkflowSuccessAction extends IAction {
  primary: IWorkflow;
  secondary?: IWorkflow;
}

/**
 * Action creator dispatched when there is a successful restoring workflow by id client
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function restoreOldWorkflowSuccessAction(
  primary: IWorkflow,
  secondary?: IWorkflow
): IRestoreOldWorkflowSuccessAction {
  return {
    type: types.RESTORE_OLD_WORKFLOWS_SUCCESS,
    primary,
    secondary,
  };
}

/**
 * Action creator for setting the flag when restoring old workflow by id client
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function restoreOldWorkflowFailureAction(error: any): IActionError {
  return {
    type: types.RESTORE_OLD_WORKFLOWS_FAILURE,
    error,
  };
}

export interface IChangeSubcategorySuccessActionCreator extends IAction {
  subcategory: string;
}

/**
 * @param {Object} subcategory Can be either the summary or a workflow
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: string
): IChangeSubcategorySuccessActionCreator {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}
