/**
 * @notes: reducer for the authentication container
 */

import * as types from "./actionTypes";
import initialState from "./initialState";
import { insertNewItemToArr } from "../../../utils/reducerUtils";
import { IAction, IActionError } from "store/model";
import { ISignedInUser } from "model/entities/User";

export interface IAuthenticationState {
  loggingIn: boolean;
  loggingOut: boolean;
  smalaToken: null | string;
  isPasswordRecovering: boolean;
  user: ISignedInUser;
  loginError: boolean;
  errorMessage: string;
  availableActions: any;
  errors: any[];
  lastCallToCheckVersion: Date;
  lang: "en" | "fr" | "es";
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.LOGIN_REQUEST_BEGIN:
      return {
        ...state,
        loggingIn: true,
      };

    case types.LOGIN_REQUEST_SUCCESS: {
      const {
        token,
        id,
        name,
        userEmail,
        created_at,
        keepSignedIn
      } = action as any; // FIXME: write a real type

      return {
        ...state,
        loggingIn: false,
        loginError: false,
        smalaToken: token,
        user: {
          id,
          name,
          email: userEmail,
          isAuthenticated: true,
          keepSignedIn: keepSignedIn,
          createdAt: created_at
        },
      };
    }

    case types.LOGIN_REQUEST_FAILURE: {
      let errorMessage = "Wrong Email/Password";
      if ((action as IActionError).error.message === "Network Error") {
        errorMessage = "Network Error (please check your connection)";
      }
      return {
        ...state,
        loggingIn: false,
        loginError: true,
        errorMessage,
        errors: insertNewItemToArr(state.errors, action),
      };
    }

    case types.LOGOUT_REQUEST_BEGIN:
      return {
        ...state,
        loggingOut: true,
      };

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.LOGOUT_REQUEST_FAILURE:
      return {
        ...state,
        loggingOut: false,
        smalaToken: null,
        user: {
          isAuthenticated: false,
          email: "",
          keepSignedIn: false,
        },
        errors: insertNewItemToArr(state.errors, action),
      };

    case types.RECOVER_PASSWORD_BEGIN:
      return {
        ...state,
        isPasswordRecovering: true,
      };

    case types.RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        isPasswordRecovering: false,
      };

    case types.RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordRecovering: false,
      };
    }

    case types.CHANGE_LANG_SUCCES: {
      const { lang } = action as any; // FIXME: write a real type
      return {
        ...state,
        lang,
      };
    }

    case types.SET_ACTIONS_AVAILABLE_SUCCESS: {
      const { availableActions } = action as any; // FIXME: write a real type
      return {
        ...state,
        availableActions,
      };
    }

    case types.GET_LAST_VERSION_SUCCESS: {
      return {
        ...state,
        lastCallToCheckVersion: new Date(),
      };
    }

    default:
      return state;
  }
}
