/**
 * Configuration for the Store in production environment
 * This configuration is used when the application is build for production
 * This is normally not imported directly as the ENV variable will determine whether to use this configuration
 * or the development configuration
 */
import { createStore } from "redux";
import rootReducer from "../reducers/rootReducer";
import { compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { persistConfig } from "./persistConfig";
import { prodMiddleware } from "./middleware";
import { history } from "./historyConfig";

// persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

/**
 * Configures the application store
 * */
export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...prodMiddleware))
  );

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
