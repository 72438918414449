import { createSelector } from "reselect";
import capitalize from "lodash/capitalize";
import { customSelectorCreator } from "../../../../selectors/appSelector";
import IRootState from "store/model";
import { IWebUser } from "model/entities/User";
import { isOptimetriksRole } from "model/application/ActionCode";

/**
 * Selector to get web users
 * @param {Object} state State of Redux store
 */
const getWebUsers = (state: IRootState) => state.users.webUsers.allUsers;

/**
 * Fetchung users by client selector. This will return True to either fetching app
 * users
 */
export const getIsFetchingWebUsers = (state: IRootState) =>
  state.users.webUsers.fetchingAllUsers;

/**
 * Get the state of adding a new web user
 * @param {Object} state Object in Redux Store
 */
export const getIsCreatingWebUsers = (state: IRootState) =>
  state.users.webUsers.creatingUsers;

/**
 * Get the updating web user state from the Redux store
 * @param {Object} state Object in redux store
 */
export const getIsUpdatingWebUser = (state: IRootState) =>
  state.users.webUsers.updatingUser;

/**
 * Selector to get the status of deleting a user
 */
export const getIsDeletingWebUser = (state: IRootState) =>
  state.users.webUsers.deletingUser;

/** selector to getting all the web users*/
export const allWebUsersSelector = createSelector(
  getWebUsers,
  (webUsers) => webUsers
);

const getPrivilege = (state: IRootState) => {
  const role = state.clients.selectedClient
    ? state.clients.selectedClient.profile
    : "";
  return role;
};

const privilege = createSelector(getPrivilege, (privilege) => privilege);

/**
 * Formats the Web users to be displayed in a Table. This is removing unused objects from each user
 */
export const allWebUsersComposedSelector = customSelectorCreator(
  [allWebUsersSelector, privilege],
  (webUsers: IWebUser[], privilege: string): IWebUser[] => {
    const emailSuffixOptimetriks = "@optimetriks.com";
    return webUsers
      .filter((webUser) => {
        if (isOptimetriksRole(privilege)) {
          return true;
        }
        return !(
          webUser.email.endsWith(emailSuffixOptimetriks) ||
          isOptimetriksRole(webUser.role)
        );
      })
      .map((webUser) => {
        return {
          ...webUser,
          role: webUser.role || "",
        };
      });
  }
);

/**
 * Get web users by id
 * @param {Object} state Redux store state
 * @returns {Object}
 */
const getWebUsersById = (state: IRootState) => state.users.webUsers.byId;

/**
 * Selector to get the web users by id
 */
export const webUsersByIdSelector = createSelector(
  getWebUsersById,
  (byId) => byId
);

/**
 * Creates a selector for web Users
 * @param {Object} state Redux store state
 * @returns {Array} Array of web users with value and label options
 */
export const webUsersSelectOptions = createSelector(
  allWebUsersSelector,
  (webUsers: IWebUser[]) => {
    const users: any[] = [];
    webUsers.forEach((webUser) => {
      const { first_name, middle_name, last_name } = webUser;
      const name = middle_name
        ? `${first_name} ${middle_name} ${last_name}`
        : `${first_name} ${last_name}`;
      users.push({
        id: webUser.id,
        value: capitalize(name),
        label: name,
      });
    });

    return users;
  }
);
