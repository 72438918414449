import * as types from "./actionTypes";
import initialState from "./initialState";
import {
  insertNewItemToArr,
  updateObjectInArray,
  convertArrayToObject,
  removeObjectFromArray,
  removeObjectFromHash,
  extractIdsFromArray,
} from "../../../utils/reducerUtils";
import uniq from "lodash/uniq";
import moment from "moment";
import { DATE_FORMAT_FULL_DATE } from "../../../utils/constants";
import { IClient } from "model/entities/Client";
import { IAction, IActionError } from "store/model";
import { firstClientActive } from "./reducer.utils";
import { IWebUser } from "model/entities/User";

export interface IClientsStates {
  allClients: IClient[];
  allIds: string[];
  byId: { [id: string]: IClient };
  errors: any[];
  recentlyFetchedClient?: IClient;
  selectedClient: IClient;
  isFetchingClient: boolean;
  isFetchingAllClients: boolean;
  isUpdatingClient: boolean;
  isDeletingClient: boolean;
  isReplicatingClient: boolean;
  isCreatingClient: boolean;
  isArchivingClient: boolean;
  isRestoringClient: boolean;
  lastUpdated?: Date;
  invalidateCache: boolean;
  owners: IWebUser[];
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function clientReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_CLIENT_BEGIN:
      return {
        ...state,
        isCreatingClient: true,
      };

    case types.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        isCreatingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.CREATE_CLIENT_SUCCESS: {
      const { client } = action as any; // FIXME: write a real type
      client.actif = true;
      return {
        ...state,
        isCreatingClient: false,
        allClients: insertNewItemToArr(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
        allIds: uniq(state.allIds.concat([client.id])),
      };
    }
    case types.REPLICATE_CLIENT_BEGIN:
      return {
        ...state,
        isReplicatingClient: true,
      };

    case types.REPLICATE_CLIENT_FAILURE:
      return {
        ...state,
        isReplicatingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.REPLICATE_CLIENT_SUCCESS: {
      const { client } = action as any;
      client.actif = true;
      return {
        ...state,
        isReplicatingClient: false,
        allClients: insertNewItemToArr(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
        allIds: uniq(state.allIds.concat([client.id])),
      };
    }
    case types.FETCH_CLIENT_BEGIN:
      return {
        ...state,
        isFetchingClient: true,
      };

    case types.FETCH_ALL_CLIENTS_BEGIN:
      return {
        ...state,
        isFetchingAllClients: true,
        isUpdatingClient: false,
        isDeletingClient: false,
        isCreatingClient: false,
        isArchivingClient: false,
        isRestoringClient: false,
        isReplicatingClient: false,
      };

    case types.FETCH_CLIENT_FAILURE:
      return {
        ...state,
        isFetchingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.FETCH_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        isFetchingAllClients: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.FETCH_CLIENT_SUCCESS: {
      let { client } = action as any; // FIXME: write a real type
      let newList: IClient[] = [];
      state.allClients.forEach((cl) => {
        if (cl.id !== client.id) {
          newList.push(cl);
        } else {
          newList.push({
            ...cl,
            ...client,
          });
        }
      });
      return {
        ...state,
        selectedClient: {
          ...state.byId[client.id],
          ...client,
        },
        isFetchingClient: false,
        allClients: newList,
        byId: {
          ...state.byId,
          [client.id]: {
            ...state.byId[client.id],
            ...client,
          },
        },
        allIds: uniq(state.allIds.concat(client.id)),
        recentlyFetchedClient: client,
        isUpdatingClient: false,
        isDeletingClient: false,
        isCreatingClient: false,
        isArchivingClient: false,
        isRestoringClient: false,
        isReplicatingClient: false,
      };
    }

    case types.FETCH_ALL_CLIENTS_SUCCESS: {
      let { selectId } = action as any; // FIXME: write a real type
      const clients: IClient[] = (action as any).clients as IClient[];
      return {
        ...state,
        isFetchingAllClients: false,
        selectedClient: firstClientActive(clients, selectId),
        allClients: clients,
        byId: convertArrayToObject(state.byId, clients, "id"),
        allIds: extractIdsFromArray(clients),
        lastUpdated: moment().format(DATE_FORMAT_FULL_DATE),
      };
    }

    case types.UPDATE_CLIENT_BEGIN:
      return {
        ...state,
        isUpdatingClient: true,
      };

    case types.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        isUpdatingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.UPDATE_CLIENT_SUCCESS: {
      const { client } = action as any; // FIXME: write a real type

      if (state.selectedClient.id === client.id) {
        state.selectedClient = {
          ...state.selectedClient,
          ...client,
        };
      }

      return {
        ...state,
        isUpdatingClient: false,
        allClients: updateObjectInArray(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
      };
    }

    case types.DELETE_CLIENT_BEGIN:
      return {
        ...state,
        isDeletingClient: true,
      };

    case types.DELETE_CLIENT_FAILURE:
      return {
        ...state,
        isDeletingClient: false,
        errors:
          state.errors.length === 0
            ? insertNewItemToArr(state.errors, action)
            : updateObjectInArray(state.errors, action),
      };

    case types.DELETE_CLIENT_SUCCESS: {
      const { id } = action as any; // FIXME: write a real type
      return {
        ...state,
        isDeletingClient: false,
        allClients: removeObjectFromArray(state.allClients, id),
        byId: removeObjectFromHash(state.byId, id),
        allIds: state.allIds.filter((did) => did !== id),
      };
    }

    case types.ARCHIVE_CLIENT_BEGIN:
      return {
        ...state,
        isArchivingClient: true,
      };

    case types.ARCHIVE_CLIENT_FAILURE:
      return {
        ...state,
        isArchivingClient: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.ARCHIVE_CLIENT_SUCCESS: {
      const { clientId } = action as any; // FIXME: write a real type
      let client = state.byId[clientId];
      client.actif = false;
      return {
        ...state,
        isArchivingClient: false,
        allClients: updateObjectInArray(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
      };
    }

    case types.RESTORE_CLIENT_BEGIN:
      return {
        ...state,
        isRestoringClient: true,
      };

    case types.RESTORE_CLIENT_FAILURE:
      return {
        ...state,
        isRestoringClient: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.RESTORE_CLIENT_SUCCESS: {
      const { clientId } = action as any; // FIXME: write a real type
      let client = state.byId[clientId];
      client.actif = true;
      return {
        ...state,
        isRestoringClient: false,
        allClients: updateObjectInArray(state.allClients, client),
        byId: {
          ...state.byId,
          [client.id]: client,
        },
      };
    }

    case types.SELECT_CLIENT: {
      const { selectedClient } = action as any; // FIXME: write a real type
      return {
        ...state,
        selectedClient,
      };
    }
    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.FETCH_ADDITIONAL_INFOS_SUCCESS:
      const { owners } = action as any;
      return {
        ...state,
        owners,
      };

    default:
      return state;
  }
}
