import { ITableOptimization } from "model/entities/TableOptimization";
import { ITableOptimization as IBETableOptimization } from "smala-tools/dist/src/types";

const prepareTableOptimizationForBackend = (
  originalTableOptimization: ITableOptimization
): IBETableOptimization => {
  const tableOptimization = JSON.parse(
    JSON.stringify(originalTableOptimization)
  );
  Object.keys(tableOptimization).forEach((att) => {
    if (tableOptimization[att] === "options") delete tableOptimization[att];
  });
  delete tableOptimization["is_2nd_dimension"];
  return tableOptimization;
};
export { prepareTableOptimizationForBackend };
