import { WorkflowsState } from "./reducer";

const initialState: WorkflowsState = {
  subCategorySelected: "index",
  isAttaching: false,
  isFetchingAll: false,
  isDeleting: false,
  isCreating: false,
  isUpdating: false,
  isArchiving: false,
  isRestoring: false,
  isFetchingSubmissionDetail: false,
  isFetchingSubmissions: false,
  isUpdatingSubmission: false,
  isUploadingFile: false,
  isFetchingWorkflowVersions: false,
  isRestoringWorkflowVersion: false,
  selectedSubmission: undefined,
  allWorkflows: [],
  workflowVersions: [],
  allSubmissions: [],
  byId: {},
  allIds: [],
  errors: [],
  lastUpdated: new Date(),
};

export default initialState;
