import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Flag from "react-world-flags";
import styles from "./styles";
import CustomTooltipWrapper from "../Tooltip/CustomTooltipWrapper";
import { IOption } from "model/application/components";
import { TInputAttributeLang } from "model/application/Lang";

const useStyles = makeStyles(styles as any);

export interface ICustomInputSelectProps<T = string> {
  options: IOption<T>[];
  name: string;
  onChange: (optSelected: T, variableName: string) => void;
  title?: string;
  value?: T;
  smallDisplay?: boolean;
  isFlagSelection?: boolean;
  isUndetermined?: boolean;
  disabled?: boolean;
  error?: any;
  lang?: TInputAttributeLang;
}

export type TCustomSelect<T = any> = React.FunctionComponent<
  ICustomInputSelectProps<T>
>;

export const CustomSelect: TCustomSelect = (props) => {
  const [isUndetermined, setIsUndetermined] = useState(props.isUndetermined);

  const handleChange = (event: any) => {
    const { name, onChange } = props;
    event.target.name = name;
    onChange(event.target.value, name);
    setIsUndetermined(false);
  };

  const {
    options,
    value,
    name,
    error,
    isFlagSelection = false,
    disabled = false,
    smallDisplay,
    lang,
  } = props;
  let { title = "" } = props;
  title = lang ? lang.title : title;

  const classes = useStyles();

  return (
    <CustomTooltipWrapper
      errorMessage={error}
      title={lang ? lang.tooltip : undefined}
      disableFocusListener={!lang || !lang.tooltip}
      disableHoverListener={!lang || !lang.tooltip}
    >
      <FormControl
        fullWidth={!smallDisplay}
        variant="outlined"
        className={`${
          isFlagSelection ? classes.formControlFlag : classes.formControl
        } ${isFlagSelection ? classes.flagDisplay : ""}`}
        error={error}
        disabled={disabled}
      >
        <InputLabel htmlFor="outlined-age-simple">{title}</InputLabel>
        <Select
          onChange={handleChange}
          inputProps={{
            className: isUndetermined
              ? classes.undeterminedInput
              : classes.input,
          }}
          classes={{
            select: classes.select,
          }}
          value={value}
          fullWidth
          margin="dense"
          input={
            <OutlinedInput
              labelWidth={title ? title.length * 8 : 0}
              name={name}
              id={name}
            />
          }
        >
          {options.map(({ key, label }, index) => {
            if (isFlagSelection) {
              return (
                <MenuItem key={index} value={key as unknown as string}>
                  <Flag code={label.toLowerCase()} width="20" />
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={index} value={key as unknown as string}>
                  {label}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </CustomTooltipWrapper>
  );
};

export default CustomSelect;
