const env = process.env.REACT_APP_ENV;

// staging and production have equal configurations
// staging will be used to test and match production environment
if (env === "production" || env === "staging") {
  module.exports = require("./Root.prod");
} else if (env === "staging_dev" || env === "development" || env === "local") {
  module.exports = require("./Root.dev");
} else {
  alert("No env found. Did you setup the .env file correctly?");
}
