import { TABLE_AGGREGATOR } from "components/Table/model";

/**
 * Checks if the email is valid
 * @param {String} email
 * @returns {Boolean} returns True if the email is valid
 */
export function isEmailValid(email: string): boolean {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email);
}

export const transposeLocalTimeToUTCTime = (localTime: Date) => {
  return new Date(
    new Date(localTime).getTime() -
      new Date(localTime).getTimezoneOffset() * 1000 * 60
  );
};

/**
 * Checks if the phone number is valid
 * @param {String} phone
 * @returns {Boolean} returns True if the phone number is valid
 */
export function isPhoneValid(phone: string, phoneRegex?: string): boolean {
  if (phoneRegex) {
    // IF WE WANT TO USE REGEX OPTIONS

    // const flags = phoneRegex.replace(/.*\/([gimy]*)$/, "$1");
    // const pattern = phoneRegex.replace(
    //   new RegExp("^/(.*?)/" + flags + "$"),
    //   "$1"
    // );
    // const regex = new RegExp(pattern, flags);

    // ELSE
    const regex = new RegExp(phoneRegex);
    return regex.test(phone);
  }
  return /^[+]+[0-9]{4,17}$/i.test(phone);
}

/**
 * Checks if the string has only alphanumerical and underscore character
 * @param {String} text
 */
export function isAlphanumerical(text: string): boolean {
  const regexp = /^[a-z][a-z0-9_]*$/;
  return regexp.test(text);
}

/**
 * Filters the values and tests the
 * @param {String} value Value to test
 * @param {String} pattern Pattern to test for
 * @returns {Boolean} True if the pattern matches False otherwise
 */
export function regexTestValue(value: string, pattern: string): boolean {
  const re = new RegExp(pattern, "gi");
  return re.test(value);
}
/**
 * Convert agiven Base64 token string to object
 * @param {String} val
 * @returns {Object} JSON parsed object
 */
export const unBase64ToObj = (val: string): string => {
  const str = Buffer.from(val, "base64").toString("utf-8");

  return JSON.parse(str);
};

/**
 * Convert data in CSV (comma separated value) format to a javascript array.
 *
 * Values are separated by a comma, or by a custom one character delimeter.
 * Rows are separated by a new-line character.
 *
 * Leading and trailing spaces and tabs are ignored.
 * Values may optionally be enclosed by double quotes.
 * Values containing a special character (comma's, double-quotes, or new-lines)
 *   must be enclosed by double-quotes.
 * Embedded double-quotes must be represented by a pair of consecutive
 * double-quotes.
 *
 * Example usage:
 *   var csv = '"x", "y", "z"\n12.3, 2.3, 8.7\n4.5, 1.2, -5.6\n';
 *   var array = csv2array(csv);
 *
 * Author: Jos de Jong, 2010
 *
 * @param {string} data      The data in CSV format.
 * @param {string} delimeter [optional] a custom delimeter. Comma ',' by default
 *                           The Delimeter must be a single character.
 * @return {Array} array     A two dimensional array containing the data
 * @throw {String} error     The method throws an error when there is an
 *                           error in the provided data.
 */
export const csv2array = (data: string, delimeter: string): any[] => {
  // Retrieve the delimeter
  if (delimeter === undefined) delimeter = ",";
  if (delimeter && delimeter.length > 1) delimeter = ",";

  // initialize variables
  const newline: string = "\n";
  const eof: string = "";
  let i: number = 0;
  let c = data.charAt(i);
  let row: number = 0;
  let array: any[] = [];

  while (c !== eof) {
    // skip whitespaces
    while (c === " " || c === "\t" || c === "\r") {
      c = data.charAt(++i); // read next char
    }

    // get value
    let value: any = "";
    if (c === '"') {
      // value enclosed by double-quotes
      c = data.charAt(++i);

      do {
        if (c !== '"') {
          // read a regular character and go to the next character
          value += c;
          c = data.charAt(++i);
        }

        if (c === '"') {
          // check for escaped double-quote
          var cnext = data.charAt(i + 1);
          if (cnext === '"') {
            // this is an escaped double-quote.
            // Add a double-quote to the value, and move two characters ahead.
            value += '"';
            i += 2;
            c = data.charAt(i);
          }
        }
      } while (c !== eof && c !== '"');

      if (c === eof) {
        throw new Error("Unexpected end of data, double-quote expected");
      }

      c = data.charAt(++i);
    } else {
      // value without quotes
      while (
        c !== eof &&
        c !== delimeter &&
        c !== newline &&
        c !== "\t" &&
        c !== "\r"
      ) {
        value += c;
        c = data.charAt(++i);
      }
    }

    // add the value to the array
    if (array.length <= row) array.push([]);
    array[row].push(value);

    // skip whitespaces
    while (c === " " || c === "\t" || c === "\r") {
      c = data.charAt(++i);
    }

    // go to the next row or column
    if (c === delimeter) {
      // to the next column
      //col++;
    } else if (c === newline) {
      // to the next row
      //col = 0;
      row++;
    } else if (c !== eof) {
      // unexpected character
      throw new Error("Delimiter expected after character " + i);
    }

    // go to the next character
    c = data.charAt(++i);
  }
  // special case of the last cell being empty:
  if (array[0].length !== array[array.length - 1].length) {
    array[array.length - 1][array[0].length - 1] = "";
  }
  return array;
};

export const clone = (obj: any): any => {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  if (obj.hasOwnProperty("file") && obj.hasOwnProperty("fileName")) {
    if (obj.file instanceof File) {
      return {
        file: new File([obj.file], obj.fileName, { type: obj.file.type }),
        fileName: obj.fileName,
      };
    } else {
      return { file: [], fileName: "" };
    }
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const formatString = (pattern: string | undefined, element: any) => {
  if (!pattern && !element.label) return "/!\\ No Name";
  if (!pattern) return element.label;
  let result = "";
  const splittedPattern = pattern.split("$");
  for (let i = 0; i < splittedPattern.length; i++) {
    if (i % 2 === 1) {
      // even idx: we replace the string per the value of the attribute
      // if there is "." char, we split
      const attrs = splittedPattern[i].split(".");
      switch (attrs.length) {
        case 1:
          result += element[splittedPattern[i]];
          break;
        case 2:
          result += element[attrs[0]][attrs[1]];
          break;
        case 3:
          result += element[attrs[0]][attrs[1]][attrs[2]];
          break;
        default: /** nothing */
      }
    } else {
      // odd idx: we display the string as it is
      result += splittedPattern[i];
    }
  }
  if (result.length === 0) {
    return "/!\\ No Name";
  }
  return result;
};
export const formatSearch = (searchString: string, category: String) =>
  `${searchString} ${category}`;

const chars = "abcdefghijklmnopqrstuvwxyz";

export const generateRandomId = () => {
  let lastTimestamp = 0;
  let currentMsIndex = 0;
  let currentTimestamp = Date.now();
  if (currentTimestamp !== lastTimestamp) {
    currentMsIndex = 1e6 + Math.floor(Math.random() * 100000);
    lastTimestamp = currentTimestamp;
  }
  currentMsIndex += 111;
  let id1 = currentTimestamp.toString(36) + currentMsIndex.toString(36);
  let id2 = id1.split("").reverse().join("");
  let prefix = "";
  for (let i = 0; i < 4; i++) {
    prefix += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return prefix + id2;
};

export const aggregateValues = (
  values: any[],
  aggregator: TABLE_AGGREGATOR
): number | string => {
  switch (aggregator) {
    case TABLE_AGGREGATOR.SUM: {
      return values.reduce(
        (acc: number, curr: any) => (isNaN(curr) ? acc : acc + curr),
        0
      );
    }
    case TABLE_AGGREGATOR.MEAN: {
      if (values.length === 0) return 0;
      return (
        values.reduce(
          (acc: number, curr: any) => (isNaN(curr) ? acc : acc + curr),
          0
        ) / values.length
      );
    }
    case TABLE_AGGREGATOR.MIN: {
      const min = values.reduce(
        (acc: number, curr: any) =>
          isNaN(curr) ? acc : acc > curr ? curr : acc,
        Infinity
      );
      return min === Infinity ? 0 : min;
    }
    case TABLE_AGGREGATOR.MAX: {
      const max = values.reduce(
        (acc: number, curr: any) =>
          isNaN(curr) ? acc : acc < curr ? curr : acc,
        -Infinity
      );
      return max === -Infinity ? 0 : max;
    }
    default: {
      return values[0];
    }
  }
};
