import {
  IWorkflow,
  IQuestionGroup,
  IQuestion,
  IWorkflowGroup,
} from "model/entities/Workflow";
import { IOption } from "model/application/components/index";
import {
  IWorkflow as IBEWorkflow,
  IQuestionGroup as IBEQuestionGroup,
  IQuestion as IBEQuestion,
  QUESTION_TYPE,
} from "smala-tools/dist/src/types";
import { clone } from "utils/utils";
import { questionTypeToColumnType } from "utils/businessUtils";

const prepareWorkflowGroupForBackend = (
  workflowGroup: IWorkflowGroup
): { primary: IBEWorkflow; secondary?: IBEWorkflow; link: any } => {
  const result: any = {
    primary: prepareWorkflowForBackend(workflowGroup.primary),
  };
  if (workflowGroup.secondary) {
    result.secondary = prepareWorkflowForBackend(workflowGroup.secondary);
    result.link = cleanLinkForBackend(workflowGroup.link);
  }
  return result;
};

const cleanLinkForBackend = (link: any) => {
  if (!link.hasOwnProperty("priority_level")) link.priority_level = 1;
  if (!link.hasOwnProperty("expiration_date_offset"))
    link.expiration_date_offset = 7;
  if (!link.hasOwnProperty("due_date_offset")) link.due_date_offset = 0;
  if (!link.hasOwnProperty("descriptive_text_template"))
    link.descriptive_text_template = "New task";
  if (!link.hasOwnProperty("descriptive_text_template_on_done"))
    link.descriptive_text_template_on_done = "New task";
  if (!link.hasOwnProperty("type")) link.type = "WORKFLOW_BASED";
  link.tags_generator_to_task = link.tags_generator_to_task.map((l: any) => {
    delete l.key;
    return l;
  });
  delete link.scope_value_tag;
  delete link.descriptive_text;
  // group the "matrix binding" questions
  const matrixBinding = {};
  link.tags_generator_to_task.forEach((l: any) => {
    if (l.hasOwnProperty("matrix_binding")) {
      const key = `${l.wf_generator_tag}|${l.wf_task_tag}`;
      if (!matrixBinding.hasOwnProperty(key)) {
        matrixBinding[key] = l.matrix_binding;
      } else {
        matrixBinding[key].push(l.matrix_binding[0]);
      }
    }
  });
  link.tags_generator_to_task = link.tags_generator_to_task.filter(
    (l: any) => !l.hasOwnProperty("matrix_binding")
  );
  Object.keys(matrixBinding).forEach((k) =>
    link.tags_generator_to_task.push({
      matrix_binding: matrixBinding[k],
      wf_generator_tag: k.split("|")[0],
      wf_task_tag: k.split("|")[1],
    })
  );
  return link;
};

const prepareWorkflowForBackend = (workflow: IWorkflow): IBEWorkflow => {
  delete workflow["error"];
  delete workflow["is_nplet"];
  if (!workflow.hasOwnProperty("actif")) workflow.actif = true;
  // remove the "_locked" property from the questions
  let result: any = clone(workflow);
  result.questionnaire.question_groups[0].questions =
    result.questionnaire.question_groups[0].questions.map((q: IQuestion) => {
      if (q.tag === "_place_selected") {
        delete q._locked;
      }
      return q;
    });

  // change unique_nplet into string[]
  if (result.unique_nplet)
    result.unique_nplet = result.unique_nplet.map((n: IOption) => n.key);

  type TChangeQuestion = (
    q: IQuestion,
    qg: IQuestionGroup
  ) => { questionGroup: IQuestionGroup; question: IQuestion };
  // remove "index" attributes from options, transform value to an object with a STRING type
  const removeIndexAttributesForOptions: TChangeQuestion = (q, qg) => {
    const convertOption = (o: any) => {
      if (!(o.value && o.value.value)) {
        o.value = { value: o.label, type: "STRING" };
        delete o.label;
        delete o.index;
      }
      return o;
    };
    if (q?.options) q.options = q.options.map(convertOption);
    return { question: q, questionGroup: qg };
  };
  // set column_type attribute
  const setColumnType: TChangeQuestion = (q, qg) => {
    q.column_type = questionTypeToColumnType(q.type);
    return { question: q, questionGroup: qg };
  };
  // remove key in question and question group
  const removeKey: TChangeQuestion = (q, qg) => {
    delete q["key"];
    delete qg["key"];
    return { question: q, questionGroup: qg };
  };
  // set default values (required = false)
  const setDefaultValues: TChangeQuestion = (q, qg) => {
    if (!q.hasOwnProperty("required")) q.required = false;
    return { question: q, questionGroup: qg };
  };
  // remove "_viewdetail" attribute from question groups, questions and typed questions
  const removeViewDetail: TChangeQuestion = (q, qg) => {
    delete q._viewdetail;
    delete qg._viewdetail;
    return { question: q, questionGroup: qg };
  };
  // remove "question_group" and "question" attributes from questions and sub-questions
  const removeQuestionGroupAndQuestionAttributes: TChangeQuestion = (q, qg) => {
    delete q.question;
    delete (q as Partial<IQuestion>).question_group;
    return { question: q, questionGroup: qg };
  };
  // remove matrix_question_tag from the conditions / operations
  const removeMatrixQuestionTagFromConditions: TChangeQuestion = (q, qg) => {
    if (q.conditions) {
      q.conditions = q.conditions.map((c) => {
        delete c.matrix_question_tag;
        return c;
      });
    }
    return { question: q, questionGroup: qg };
  };
  const applyQuestionChanges = (changes: TChangeQuestion[]) => {
    result.questionnaire.question_groups =
      result.questionnaire.question_groups.map((qg: IQuestionGroup) => {
        for (let change of changes) {
          qg = change({} as IQuestion, qg).questionGroup;
        }
        qg.questions = qg.questions.map((q: IQuestion) => {
          for (let change of changes) {
            q = change(q, qg).question;
          }
          if (q.matrix) {
            q.matrix.typed_questions = q.matrix.typed_questions.map(
              (tq: IQuestion) => {
                for (let change of changes) {
                  tq = change(tq, qg).question;
                }
                if (!tq.default_value) delete tq.default_value;
                if (!tq.validation) delete tq.validation;
                if (!tq.validation_text) delete tq.validation_text;
                return tq;
              }
            );
          }
          return q;
        });
        return qg;
      });
  };
  // correct question group to put an index for each one of them
  result.questionnaire.question_groups =
    result.questionnaire.question_groups.map(
      (qg: IBEQuestionGroup, idx: number) => {
        qg.questions = qg.questions.map((q, index) => {
          const indexesBefore = result.questionnaire.question_groups
            .filter((qg: IQuestionGroup, qgIdx: number) => qgIdx < idx)
            .reduce((acc: number, curr: IQuestionGroup) => {
              acc = acc + curr.questions.filter((q) => !q.deprecated).length;
              return acc;
            }, 0);
          return { ...q, index: indexesBefore + index };
        });
        return { ...qg, index: idx };
      }
    );

  // put all the deprecated questions to the questionnaire directly
  //result.questionnaire.question_groups[0].questions = workflow.deprecated_questions.concat(result.questionnaire.question_groups[0].questions);
  delete result.deprecated_questions;
  applyQuestionChanges([
    removeKey,
    setColumnType,
    setDefaultValues,
    removeIndexAttributesForOptions,
    removeViewDetail,
    removeMatrixQuestionTagFromConditions, // apparently, I did not have to add this one...
    removeQuestionGroupAndQuestionAttributes,
  ]);
  // remove matrix_question_tag in the sub-questions (but not in the questions!! (c'est Malik qui demande...))
  result.questionnaire.question_groups =
    result.questionnaire.question_groups.map((qg: IQuestionGroup) => {
      qg.questions = qg.questions.map((q: IQuestion) => {
        if (q.matrix) {
          q.matrix.typed_questions = q.matrix.typed_questions.map((tq) => {
            if (q.conditions) {
              q.conditions = q.conditions.map((c) => {
                delete c.matrix_question_tag;
                return c;
              });
            }
            if (q.operations) {
              q.operations = q.operations.map((o) => {
                delete o.matrix_question_tag;
                return o;
              });
            }
            return tq;
          });
        }
        return q;
      });
      return qg;
    });
  return result as IBEWorkflow;
};

const prepareQuestionOptionForFrontend = (o: any, index: number): IOption => {
  o.label = `${o.value ? o.value.value : o.value}`;
  o.index = index;
  delete o.value;
  return o;
};

const prepareQuestionsForFrontend = (
  questions: IBEQuestion[],
  questionGroup?: string,
  matrixQuestion?: string
): IQuestion[] => {
  // FIXME: to remove when no more user is using 2.2 version
  // set "param" property to the operand of compute for no breaking change
  let res: any[] = questions.map((q) => {
    if (q.type === "compute") {
      q.operations = q.operations!.map((o) => {
        if (o.first.hasOwnProperty("attr")) o.first.param = o.first.attr;
        if (o.second.hasOwnProperty("attr")) o.second.param = o.second.attr;
        delete o.first.attr;
        delete o.second.attr;
        return o;
      });
    }
    return q;
  });
  // change "multiple choice with none to multiple choice"
  res = res.map((q: IQuestion) => {
    if (q.type === QUESTION_TYPE.MULTIPLE_CHOICE_WITH_NONE) {
      q.type = QUESTION_TYPE.MULTIPLE_CHOICE;
    }
    return q;
  });
  // add "_viewdetail" attribute to question groups, questions and typed questions
  res = res.map((q: IQuestion) => {
    q._viewdetail = false;
    q.question_group = questionGroup ? questionGroup : "questiongroup";
    if (matrixQuestion) q.question = matrixQuestion;
    if (q.matrix) {
      q.matrix.typed_questions = q.matrix.typed_questions.map((tq: any) => {
        tq._viewdetail = false;
        return tq;
      });
    }
    return q;
  });
  // change option value.value to label, add index
  res = res.map((q: any) => {
    if (q.options) q.options = q.options.map(prepareQuestionOptionForFrontend);
    if (q.matrix) {
      q.matrix.typed_questions = q.matrix.typed_questions.map((sq: any) => {
        if (sq.options)
          sq.options = sq.options.map(prepareQuestionOptionForFrontend);
        return sq;
      });
    }
    return q;
  });
  // add matrix_question_tag to the conditions / operations if linked to a matrix on list question
  const subQuestionTags: { tag: string; matrixTag: string }[] = res.reduce(
    (acc, curr) => {
      if (curr.matrix) {
        acc = acc.concat(
          curr.matrix.typed_questions.map((sq: IQuestion) => ({
            tag: sq.tag,
            matrixTag: curr.tag,
          }))
        );
      }
      return acc;
    },
    []
  );
  res = res.map((q: IQuestion) => {
    if (q.conditions) {
      q.conditions = q.conditions.map((cond: any) => {
        const subQTagsDetected = subQuestionTags.find(
          (e) => e.tag === cond.first.tag
        )
          ? subQuestionTags.find((e) => e.tag === cond.first.tag)
          : subQuestionTags.find((e) => e.tag === cond.second.tag);
        if (subQTagsDetected) {
          cond.matrix_question_tag = subQTagsDetected.matrixTag;
        }
        return cond;
      });
    }
    if (q.operations) {
      q.operations = q.operations.map((op: any) => {
        const subQTagsDetected = subQuestionTags.find(
          (e) => e.tag === op.first.tag
        )
          ? subQuestionTags.find((e) => e.tag === op.first.tag)
          : subQuestionTags.find((e) => e.tag === op.second.tag);
        if (subQTagsDetected) {
          op.matrix_question_tag = subQTagsDetected.matrixTag;
        }
        return op;
      });
    }
    if (q.matrix) {
      q.matrix.typed_questions = q.matrix.typed_questions.map((sq: any) => {
        const subQuestionTags = q.matrix!.typed_questions.map(
          (tq: IQuestion) => tq.tag
        );
        if (sq.conditions) {
          sq.conditions = sq.conditions.map((cond: any) => {
            if (
              subQuestionTags.includes(cond.first.tag) ||
              subQuestionTags.includes(cond.second.tag)
            ) {
              cond.matrix_question_tag = q.tag;
            }
            return cond;
          });
        }
        if (sq.operations) {
          sq.operations = sq.operations.map((op: any) => {
            if (
              subQuestionTags.includes(op.first.tag) ||
              subQuestionTags.includes(op.second.tag)
            ) {
              op.matrix_question_tag = q.tag;
            }
            return op;
          });
        }
        return sq;
      });
    }
    return q;
  });
  return res;
};

const prepareWorkflowsForFrontend = (workflows: IBEWorkflow[]): IWorkflow[] => {
  const results: any[] = workflows.map((w) => {
    let result: any = w;
    // add actif:true if no "actif" attribute
    if (!result.hasOwnProperty("actif")) {
      result["actif"] = true;
    }
    // remove all subquestions if there are deprecated
    w.questionnaire.question_groups = w.questionnaire.question_groups.map(
      (qg) => {
        qg.questions = qg.questions.map((q) => {
          if (q.matrix) {
            q.matrix.typed_questions = q.matrix.typed_questions.filter(
              (tq) => !tq.hasOwnProperty("deprecated")
            );
          }
          return q;
        });
        return qg;
      }
    );
    // put all the deprecated questions in a separate attribute
    result.deprecated_questions =
      w.questionnaire.question_groups[0].questions.filter((q) => q.deprecated);
    w.questionnaire.question_groups[0].questions =
      w.questionnaire.question_groups[0].questions.filter(
        (q) => !q.hasOwnProperty("deprecated")
      );
    // if no "smala_list_ids" defined in the questionnaire object, add it
    if (!result.questionnaire.hasOwnProperty("smala_list_ids")) {
      result.questionnaire.smala_list_ids = [];
    }
    // add "_viewdetail" attribute to question groups
    result.questionnaire.question_groups =
      result.questionnaire.question_groups.map((qg: any) => {
        qg._viewdetail = false;
        return qg;
      });
    // change unique_nplet into options
    if (result.unique_nplet)
      result.unique_nplet = result.unique_nplet.map((n: string) => ({
        key: n,
        label: n,
      }));
    // if the first question of the questionnaire is "_place_selected", add the "_locked" property to the question
    result.questionnaire.question_groups[0].questions =
      result.questionnaire.question_groups[0].questions.map(
        (q: IBEQuestion) => {
          if (q.tag === "_place_selected") {
            q["_locked"] = true;
          }
          return q;
        }
      );
    result.questionnaire.question_groups =
      result.questionnaire.question_groups.map((qg: IBEQuestionGroup) => {
        qg.questions = prepareQuestionsForFrontend(qg.questions, qg.tag) as any;
        return qg;
      });
    // correct question group to put an index for each one of them
    result.questionnaire.question_groups =
      result.questionnaire.question_groups.map(
        (qg: IBEQuestionGroup, idx: number) => {
          qg.questions = qg.questions.map((q, index) => ({ ...q, index }));
          return { ...qg, index: idx };
        }
      );

    // add "question_group" to questions, and "question_group" + "question" to subquestions
    result.questionnaire.question_groups =
      result.questionnaire.question_groups.map((qg: IBEQuestionGroup) => {
        qg.questions = qg.questions.map((q: any) => {
          q.question_group = qg.tag;
          if (q.matrix) {
            q.matrix.typed_questions = q.matrix.typed_questions.map(
              (sq: any) => {
                sq.question_group = qg.tag;
                sq.question = q.tag;
                return sq;
              }
            );
          }
          return q;
        });
        return qg;
      });
    return result;
  });
  return results as IWorkflow[];
};

const prepareSubmissionForBackend = (submission: any) => {
  const submissionClone = clone(submission);
  delete submissionClone._displayed_name;
  for (const prop of Object.keys(submission)) {
    if (typeof submissionClone[prop] === "object") {
      delete submissionClone[prop]; // dirty hack to prevent error
      //delete submissionClone[prop].title;
      //delete submissionClone[`${prop}__id`];
      //delete submissionClone[`${prop}__list_id`];
    }
  }
  return submissionClone;
};

export {
  prepareWorkflowForBackend,
  prepareWorkflowsForFrontend,
  prepareQuestionsForFrontend,
  prepareQuestionOptionForFrontend,
  prepareWorkflowGroupForBackend,
  prepareSubmissionForBackend,
};
