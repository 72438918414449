import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "../Buttons/CustomButton";
import styles from "./styles";
import TLang, { TConfirmModalLang } from "model/application/Lang";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(styles as any);

export interface ICustomDialogWarningProps {
  children?: any;
  isOpen: boolean;
  onClose?: () => void;
  onConfirmAction?: () => void;
  confirmBtnText?: string;
  singleButton?: boolean;
  lang: TConfirmModalLang;
  rootLang: TLang;
}

/**
 * Stateless component for displaying a dialog with a warning message.
 */
export const CustomDialogWarning: React.FunctionComponent<ICustomDialogWarningProps> = ({
  isOpen,
  onClose,
  children,
  onConfirmAction,
  confirmBtnText,
  singleButton = false,
  rootLang,
  lang,
}) => {
  const classes = useStyles();

  const title = lang.title ? lang.title : rootLang.modal.warningTitle;
  confirmBtnText = confirmBtnText ? confirmBtnText : rootLang.modal.proceed;
  const description = lang.description ? lang.description : "";

  if (singleButton) {
    return (
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b
              className="col-sm-push-12"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </DialogContentText>
          <br />
          {children}
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={onClose}
            type={BUTTON_TYPES_OUTSIDE_TABLE.CANCEL}
          >
            {rootLang.modal.cancel}
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b
              className="col-sm-push-12"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </DialogContentText>
          <br />
          {children}
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <CustomButton
            onClick={onClose}
            type={BUTTON_TYPES_OUTSIDE_TABLE.CANCEL}
          >
            {rootLang.modal.cancel}
          </CustomButton>
          {onConfirmAction ? (
            <CustomButton
              onClick={onConfirmAction}
              type={BUTTON_TYPES_OUTSIDE_TABLE.ERROR}
            >
              {confirmBtnText}
            </CustomButton>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    );
  }
};

export default CustomDialogWarning;
