/**
 * @notes: Transformation action types
 */
export const CREATE_TRANSFORMATION_BEGIN = "CREATE_TRANSFORMATION_BEGIN";
export const CREATE_TRANSFORMATION_SUCCESS = "CREATE_TRANSFORMATION_SUCCESS";
export const CREATE_TRANSFORMATION_FAILURE = "CREATE_TRANSFORMATION_FAILURE";

export const UPDATE_TRANSFORMATION_BEGIN = "UPDATE_TRANSFORMATION_BEGIN";
export const UPDATE_TRANSFORMATION_SUCCESS = "UPDATE_TRANSFORMATION_SUCCESS";
export const UPDATE_TRANSFORMATION_FAILURE = "UPDATE_TRANSFORMATION_FAILURE";

export const FETCH_ALL_TRANSFORMATIONS_BEGIN =
  "FETCH_ALL_TRANSFORMATIONS_BEGIN";
export const FETCH_ALL_TRANSFORMATIONS_SUCCESS =
  "FETCH_ALL_TRANSFORMATIONS_SUCCESS";
export const FETCH_ALL_TRANSFORMATIONS_FAILURE =
  "FETCH_ALL_TRANSFORMATIONS_FAILURE";

export const DELETE_TRANSFORMATION_BEGIN = "DELETE_TRANSFORMATION_BEGIN";
export const DELETE_TRANSFORMATION_SUCCESS = "DELETE_TRANSFORMATION_SUCCESS";
export const DELETE_TRANSFORMATION_FAILURE = "DELETE_TRANSFORMATION_FAILURE";
