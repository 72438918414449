/**
 * @notes: Endpoints, update the API version here, along with the DOMAIN that will be used
 * This will be used all across the application in several containers and actions.
 * This creates a central place for updates that will be globally affected.
 * Note that the process.env.REACT_APP_ENV variable is set when the application is started.
 * 1. production environment variable is set when a build is run using yarn build (or npm run build), after which
 * the build can be run using yarn serve (or npm run serve)
 * 2. staging environment variable is set using the yarn start:staging script (or npm run start:staging)
 * 3. development environment variable is set using yarn start:dev script (or npm run start:dev). This will target using
 * a mockApi
 */

const LATEST_RELEASE = "v2-16";

export const API_BASE_URL = () => {
  if (!process.env.REACT_APP_ENV) {
    throw new Error("Missing env variables REACT_APP_ENV");
  }
  if (process.env.REACT_APP_ENV === "local") return "http://localhost:8907";
  // setup environment for the url. If we are in production, don't specify it.
  let envForUrl;
  switch (process.env.REACT_APP_ENV) {
    case "development":
      envForUrl = "develop";
      break;
    case "staging":
      envForUrl = "staging";
      break;
    case "production":
      envForUrl = LATEST_RELEASE;
      break;
  }
  return `https://${envForUrl}.fieldproapp.com/web`;
};
