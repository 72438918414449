/**
 * @notes: API Service. This is a wrapper around axios and will make api calls to API. This is convenient as it allows
 * the application to interchange 3rd party libraries or even use a custom one, if the need arises, without need to change
 * all over codebase.
 */

import { API_BASE_URL } from "./endpoints";
import axios from "axios";
import { CancelToken } from "axios";
import qs from "qs";
import { shouldRunInBackground } from "./utils";
import { transposeLocalTimeToUTCTime } from "utils/utils";

class ApiService {
  constructor() {
    this.cancelToken = CancelToken.source();
    this.service = axios.create({
      baseURL: API_BASE_URL(),
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE",
      },
      timeout: 60000,
    });
    this.token = "";
    this.clientId = "";
    this.runInBackground = false;
    this.service.interceptors.request.use(this.handleRequestInterceptor);
    this.service.interceptors.response.use(
      this.handleResponseSuccess,
      this.handleResponseError
    );
  }

  setToken(token) {
    this.token = token;
  }

  setClientId(clientId) {
    this.clientId = clientId;
  }

  setRunInBackground(runInBackground) {
    this.runInBackground = runInBackground;
  }

  /**
   * Request interceptor config. This is called before any request is made to API.
   * @param {Object} config
   * @return {Object} config object for axios
   * */
  handleRequestInterceptor = (config) => {
    config.headers = {
      Auth: this.token,
    };
    if (this.clientId) {
      config.headers["client-id"] = this.clientId;
      config.headers["local-timestamp"] = new Date().toISOString();
      config.headers["action_local_time"] = transposeLocalTimeToUTCTime(
        new Date()
      ).toISOString();
    }
    if (this.runInBackground) {
      config.headers["run-in-background"] = "true";
      config.headers["local-timestamp"] = new Date().toISOString();
      config.headers["action_local_time"] = transposeLocalTimeToUTCTime(
        new Date()
      ).toISOString();
    }
    this.setRunInBackground(false);
    return config;
  };

  /**
   * Handles successful Response. This is used to handle the response accordingly
   * MUST return the response
   * @param {Object} response Response Object from request
   * @return {Object} Response*/
  // Reference https://github.com/axios/axios#response-schema
  handleResponseSuccess = (response) => {
    // extract the data block received from Smala API
    // this will contain the data we need for the application
    let { data } = response.data;

    return {
      ...response,
      data,
    };
  };

  /**
   * Handles response errors
   * @param {Object} error Error Object received from response
   * */
  // Reference https://github.com/axios/axios#handling-errors
  handleResponseError = (error) => {
    var config = error.config;

    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(error);

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(error);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, config.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
      return axios(config);
    });
  };

  redirectTo(path) {
    document.location = path;
  }

  multipartUpdate = (endpoint, files, body = {}) => {
    const formData = new FormData();
    files.forEach((f) => {
      const { file, fileName } = f;
      formData.append(fileName, file, fileName);
    });
    for (let att in body) {
      formData.set(att, JSON.stringify(body[att]));
    }
    this.cancelToken = CancelToken.source();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      cancelToken: this.cancelToken.token,
    };
    return this.service.post(endpoint, formData, config);
  };

  /**
   * Perform POST requests to API
   * @param {String} url Url to make POST request
   * @param {Object} payload Payload Object data to POST to api
   * */
  post(url, payload = undefined, forceRunInTheForeground = false) {
    if (!forceRunInTheForeground && shouldRunInBackground(payload)) {
      this.setRunInBackground(true);
    }
    this.cancelToken.cancel(`Cancelled Ongoing request on ${url}`);
    this.cancelToken = CancelToken.source();
    return this.service.post(url, payload, {
      cancelToken: this.cancelToken.token,
    });
  }

  /**
   * perform GET request
   * @param {String} url
   * */
  get(url, query = null) {
    this.cancelToken.cancel(`Cancelled Ongoing request on ${url}`);
    this.cancelToken = CancelToken.source();
    return this.service.get(
      url,
      {
        params: query,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "comma" }),
      },
      {
        cancelToken: this.cancelToken.token,
      }
    );
  }

  /**
   * Patch HTTP verb,handles patch requests
   * @param {String} url
   * @param {Object} payload
   * */
  patch(url, payload = undefined) {
    if (shouldRunInBackground(payload)) {
      this.setRunInBackground(true);
    }
    this.cancelToken.cancel(`Cancelled Ongoing request on ${url}`);
    this.cancelToken = CancelToken.source();
    return this.service.patch(url, payload, {
      cancelToken: this.cancelToken.token,
    });
  }

  /**
   * Delete HTTP verb, handles delete requests
   * @param {String} url Url to post the delete method to
   * @param {Object} payload [OPTIONAL] Payload to send with request
   */
  delete(url, payload = undefined) {
    if (shouldRunInBackground(payload)) {
      this.setRunInBackground(true);
    }
    this.cancelToken.cancel(`Cancelled Ongoing request on ${url}`);
    this.cancelToken = CancelToken.source();
    return this.service.delete(
      url,
      {
        data: {
          ...payload,
        },
      },
      {
        cancelToken: this.cancelToken.token,
      }
    );
  }
}

export default new ApiService();
