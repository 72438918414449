import React from "react";
import { Button, Badge, ButtonBaseProps } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FilterListIcon from "@material-ui/icons/FilterList";
import RunIcon from "@material-ui/icons/PlayArrow";
import DownloadIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import ArchiveIcon from "@material-ui/icons/Archive";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import DetailIcon from "@material-ui/icons/RemoveRedEye";
import HelpIcon from "@material-ui/icons/HelpOutline";
import RestoreIcon from "@material-ui/icons/RestoreFromTrash";
import RestoreVersionIcon from "@material-ui/icons/Restore";
import HideIcon from "@material-ui/icons/VisibilityOff";
import DuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CloseIcon from "@material-ui/icons/Close";
import PaymentIcon from "@material-ui/icons/Payment";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { makeStyles } from "@material-ui/core/styles";
import { ACTION_TYPE } from "../../model/application/UIActionTypes";
import styles from "./styles";

export enum BUTTON_TYPES_OUTSIDE_TABLE {
  CANCEL = "CANCEL",
  DEFAULT = "DEFAULT",
  ACTION = "ACTION",
  WARNING = "WARNING",
  ERROR = "ERROR",
  BACK = "BACK",
  LOGIN = "LOGIN",
  SHOW_ACTIVITY = "SHOW_ACTIVITY",
  TIMELINE_ACTIVITY_DETAILS = "TIMELINE_ACTIVITY_DETAILS",
}

export enum BUTTON_COLOR {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  INFO = "INFO",
  ERROR = "ERROR",
  LOGIN = "LOGIN",
  REPLICATE = "REPLICATE",
}

const useStyles = makeStyles(styles as any);

interface ICustomButtonProps extends Omit<ButtonBaseProps, "type"> {
  isTableAction?: boolean;
  isChipAction?: boolean;
  darkMode?: boolean;
  color?: BUTTON_COLOR;
  badgeContent?: number;
  type: BUTTON_TYPES_OUTSIDE_TABLE | ACTION_TYPE;
  text?: string;
  buttonContainer?: any;
}

export const CustomButton: React.FunctionComponent<ICustomButtonProps> = (
  props: ICustomButtonProps
) => {
  const classes = useStyles();

  const buildButton = () => {
    const {
      type,
      darkMode,
      isTableAction,
      badgeContent,
      color,
      text,
      buttonContainer,
      ...rest
    } = props;
    if (!isTableAction) {
      switch (type) {
        case BUTTON_TYPES_OUTSIDE_TABLE.CANCEL: {
          return <Button color="default" {...rest} />;
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.ACTION: {
          return <Button variant="contained" color="primary" {...rest} />;
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.LOGIN: {
          return (
            <Button
              variant="contained"
              className={`${classes.LoginButton}`}
              {...rest}
            >
              <span className={`${classes.LoginButtonLabel}`}>{text}</span>
            </Button>
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.SHOW_ACTIVITY: {
          return (
            <Button
              variant="contained"
              className={`${classes.ShowActivityButton}`}
              autoCapitalize={"false"}
              {...rest}
            >
              <span className={`${classes.ShowActivityButtonLabel}`}>
                {text}
              </span>
            </Button>
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.TIMELINE_ACTIVITY_DETAILS: {
          return (
            <Button
              size="small"
              className={`${classes.ShowTimeLineActivityButton}`}
              endIcon={
                <ArrowRightAltIcon
                  className={`${classes.ShowTimeLineActivityLabel}`}
                />
              }
              {...rest}
            >
              <span className={`${classes.ShowTimeLineActivityLabel}`}>
                {text}
              </span>
            </Button>
          );
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.WARNING:
        case BUTTON_TYPES_OUTSIDE_TABLE.ERROR: {
          return <Button variant="contained" color="secondary" {...rest} />;
        }
        case BUTTON_TYPES_OUTSIDE_TABLE.BACK: {
          return (
            <Fab
              variant="round"
              size="small"
              color="primary"
              aria-label="Back"
              className={classes.BackButton}
              {...rest}
            >
              <ArrowBackIcon aria-hidden={false} aria-label="ArrowBackIcon" />
            </Fab>
          );
        }
        default: {
          return (
            <Fab
              variant="round"
              size="small"
              color="primary"
              aria-label={type}
              {...rest}
            >
              {selectActionButton(type as ACTION_TYPE, badgeContent)}
            </Fab>
          );
        }
      }
    } else {
      const realColor = color ? color : BUTTON_COLOR.SUCCESS;
      // table action button
      if (type === ACTION_TYPE.DETAIL && !props.isChipAction) {
        return (
          <Button
            className={classes.TableDetailActionButton}
            variant="contained"
            color="secondary"
            {...rest}
          >
            {text}
          </Button>
        );
      } else {
        return (
          <Fab
            aria-label={type}
            className={`${darkMode ? classes.DarkButton : ""} ${
              classes.TableActionButton
            } ${classes[`${realColor}Color`]}`}
            {...rest}
          >
            {selectActionButton(type as ACTION_TYPE, badgeContent)}
          </Fab>
        );
      }
    }
  };

  if (props.isTableAction || props.type === BUTTON_TYPES_OUTSIDE_TABLE.LOGIN)
    return buildButton();
  return (
    <div
      className={`${
        props.buttonContainer ? props.buttonContainer : classes.ButtonContainer
      }`}
    >
      {buildButton()}
    </div>
  );
};

const selectActionButton = (actionType: ACTION_TYPE, badgeContent?: number) => {
  switch (actionType) {
    case ACTION_TYPE.FILTER:
      return <FilterListIcon aria-label="FilterListIcon" />;
    case ACTION_TYPE.EXPAND:
    case ACTION_TYPE.CREATE:
      return <AddIcon aria-label="AddIcon" />;
    case ACTION_TYPE.COLLAPSE:
      return <RemoveIcon aria-label="RemoveIcon" />;
    case ACTION_TYPE.REPLICATE:
      return <FileCopyIcon aria-label="FileCopyIcon" />;
    case ACTION_TYPE.RUN:
      return <RunIcon aria-label="RunIcon" />;
    case ACTION_TYPE.EDIT_MULTIPLE:
    case ACTION_TYPE.EDIT:
      return <EditIcon aria-label="EditIcon" />;
    case ACTION_TYPE.ARCHIVE_MULTIPLE:
    case ACTION_TYPE.ARCHIVE:
      return <ArchiveIcon aria-label="ArchiveIcon" />;
    case ACTION_TYPE.RESTORE_MULTIPLE:
    case ACTION_TYPE.RESTORE:
      return <RestoreIcon aria-label="RestoreIcon" />;
    case ACTION_TYPE.DELETE_MULTIPLE:
    case ACTION_TYPE.DELETE:
      return <DeleteIcon aria-label="DeleteIcon" />;
    case ACTION_TYPE.RESTORE_VERSION:
      return <RestoreVersionIcon aria-label="RestoreVersionIcon" />;
    case ACTION_TYPE.DOWNLOAD:
      return <DownloadIcon aria-label="DownloadIcon" />;
    case ACTION_TYPE.SAVE:
      return <SaveIcon aria-label="SaveIcon" />;
    case ACTION_TYPE.REFRESH:
      return <RefreshIcon aria-label="RefreshIcon" />;
    case ACTION_TYPE.HELP:
      return <HelpIcon aria-label="HelpIcon" />;
    case ACTION_TYPE.DETAIL:
    case ACTION_TYPE.COLUMN_VISIBILITY:
      return <DetailIcon aria-label="DetailIcon" />;
    case ACTION_TYPE.HIDDEN:
      return <HideIcon aria-label="HideIcon" />;
    case ACTION_TYPE.DUPLICATE:
      return <DuplicateIcon aria-label="DuplicateIcon" />;
    case ACTION_TYPE.NOTIFICATION:
      return (
        <Badge badgeContent={badgeContent} color="primary">
          <NotificationsIcon aria-label="NotificationsIcon" />
        </Badge>
      );
    case ACTION_TYPE.CLOSE:
      return <CloseIcon aria-label="CloseIcon" />;
    case ACTION_TYPE.STRIPE_PORTAL:
      return <PaymentIcon aria-label="PaymentIcon" />;
    default:
      return <div>UNKNOWN ACTION TYPE</div>;
  }
};

export default CustomButton;
