import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../../actionCreators/ajaxActionCreator";
import {
  createEmailGenerationApiCall,
  deleteEmailGenerationApiCall,
  fetchEmailGenerationsApiCall,
  updateEmailGenerationApiCall,
} from "./api";
import { createNotificationActionCreator } from "../../../notifications/actionCreator";
import * as notificationTypes from "../../../notifications/actionTypes";
import * as levels from "../../../notifications/constants";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../../actions/appActions";
import { getLang } from "../../../authentication/redux/selector";
import * as lang from "../../../../lang";
import { getSuccessNotificationMessage } from "../../../../lang/utils";
import { IEmailGeneration } from "model/entities/Dashboard";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IClient } from "model/entities/Client";
import { getSelectedClient } from "containers/clients/redux/selectors";
import {
  createEmailGenerationBeginActionCreator,
  createEmailGenerationFailureActionCreator,
  createEmailGenerationSuccessActionCreator,
  deleteEmailGenerationBeginActionCreator,
  deleteEmailGenerationFailureActionCreator,
  deleteEmailGenerationSuccessActionCreator,
  fetchAllEmailGenerationsBeginActionCreator,
  fetchAllEmailGenerationsFailureActionCreator,
  fetchAllEmailGenerationsSuccessActionCreator,
  updateEmailGenerationBeginActionCreator,
  updateEmailGenerationFailureActionCreator,
  updateEmailGenerationSuccessActionCreator,
} from "./actionCreators";
import { IDispatchAndGetState } from "store/model";

// EMAILGENERATIONS

/**
 * Create EmailGeneration action which creates an emailGeneration for embedding
 * id: string;  
 * body: string;
  queries: Query [];
  web_users: string [];
  frequency: string;
  date_range: string;
  start_date: Date;
  start_time: string;
  * @param {String} id primary_key the emailgeneration in the tables
 * @param {String} body body of the email
 * @param {Query []} queries list of queries to embed in the body of email.
 * @param {String []} web_users list of web_users who should receive email
 * @param {String} frequency Frequency of the email shipping (Value between ONCE, DAILY, WEEKLY, MONTHLY)
 * @param {String} date_range (Value between TODAY, YESTERDAY, LAST_7_DAYS, LAST_30_DAYS)
 * @param {Date} start_date Date of the first email being shipped (DATE)
 * @param {String} start_time Time of the first email being shipped
 * @returns {Function}
 */
export const createEmailGenerationAction: ICreateEmailGenerationActionFunc = (
  emailGeneration: IEmailGeneration,
  clientId = undefined
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState()) as IClient;

    let client_id = clientId || client.id;
    dispatch(ajaxRequestAction());
    dispatch(createEmailGenerationBeginActionCreator());

    return createEmailGenerationApiCall(emailGeneration, client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id } = data;
        const emailGenerationWithId = {
          ...emailGeneration,
          id: id,
        };
        dispatch(ajaxSuccessAction());
        dispatch(
          createEmailGenerationSuccessActionCreator(emailGenerationWithId)
        );
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.NOTIFICATION
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateEmailGeneration",
          error,
          createEmailGenerationFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Update EmailGeneration action which creates an emailGeneration for embedding
 * @param {Object} emailGeneration EmailGeneration object to edit
 * @returns {Function}
 */
export const updateEmailGenerationAction: TUpdateEmailGenerationActionFunc = (
  emailGeneration
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateEmailGenerationBeginActionCreator());
    const client = getSelectedClient(getState()) as IClient;

    let client_id = client.id;
    return updateEmailGenerationApiCall({ ...emailGeneration }, client_id)
      .then((serverResponse) => {
        dispatch(ajaxSuccessAction());
        dispatch(updateEmailGenerationSuccessActionCreator(emailGeneration));

        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.NOTIFICATION
              //emailGeneration.title
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateEmailGeneration",
          error,
          updateEmailGenerationFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Action to fetch allEmailGenerations
 * @returns {Function}
 */
export const fetchAllEmailGenerationsAction: TFetchAllEmailGenerationsActionFunc =
  (clientId = undefined) => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAllEmailGenerationsBeginActionCreator());
      const client = getSelectedClient(getState()) as IClient;
      const client_id = clientId || client.id;
      return fetchEmailGenerationsApiCall(client_id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { email_generations } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllEmailGenerationsSuccessActionCreator(email_generations)
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllEmailGenerationsError",
            error,
            fetchAllEmailGenerationsFailureActionCreator,
            currLang
          );
        });
    };
  };
/**
 * Delete EmailGeneration Action dispatches action creators to redux store and makes api calls to delete a emailGeneration by id
 * @param {String} emailGeneration_id EmailGeneration id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteEmailGenerationAction: TDeleteEmailGenerationActionFunc = (
  emailGenerationId
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteEmailGenerationBeginActionCreator());
    const client = getSelectedClient(getState()) as IClient;
    const clientId = client.id;
    return deleteEmailGenerationApiCall(emailGenerationId, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteEmailGenerationSuccessActionCreator(emailGenerationId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.NOTIFICATION
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteEmailGenerationError",
          error,
          deleteEmailGenerationFailureActionCreator,
          currLang
        );
      });
  };
};

export type ICreateEmailGenerationActionFunc = (
  emailGeneration: IEmailGeneration,
  clientId?: string
) => IDispatchAndGetState<void>;
export type TUpdateEmailGenerationActionFunc = (
  emailGeneration: IEmailGeneration
) => IDispatchAndGetState<any>;
export type TFetchAllEmailGenerationsActionFunc =
  () => IDispatchAndGetState<void>;
export type TDeleteEmailGenerationActionFunc = (
  emailGenerationId: string
) => IDispatchAndGetState<void>;

export interface IEmailGenerationActions {
  createEmailGenerationAction: ICreateEmailGenerationActionFunc;
  updateEmailGenerationAction: TUpdateEmailGenerationActionFunc;
  fetchAllEmailGenerationsAction: TFetchAllEmailGenerationsActionFunc;
  deleteEmailGenerationAction: TDeleteEmailGenerationActionFunc;
}

const actions: IEmailGenerationActions = {
  createEmailGenerationAction: createEmailGenerationAction,
  updateEmailGenerationAction: updateEmailGenerationAction,
  fetchAllEmailGenerationsAction: fetchAllEmailGenerationsAction,
  deleteEmailGenerationAction: deleteEmailGenerationAction,
};

export default actions;
