import * as types from "./actionTypes";
import { IAction, IActionError } from "store/model";
import initialState from "./initialState";
import {
  IChangeSubcategorySuccessActionCreator,
  IFetchDocumentsSuccessAction,
} from "./actionCreators";
import { IDocument } from "model/entities/Document";

export interface Documentstate {
  isFetchingDocuments: boolean;
  isUploadingDocuments: boolean;
  isDeletingDocuments: boolean;
  allDocuments: IDocument[];
  errors: any[];
  subCategorySelected: string;
  selectedFolder: string;
}

const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case types.FETCH_DOCUMENTS_BEGIN:
      return {
        ...state,
        isFetchingDocuments: true,
      };
    case types.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        allDocuments: (action as IFetchDocumentsSuccessAction).documents,
        isFetchingDocuments: false,
      };
    case types.FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        isFetchingDocuments: false,
        errors: (action as IActionError).error,
      };
    case types.UPLOAD_DOCUMENTS_BEGIN:
      return {
        ...state,
        isUploadingDocuments: true,
      };
    case types.UPLOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isUploadingDocuments: false,
      };
    case types.UPLOAD_DOCUMENTS_FAILURE:
      return {
        ...state,
        isUploadingDocuments: false,
        errors: (action as IActionError).error,
      };

    case types.DELETE_DOCUMENTS_BEGIN:
      return {
        ...state,
        isDeletingDocuments: true,
      };
    case types.DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isDeletingDocuments: false,
      };
    case types.DELETE_DOCUMENTS_FAILURE:
      return {
        ...state,
        isDeletingDocuments: false,
        errors: (action as IActionError).error,
      };

    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory, selectedFolder } =
        action as IChangeSubcategorySuccessActionCreator;
      return {
        ...state,
        subCategorySelected: subcategory,
        selectedFolder,
      };
    }

    default:
      return state;
  }
};

export default reducer;
