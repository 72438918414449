import { createSelector } from "reselect";
import { customSelectorCreator } from "../../selectors/appSelector";
import IRootState from "store/model";

/**
 * Gets the notifications
 * @param {Object} state State of redux store
 */
const getAllNotifications = (state: IRootState) =>
  state.notify.allNotifications;

/**
 * Gets the most recent notification
 * @param {Object} state Redux Store state
 */
const getRecentNotification = (state: IRootState) =>
  state.notify.recentNotification;

/**
 * Gets the date of the last successful notification
 * @param {Object} state Redux store state
 * @return {Object}
 */
const getLastSuccessNotification = (state: IRootState) =>
  state.notify.lastSuccessNotification;

/**
 * Selector to get the most recent notification
 * @param {Object} state Redux store state
 * @returns {Object} Returns the most recent notification message
 */
export const recentNotificationSelector = createSelector(
  getRecentNotification,
  (recentNotification) => recentNotification
);

/**
 * Notifications Selector
 */
export const allNotificationsSelector = customSelectorCreator(
  getAllNotifications,
  (allNotifications) => allNotifications
);

/**
 * Selector to get the date of the latest successful notification
 * @param {Object} state Redux store state
 * @returns {Object} Returns the date of the most recent successful notification
 */
export const lastSuccessNotificationSelector = createSelector(
  getLastSuccessNotification,
  (lastSuccessNotification) => lastSuccessNotification
);
