import * as types from "./actionTypes";
import { IAction, IActionError } from "store/model";
import ITable from "model/entities/Database";

export function fetchTablesForClientBeginAction(): IAction {
  return { type: types.FETCH_TABLES_BEGIN };
}
export function fetchTablesForClientFailureAction(error: any): IActionError {
  return { type: types.FETCH_TABLES_FAILURE, error };
}

export interface IFetchTablesForClientSuccessAction extends IAction {
  clientId: string;
  tables: ITable[];
}
export const fetchTablesForClientSuccessAction = (
  clientId: string,
  tables: ITable[]
): IFetchTablesForClientSuccessAction => ({
  type: types.FETCH_TABLES_SUCCESS,
  clientId,
  tables,
});
