import React, { Component } from "react";
import CustomInputText, {
  INPUT_TEXT_TYPE,
} from "../../../components/Input/CustomInputText";
import { ICreateEditModalProps } from "model/application/modal/CreateEditModal";

interface TAttributes {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}
type IChangePasswordModalProps = ICreateEditModalProps<{}, TAttributes>;
export interface IChangePasswordModalState {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

class ChangePasswordModal extends Component<
  IChangePasswordModalProps,
  IChangePasswordModalState
> {
  constructor(props: IChangePasswordModalProps) {
    super(props);
    this.state = {
      oldPassword: props.attributes.oldPassword,
      newPassword: props.attributes.newPassword,
      confirmPassword: props.attributes.confirmPassword,
    };
  }

  /**
   * Handles input changes
   * @param {Object} e Event Object
   */
  handleInputChange = (value: any, name: string) => {
    const { onChangeAttributeValues } = this.props;
    onChangeAttributeValues(name, value);
  };

  render() {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const { errors, lang } = this.props;

    const shouldMarkError = (field: string) => {
      const hasError = errors[field];
      return hasError;
    };

    return (
      <div>
        <CustomInputText
          defaultValue={oldPassword}
          error={shouldMarkError("oldPassword")}
          name="oldPassword"
          type={INPUT_TEXT_TYPE.PASSWORD}
          label={lang.mainLayout.changePasswordModal.inputOldPassword.title}
          onChange={this.handleInputChange}
        />
        <CustomInputText
          defaultValue={newPassword}
          error={shouldMarkError("newPassword")}
          name="newPassword"
          type={INPUT_TEXT_TYPE.PASSWORD}
          label={lang.mainLayout.changePasswordModal.inputNewPassword.title}
          onChange={this.handleInputChange}
        />
        <CustomInputText
          defaultValue={confirmPassword}
          error={shouldMarkError("confirmPassword")}
          name="confirmPassword"
          type={INPUT_TEXT_TYPE.PASSWORD}
          label={
            lang.mainLayout.changePasswordModal.inputConfirmNewPassword.title
          }
          onChange={this.handleInputChange}
        />
      </div>
    );
  }
}

export default ChangePasswordModal;
