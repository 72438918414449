import * as types from "./actionTypes";
import initialState from "./initialState";
import {
  updateErrorsList,
  insertNewItemToArr,
} from "../../../../utils/reducerUtils2";
import { IEmailGeneration } from "model/entities/Dashboard";
import { IAction } from "store/model";
import * as actionCreators from "./actionCreators";
import { prepareEmailGenerationsForFrontend } from "./utils";

export interface IEmailGenerationState {
  isFetchingAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isArchiving: boolean;
  isRestoring: boolean;
  isComputing: boolean;
  selectedEmailGeneration: IEmailGeneration | null;
  emailGenerations: IEmailGeneration[];
  errors: any[];
}

/**
 * Dashboards reducer takes current state and action and returns a new state for the dashboards object in redux store
 * @param state initial state of dashboards
 * @param action Action object dispatched from actions
 * @return {Object} new state or initial state
 * */
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_EMAILGENERATION_BEGIN:
      return {
        ...state,
        isCreating: true,
      };

    case types.CREATE_EMAILGENERATION_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: updateErrorsList(state, action),
      };

    case types.CREATE_EMAILGENERATION_SUCCESS: {
      const {
        emailGeneration,
      } = action as actionCreators.ICreateEmailGenerationSuccessAction;

      return {
        ...state,
        isCreating: false,
        emailGenerations: insertNewItemToArr(
          state.emailGenerations,
          emailGeneration
        ),
      };
    }

    case types.UPDATE_EMAILGENERATION_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_EMAILGENERATION_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_EMAILGENERATION_SUCCESS: {
      const {
        emailGeneration,
      } = action as actionCreators.IUpdateEmailGenerationSuccessAction;

      const emailGenerations = state.emailGenerations.map((t) =>
        t.id === emailGeneration.id ? emailGeneration : t
      );

      return {
        ...state,
        isUpdating: false,
        emailGenerations,
      };
    }

    case types.DELETE_EMAILGENERATION_BEGIN:
      return {
        ...state,
        isDeleting: true,
      };

    case types.DELETE_EMAILGENERATION_FAILURE: {
      return {
        ...state,
        isDeleting: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.DELETE_EMAILGENERATION_SUCCESS: {
      const {
        id,
      } = action as actionCreators.IDeleteEmailGenerationSuccessAction;

      const emailGenerations = state.emailGenerations.filter(
        (t) => t.id !== id
      );

      return {
        ...state,
        isDeleting: false,
        emailGenerations,
      };
    }

    case types.FETCH_ALL_EMAILGENERATIONS_BEGIN: {
      return {
        ...state,
        isFetchingAll: true,
      };
    }

    case types.FETCH_ALL_EMAILGENERATIONS_FAILURE: {
      return {
        ...state,
        isFetchingAll: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_ALL_EMAILGENERATIONS_SUCCESS: {
      let {
        emailGenerations: beEmailGenerations,
      } = action as actionCreators.IFetchEmailGenerationsSuccessAction;
      const emailGenerations = prepareEmailGenerationsForFrontend(
        beEmailGenerations
      );
      return {
        ...state,
        isFetchingAll: false,
        emailGenerations,
      };
    }

    default:
      return state;
  }
}
