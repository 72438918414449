import {
  LIST_SCOPE,
  LIST_ACCESS_RIGHT_FOR_MOBILE_USER,
  LIST_ACCESS_RIGHT_FOR_WEB_USER,
  LIST_TYPE,
  IListSchema,
  IList,
} from "model/entities/List";
import {
  IList as IBEList,
  IListSchema as IBEListSchema,
  LIST_ATTRIBUTE_TYPE,
} from "smala-tools/dist/src/types";
import { IListItem } from "model/entities/ListItem";
import { clone } from "utils/utils";
import { IOption } from "model/application/components";

const prepareListForBackend = (l: IList): IBEList => {
  let list: IList = clone(l);

  const removeAllEmptyAttr = (list: IList): IList => {
    // remove all null values and all empty string values from the object
    const listCleaned: any = {};
    Object.keys(list).forEach((key) => {
      const val = list[key];
      if (!(val === null || val === "")) {
        listCleaned[key] = val;
      }
    });
    return listCleaned;
  };

  if (!list.scope) list.scope = LIST_SCOPE.GLOBAL;
  if (!list.mobile_access_right)
    list.mobile_access_right = LIST_ACCESS_RIGHT_FOR_MOBILE_USER.FULL_ACCESS;
  if (!list.web_access_right)
    list.web_access_right = LIST_ACCESS_RIGHT_FOR_WEB_USER.FULL_ACCESS;
  if (!list.list_type) list.list_type = LIST_TYPE.GENERIC;
  if (
    !list.hasOwnProperty("can_update_items_ownership") ||
    list.can_update_items_ownership == null
  )
    list.can_update_items_ownership = false;
  // replace "label" by "value" in options
  list.schema = prepareListSchemaForBackend(list.schema) as any;
  // remove all 'key' from the schema
  // remove all empty properties from the schema
  list.schema.forEach((attr) => {
    delete attr["key"];
  });
  list.schema = list.schema.map((att) => {
    for (let prop in att) {
      if (
        att[prop] == null ||
        att[prop] === "" ||
        (Array.isArray(att[prop]) && !att[prop].length)
      ) {
        delete att[prop];
      }
    }
    return att;
  });
  list = removeAllEmptyAttr(list);
  // remove the partial_data property and the other frontend properties
  delete (list as any).partial_data;
  delete (list as any).workflows;
  delete (list as any).query;
  return list as any as IBEList;
};

const prepareListSchemaForFrontend = (
  schema: IBEListSchema[]
): IListSchema[] => {
  return schema.map((att: any) => {
    if (att.options) {
      att.options = att.options.map((opt: any, index: number) => {
        opt.label = opt.value;
        delete opt.value;
        opt.index = index;
        return opt;
      });
    }
    return att;
  });
};

const prepareListSchemaForBackend = (
  schema: IListSchema[]
): IBEListSchema[] => {
  return schema.map((att: any) => {
    if (att.options) {
      att.options = att.options.map((opt: any) => {
        opt.value = opt.label;
        delete opt.index;
        delete opt.label;
        return opt;
      });
    }
    return att;
  });
};

const prepareListsForFrontend = (lists: IBEList[]): IList[] => {
  let result: IBEList[] = clone(lists);
  result = result.map((l) => {
    if (l.task_meta) {
      delete l.task_meta["scope_question_text"];
      delete l.task_meta["scope"];
    }
    return l;
  });
  result = result.map((l) => {
    // add actif:true if no "actif" attribute
    if (!l.hasOwnProperty("actif")) {
      l.actif = true;
    }
    // add patial_fetch and big list flag
    l["partial_data"] = true;
    l["workflows"] = [];
    l["big_list"] = l["items"] && l["items"].length >= 1000;
    return l;
  });
  // if there is no index, add an index to the attributes
  const compareFct = (a: any, b: any) => {
    if (!a) return -1;
    if (!b) return 1;
    if (a < b) return -1;
    return 1;
  };
  result = result.map((l) => {
    let newSchema = l.schema.filter((att) => att.deprecated);
    let indexes = l.schema.map((attr) => attr.index);
    newSchema = newSchema.concat(
      l.schema
        .filter((att) => !att.deprecated)
        .sort(compareFct)
        .map((att) => {
          if (att.options) {
            att.options = att.options.filter((opt) => !opt.deprecated);
          }
          if (att.index !== undefined) {
            return att;
          } else {
            let i = 0;
            while (indexes.includes(i)) {
              i++;
            }
            indexes.push(i);
            return { ...att, index: i };
          }
        })
        .sort((a, b) => a.index! - b.index!)
    );
    l.schema = newSchema;
    return l;
  });
  // reformat options: change "value" to "label"
  result = result.map((l) => {
    l.schema = prepareListSchemaForFrontend(l.schema) as any;
    return l;
  });
  return result as any[] as IList[];
};

const prepareItemsForBackend = (
  items: IListItem[],
  listSchema: IListSchema[]
) => {
  listSchema.forEach((att) => {
    switch (att.type) {
      case "MULTIPLE_CHOICE": {
        items = items.map((i) => {
          if (i.hasOwnProperty(att.column_tag)) {
            return {
              ...i,
              [att.column_tag]: att.options!.reduce((acc, curr) => {
                acc[curr.key] = i[att.column_tag]
                  ? i[att.column_tag].find((e: IOption) => e.key === curr.key)
                    ? true
                    : false
                  : false;
                return acc;
              }, {}),
            };
          }
          return i;
        });
        break;
      }
      case "SINGLE_CHOICE": {
        items = items.map((i) => {
          if (i[att.column_tag]) {
            i[att.column_tag] = i[att.column_tag].hasOwnProperty("value")
              ? i[att.column_tag].value
              : i[att.column_tag];
          }
          return i;
        });
        break;
      }
      default: /** do nothing */
    }
  });
  // remove all attributes which are not part of the schema (except "_id" and "_owners","_bi_timestamp")
  items = items.map((i) => {
    for (let att in i) {
      if (
        !listSchema.find((a) => a.column_tag === att) &&
        !["_id", "_owners", "_bi_timestamp"].includes(att)
      ) {
        delete i[att];
      }
    }
    return i;
  });
  return items;
};

const prepareItemsForFrontend = (
  items: IListItem[],
  listSchema: IListSchema[]
): IListItem[] => {
  listSchema.forEach((att) => {
    switch (att.type) {
      case LIST_ATTRIBUTE_TYPE.SINGLE_CHOICE: {
        items = items.map((i) => {
          let formattedItem = { ...i };
          formattedItem[att.column_tag] = i[att.column_tag];
          return formattedItem;
        });
        break;
      }
      case LIST_ATTRIBUTE_TYPE.MULTIPLE_CHOICE: {
        items = items.map((i) => {
          let formattedItem = i;
          if (
            !(
              formattedItem[att.column_tag] &&
              formattedItem[att.column_tag][0] &&
              formattedItem[att.column_tag][0].key
            )
          ) {
            // replace the multiple choice options to the array of key/value objects
            formattedItem[att.column_tag] = att
              .options!.map((o) => o.key)
              .filter((o) => i[`${att.column_tag}__${o}`])
              .map((o) => ({
                key: o,
                label: att.options!.find((opt) => opt.key === o)!.label,
              }));
            att
              .options!.map((o) => o.key)
              .forEach((o) => delete formattedItem[`${att.column_tag}__${o}`]);
          }
          return formattedItem;
        });
        break;
      }
      default: /** do nothing */
    }
  });
  return items.map((i) => {
    // for the items with no "_actif" property, add the property, set to true
    if (!i.hasOwnProperty("_actif")) {
      i["_actif"] = true;
    }
    return i;
  });
};

export {
  prepareListForBackend,
  prepareListsForFrontend,
  prepareItemsForBackend,
  prepareItemsForFrontend,
  prepareListSchemaForFrontend,
  prepareListSchemaForBackend,
};
