import * as types from "../actionTypes/mobileUserActionTypes";

import initialState from "./initialState";
import {
  insertNewItemToArr,
  removeObjectFromArray,
  removeObjectFromHash,
  convertArrayToObject,
} from "../../../../utils/reducerUtils2";
import { IMobileUser } from "model/entities/User";
import { IAction, IActionError } from "store/model";
import {
  IBulkLicenseMobileUsersActionSuccess,
  ICreateMobileUsersActionSuccess,
  IDeleteMobileUserActionSuccess,
  IDeleteMobileUsersActionSuccess,
  IFetchMobileUsersActionSuccess,
  IFetchMobUserSubmissionsSuccessActionCreator,
  ILicenseMobileUserActionSuccess,
  IUpdateMobileUserSuccessActionCreator,
} from "../actionCreator/mobileUserActionCreator";

export interface IMobileUsersState {
  fetchingAllUsers: boolean;
  creatingUsers: boolean;
  updatingUser: boolean;
  deletingUser: boolean;
  deletingUsers: boolean;
  isLicensingUser: boolean;
  isUnlicensingUser: boolean;
  isBulkLicensingUsers: boolean;
  isBulkUnlicensingUsers: boolean;
  fetchingUserSubmissions: boolean;
  fetchingUserSubmissionDetail: boolean;
  lastUpdated: Date;
  user: IMobileUser;
  didInvalidate: boolean;
  allUsers: IMobileUser[];
  byId: { [id: string]: IMobileUser };
  byCreatedBy: { [id: string]: IMobileUser };
  byPhone: { [id: string]: IMobileUser };
  errors: any[];
}

/**
 * User Management reducer takes current state of user management object in Redux Store and action and
 * returns a new state for the Reducer to store. This reducer should NEVER modify the state of the store.
 * For each newly dispatched action, there is a new object state created.
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state
 * */
export default function reducer(
  state = initialState.mobileUsers,
  action: IAction
) {
  switch (action.type) {
    case types.CREATE_MOBILE_USERS_BEGIN:
      return Object.assign({}, state, {
        creatingUsers: true,
      });

    case types.CREATE_MOBILE_USERS_FAILURE: {
      const { type, error } = action as IActionError;
      return Object.assign({}, state, {
        creatingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...error,
          actionType: type,
        }),
      });
    }

    case types.CREATE_MOBILE_USERS_SUCCESS: {
      const { users } = action as ICreateMobileUsersActionSuccess;

      let allUsers = state.allUsers;
      let byId = state.byId;
      let byPhone = state.byPhone;
      (users as IMobileUser[]).forEach((user) => {
        if (!byId[user.id]) {
          allUsers = insertNewItemToArr(allUsers, user);
          byId = Object.assign({}, byId, {
            [user.id]: {
              ...user,
            },
          });
          byPhone = Object.assign({}, byPhone, {
            [user.phone]: {
              ...user,
            },
          });
        }
      });

      return Object.assign({}, state, {
        creatingUsers: false,
        allUsers,
        byId,
        byPhone,
      });
    }

    case types.FETCH_MOBILE_USERS_BY_CLIENT_BEGIN:
      return {
        ...state,
        fetchingAllUsers: true,
        addingUser: false,
        creatingUsers: false,
        updatingUser: false,
        deletingUser: false,
        deletingUsers: false,
        isLicensingUser: false,
        isUnlicensingUser: false,
        isBulkLicensingUsers: false,
        isBulkUnlicensingUsers: false,
      };

    case types.FETCH_MOBILE_USERS_BY_CLIENT_SUCCESS: {
      const { users } = action as IFetchMobileUsersActionSuccess;

      return {
        ...state,
        fetchingAllUsers: false,
        allUsers: users,
        byId: convertArrayToObject(state.byId, users, "id"),
        byCreatedBy: convertArrayToObject(
          state.byCreatedBy,
          users,
          "created_by"
        ),
        byPhone: convertArrayToObject(state.byPhone, users, "phone"),
      };
    }

    case types.FETCH_MOBILE_USERS_BY_CLIENT_FAILURE:
      return Object.assign({}, state, {
        fetchingAllUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      });

    case types.UPDATE_MOBILE_USERS_BEGIN:
      return {
        ...state,
        updatingUser: true,
      };

    case types.UPDATE_MOBILE_USERS_FAILURE:
      return {
        ...state,
        updatingUser: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };

    case types.UPDATE_MOBILE_USERS_SUCCESS: {
      const { mobileUsers } = action as IUpdateMobileUserSuccessActionCreator;

      const allUsers = state.allUsers.map((u) => {
        const mobUsr = mobileUsers.find((uu) => uu.id === u.id);
        if (mobUsr) {
          return { ...u, ...mobUsr };
        } else return u;
      });
      const byId = state.byId;
      mobileUsers.forEach((u) => {
        byId[u.id] = u;
      });

      return {
        ...state,
        updatingUser: false,
        allUsers,
        byId,
      };
    }

    case types.DELETE_MOBILE_USER_BEGIN:
      return {
        ...state,
        deletingUser: true,
      };

    case types.DELETE_MOBILE_USER_FAILURE:
      return {
        ...state,
        deletingUser: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.DELETE_MOBILE_USER_SUCCESS: {
      const { id } = action as IDeleteMobileUserActionSuccess;
      const user = state.byId[id];

      return {
        ...state,
        deletingUser: false,
        allUsers: removeObjectFromArray(state.allUsers, id),
        byId: removeObjectFromHash(state.byId, id),
        byCreatedBy: removeObjectFromHash(state.byCreatedBy, user.created_by),
        byPhone: removeObjectFromHash(state.byPhone, user.phone),
      };
    }

    case types.DELETE_MOBILE_USERS_BEGIN:
      return {
        ...state,
        deletingUsers: true,
      };

    case types.DELETE_MOBILE_USERS_FAILURE:
      return {
        ...state,
        deletingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };

    case types.DELETE_MOBILE_USERS_SUCCESS: {
      const { ids } = action as IDeleteMobileUsersActionSuccess;

      let allUsers = state.allUsers;
      let byId = state.byId;
      let byCreatedBy = state.byCreatedBy;
      let byPhone = state.byPhone;
      (ids as string[]).forEach((id) => {
        const user = state.byId[id];
        allUsers = removeObjectFromArray(allUsers, user.id);
        byId = removeObjectFromHash(byId, user.id);
        byCreatedBy = removeObjectFromHash(byCreatedBy, user.created_by);
        byPhone = removeObjectFromHash(byPhone, user.phone);
      });

      return {
        ...state,
        deletingUsers: false,
        allUsers,
        byId,
        byCreatedBy,
        byPhone,
      };
    }

    case types.UNLICENSE_MOBILE_USER_BEGIN: {
      return {
        ...state,
        isUnlicensingUser: true,
      };
    }

    case types.LICENSE_MOBILE_USER_BEGIN: {
      return {
        ...state,
        isLicensingUser: true,
      };
    }

    case types.UNLICENSE_MOBILE_USER_FAILURE: {
      return {
        ...state,
        isUnlicensingUser: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };
    }

    case types.LICENSE_MOBILE_USER_FAILURE: {
      return {
        ...state,
        isLicensingUser: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };
    }

    case types.UNLICENSE_MOBILE_USER_SUCCESS:
    case types.LICENSE_MOBILE_USER_SUCCESS: {
      const { user } = action as ILicenseMobileUserActionSuccess;
      const fullUser = state.allUsers.find((u) => u.id === user.id)!;
      fullUser.licensed = !fullUser.licensed;
      let newUserList: IMobileUser[] = [];
      state.allUsers.forEach((usr) => {
        usr.id !== user.id ? newUserList.push(usr) : newUserList.push(fullUser);
      });
      return {
        ...state,
        isLicensingUser: false,
        isUnlicensingUser: false,
        allUsers: newUserList,
        byId: {
          ...state.byId,
          [user.id]: fullUser,
        },
        byCreatedBy: {
          ...state.byCreatedBy,
          [user.created_by]: fullUser,
        },
        byPhone: {
          ...state.byPhone,
          [user.phone]: fullUser,
        },
      };
    }

    case types.BULK_LICENSE_MOBILE_USERS_BEGIN: {
      return {
        ...state,
        isBulkLicensingUsers: true,
      };
    }

    case types.BULK_LICENSE_MOBILE_USERS_FAILURE: {
      return {
        ...state,
        isBulkLicensingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };
    }

    case types.BULK_LICENSE_MOBILE_USERS_SUCCESS: {
      const { userIds } = action as IBulkLicenseMobileUsersActionSuccess;
      const newUserList = state.allUsers.map((u) => {
        if (userIds.includes(u.id)) {
          u.licensed = true;
        }
        return u;
      });

      return {
        ...state,
        isBulkLicensingUsers: false,
        allUsers: newUserList,
        byId: convertArrayToObject(state.byId, newUserList, "id"),
        byCreatedBy: convertArrayToObject(
          state.byCreatedBy,
          newUserList,
          "created_by"
        ),
        byPhone: convertArrayToObject(state.byPhone, newUserList, "phone"),
      };
    }

    case types.BULK_UNLICENSE_MOBILE_USERS_BEGIN: {
      return {
        ...state,
        isBulkUnlicensingUsers: true,
      };
    }

    case types.BULK_UNLICENSE_MOBILE_USERS_FAILURE: {
      return {
        ...state,
        isBulkUnlicensingUsers: false,
        errors: insertNewItemToArr(state.errors, {
          ...(action as IActionError).error,
          actionType: action.type,
        }),
      };
    }

    case types.BULK_UNLICENSE_MOBILE_USERS_SUCCESS: {
      const { userIds } = action as IBulkLicenseMobileUsersActionSuccess;
      const newUserList = state.allUsers.map((u) => {
        if (userIds.includes(u.id)) {
          u.licensed = false;
        }
        return u;
      });

      return {
        ...state,
        isBulkUnlicensingUsers: false,
        allUsers: newUserList,
        byId: convertArrayToObject(state.byId, newUserList, "id"),
        byCreatedBy: convertArrayToObject(
          state.byCreatedBy,
          newUserList,
          "created_by"
        ),
        byPhone: convertArrayToObject(state.byPhone, newUserList, "phone"),
      };
    }
    case types.FETCH_MOBUSER_SUBMISSIONS_BEGIN:
      return {
        ...state,
        fetchingUserSubmissions: true,
      };
    case types.FETCH_MOBUSER_SUBMISSIONS_FAILURE:
      return {
        ...state,
        fetchingUserSubmissions: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };
    case types.FETCH_MOBUSER_SUBMISSIONS_SUCCESS: {
      const { submissions } =
        action as IFetchMobUserSubmissionsSuccessActionCreator;
      return {
        ...state,
        fetchingUserSubmissions: false,
        submissions,
      };
    }
    case types.FETCH_MOBUSER_SUBMISSION_DETAIL_BEGIN:
      return {
        ...state,
        fetchingUserSubmissionDetail: true,
      };
    case types.FETCH_MOBUSER_SUBMISSION_DETAIL_FAILURE:
      return {
        ...state,
        fetchingUserSubmissionDetail: false,
        errors: insertNewItemToArr(
          state.errors,
          (action as IActionError).error
        ),
      };
    case types.FETCH_MOBUSER_SUBMISSION_DETAIL_SUCCESS: {
      return {
        ...state,
        fetchingUserSubmissionDetail: false,
      };
    }
    case types.CLEAR_DATA: {
      return initialState.mobileUsers;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState.mobileUsers,
      };

    default:
      return state;
  }
}
