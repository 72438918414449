/**
 * @notes: Api wrapper to communicate with ApiService to handle api calls for team management
 */
import apiService from "../../../api/api";
import ITeam from "model/entities/Team";
import { IHierarchyDependency } from "model/entities/HierarchyDependency";
import { IServerResponse } from "actions/appActions";
import { ITeam as IBETeam } from "smala-tools/dist/src/types";

const TEAMS_ENDPOINT = `/teams`;
const TEAM_ENDPOINT = `/team`;
const CLIENTS_ENDPOINT = `/clients`;
const HIERARCHY_ENDPOINT = "/hierarchy";

/**
 * Creates a team by making a POST request to api with the given parameters
 * @param {Object} team new team details
 * @param {String} clientId Client id
 * @returns {Promise}
 * */
export type TCreateTeamFunc = (
  team: ITeam,
  clientId: string
) => Promise<IServerResponse<{ id: string }>>;
export const createTeamApiCall: TCreateTeamFunc = (
  team: ITeam,
  clientId: string
) => {
  return apiService.post(
    `${CLIENTS_ENDPOINT}/${clientId}${TEAM_ENDPOINT}`,
    team
  );
};

/**
 * Creates teams by making a POST request to api with the given parameters
 * @param {Array} teams Detail of the teams to create
 * @param {String} clientId Client id
 * @returns {Promise}
 * */
export type TCreateTeamsFunc = (
  teams: ITeam[],
  clientId: string
) => Promise<IServerResponse<{ teams: IBETeam[] }>>;
export const createTeamsApiCall: TCreateTeamsFunc = (teams, clientId) => {
  const body = { teams };
  return apiService.post(
    `${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}`,
    body
  );
};

/**
 * Fetch all teams api call for a single client
 * @param {String} clientId Client Id
 * @return {Promise}
 * */
export type TFetchTeamsForClientResponse = { teams: IBETeam[] };
export type TFetchTeamsForClientFunc = (
  clientId: string
) => Promise<IServerResponse<{ teams: IBETeam[] }>>;
export const fetchTeamsForClientApiCall: TFetchTeamsForClientFunc = (
  clientId
) => {
  return apiService.get(`${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}`);
};

/**
 * Delete Teams API call makes a POST request to delete teams
 * @param {String} ids Id of the teams to delete
 * @param {String} clientId Id of the client concerned
 * @return {Promise}
 * */
export type TDeleteTeamsFunc = (
  ids: string[],
  clientId: string
) => Promise<IServerResponse<{}>>;
export const deleteTeamsApiCall: TDeleteTeamsFunc = (ids, clientId) => {
  return apiService.delete(`${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}`, {
    ids,
  });
};

/**
 * Archive Teams API call makes a POST request to archive teams
 * @param {String} ids Id of the teams to archive
 * @param {String} clientId Id of the client concerned
 * @return {Promise}
 * */
export type TArchiveTeamsFunc = (
  ids: string[],
  clientId: string
) => Promise<IServerResponse<{}>>;
export const archiveTeamsApiCall: TArchiveTeamsFunc = (ids, clientId) => {
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}/archive`,
    { ids }
  );
};

/**
 * Restore Teams API call makes a POST request to restore a teams
 * @param {String} ids Id of the teams to restore
 * @param {String} clientId Id of the client concerned
 * @return {Promise}
 * */
export type TRestoreTeamsFunc = (
  ids: string[],
  clientId: string
) => Promise<IServerResponse<{}>>;
export const restoreTeamsApiCall: TRestoreTeamsFunc = (ids, clientId) => {
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}/restore`,
    { ids }
  );
};

/**
 * Update team API call
 * @param {String} clientId Id of the client concerned
 * @param {Array} teams Array of teams to import
 * */
export type TUpdateTeamsFunc = (
  teams: ITeam[],
  clientId: string
) => Promise<IServerResponse<{}>>;
export const updateTeamsApiCall: TUpdateTeamsFunc = (teams, clientId) => {
  return apiService.patch(`${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}`, {
    teams,
  });
};

/**
 * Assign Mobile users to teams
 * @param {String} clientId Client Id in which we want to do the action
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export type TAssignMobileUsersToTeamsFunc = (
  clientId: string,
  links: any
) => Promise<IServerResponse<any>>;
export const assignMobileUsersToTeamsApiCall: TAssignMobileUsersToTeamsFunc = (
  clientId,
  links
) => {
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}/assign`,
    {
      links,
    }
  );
};

/**
 * Assign Web users to teams
 * @param {String} clientId Client Id in which we want to do the action
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export function assignWebUsersToTeamsApiCall(clientId: string, links: any) {
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${TEAMS_ENDPOINT}/assign_web`,
    { links }
  );
}

/**
 * Create or update hierarchy dependencies for client
 * @param {String} hierarchyDependencies all hierarchy dependencies
 * @param {String} clientId Client Id in which we want to do the action
 * @returns {Promise}
 */
export type TUpdateHierarchyFunc = (
  hierarchyDependencies: IHierarchyDependency[],
  clientId: string
) => Promise<IServerResponse<{ teams: IBETeam[] }>>;
export const updateHierarchyApiCall: TUpdateHierarchyFunc = (
  hierarchyDependencies,
  clientId
) => {
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${HIERARCHY_ENDPOINT}`,
    {
      hierarchy_dependencies: hierarchyDependencies.filter(
        (e) => !e.removeBeforeBackEnd
      ),
    }
  );
};
