import React, { useState } from "react";
import { Menu, MenuItem, Fab, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./styles";
import { ITableRowContent } from "model/application/components";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(styles as any);

export interface IMoreButtonOptions {
  additionnalInfos?: any;
  disabled?: (items: any[], additionnalInfos: any) => boolean;
  action: (id?: string) => void;
  value?: string;
  checked?: boolean;
  label: string;
}

export interface ICustomMoreButtonProps {
  options: IMoreButtonOptions[];
  selectedItems?: ITableRowContent[];
  onAction?: (action: (id?: string) => void) => void;
  onSelectDeselectOption?: (
    value: string | undefined,
    checked: boolean
  ) => void;
  icon?: any;
  isTableAction?: boolean;
}

export const CustomMoreButton: React.FunctionComponent<ICustomMoreButtonProps> = ({
  options,
  selectedItems,
  onAction,
  onSelectDeselectOption,
  icon,
  isTableAction,
}) => {
  // bind the style
  const classes = useStyles();
  // declare state attributes
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleSelect = (option: IMoreButtonOptions) => () => {
    // if option has attribute "checked"
    if (option.hasOwnProperty("checked") && onSelectDeselectOption) {
      onSelectDeselectOption(option.value, !option.checked);
    }
    // if option has attribute "action"
    if (option.hasOwnProperty("action")) {
      if (onAction != null) {
        onAction(option.action);
      } else {
        option.action();
      }
    }
    handleClose();
  };

  const realIcon = icon ? icon : <MoreVertIcon />;

  return (
    <div
      className={`${
        isTableAction
          ? classes.ButtonContainerNoPadding
          : classes.ButtonContainer
      }`}
    >
      <Fab
        variant="round"
        size="small"
        color="primary"
        aria-label={"More"}
        className={
          isTableAction ? classes.TableActionButton : classes.ActionButton
        }
        onClick={handleClick}
      >
        {realIcon}
      </Fab>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 230,
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option, idx) => (
          <MenuItem
            key={`option.label-${idx}`}
            onClick={handleSelect(option)}
            disabled={
              option.disabled
                ? option.disabled(
                    selectedItems ? selectedItems : [],
                    option.additionnalInfos
                  )
                : false
            }
          >
            {option.hasOwnProperty("checked") ? (
              option.checked ? (
                <CheckIcon aria-label="CheckIcon" />
              ) : null
            ) : null}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMoreButton;
