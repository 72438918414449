import { createTheme } from "@material-ui/core";
import {
  primaryColor,
  secondaryColor,
  cancelColor,
  defaultColor,
} from "assets/colors";

export const StoryAppTheme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Lato","Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  palette: {
    primary: {
      main: "#124E5D",
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#fff",
    },
    cancel: {
      main: cancelColor,
      contrastText: "#fff",
    },
    default: {
      main: defaultColor,
      contrastText: "#fff",
    },
  },
});

export const AppTheme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily:
      '"BasierCircle","Lato","Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  palette: {
    primary: {
      main: primaryColor,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#fff",
    },
    cancel: {
      main: cancelColor,
      contrastText: "#fff",
    },
    default: {
      main: defaultColor,
      contrastText: "#fff",
    },
  },
});
