import { TeamsState } from "containers/teams/redux/reducer";

const initialState: TeamsState = {
  subCategorySelected: "team",
  isFetchingTeamsForClient: false,
  isRemovingUsersFromAllTeams: false,
  isAssigningMobileUsersToTeams: false,
  isAssigningWebUsersToTeams: false,
  isCreatingTeam: false,
  isCreatingTeams: false,
  isUpdatingTeam: false,
  isDeletingTeams: false,
  isArchivingTeams: false,
  isRestoringTeams: false,
  selectedTeam: undefined,
  allTeams: [],
  allIds: [],
  errors: [],
};

export default initialState;
