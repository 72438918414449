import React from "react";
import { Route, Redirect } from "react-router-dom";

interface IPrivateRouteProps {
  component: any;
  location: any;
  isAuthenticated: boolean;
}

/**
 * PrivateRoute stateless component. This is a custom route component that uses the same API as the Route component
 * provided by react-router-dom. This will allow the Application to protect certain routes from user access unless
 * they are logged in. This will limit viewing to only logged in and authenticated users. This Private route
 * takes in a component (A React Component), isAuthenticated boolean value and any other props that can be
 * passed down to the Route Component
 * @param component Component to Render when this user is authenticated
 * @param isAuthenticated Whether the user is authenticated/admin
 * @param rest the other props to pass to Route Component
 */
const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({
  component: Component,
  isAuthenticated,
  ...rest
}: IPrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
