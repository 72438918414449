import {
  IList as IBEList,
  IListSchema as IBEListSchema,
} from "smala-tools/dist/src/types";
import { IOption, ITableRowContent } from "model/application/components";
import { IListItem } from "./ListItem";
export {
  LIST_SCOPE,
  LIST_ACCESS_RIGHT_FOR_MOBILE_USER,
  LIST_ACCESS_RIGHT_FOR_WEB_USER,
  LIST_ATTRIBUTE_TYPE,
  LIST_TYPE,
  TASK_TYPE,
} from "smala-tools/dist/src/types";
export type {
  ITaskDescriptiveText,
  IMatrixBinding,
  IGeneratorToTask,
  ITaskMeta,
} from "smala-tools/dist/src/types";

export interface IListSchema extends Omit<IBEListSchema, "options"> {
  options?: IOption<string>[];
  index: number;
}

export interface IList<T extends IListItem = IListItem>
  extends Omit<
    IBEList,
    "actif" | "items" | "client_id" | "updated_at" | "created_at" | "schema"
  > {
  actif: boolean;
  items: T[];
  client_id?: string;
  updated_at?: Date;
  created_at?: Date;
  schema: IListSchema[];
  partial_data: boolean;
  big_list: boolean;
  workflows: { id: string; name: string }[];
  item_count?: number;
  query: any; // additionnal frontend property to store the query running on the list
  show_ids: boolean;
}

export interface IListInTable extends ITableRowContent {
  actif: boolean;
  list_id: string;
  name: string;
  item_nb: number;
}
