import clsx from "clsx";
import React, { Component } from "react";
import SidebarItem from "./SidebarItem";
import { connect } from "react-redux";
import optimetriks_logo from "../../assets/images/png/optimetriks_logo.png";
import {
  getSelectedClient,
  getLevelViewOptions,
} from "../../containers/clients/redux/selectors";
import { getRoutes } from "../../config/routes";
import { HOME_ROUTE } from "config/constants";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { listsComposedSelector } from "containers/lists/redux/selectors";
import { workflowsComposedSelector } from "containers/workflows/redux/selectors";
import { dashboardsComposedSelector } from "containers/dashboards/redux/selectors";
import { getSelectedSubCategory as getSelectedSubCategoryUsermgt } from "containers/usermgt/redux/selectors/selectors";
import { getSelectedSubCategory as getSelectedSubCategoryWorkflows } from "containers/workflows/redux/selectors";
import { getSelectedSubCategory as getSelectedSubCategoryLists } from "containers/lists/redux/selectors";
import { getSelectedSubCategory as getSelectedSubCategoryDashboards } from "containers/dashboards/redux/selectors";
import { getSelectedSubCategory as getSelectedSubCategoryEnvironments } from "containers/environments/redux/selectors";
import { getSelectedSubCategory as getSelectedSubCategoryTeams } from "containers/teams/redux/selectors";
import { getSelectedSubCategory as getSelectedSubCategoryPlaces } from "containers/places/redux/selectors";
import { bindActionCreators, Dispatch } from "redux";
import * as userActions from "containers/usermgt/redux/actions/userActions";
import * as listActions from "containers/lists/redux/actions";
import * as dashboardActions from "containers/dashboards/redux/actions";
import * as workflowActions from "containers/workflows/redux/actions";
import * as placeActions from "containers/places/redux/actions";
import * as teamActions from "containers/teams/redux/actions";
import * as documentActions from "containers/documents/redux/actions";
import * as environmentActions from "containers/environments/redux/actions";
import TLang from "model/application/Lang";
import { IClient } from "model/entities/Client";
import IRootState from "store/model";
import { IDashboard } from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import { IWorkflowGroup } from "model/entities/Workflow";
import { IOption } from "model/application/components";
import { getPrivilege } from "../../containers/authentication/redux/selector";
import { getSelectedDocuments } from "../../containers/documents/redux/selectors";
import { isOptimetriksRole } from "model/application/ActionCode";
import { allTablesSelector } from "containers/environments/redux/dbSchema/selectors";

interface ISidebarCategory {
  name: string;
  id: string;
}

export interface ISidebarProps {
  actionsAvailable: any;
  selectedSubCategory: any;
  client: IClient;
  dashboards: IDashboard[];
  lists: IList[];
  workflows: IWorkflowGroup[];
  levels: IOption[];
  userActions: any;
  documentActions: any;
  workflowActions: any;
  listActions: any;
  teamActions: any;
  dashboardActions: any;
  environmentActions: any;
  placeActions: any;
  classes: any;
  disabledSidebar: boolean;
  location: any;
  currentRole: string;
  lang: TLang;
}

interface ISidebarStates {
  width: number;
  miniSidebar: boolean;
  open: boolean;
}

/**
 * Sidebar stateless component
 * This will render the component as a drawer on the left side of the screen
 * This gives access to the various dashboard screens through menu items
 */
export class Sidebar extends Component<ISidebarProps, ISidebarStates> {
  constructor(props: ISidebarProps) {
    super(props);
    this.state = {
      width: window.innerWidth,
      miniSidebar: true,
      open: false,
    };

    this.activeRoute.bind(this);
  }

  /**
   * Adds an active class to the route
   * @param {String} routeName the name of the current route being displayed
   * @returns {String} The class name to add to the route in the sidebar component
   * */
  activeRoute(routeName: string) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  buildSidebarItem = (
    item: any,
    index: number,
    isOpen: boolean,
    changeSubCategoryFunction: (id: string) => void,
    selectedSubCategory: string,
    subCategories: any[]
  ) => {
    const { disabledSidebar } = this.props;
    return (
      <SidebarItem
        key={index}
        activeRoute={this.activeRoute(item.path)}
        path={item.path}
        icon={item.icon}
        title={item.title}
        changeSubCategoryFunction={changeSubCategoryFunction}
        selectedSubCategory={selectedSubCategory}
        subCategories={subCategories}
        open={isOpen}
        disabled={disabledSidebar}
      />
    );
  };

  /**
   * Creates navigation links for the sidebar Only create ALL Navigation links if the logged in user
   * is a Super Admin
   * @returns {Array}
   * */
  createNavigationLinks = (lang: TLang) => {
    const {
      actionsAvailable,
      client: { modules },
      selectedSubCategory,
      dashboards,
      lists,
      workflows,
      levels,
      userActions,
      documentActions,
      workflowActions,
      dashboardActions,
      environmentActions,
      listActions,
      teamActions,
      placeActions,
      currentRole,
    } = this.props;
    const { open } = this.state;

    return getRoutes(actionsAvailable, lang, modules)
      .filter((item) => item.path !== HOME_ROUTE)
      .map((item, index) => {
        let subCategorySelected = "index";
        let subCategories: ISidebarCategory[] = [];
        let onSubCategoryClick = (id: string) => {};

        switch (item.icon) {
          case "environment": {
            subCategorySelected = selectedSubCategory.environments;
            const dashboardSelected = dashboards.find(
              (d) => d.id === subCategorySelected
            );
            if (dashboardSelected) {
              subCategorySelected = dashboardSelected.folder || "default";
            }
            onSubCategoryClick = environmentActions.changeSubcategoryAction;
            const folders = [
              ...new Set(
                dashboards
                  .filter((d) => d.actif)
                  .filter((d) => ["WEB", "MOBILE_AND_WEB"].includes(d.type))
                  .map((d) => d.folder || "default")
              ),
            ];
            subCategories = [
              {
                name: lang.mainLayout.sidebar.summary,
                id: "index",
              },
              ...folders
                .filter((f) =>
                  ["Job logs", "Sanity check", "Database structure"].includes(f)
                )
                .map((f) => ({ name: f, id: f })),
            ];
            if (isOptimetriksRole(currentRole)) {
              subCategories.push({
                name: "Transformations",
                id: "transformations",
              });
            }
            if (isOptimetriksRole(currentRole)) {
              subCategories.push({
                name: "Database",
                id: "database",
              });
            }
            if (isOptimetriksRole(currentRole)) {
              subCategories.push({
                name: "Notifications",
                id: "notifications",
              });
            }
            if (isOptimetriksRole(currentRole)) {
              subCategories.push({
                name: "Streams",
                id: "streams",
              });
            }
            if (isOptimetriksRole(currentRole)) {
              subCategories.push({
                name: "Optimizations",
                id: "tableOptimizations",
              });
            }
            break;
          }
          case "dashboard": {
            subCategorySelected = selectedSubCategory.dashboards;
            const dashboardSelected = dashboards.find(
              (d) => d.id === subCategorySelected
            );
            if (dashboardSelected) {
              subCategorySelected = dashboardSelected.folder || "default";
            }
            onSubCategoryClick = dashboardActions.changeSubcategoryAction;
            const folders = [
              ...new Set(
                dashboards
                  .filter((d) => d.actif)
                  .filter((d) => ["WEB", "MOBILE_AND_WEB"].includes(d.type))
                  .map((d) => d.folder || "default")
              ),
            ];
            subCategories = [
              {
                name: lang.mainLayout.sidebar.summary,
                id: "index",
              },
              ...folders
                .filter(
                  (f) =>
                    ![
                      "Job logs",
                      "Sanity check",
                      "Database structure",
                    ].includes(f)
                )
                .map((f) => ({ name: f, id: f })),
            ];
            break;
          }
          case "person": {
            subCategorySelected = selectedSubCategory.usermgt;
            onSubCategoryClick = userActions.changeSubcategoryAction;
            subCategories = [
              {
                name: lang.mainLayout.sidebar.mobile_users,
                id: "mobileuser",
              },
              {
                name: lang.mainLayout.sidebar.web_users,
                id: "webuser",
              },
            ];
            break;
          }
          case "group_work": {
            subCategorySelected = selectedSubCategory.teams;
            onSubCategoryClick = teamActions.changeSubcategoryAction;
            subCategories = [
              {
                name: lang.mainLayout.sidebar.teams,
                id: "team",
              },
              ...levels
                .filter((l, i) => i !== 0)
                .map((l) => ({
                  name: l.label,
                  id: l.key,
                })),
            ];
            break;
          }
          case "assignment": {
            subCategorySelected = selectedSubCategory.documents;
            onSubCategoryClick = documentActions.changeSubcategoryAction;
            if (isOptimetriksRole(currentRole)) {
              subCategories = [
                {
                  name: lang.mainLayout.sidebar.clients,
                  id: "index",
                },
                {
                  name: "Optimetriks",
                  id: "optimetriks",
                },
              ];
            } else {
              subCategories = [
                {
                  name: lang.mainLayout.sidebar.clients,
                  id: "index",
                },
              ];
            }
            break;
          }
          case "work": {
            subCategorySelected = selectedSubCategory.workflows;
            onSubCategoryClick = workflowActions.changeSubcategoryAction;
            subCategories = [
              {
                name: lang.mainLayout.sidebar.summary,
                id: "index",
              },
              ...workflows
                .filter((w) => w.actif)
                .map((w) => ({
                  name: `${w.primary.name}`,
                  id: w.primary.id,
                })),
              ...workflows
                .filter((w) => w.actif)
                .filter((w) => w.secondary)
                .map((w) => ({
                  name: `${w.secondary!.name}`,
                  id: w.secondary!.id,
                })),
            ];
            break;
          }
          case "list": {
            subCategorySelected = selectedSubCategory.lists;
            onSubCategoryClick = listActions.changeSubcategoryAction;
            subCategories = [
              {
                name: lang.mainLayout.sidebar.summary,
                id: "index",
              },
              ...lists
                .filter((l) => l.actif)
                .map((l) => ({
                  name: l.name,
                  id: l.id,
                })),
            ];
            break;
          }
          case "photo_camera": {
            subCategorySelected = "index";
            subCategories = [
              {
                name: lang.mainLayout.sidebar.summary,
                id: "index",
              },
            ];
            break;
          }
          case "place": {
            subCategorySelected = selectedSubCategory.places;
            onSubCategoryClick = placeActions.changeSubcategoryAction;
            subCategories = [
              {
                name: lang.mainLayout.sidebar.map,
                id: "map",
              },
              {
                name: lang.mainLayout.sidebar.list,
                id: "place",
              },
            ];
            break;
          }
          case "supervisor_account": {
            subCategorySelected = "index";
            subCategories = [
              {
                name: lang.mainLayout.sidebar.summary,
                id: "index",
              },
            ];
            break;
          }
          default: // do nothing
        }
        if (subCategories.length === 1) subCategories = [];
        return this.buildSidebarItem(
          item,
          index,
          open,
          onSubCategoryClick,
          subCategorySelected,
          subCategories
        );
      });
  };

  render() {
    const { client, classes, lang } = this.props;
    const { open } = this.state;
    return (
      <Drawer
        onMouseEnter={() => this.setState({ open: true })}
        onMouseLeave={() => this.setState({ open: false })}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <PerfectScrollbar>
          {
            <NavLink to={HOME_ROUTE}>
              <img
                className={classes.NavLinkImage}
                style={{
                  filter: `grayscale(${
                    client && client.status === "pilot" ? 1 : 0
                  })`,
                }}
                src={
                  client
                    ? client.logo
                      ? client.logo
                      : optimetriks_logo
                    : optimetriks_logo
                }
                alt={"ald"}
                aria-label="HomeRouteNavLink"
              />
            </NavLink>
          }
          <List>{this.createNavigationLinks(lang)}</List>
        </PerfectScrollbar>
      </Drawer>
    );
  }
}

/**
 * maps the state of the redux store to the LoginContainer props
 * @param {Object} state of redux store
 * @param {Object} ownProps LoginContainer properties
 * @returns {Object} new state of redux store
 */
function mapStateToProps(state: IRootState) {
  return {
    client: getSelectedClient(state),
    dashboards: dashboardsComposedSelector(state),
    tables: allTablesSelector(state),
    lists: listsComposedSelector(state),
    workflows: workflowsComposedSelector(state),
    levels: getLevelViewOptions(state),
    currentRole: getPrivilege(state),
    selectedSubCategory: {
      usermgt: getSelectedSubCategoryUsermgt(state),
      dashboards: getSelectedSubCategoryDashboards(state),
      environments: getSelectedSubCategoryEnvironments(state),
      teams: getSelectedSubCategoryTeams(state),
      workflows: getSelectedSubCategoryWorkflows(state),
      places: getSelectedSubCategoryPlaces(state),
      lists: getSelectedSubCategoryLists(state),
      documents: getSelectedDocuments(state),
    },
  };
}

/**
 * maps dispatch actions to props in this container
 * component
 * @param {Object} dispatch
 * @returns {Object} actions object
 */
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    workflowActions: bindActionCreators(workflowActions as any, dispatch),
    listActions: bindActionCreators(listActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions as any, dispatch),
    environmentActions: bindActionCreators(environmentActions as any, dispatch),
    placeActions: bindActionCreators(placeActions, dispatch),
    documentActions: bindActionCreators(documentActions as any, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(Sidebar));
