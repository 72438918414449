import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "../containers/authentication/redux/reducer";
import app from "./appReducer";
import notify from "../containers/notifications/reducer";
import ajax from "./ajaxReducer";
import { routerReducer } from "react-router-redux";
import users from "../containers/usermgt/redux/reducer/reducer";
import clients from "../containers/clients/redux/reducer";
import teams from "../containers/teams/redux/reducer";
import dashboards from "../containers/dashboards/redux/reducer";
import workflows from "../containers/workflows/redux/reducer";
import lists from "../containers/lists/redux/reducer";
import pictures from "../containers/pictures/redux/reducer";
import places from "../containers/places/redux/reducer";
import documents from "../containers/documents/redux/reducer";
import environments from "../containers/environments/redux/reducer";
/**
 * Combines all reducers for use in the application
 * Uses short hand property names from ES6
 *  */
const rootReducer = (history) =>
  combineReducers({
    app,
    auth,
    notify,
    ajax,
    users,
    places,
    clients,
    teams,
    dashboards,
    workflows,
    lists,
    pictures,
    documents,
    environments,
    router: connectRouter(history),
    routing: routerReducer,
  }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default rootReducer;
