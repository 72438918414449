import * as types from "./actionTypes";
import { IAction, IActionError, IBeginAction } from "store/model";
import { ITransformation } from "model/entities/Dashboard";
import { ITransformation as IBETransformation } from "smala-tools/dist/src/types";

// TRANSFORMATIONS

export interface ICreateTransformationSuccessAction extends IAction {
  transformation: ITransformation;
}
export interface IUpdateTransformationSuccessAction extends IAction {
  transformation: ITransformation;
}
export interface IDeleteTransformationSuccessAction extends IAction {
  id: string;
}
export interface IArchiveTransformationsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreTransformationsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchTransformationsSuccessAction extends IAction {
  transformations: IBETransformation[];
}

/**
 * Create transformation begin action creator
 * This creates an action informing redux store that the creation process for a transformation has just began
 * @returns {Object}
 */
export function createTransformationBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_TRANSFORMATION_BEGIN,
  };
}

/**
 * Failure Action for creating a transformation instructing that the creation process for a transformation has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createTransformationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_TRANSFORMATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a transformation
 * @param {Object} transformation Transformation object,
 * @returns {Object}
 */
export const createTransformationSuccessActionCreator = (
  transformation: ITransformation
): ICreateTransformationSuccessAction => {
  return {
    type: types.CREATE_TRANSFORMATION_SUCCESS,
    transformation,
  };
};

/**
 * Edit dashboard begin action creator
 * This creates an action informing redux store that the creation process for a dashboard has just began
 * @returns {Object}
 */
export function updateTransformationBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_TRANSFORMATION_BEGIN,
  };
}

/**
 * Failure Action for editing a dashboard instructing that the edition process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateTransformationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_TRANSFORMATION_FAILURE,
    error,
  };
}

/**
 * Success action for editing a dashboard
 * @param {Object} dashboard Dashboard object,
 * @returns {Object}
 */
export function updateTransformationSuccessActionCreator(
  transformation: ITransformation
): IUpdateTransformationSuccessAction {
  return {
    type: types.UPDATE_TRANSFORMATION_SUCCESS,
    transformation,
  };
}

/**
 * Delete dashboard begin action creator
 * This creates an action informing redux store that the creation process for a dashboard has just began
 * @returns {Object}
 */
export function deleteTransformationBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_TRANSFORMATION_BEGIN,
  };
}

/**
 * Failure Action for creating a dashboard instructing that the creation process for a dashboard has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteTransformationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_TRANSFORMATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating a dashboard
 * @param {Object} dashboardId Dashboard object,
 * @returns {Object}
 */
export function deleteTransformationSuccessActionCreator(
  dashboardId: string
): IDeleteTransformationSuccessAction {
  return {
    type: types.DELETE_TRANSFORMATION_SUCCESS,
    id: dashboardId,
  };
}

/**
 * begin action when there is a request to fetch all transformations
 * @returns {Object}
 * */
export const fetchAllTransformationsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_TRANSFORMATIONS_BEGIN,
});

/**
 * Failure action for fetching all transformations. This action is dispatched when there is an error fetching all
 * transformations
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllTransformationsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_TRANSFORMATIONS_FAILURE,
  error,
});

/**
 * Fetch all transformations action creator
 * @param {Array} transformations an array of dashboard objects
 * @returns {Object}
 * */
export const fetchAllTransformationsSuccessActionCreator = (
  transformations: IBETransformation[]
): IFetchTransformationsSuccessAction => ({
  type: types.FETCH_ALL_TRANSFORMATIONS_SUCCESS,
  transformations,
});
