import { changeSubcategorySuccessActionCreator } from "../actionCreator/userActionCreator";
import { IDispatchAndGetState } from "store/model";

export type IChangeSubcategoryActionFunc = (
  subcategory: "mobileuser" | "webuser"
) => IDispatchAndGetState<void>;
export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory
) => {
  return (dispatch) => {
    return new Promise(() =>
      dispatch(changeSubcategorySuccessActionCreator(subcategory))
    );
  };
};
