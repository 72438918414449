const drawerWidth = "200px";

export default (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.primary.main,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "60px",
    background: theme.palette.primary.main,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  SidebarItemIconActive: {
    color: "#FFF",
    minWidth: "unset",
    width: "45px",
  },
  SidebarItemIcon: {
    color: "#FFF9",
    minWidth: "unset",
    width: "45px",
  },
  SidebarItemTextActive: {
    color: "#FFF",
  },
  SidebarItemText: {
    color: "#FFF9",
  },
  NavLinkImage: {
    background: "white",
    padding: "5px",
    margin: "5px",
    borderRadius: "100px",
    width: "calc(100% - 10px)",
  },
  ExpansionPanelRoot: {
    background: "none",
    boxShadow: "none",
    borderLeft: "0px white solid !important",
    borderRadius: "unset !important",
  },
  ExpansionPanelRootActive: {
    background: "none",
    boxShadow: "none",
    borderLeft: "2px white solid !important",
    borderRadius: "unset !important",
  },
  ExpansionPanelSummaryContent: {
    margin: "0px !important",
    padding: "0px",
  },
  ExpansionPanelSummaryRoot: {
    justifyContent: "start",
    padding: "0px",
  },
  ExpansionPanelDetailsContent: {
    margin: "0px",
    padding: "0px",
  },
  ExpansionPanelDetailsRoot: {
    justifyContent: "start",
    padding: "0px",
  },
});
