export const CREATE_WORKFLOW_BEGIN = "CREATE_WORKFLOW_BEGIN";
export const CREATE_WORKFLOW_SUCCESS = "CREATE_WORKFLOW_SUCCESS";
export const CREATE_WORKFLOW_FAILURE = "CREATE_WORKFLOW_FAILURE";

export const FETCH_ALL_WORKFLOWS_BEGIN = "FETCH_ALL_WORKFLOWS_BEGIN";
export const FETCH_ALL_WORKFLOWS_SUCCESS = "FETCH_ALL_WORKFLOWS_SUCCESS";
export const FETCH_ALL_WORKFLOWS_FAILURE = "FETCH_ALL_WORKFLOWS_FAILURE";

export const UPDATE_WORKFLOW_BEGIN = "UPDATE_WORKFLOW_BEGIN";
export const UPDATE_WORKFLOW_SUCCESS = "UPDATE_WORKFLOW_SUCCESS";
export const UPDATE_WORKFLOW_FAILURE = "UPDATE_WORKFLOW_FAILURE";

export const DELETE_WORKFLOW_BEGIN = "DELETE_WORKFLOW_BEGIN";
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS";
export const DELETE_WORKFLOW_FAILURE = "DELETE_WORKFLOW_FAILURE";

export const ARCHIVE_WORKFLOW_BEGIN = "ARCHIVE_WORKFLOW_BEGIN";
export const ARCHIVE_WORKFLOW_SUCCESS = "ARCHIVE_WORKFLOW_SUCCESS";
export const ARCHIVE_WORKFLOW_FAILURE = "ARCHIVE_WORKFLOW_FAILURE";

export const RESTORE_WORKFLOW_BEGIN = "RESTORE_WORKFLOW_BEGIN";
export const RESTORE_WORKFLOW_SUCCESS = "RESTORE_WORKFLOW_SUCCESS";
export const RESTORE_WORKFLOW_FAILURE = "RESTORE_WORKFLOW_FAILURE";

export const FETCH_WORKFLOWS_FOR_CLIENT_SUCCESS =
  "FETCH_WORKFLOWS_FOR_CLIENT_SUCCESS";

export const FETCH_SUBMISSIONS_BEGIN = "FETCH_SUBMISSIONS_BEGIN";
export const FETCH_SUBMISSIONS_SUCCESS = "FETCH_SUBMISSIONS_SUCCESS";
export const FETCH_SUBMISSIONS_FAILURE = "FETCH_SUBMISSIONS_FAILURE";

export const FETCH_SUBMISSION_DETAIL_BEGIN = "FETCH_SUBMISSION_DETAIL_BEGIN";
export const FETCH_SUBMISSION_DETAIL_SUCCESS =
  "FETCH_SUBMISSION_DETAIL_SUCCESS";
export const FETCH_SUBMISSION_DETAIL_FAILURE =
  "FETCH_SUBMISSION_DETAIL_FAILURE";

export const DELETE_SUBMISSION_BEGIN = "DELETE_SUBMISSION_BEGIN";
export const DELETE_SUBMISSION_SUCCESS = "DELETE_SUBMISSION_SUCCESS";
export const DELETE_SUBMISSION_FAILURE = "DELETE_SUBMISSION_FAILURE";

export const UPDATE_SUBMISSION_BEGIN = "UPDATE_SUBMISSION_BEGIN";
export const UPDATE_SUBMISSION_SUCCESS = "UPDATE_SUBMISSION_SUCCESS";
export const UPDATE_SUBMISSION_FAILURE = "UPDATE_SUBMISSION_FAILURE";

export const RESET_SUBMISSION_SELECTION = "RESET_SUBMISSION_SELECTION";

export const UPLOAD_FILE_BEGIN = "UPLOAD_FILE_BEGIN";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

export const FETCH_WORKFLOW_VERSIONS_BEGIN = "FETCH_WORKFLOW_VERSIONS_BEGIN";
export const FETCH_WORKFLOW_VERSIONS_SUCCESS =
  "FETCH_WORKFLOW_VERSIONS_SUCCESS";
export const FETCH_WORKFLOW_VERSIONS_FAILURE =
  "FETCH_WORKFLOW_VERSIONS_FAILURE";

export const RESTORE_OLD_WORKFLOWS_BEGIN = "RESTORE_OLD_WORKFLOWS_BEGIN";
export const RESTORE_OLD_WORKFLOWS_SUCCESS = "RESTORE_OLD_WORKFLOWS_SUCCESS";
export const RESTORE_OLD_WORKFLOWS_FAILURE = "RESTORE_OLD_WORKFLOWS_FAILURE";

export const CLEAR_DATA = "CLEAR_DATA";

export const CHANGE_SUBCATEGORY_SELECTED_SUCCESS =
  "CHANGE_WORKFLOW_SUBCATEGORY_SELECTED_SUCCESS";

export { LOGOUT_REQUEST_SUCCESS } from "../../authentication/redux/actionTypes";
