import { IFilter } from "model/application/Filter";
import {
  DEFAULT_DASHBOARD,
  IDashboard,
  KPI_TYPE,
  PIVOT_TYPE,
} from "model/entities/Dashboard";
import { transposeLocalTimeToUTCTime } from "utils/utils";
import FILTER_TYPE, { FILTER_TAG } from "./TypeFilter";
import { IMetaHierarchyDependency } from "model/entities/HierarchyDependency";

export const getBackendQuery = (
  query: any,
  metaHierarchy: IMetaHierarchyDependency,
  dashboardId?: string
): any => {
  const levelNames = Object.values(metaHierarchy)
    .filter((e) => e)
    .map(
      (e) => "_labels_" + e.level_type_name.toLowerCase().split(" ").join("_")
    );
  return Object.keys(query).reduce((acc, curr) => {
    if (!levelNames.includes(curr)) {
      switch (curr) {
        case FILTER_TAG.HIERARCHY:
        case FILTER_TAG.LABELS:
        case FILTER_TAG.MORE_FILTER: {
          // don't send back "LABELS" filter to the backend: only the "teams" has to be send
          // don't send back "HIERARCHY" filter to the backend: only the "teams" has to be send
          // don't send back "MORE_FILTER" filter to the backend. Keep it only for the raw query
          break;
        }
        case FILTER_TAG.ACTION_CODE: {
          acc["action_code"] = query[curr];
          break;
        }
        case FILTER_TAG.TEAMS: {
          acc["teams"] = query[curr];
          break;
        }
        case FILTER_TAG.DATE: {
          acc["start_date"] = transposeLocalTimeToUTCTime(
            query[curr].startDate
          );
          acc["end_date"] = transposeLocalTimeToUTCTime(query[curr].endDate);
          // special case of WEEKLY / MONYHLY recap dashboard: we "cheat" with the date
          if (dashboardId === DEFAULT_DASHBOARD.MONTHLY_SUBMISSIONS) {
            acc["start_date"] = new Date(
              new Date(acc["start_date"]).getTime() + 1000 * 60 * 60 * 24 * 30
            ).toISOString();
            acc["end_date"] = new Date(
              new Date(acc["end_date"]).getTime() + 1000 * 60 * 60 * 24 * 30
            ).toISOString();
          } else if (dashboardId === DEFAULT_DASHBOARD.WEEKLY_SUBMISSIONS) {
            acc["start_date"] = new Date(
              new Date(acc["start_date"]).getTime() + 1000 * 60 * 60 * 24 * 7
            ).toISOString();
            acc["end_date"] = new Date(
              new Date(acc["end_date"]).getTime() + 1000 * 60 * 60 * 24 * 7
            ).toISOString();
          }
          break;
        }
        default: {
          acc[curr] = query[curr];
        }
      }
    }
    return acc;
  }, {});
};

export const getQuery = (filters: IFilter[], dashboard?: IDashboard) => {
  const matrixPivotUsed =
    dashboard &&
    dashboard.kpis.find(
      (kpi) =>
        kpi.type === KPI_TYPE.MATRIX &&
        ([PIVOT_TYPE.TEAM, PIVOT_TYPE.MOBILE_USER].includes(
          kpi.row_pivot_type as PIVOT_TYPE
        ) ||
          [PIVOT_TYPE.TEAM, PIVOT_TYPE.MOBILE_USER].includes(
            kpi.column_pivot_type as PIVOT_TYPE
          ))
    )
      ? true
      : false;
  return filters.reduce((acc: any, curr: IFilter) => {
    if (curr.value) {
      if (curr.type === FILTER_TYPE.MULTIPLE_CHOICE) {
        // if no option marked for a multiple choice filter, we consider that the filter is not active...
        if (curr.value.length !== 0) {
          acc[curr.tag] = curr.value;
        } else if (matrixPivotUsed) {
          // except if we are in a matrix with a pivot on users/teams. In that case, we need to set all the options as filter.
          acc[curr.tag] = curr.options?.map((o) => o.key);
        }
      } else {
        acc[curr.tag] = curr.value;
      }
    } else if (matrixPivotUsed) {
      // except if we are in a matrix with a pivot on users/teams. In that case, we need to set all the options as filter.
      acc[curr.tag] = curr.options?.map((o) => o.key);
    }
    return acc;
  }, {});
};

export const setDefaultMinDate = (numberOfGoBack: number): number => {
  let goBackDays = numberOfGoBack === undefined ? 180 : numberOfGoBack;
  return new Date().setDate(new Date().getDate() - goBackDays);
};
