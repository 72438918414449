import { DASHBOARD_TYPE } from "smala-tools/dist/src/types";
import {
  IDashboard,
  ISubQuery,
  DB_TYPE,
  IKPI,
  AXIS_TYPE,
  AGGREGATOR,
  DEFAULT_DASHBOARD,
} from "model/entities/Dashboard";
import { IDashboard as IBEDashboard } from "smala-tools/dist/src/types/dashboards";
import { clone } from "utils/utils";

const prepareDashboardForBackend = (
  originalDashboard: IDashboard
): IDashboard => {
  const dashboard = clone(originalDashboard);
  Object.keys(dashboard).forEach((att) => {
    if (
      !["date_filter", "team_filter", "user_filter"].includes(att) &&
      dashboard[att] === ""
    )
      delete dashboard[att];
  });
  if (dashboard.alias_mapping)
    dashboard.alias_mapping = dashboard.alias_mapping.map((e: any) => {
      delete e.index;
      return e;
    });
  if (
    ![DASHBOARD_TYPE.MOBILE_AND_WEB, DASHBOARD_TYPE.MOBILE].includes(
      dashboard.type
    )
  ) {
    delete dashboard.time_ranges;
  }
  if (dashboard.hasOwnProperty("locked")) {
    delete dashboard.locked;
  }
  dashboard.kpis = dashboard.kpis.map((kpi: { [x: string]: any }) => {
    delete kpi.id;
    delete kpi.key;
    delete kpi.data; // make sure there is no data in the kpi (happens sometimes when uploading a WIP json file)
    Object.keys(kpi).forEach((att) => {
      if (kpi[att] === "") delete kpi[att];
    });
    if (kpi.sub_queries) {
      kpi.sub_queries = kpi.sub_queries.map((subq: any) => {
        delete subq.index;
        Object.keys(subq).forEach((subqatt) => {
          if (subq[subqatt] === "") delete subq[subqatt];
        });
        return subq;
      });
      kpi.sub_queries.forEach((sq: ISubQuery) => delete sq.index);
    }

    if (kpi.values) {
      kpi.values = kpi.values.map((val: any) => {
        delete val.key;
        return val;
      });
    }
    return kpi;
  });
  delete dashboard.id;
  delete dashboard.client_id;
  delete dashboard.updated_at;
  delete dashboard.created_at;
  delete dashboard.query;
  return dashboard;
};

const prepareDashboardsForFrontend = (
  originalDashboards: IBEDashboard[]
): IDashboard[] => {
  let dashboards = clone(originalDashboards);
  // make sure all the default attributes are set
  dashboards = dashboards.map((d: any) => {
    d.query = {};
    if (!d.hasOwnProperty("db_type")) d.db_type = DB_TYPE.OPTIMETRIKS;
    if (!d.hasOwnProperty("host")) d.host = "";
    if (!d.hasOwnProperty("port")) d.port = "";
    if (!d.hasOwnProperty("db_name")) d.db_name = "";
    if (!d.hasOwnProperty("user")) d.user = "";
    if (!d.hasOwnProperty("password")) d.password = "";
    if (!d.hasOwnProperty("list_id")) d.list_id = "";
    d.alias_mapping =
      d.alias_mapping &&
      d.alias_mapping.map((value: any, i: number) => {
        return {
          ...value,
          index: i,
        };
      });
    d.kpis = d.kpis
      .sort(
        (a: any, b: any) => Number.parseInt(a.index) - Number.parseInt(b.index)
      )
      .map((k: any, idx: number) => ({ ...k, index: idx }))
      .map((k: IKPI) => {
        if (!k.hasOwnProperty("sub_queries")) k.sub_queries = [];
        if (!k.hasOwnProperty("row_query")) k.row_query = "";
        if (!k.hasOwnProperty("query")) k.query = "";
        if (!k.hasOwnProperty("aggregator")) k.aggregator = AGGREGATOR.SUM;
        if (!k.hasOwnProperty("x_axis_type")) k.x_axis_type = AXIS_TYPE.TEXT;
        if (!k.hasOwnProperty("is_filled")) k.is_filled = false;
        if (!k.hasOwnProperty("full_width")) k.full_width = false;
        if (!k.hasOwnProperty("unit")) k.unit = "";
        if (k.sub_queries) {
          k.sub_queries = k.sub_queries.map((subq: any, index: number) => ({
            ...subq,
            index,
          }));
        }
        return k;
      });
    if (d.id === "_log_report") {
      d.kpis[0].full_width = true;
    }
    return d;
  });
  return dashboards;
};

const cleanKpis = (
  dashboardId: string,
  kpis: Partial<IKPI>[]
): Partial<IKPI>[] => {
  let result = kpis;
  if (dashboardId === DEFAULT_DASHBOARD.GPS_TRACKING) {
    result[0].data = kpis[0].data.map((d: any) => {
      if (d.label === "places_visited") {
        d.data = d.data.filter((e: any) => e.lat && e.lng);
      }
      return d;
    });
  }
  return result;
};

export { cleanKpis, prepareDashboardForBackend, prepareDashboardsForFrontend };
