import {
  createTeamBeginAction,
  createTeamFailureAction,
  createTeamSuccessAction,
  fetchTeamsForClientBeginAction,
  fetchTeamsForClientFailureAction,
  fetchTeamsForClientSuccessAction,
  selectTeamActionCreator,
  createTeamsSuccessAction,
  createTeamsFailureAction,
  createTeamsBeginAction,
  updateTeamsFailureAction,
  updateTeamsSuccessAction,
  updateTeamsBeginAction,
  deleteTeamsBeginAction,
  deleteTeamsSuccessAction,
  deleteTeamsFailureAction,
  assignMobileUsersToTeamsFailureAction,
  assignMobileUsersToTeamsSuccessAction,
  assignMobileUsersToTeamsBeginAction,
  assignWebUsersToTeamsFailureAction,
  assignWebUsersToTeamsSuccessAction,
  assignWebUsersToTeamsBeginAction,
  restoreTeamsFailureAction,
  restoreTeamsSuccessAction,
  restoreTeamsBeginAction,
  archiveTeamsFailureAction,
  archiveTeamsSuccessAction,
  archiveTeamsBeginAction,
  updateHierarchyBeginAction,
  updateHierarchySuccessAction,
  updateHierarchyFailureAction,
  changeSubcategorySuccessActionCreator,
} from "./actionCreators";
import {
  createTeamApiCall,
  fetchTeamsForClientApiCall,
  createTeamsApiCall,
  updateTeamsApiCall,
  deleteTeamsApiCall,
  assignMobileUsersToTeamsApiCall,
  assignWebUsersToTeamsApiCall,
  restoreTeamsApiCall,
  archiveTeamsApiCall,
  updateHierarchyApiCall,
} from "./api";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../actionCreators/ajaxActionCreator";
import { createNotificationActionCreator } from "../../notifications/actionCreator";
import * as notifyTypes from "../../notifications/actionTypes";
import * as notifyLevels from "../../notifications/constants";
import { getSelectedClient } from "../../clients/redux/selectors";
import { teamsByIdSelector } from "./selectors";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../actions/appActions";
import { getLang } from "../../authentication/redux/selector";
import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import {
  updateClientSuccessActionCreator,
  selectClientActionCreator,
} from "containers/clients/redux/actionCreators";
import ITeam from "model/entities/Team";
import { IDispatchAndGetState } from "store/model";
import { Dispatch } from "redux";
import { IHierarchyDependency } from "model/entities/HierarchyDependency";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { getLowestLevelNb } from "../utils";

/**
 * Create Team Action dispatches action creators to redux store and makes api calls to create a team
 * @param {Object} team New team to create with its details
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function createTeamAction(
  team: ITeam,
  clientId?: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createTeamBeginAction());

    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    return createTeamApiCall(team, client_id)
      .then((serverResponse) => {
        const data =
          extractDataAndCheckErrorStatus<{ id: string }>(serverResponse);
        const { id } = data;
        team.id = id;

        dispatch(ajaxSuccessAction());
        dispatch(createTeamSuccessAction(client_id, team));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.TEAM,
              team.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateTeamError",
          error,
          createTeamFailureAction,
          currLang
        );
      });
  };
}

/**
 * Create Teams Action dispatches action creators to redux store and makes api calls
 * @param {Array} teams Name of the teams
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function createTeamsAction(
  newTeams: ITeam[]
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createTeamsBeginAction());

    const client = getSelectedClient(getState());
    const client_id = client.id;

    // add the iid to the teams
    newTeams = newTeams.map((t, iid) => ({
      ...t,
      iid: iid + "",
    }));

    return createTeamsApiCall(newTeams, client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<any>(serverResponse);
        const { teams } = data;

        newTeams = teams.map((t: any) => {
          const fullTeam: any = newTeams.filter(
            (tm: any) => tm.iid === t.iid
          )[0];
          delete fullTeam.iid;
          fullTeam.id = t.id;
          return fullTeam;
        });

        dispatch(ajaxSuccessAction());
        dispatch(createTeamsSuccessAction(client_id, newTeams));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.TEAM,
              newTeams.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateTeamsError",
          error,
          createTeamsFailureAction,
          currLang
        );
      });
  };
}

export function selectTeamAction(id: string): any {
  return (dispatch: Dispatch, getState: any) => {
    // get the team from Redux store if it exists
    const team = teamsByIdSelector(getState(), id);
    dispatch(selectTeamActionCreator(team));
  };
}

/**
 * Fetch all Teams Action dispatches action creators to redux store and makes api calls to fetch all teams
 * for a given client
 * @param {String} clientId Client id to fetch user teams for
 * @return {any} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function fetchTeamsForClientAction(
  clientId: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchTeamsForClientBeginAction());

    return fetchTeamsForClientApiCall(clientId)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<any>(serverResponse);
        const { teams } = data;
        dispatch(ajaxSuccessAction());
        dispatch(fetchTeamsForClientSuccessAction(clientId, teams));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchTeamsForClientError",
          error,
          fetchTeamsForClientFailureAction,
          currLang
        );
      });
  };
}

/**
 * Delete Teams Action dispatches action creators to redux store and makes api calls to delete teams
 * @param {Array} ids Team ids to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function deleteTeamsAction(ids: string[]): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteTeamsBeginAction());

    const client = getSelectedClient(getState());
    const clientId = client.id;

    return deleteTeamsApiCall(ids, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteTeamsSuccessAction(ids));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.TEAM,
              ids.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteTeamsError",
          error,
          deleteTeamsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Archive Teams Action dispatches action creators to redux store and makes api calls to archive teams
 * @param {Array} ids Team ids to archive
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function archiveTeamsAction(ids: string[]): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveTeamsBeginAction());

    const client = getSelectedClient(getState());
    const clientId = client.id;

    return archiveTeamsApiCall(ids, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveTeamsSuccessAction(ids));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.TEAM,
              ids.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveTeamsError",
          error,
          archiveTeamsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Restore Teams Action dispatches action creators to redux store and makes api calls to restore teams
 * @param {Array} ids Team ids to restore
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function restoreTeamsAction(ids: string[]): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreTeamsBeginAction());

    const client = getSelectedClient(getState());
    const clientId = client.id;

    return restoreTeamsApiCall(ids, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreTeamsSuccessAction(ids));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.TEAM
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreTeamsError",
          error,
          restoreTeamsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Update Teams Action dispatches action creators to redux store and makes api calls to delete a license by id
 * @param {Array} teams Array of teams to import
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function updateTeamsAction(teams: ITeam[]): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateTeamsBeginAction());

    const client = getSelectedClient(getState());
    const clientId = client.id;

    return updateTeamsApiCall(teams, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateTeamsSuccessAction(teams));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.TEAM,
              teams.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateTeamsError",
          error,
          updateTeamsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Assign Mobile users to teams Action dispatches action creators to redux store and makes api calls to link mobile users to teams
 * @param {Array} links Detail of the links to add between mobile users and teams
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function assignMobileUsersToTeamsAction(
  links: any
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    const client = getSelectedClient(getState());
    const client_id = client.id;

    dispatch(ajaxRequestAction());
    dispatch(assignMobileUsersToTeamsBeginAction());

    return assignMobileUsersToTeamsApiCall(client_id, links)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(assignMobileUsersToTeamsSuccessAction(links));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.teams.subCategories.teams.customNotifications
              .assignToTeams
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "AssignMobileUsersToTeamsError",
          error,
          assignMobileUsersToTeamsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Assign Web users to teams Action dispatches action creators to redux store and makes api calls to link mobile users to teams
 * @param {Array} links Detail of the links to add between web users and teams
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function assignWebUsersToTeamsAction(
  links: any
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    const client = getSelectedClient(getState());
    const client_id = client.id;

    dispatch(ajaxRequestAction());
    dispatch(assignWebUsersToTeamsBeginAction());

    return assignWebUsersToTeamsApiCall(client_id, links)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(assignWebUsersToTeamsSuccessAction(links));
        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.teams.subCategories.teams.customNotifications
              .assignWebUserToTeams
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "AssignWebUsersToTeamsError",
          error,
          assignWebUsersToTeamsFailureAction,
          currLang
        );
      });
  };
}

export function updateHierarchyAction(
  hierarchyDependencies: IHierarchyDependency[]
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    const client = getSelectedClient(getState());
    const client_id = client.id;
    const cleanedHierarchyDepencies = hierarchyDependencies.filter(
      (hd) =>
        hd.level_type_number <=
        getLowestLevelNb(client.meta_hierarchy_dependencies)
    );
    dispatch(ajaxRequestAction());
    dispatch(updateHierarchyBeginAction());

    return updateHierarchyApiCall(cleanedHierarchyDepencies, client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<any>(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(updateHierarchySuccessAction(data.teams));

        const clientModified = {
          ...client,
          hierarchy_dependencies: cleanedHierarchyDepencies,
        };

        // modify client and selectedClient
        dispatch(updateClientSuccessActionCreator(clientModified));
        dispatch(selectClientActionCreator(clientModified));

        dispatch(
          createNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.teams.subCategories.teams.customNotifications
              .editHierarchy
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "updateHierarchyError",
          error,
          updateHierarchyFailureAction,
          currLang
        );
      });
  };
}

/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "mobileuser" or "webuser"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function changeSubcategoryAction(subcategory: any): any {
  return (dispatch: Dispatch) => {
    return dispatch(changeSubcategorySuccessActionCreator(subcategory));
  };
}
