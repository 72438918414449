/**
 * @notes: Action creator for Authentication container component. These actions will be handled
 * These are pure functions that return the same expected output for the same number of inputs.
 * They simply return the action type and the object payload
 */
import * as types from "./actionTypes";
import { Action } from "redux";

interface IActionError extends Action {
  error: any;
}

/**
 * Login Request begin Action
 * This will trigger to the Redux Store that a login request action has begin. Can be used
 * To perform async checks
 * @returns {Object} Object Payload with the type of action to update the state of the Store
 * */
export function loginRequestBeginAction(): Action {
  return { type: types.LOGIN_REQUEST_BEGIN };
}

/**
 * Login Request failure action. This is dispatched to the Redux Store with the information
 * of the error raised during the Login process
 * @param {Object} error Error Object received from API
 * @returns {Object} Payload object with the type of action and the Payload information
 * */
export function loginRequestFailureAction(error: any): IActionError {
  return { type: types.LOGIN_REQUEST_FAILURE, error };
}

interface ILoginRequestSuccessActionError extends Action {
  token: string;
  id: string;
  created_at: string;
  userEmail: string;
  keepSignedIn: boolean;
}
/**
 * Login Request success action is dispatched to the redux store when we have successfully
 * logged in to the system. This will be used to update the state of the store with the payload
 * information received from the API
 * @param {String} token Token received from API
 * @param {String} userEmail User's email address on login
 * @param {Boolean} keepSignedIn Whether the user wants to retain session even when the browser/ window is closed
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function loginRequestSuccessAction(
  token: string,
  id: string,
  created_at: string,
  userEmail: string,
  keepSignedIn: boolean
): ILoginRequestSuccessActionError {
  return {
    type: types.LOGIN_REQUEST_SUCCESS,
    token,
    id,
    created_at,
    userEmail,
    keepSignedIn,
  };
}

/**
 * Log out request action. This returns an object with the type of action to dispatch to REDUX store
 *
 * @return {Object} Object with the type of action to dispatch to REDUX store.
 * */
export function logoutRequestBeginAction(): Action {
  return {
    type: types.LOGOUT_REQUEST_BEGIN,
  };
}

/**
 * Logout error action returns an object with the type of action and the error object from the failed logout request
 * @param {Object} error Error information object with details of unsuccessful logout
 * @return {Object} Object with type of action and error object
 * */
export function logoutErrorAction(error: any): IActionError {
  return {
    type: types.LOGOUT_REQUEST_FAILURE,
    error,
  };
}

/**
 * Logout success action which returns an object with the action type and payload from logout api call
 * This will enable the redux store to resolve the payload, update the auth object and logout the user
 * @return {Object} Object with type of action and the payload
 * */
export function logoutSuccessAction(): Action {
  return {
    type: types.LOGOUT_REQUEST_SUCCESS,
  };
}

/**
 * Recover password begin action. This returns an object with the type of action to dispatch to REDUX store
 * @return {Object} Object with the type of action to dispatch to REDUX store.
 * */
export function recoverPasswordBeginAction(): Action {
  return {
    type: types.RECOVER_PASSWORD_BEGIN,
  };
}

/**
 * Recover password error action returns an object with the type of action and the error object from the failed logout request
 * @param {Object} error Error information object with details of unsuccessful logout
 * @return {Object} Object with type of action and error object
 * */
export function recoverPasswordFailureAction(error: any): IActionError {
  return {
    type: types.RECOVER_PASSWORD_FAILURE,
    error,
  };
}

/**
 * Recover password success action which returns an object with the action type and payload from logout api call
 * This will enable the redux store to resolve the payload, update the auth object and logout the user
 * @return {Object} Object with type of action and the payload
 * */
export function recoverPasswordSuccessAction(): Action {
  return {
    type: types.RECOVER_PASSWORD_SUCCESS,
  };
}

/**
 * Change password begin action. This returns an object with the type of action to dispatch to REDUX store
 * @return {Object} Object with the type of action to dispatch to REDUX store.
 * */
export function changePasswordBeginAction(): Action {
  return {
    type: types.CHANGE_PASSWORD_BEGIN,
  };
}

/**
 * Change password error action returns an object with the type of action and the error object from the failed edit password request
 * @param {Object} error Error information object with details
 * @return {Object} Object with type of action and error object
 * */
export function changePasswordFailureAction(error: any): IActionError {
  return {
    type: types.CHANGE_PASSWORD_FAILURE,
    error,
  };
}

/**
 * Edit profile success action which returns an object with the action type and payload from logout api call
 * This will enable the redux store to resolve the payload, update the auth object and logout the user
 * @return {Object} Object with type of action and the payload
 * */
export function changePasswordSuccessAction(): Action {
  return {
    type: types.CHANGE_PASSWORD_SUCCESS,
  };
}

interface IChangeLanguageSuccessAction extends Action {
  lang: string;
}

export function changeLanguageSuccessAction(
  lang: string
): IChangeLanguageSuccessAction {
  return {
    type: types.CHANGE_LANG_SUCCES,
    lang,
  };
}

interface ISetActionsAvailableSuccessAction extends Action {
  availableActions: any;
}
/**
 * @return {Object} Object with type of action and the payload
 * */
export function setActionsAvailableSuccessAction(
  availableActions: any
): ISetActionsAvailableSuccessAction {
  return {
    type: types.SET_ACTIONS_AVAILABLE_SUCCESS,
    availableActions,
  };
}

interface IUpdateSmalaTokenAction extends Action {
  smalaToken: any;
}
/**
 * Updates the Smala Token received from the API
 * @param {String} smalaToken Token received from Smala API
 * @returns {Object} Object with Type of action and the smala token
 * */
export function updateSmalaTokenAction(
  smalaToken: any
): IUpdateSmalaTokenAction {
  return {
    type: types.UPDATE_SMALA_TOKEN,
    smalaToken,
  };
}

interface ICheckLastVersionActionSuccess extends Action {}
/**
 * Updates the timestamp of the last version api call
 * @returns {Object} Object
 * */
export function checkLastVersionActionSuccess(): ICheckLastVersionActionSuccess {
  return {
    type: types.GET_LAST_VERSION_SUCCESS,
  };
}
