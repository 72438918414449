import { QUESTION_TYPE, COLUMN_TYPE } from "model/entities/Workflow";
import { LIST_ATTRIBUTE_TYPE } from "model/entities/List";

export const questionTypeToColumnType = (
  questionType: QUESTION_TYPE | undefined
): COLUMN_TYPE => {
  switch (questionType) {
    case QUESTION_TYPE.GPS:
      return COLUMN_TYPE.STRING; // should not be important... To check
    case QUESTION_TYPE.DECIMAL:
    case QUESTION_TYPE.COMPUTE:
    case QUESTION_TYPE.MULTIPLE_INPUT_DECIMAL:
      return COLUMN_TYPE.FLOAT;
    case QUESTION_TYPE.INTEGER:
    case QUESTION_TYPE.MULTIPLE_INPUT_INTEGER:
    case QUESTION_TYPE.RATING:
      return COLUMN_TYPE.INTEGER;
    case QUESTION_TYPE.SINGLE_CHOICE:
    case QUESTION_TYPE.MULTIPLE_CHOICE:
    case QUESTION_TYPE.MULTIPLE_CHOICE_WITH_NONE:
      return COLUMN_TYPE.STRING;
    default:
      return COLUMN_TYPE.STRING;
  }
};

export const questionTypeToColumnTypeForConditionAndOperation = (
  questionType: QUESTION_TYPE | undefined
): COLUMN_TYPE => {
  switch (questionType) {
    case QUESTION_TYPE.GPS:
      return COLUMN_TYPE.STRING;
    case QUESTION_TYPE.DECIMAL:
    case QUESTION_TYPE.COMPUTE:
    case QUESTION_TYPE.MULTIPLE_INPUT_DECIMAL:
      return COLUMN_TYPE.FLOAT;
    case QUESTION_TYPE.INTEGER:
    case QUESTION_TYPE.MULTIPLE_INPUT_INTEGER:
      return COLUMN_TYPE.INTEGER;
    case QUESTION_TYPE.SINGLE_CHOICE:
    case QUESTION_TYPE.MULTIPLE_CHOICE:
    case QUESTION_TYPE.MULTIPLE_CHOICE_WITH_NONE:
      return COLUMN_TYPE.BOOLEAN;
    default:
      return COLUMN_TYPE.STRING;
  }
};

export const attributeTypeToQuestionType = (
  attributeType: LIST_ATTRIBUTE_TYPE
): QUESTION_TYPE | undefined => {
  switch (attributeType) {
    case LIST_ATTRIBUTE_TYPE.BOOLEAN:
      return undefined;
    case LIST_ATTRIBUTE_TYPE.TEXT:
      return QUESTION_TYPE.TEXT;
    case LIST_ATTRIBUTE_TYPE.DECIMAL:
      return QUESTION_TYPE.DECIMAL;
    case LIST_ATTRIBUTE_TYPE.INTEGER:
      return QUESTION_TYPE.INTEGER;
    case LIST_ATTRIBUTE_TYPE.SINGLE_CHOICE:
      return QUESTION_TYPE.SINGLE_CHOICE;
    case LIST_ATTRIBUTE_TYPE.MULTIPLE_CHOICE:
      return QUESTION_TYPE.MULTIPLE_CHOICE;
    case LIST_ATTRIBUTE_TYPE.PICTURE:
      return QUESTION_TYPE.PICTURE;
    case LIST_ATTRIBUTE_TYPE.GPS:
      return QUESTION_TYPE.GPS;
    case LIST_ATTRIBUTE_TYPE.SIGNATURE:
      return QUESTION_TYPE.SIGNATURE;
    case LIST_ATTRIBUTE_TYPE.DATE:
      return QUESTION_TYPE.DATE_PICKER;
    case LIST_ATTRIBUTE_TYPE.PHONE_NUMBER:
      return QUESTION_TYPE.PHONE_NUMBER;
    default:
      return QUESTION_TYPE.TEXT;
  }
};

export const attributeTypeToColumnType = (
  attributeType: LIST_ATTRIBUTE_TYPE
): COLUMN_TYPE | undefined => {
  return questionTypeToColumnType(attributeTypeToQuestionType(attributeType));
};

export const attributeTypeToColumnTypeForConditionAndOperation = (
  attributeType: LIST_ATTRIBUTE_TYPE
): COLUMN_TYPE | undefined => {
  return questionTypeToColumnTypeForConditionAndOperation(
    attributeTypeToQuestionType(attributeType)
  );
};
