import {
  fetchListsForClientBeginAction,
  fetchListsForClientSuccessAction,
  fetchListsForClientFailureAction,
  createListBeginAction,
  createListSuccessAction,
  createListFailureAction,
  updateListBeginAction,
  updateListSuccessAction,
  updateListFailureAction,
  deleteListBeginAction,
  deleteListSuccessAction,
  deleteListFailureAction,
  fetchItemsForListBeginAction,
  fetchItemsForListSuccessAction,
  fetchItemsForListFailureAction,
  createItemsBeginAction,
  createItemsSuccessAction,
  createItemsFailureAction,
  updateItemsBeginAction,
  selectListActionCreator,
  updateItemsSuccessAction,
  updateItemsFailureAction,
  restoreListBeginAction,
  restoreListSuccessAction,
  restoreListFailureAction,
  archiveListBeginAction,
  archiveListSuccessAction,
  archiveListFailureAction,
  deleteItemsSuccessAction,
  deleteItemsBeginAction,
  deleteItemsFailureAction,
  archiveItemsBeginAction,
  archiveItemsSuccessAction,
  archiveItemsFailureAction,
  restoreItemsBeginAction,
  restoreItemsSuccessAction,
  restoreItemsFailureAction,
  uploadFileBeginAction,
  uploadFileSuccessAction,
  uploadFileFailureAction,
  assignItemsBeginAction,
  assignItemsSuccessAction,
  assignItemsFailureAction,
  unassignItemsBeginAction,
  unassignItemsSuccessAction,
  unassignItemsFailureAction,
  changeSubcategorySuccessActionCreator,
  downloadListItemsBeginAction,
  downloadListItemsSuccessAction,
  downloadListItemsFailureAction,
  fetchListItemsActivityBeginAction,
  fetchListItemsActivitySuccessAction,
  fetchListItemsActivityFailureAction,
  fetchSubmissionBeginActionCreator,
  fetchSubmissionSuccessActionCreator,
  fetchSubmissionFailureActionCreator,
} from "./actionCreators";
import { createNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationTypes from "../../notifications/actionTypes";
import * as notificationLevels from "../../notifications/constants";
import { getSelectedClient } from "../../clients/redux/selectors";
import { getListById } from "./selectors";
import {
  fetchListsByClientApiCall,
  createListApiCall,
  updateListApiCall,
  deleteListApiCall,
  fetchItemsForListApiCall,
  createItemsApiCall,
  updateItemsApiCall,
  restoreListApiCall,
  archiveListApiCall,
  deleteItemsApiCall,
  archiveItemsApiCall,
  restoreItemsApiCall,
  uploadFileApiCall,
  assignItemsApiCall,
  unassignItemsApiCall,
  downloadListItemsApiCall,
  TFetchListsByClientResponse,
  fetchListItemActivitiesApiCall,
  fetchSubmissionDetailApiCall,
} from "./api";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  IServerResponse,
  treatErrorNotification,
} from "../../../actions/appActions";
import { getLang } from "../../authentication/redux/selector";
import * as lang from "../../../lang";
import {
  formatString,
  getSuccessNotificationMessage,
} from "../../../lang/utils";
import { appAddNotification } from "actionCreators/appActionCreator";
import {
  LevelNotification,
  TypeNotification,
} from "model/application/Notification";
import { transposeLocalTimeToUTCTime } from "utils/utils";
import { IDispatchAndGetState } from "store/model";
import { IList, IListSchema, LIST_ATTRIBUTE_TYPE } from "model/entities/List";
import { Dispatch } from "redux";
import { IListItem } from "model/entities/ListItem";
import { prepareListsForFrontend } from "./utils";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { getBackendQuery } from "components/Filter/Filter.utils";

const MAX_FETCH_NUMBER = 1000;
export type IFetchSubmissionDetailActionFunc = (
  workflowId: string,
  submissionId: string
) => IDispatchAndGetState<any>;
/**
 * Fetch Lists per client Action dispatches action creators to redux store and makes api calls to fetch all lists of the client
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function fetchListsForClientAction(
  clientId: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchListsForClientBeginAction());

    return fetchListsByClientApiCall(clientId)
      .then((serverResponse) => {
        const data =
          extractDataAndCheckErrorStatus<TFetchListsByClientResponse>(
            serverResponse
          );
        const { lists } = data;
        dispatch(ajaxSuccessAction());
        const formattedLists = prepareListsForFrontend(lists);
        dispatch(fetchListsForClientSuccessAction(clientId, formattedLists));

        formattedLists.forEach((list) => {
          if (list.big_list) {
            // add notification
            dispatch(
              appAddNotification({
                level: LevelNotification.Warning,
                type: TypeNotification.PartialFetchListItems,
                id: list.id,
                label: `The list ${list.name} is too large to be downloaded entirely. ${MAX_FETCH_NUMBER} items can be fetched at the same time.`,
              })
            );
          }
        });
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchListsForClientError",
          error,
          fetchListsForClientFailureAction,
          currLang
        );
      });
  };
}
/**
 * Create List Action dispatches action creators to redux store and makes api calls to create a list
 * @param {String} clientId Client id
 * @param {String} name Name of the List
 * @param {String} description Description of the List
 * @param {String} scope Scope of the created list
 * @param {String} accessRight Access right of the created list
 * @param {String} description Description for the created list
 * @param {Array} schema An array of the attributes of the list with their type, default is an empty array
 * @param {Array} newItems Array of items to insert to the list
 * @param {String} title_in_card String template for building the title of the card
 * @param {String} picture_in_card Attribute tag of the picture attribute to display in card
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function createListAction(
  list: IList,
  clientId = null
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createListBeginAction());

    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    interface IDataList {
      list_id: string;
    }

    return createListApiCall(client_id, list)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<IDataList>(serverResponse);
        const { list_id } = data;

        // build the list object
        const updatedList = {
          ...list,
          id: list_id,
          client_id: client_id,
          actif: true,
        };

        dispatch(ajaxSuccessAction());
        dispatch(createListSuccessAction(client_id, updatedList));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.LIST,
              list.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateListError",
          error,
          createListFailureAction,
          currLang
        );
      });
  };
}

/**
 * Update List Action dispatches action creators to redux store and makes api calls to delete a list by id
 * @param {Object} newList New properties of the list
 * @param {String} clientId Client id
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function updateListAction(
  newList: IList,
  clientId = null
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(updateListBeginAction());

    // get the list to update
    const list = getListById(getState())[newList.id] || {};

    return updateListApiCall(client_id, newList.id, newList)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        const updatedList = {
          ...list,
          ...newList,
        };
        dispatch(ajaxSuccessAction());
        dispatch(updateListSuccessAction(updatedList));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.LIST,
              updatedList.name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateListError",
          error,
          updateListFailureAction,
          currLang
        );
      });
  };
}

/**
 * Delete List Action dispatches action creators to redux store and makes api calls to delete a list by id
 * @param {String} listId List id to delete
 * @param {String} clientId Client id
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function deleteListAction(
  listId: string,
  clientId = null
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(deleteListBeginAction());

    return deleteListApiCall(client_id, listId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteListSuccessAction(listId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.LIST
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteListError",
          error,
          deleteListFailureAction,
          currLang
        );
      });
  };
}

/**
 * Archive List Action dispatches action creators to redux store and makes api calls to archive a list by id
 * @param {String} listId List id to archive
 * @param {String} clientId Client id
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function archiveListAction(
  listId: string,
  clientId = null
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(archiveListBeginAction());

    return archiveListApiCall(client_id, listId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveListSuccessAction(listId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.LIST
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveListError",
          error,
          archiveListFailureAction,
          currLang
        );
      });
  };
}

/**
 * Restore List Action dispatches action creators to redux store and makes api calls to restore a list by id
 * @param {String} listId List id to restore
 * @param {String} clientId Client id
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function restoreListAction(
  listId: string,
  clientId = null
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(restoreListBeginAction());

    return restoreListApiCall(client_id, listId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreListSuccessAction(listId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.LIST
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreListError",
          error,
          restoreListFailureAction,
          currLang
        );
      });
  };
}

/**
 * Fetch all items for a given list
 * @param {String} listId List Id to fetch items for a given list
 * @param {Object} params Parameters to join to the query
 * @param {Boolean} promise If you want the action to return a Promise (for async call) or nothing
 * @return {Function} Function with dispatch and getState to dispatch actions to reducers and getState with the
 * state of current store
 */
export function fetchItemsForListAction(
  listId: string,
  params: {
    client_id?: string;
    offset?: number;
    query?: any;
    eraseExisting?: boolean;
    filter_duplicates?: string;
    detail?: boolean;
    origin_map?: boolean;
  } = {},
  promise?: boolean
): any {
  return (dispatch: Dispatch, getState: any) => {
    let clientId = params.client_id;
    let offset = params.offset;
    let eraseExisting = params.eraseExisting;
    const currLang = lang[getLang(getState())];
    let rawQuery = params.query ? params.query : {};
    const list = getListById(getState())[listId];
    // merge _location filter with the other one
    if (rawQuery._location) {
      // case of the _location filter active => we add it to the others
      rawQuery = {
        ...list.query,
        _location: rawQuery._location,
      };
    } else {
      // classical "filter" => we add the _location filter if it exists
      if (list.query && list.query._location) {
        rawQuery = {
          ...rawQuery,
          _location: list.query._location,
        };
      }
    }

    const client = getSelectedClient(getState());
    clientId = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(fetchItemsForListBeginAction(params.detail));

    const processBackendResponse = (
      backendResponse: IServerResponse<{
        schema: { type: string; column_name: string }[];
        items: any[];
      }>
    ) => {
      interface IDataList {
        items: IListItem[];
      }
      const data = extractDataAndCheckErrorStatus<IDataList>(backendResponse);
      const { items } = data;
      dispatch(ajaxSuccessAction());
      dispatch(
        fetchItemsForListSuccessAction(
          listId,
          items,
          offset ? offset : 0,
          eraseExisting ? true : false,
          list.schema,
          params.detail ? undefined : rawQuery // if fetch item detail, we don't reset the filters
        )
      );
      if (params.filter_duplicates) {
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            formatString(
              currLang.notifications.successNotifications.filterDuplicates,
              [list.items.length]
            )
          )
        );
      }
      return data;
    };

    const fetchItemsForListApiCallPremade = () => {
      return fetchItemsForListApiCall(
        clientId as string,
        listId,
        offset ? offset : 0,
        params.query,
        params.filter_duplicates || undefined,
        params.detail || undefined,
        params.origin_map || undefined
      );
    };

    // check if the backend call is really needed
    let backendQuery = getBackendQuery(
      rawQuery,
      client.meta_hierarchy_dependencies
    );
    // first convert the multiple choice filters to the good format
    backendQuery = Object.keys(backendQuery).reduce((acc, curr) => {
      const att = list.schema.find((a) => a.column_tag === curr);
      if (
        ["_updated_at", "_created_at", "_last_visit_date"].includes(curr) ||
        (att && att.type === LIST_ATTRIBUTE_TYPE.DATE)
      ) {
        // expected format: { datetag: {start:"2020-...", end:"2020-..."} }
        acc[curr] = {
          start: backendQuery[curr].startDate,
          end: backendQuery[curr].endDate,
        };
      } else acc[curr] = backendQuery[curr];
      return acc;
    }, {});
    params.query = JSON.stringify(backendQuery);

    if (Object.keys(params).length === 0 && !list.partial_data) {
      // no need for calling the backend. Call directly the reducer
      fetchItemsForListSuccessAction(
        listId,
        list.items,
        offset || 0,
        eraseExisting ? true : false,
        list.schema,
        params.detail ? undefined : rawQuery // if fetch item detail, we don't reset the filters
      );
      if (params.filter_duplicates) {
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            formatString(
              currLang.notifications.successNotifications.filterDuplicates,
              [list.items.length]
            )
          )
        );
      }
      return;
    } else {
      if (promise) {
        return new Promise((resolve, reject) => {
          fetchItemsForListApiCall(
            clientId as string,
            listId,
            offset || 0,
            params.query || "{}",
            params.filter_duplicates || undefined,
            params.detail || undefined,
            params.origin_map || undefined
          )
            .then((serverResponse) => {
              const data = processBackendResponse(serverResponse);
              resolve(data);
            })
            .catch((error) => {
              treatErrorNotification(
                dispatch,
                "FetchItemsForListError",
                error,
                fetchItemsForListFailureAction,
                currLang
              );
              reject(error);
            });
        });
      } else {
        return fetchItemsForListApiCallPremade()
          .then((serverResponse) => {
            processBackendResponse(serverResponse);
          })
          .catch((error) => {
            treatErrorNotification(
              dispatch,
              "FetchItemsForListError",
              error,
              fetchItemsForListFailureAction,
              currLang
            );
          });
      }
    }
  };
}

export function downloadListItemsAction(listId: string, query?: any): any {
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];

    const clientId = getSelectedClient(getState()).id as string;

    dispatch(ajaxRequestAction());
    dispatch(downloadListItemsBeginAction());

    return downloadListItemsApiCall(clientId as string, listId, query)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(downloadListItemsSuccessAction());
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.notifications.successNotifications.downloadList
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "downloadListItemsError",
          error,
          downloadListItemsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Create Item Action dispatches action creators to redux store and makes api calls to create an item
 * @param {String} clientId Client id
 * @param {String} listId List id
 * @param {Array} items An array of the attributes of the list with their values
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function createItemsAction(
  listId: string,
  items: IListItem[],
  listSchema: IListSchema[],
  clientId?: string
): any {
  items = items.map((item) => {
    if (item._owners && item._owners[0] === "") {
      delete item._owners;
    }
    return {
      ...item,
      _bi_timestamp: transposeLocalTimeToUTCTime(new Date()).toISOString(),
    };
  });
  return (dispatch: Dispatch, getState: any) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(createItemsBeginAction());

    return createItemsApiCall(client_id, listId, items, listSchema)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(createItemsSuccessAction(listId, items, listSchema));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.ITEM,
              items.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateItemError",
          error,
          createItemsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Upload image
 * @param {Array} files file objects to upload
 * @param {Array} fileMetas array of meta info to give to the backend for each files
 * @returns {Promise}
 */
export function uploadFileAction(
  files: any,
  fileMetas: any
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginAction());

    return new Promise(function (resolve, reject) {
      uploadFileApiCall(files, fileMetas)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessAction());
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.ITEM
              )
            )
          );
          resolve(data as any);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureAction,
            currLang
          );
          reject("Something went wrong");
        });
    });
  };
}

/**
 * Delete Items Action dispatches action creators to redux store and makes api calls to delete items by id
 * @param {String} listId List id where to delete the items
 * @param {String} clientId Client id where to delete the items
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function deleteItemsAction(
  itemIds: string[],
  listId: string,
  clientId?: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(deleteItemsBeginAction());

    return deleteItemsApiCall(client_id, listId, itemIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteItemsSuccessAction(listId, itemIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.ITEM,
              itemIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteItemsError",
          error,
          deleteItemsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Archive Items Action dispatches action creators to redux store and makes api calls to archive items by ids
 * @param {String} itemIds Item ids to archive
 * @param {String} listId List id where to archive the items
 * @param {String} clientId Client id where to archive the items
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function archiveItemsAction(
  itemIds: string[],
  listId: string,
  clientId?: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(archiveItemsBeginAction());

    return archiveItemsApiCall(client_id, listId, itemIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveItemsSuccessAction(listId, itemIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.ITEM,
              itemIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveItemsError",
          error,
          archiveItemsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Restore Items Action dispatches action creators to redux store and makes api calls to restore items by ids
 * @param {String} itemIds Item ids to restore
 * @param {String} listId List id where to restore the items
 * @param {String} clientId Client id where to restore the items
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function restoreItemsAction(
  itemIds: string[],
  listId: string,
  clientId?: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = clientId || client.id;

    dispatch(ajaxRequestAction());
    dispatch(restoreItemsBeginAction());

    return restoreItemsApiCall(client_id, listId, itemIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreItemsSuccessAction(listId, itemIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.ITEM,
              itemIds.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreItemsError",
          error,
          restoreItemsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Edit Item Action dispatches action creators to redux store and makes api calls to edit an item by id
 * @param {Array} items Items to edit (with their id and all the new properties to change)
 * @param {String} listId List id
 * @param {String} clientId Client id
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function updateItemsAction(
  items: any,
  listId: string,
  listSchema: IListSchema[],
  clientId?: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this item from the list
    const client = getSelectedClient(getState());
    const client_id = clientId ? clientId : client.id;

    items = items.map((i: any) => {
      const cleanedItem = {};
      for (const attr in i) {
        if (
          attr !== "_client_id" &&
          attr !== "_updated_at" &&
          attr !== "_list_id" &&
          attr !== "_backend_version" &&
          attr !== "_actif" &&
          attr !== "_created_at" &&
          attr !== "_created_by" &&
          attr !== "_created_source" &&
          attr !== "_updated_by" &&
          attr !== "_updated_source"
        ) {
          cleanedItem[attr] = i[attr];
        }
      }
      return cleanedItem;
    });

    dispatch(ajaxRequestAction());
    dispatch(updateItemsBeginAction());

    return updateItemsApiCall(client_id, items, listId, listSchema)
      .then((serverResponse) => {
        const data: any = extractDataAndCheckErrorStatus(serverResponse);
        const new_items = (items as IListItem[]).map((i) => ({
          ...i,
          _updated_at: data.filter((it: any) => it._id === i._id).updated_at,
        }));
        new_items.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (item[key] === "__CLEAR") {
              item[key] = "";
            } else if (typeof item[key] === "object") {
              Object.keys(item[key]).forEach((k) => {
                if (item[key][k] === "__CLEAR") {
                  item[key][k] = null;
                }
              });
            }
          });
        });
        dispatch(ajaxSuccessAction());
        dispatch(updateItemsSuccessAction(new_items, listId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.ITEM,
              items.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "EditItemError",
          error,
          updateItemsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Assign Items Action dispatches action creators to redux store and makes api calls to restore items by ids
 * @param {Array} links Detail of the links to add between items and teams
 * @param {String} listId List id concerned
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function assignItemsAction(
  links: any,
  listId: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = client.id;

    dispatch(ajaxRequestAction());
    dispatch(assignItemsBeginAction());

    return assignItemsApiCall(client_id, listId, links)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(assignItemsSuccessAction(links));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.lists.subCategories.items.customNotifications
              .assignItem
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "AssignItemsError",
          error,
          assignItemsFailureAction,
          currLang
        );
      });
  };
}

/**
 * Unassign Items Action dispatches action creators to redux store and makes api calls to restore items by ids
 * @param {Array} links Detail of the links to add between items and teams
 * @param {String} listId List id concerned
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function unassignItemsAction(
  links: any,
  listId: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    // get the currently selected client from the redux store
    // this will be used to update the store, removing this list from the client
    const client = getSelectedClient(getState());
    const client_id = client.id;

    dispatch(ajaxRequestAction());
    dispatch(unassignItemsBeginAction());

    return unassignItemsApiCall(client_id, listId, links)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(unassignItemsSuccessAction(links));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            currLang.containers.lists.subCategories.items.customNotifications
              .unassignItem
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UnassignItemsError",
          error,
          unassignItemsFailureAction,
          currLang
        );
      });
  };
}

export function selectListAction(id: string): any {
  return (dispatch: Dispatch, getState: any) => {
    // get the list from Redux store if it exists
    const list = getListById(getState())[id];
    dispatch(selectListActionCreator(list));
  };
}

/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "mobileuser" or "webuser"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function changeSubcategoryAction(subcategory: string): any {
  return (dispatch: Dispatch, getState: any) => {
    if (subcategory === "index") {
      dispatch(changeSubcategorySuccessActionCreator(subcategory));
    } else {
      // fetch the group from redux store if it exists
      const list = getListById(getState())[subcategory];
      dispatch(selectListActionCreator(list));
      dispatch(changeSubcategorySuccessActionCreator(subcategory));
    }
  };
}
/**
 * Action to fetch submissions by placeId
 * @param {string} listItemId id of item whose we want to seach all related submissions
 * @returns {Function}
 */
export function fetchListItemActivities(query: any): IDispatchAndGetState<any> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const clientId = client.id;
    dispatch(ajaxRequestAction());
    dispatch(fetchListItemsActivityBeginAction());
    return fetchListItemActivitiesApiCall(clientId, query)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(fetchListItemsActivitySuccessAction("place", query));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.FETCH,
              SUB_CATEGORIES.SUBMISSION,
              data.submissions.length,
              true
            )
          )
        );
        return data.submissions;
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchSubmissionError",
          error,
          fetchListItemsActivityFailureAction,
          currLang
        );
      });
  };
}
/**
 * Action to fetch submissions
 * @param {String} workflowId Id of the workflow where we want the submissions
 * @param {String} submissionId Id of the submission where we want to fetch
 * @returns {Function}
 */
export const fetchSubmissionDetailAction: IFetchSubmissionDetailActionFunc = (
  workflowId,
  submissionId
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    const client = getSelectedClient(getState());
    const clientId = client.id;

    dispatch(ajaxRequestAction());
    dispatch(fetchSubmissionBeginActionCreator());

    return new Promise((res, rej) => {
      fetchSubmissionDetailApiCall(workflowId, submissionId, clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(fetchSubmissionSuccessActionCreator(data.submission));
          dispatch(
            createNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.FETCH,
                SUB_CATEGORIES.SUBMISSION,
                data.submission._id,
                true
              )
            )
          );
          res(data.submission);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchSubmissionError",
            error,
            fetchSubmissionFailureActionCreator,
            currLang
          );
          rej(error);
        });
    });
  };
};
