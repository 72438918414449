import { IStreamState } from "./reducer";

const initialState: IStreamState = {
  subCategorySelected: "index",
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isComputing: false,
  selectedStream: null,
  streams: [],
  errors: [],
};

export default initialState;
