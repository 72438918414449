import * as types from "./actionTypes";
import { IAction, IActionError, IBeginAction } from "store/model";
import { IEmailGeneration } from "model/entities/Dashboard";
import { IEmailGeneration as IBEEmailGeneration } from "smala-tools/dist/src/types";

export interface ICreateEmailGenerationSuccessAction extends IAction {
  emailGeneration: IEmailGeneration;
}
export interface IUpdateEmailGenerationSuccessAction extends IAction {
  emailGeneration: IEmailGeneration;
}
export interface IDeleteEmailGenerationSuccessAction extends IAction {
  id: string;
}
export interface IArchiveEmailGenerationsSuccessAction extends IAction {
  ids: string[];
}
export interface IRestoreEmailGenerationsSuccessAction extends IAction {
  ids: string[];
}
export interface IFetchEmailGenerationsSuccessAction extends IAction {
  emailGenerations: IBEEmailGeneration[];
}

/**
 * Create emailGeneration begin action creator
 * This creates an action informing redux store that the creation process for an emailGeneration has just began
 * @returns {Object}
 */
export function createEmailGenerationBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_EMAILGENERATION_BEGIN,
  };
}

/**
 * Failure Action for creating aN emailGeneration instructing that the creation process for aN emailGeneration has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function createEmailGenerationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.CREATE_EMAILGENERATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating an emailGeneration
 * @param {Object} emailGeneration EmailGeneration object,
 * @returns {Object}
 */
export const createEmailGenerationSuccessActionCreator = (
  emailGeneration: IEmailGeneration
): ICreateEmailGenerationSuccessAction => {
  return {
    type: types.CREATE_EMAILGENERATION_SUCCESS,
    emailGeneration,
  };
};

/**
 * Edit EmailGeneration begin action creator
 * This creates an action informing redux store that the creation process for an emailGeneration has just began
 * @returns {Object}
 */
export function updateEmailGenerationBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_EMAILGENERATION_BEGIN,
  };
}

/**
 * Failure Action for editing a email generation instructing that the edition process for a email generation has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function updateEmailGenerationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.UPDATE_EMAILGENERATION_FAILURE,
    error,
  };
}

/**
 * Success action for editing an emailGeneration
 * @param {Object} emailGeneration EmailGeneration object,
 * @returns {Object}
 */
export function updateEmailGenerationSuccessActionCreator(
  emailGeneration: IEmailGeneration
): IUpdateEmailGenerationSuccessAction {
  return {
    type: types.UPDATE_EMAILGENERATION_SUCCESS,
    emailGeneration,
  };
}

/**
 * Delete emailGeneration begin action creator
 * This creates an action informing redux store that the creation process for an emailGeneration has just began
 * @returns {Object}
 */
export function deleteEmailGenerationBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_EMAILGENERATION_BEGIN,
  };
}

/**
 * Failure Action for creating an emailGeneration instructing that the creation process for an emailGeneration has failed
 * @param {Object} error Error Object
 * @returns {Object}
 */
export function deleteEmailGenerationFailureActionCreator(
  error: any
): IActionError {
  return {
    type: types.DELETE_EMAILGENERATION_FAILURE,
    error,
  };
}

/**
 * Success action for creating an emailGeneration
 * @param {Object} emailGenerationId EmailGeneration object,
 * @returns {Object}
 */
export function deleteEmailGenerationSuccessActionCreator(
  emailGenerationId: string
): IDeleteEmailGenerationSuccessAction {
  return {
    type: types.DELETE_EMAILGENERATION_SUCCESS,
    id: emailGenerationId,
  };
}

/**
 * begin action when there is a request to fetch all emailGenerations
 * @returns {Object}
 * */
export const fetchAllEmailGenerationsBeginActionCreator = (): IBeginAction => ({
  type: types.FETCH_ALL_EMAILGENERATIONS_BEGIN,
});

/**
 * Failure action for fetching all emailGenerations. This action is dispatched when there is an error fetching all
 * emailGenerations
 * @param {Object} error
 * @returns {Object}
 * */
export const fetchAllEmailGenerationsFailureActionCreator = (
  error: any
): IActionError => ({
  type: types.FETCH_ALL_EMAILGENERATIONS_FAILURE,
  error,
});

/**
 * Fetch all emailGenerations action creator
 * @param {Array} emailGenerations an array of emailGeneration objects
 * @returns {Object}
 * */
export const fetchAllEmailGenerationsSuccessActionCreator = (
  emailGenerations: IBEEmailGeneration[]
): IFetchEmailGenerationsSuccessAction => ({
  type: types.FETCH_ALL_EMAILGENERATIONS_SUCCESS,
  emailGenerations,
});
