import { IEnvironmentState } from "./reducer";
import emailGenerationInitState from "./emailGenerations/initialState";
import transformationsInitState from "./transformations/initialState";
import streamsInitState from "./streams/initialState";
import dbSchemaInitState from "./dbSchema/initialState";
import tableOptimizationState from "./tableOptimizations/initialState";

const initialState: IEnvironmentState = {
  subCategorySelected: "index",
  emailGenerations: emailGenerationInitState,
  transformations: transformationsInitState,
  dbSchema: dbSchemaInitState,
  streams: streamsInitState,
  tableOptimizations: tableOptimizationState,
};

export default initialState;
