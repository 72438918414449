/**
 * @notes: State of the authentication container
 */

import { IAuthenticationState } from "containers/authentication/redux/reducer";

const initialState: IAuthenticationState = {
  loggingIn: false,
  loggingOut: false,
  smalaToken: null,
  isPasswordRecovering: false,
  user: {
    id: "",
    isAuthenticated: false,
    email: "",
    keepSignedIn: true,
  },
  loginError: false,
  errorMessage: "",
  errors: [],
  availableActions: {},
  lastCallToCheckVersion: new Date(0),
  lang: "en",
};

export default initialState;
