import React from "react";
import { render } from "react-dom";
//import registerServiceWorker from "./registerServiceWorker";
import configureStore from "./store/configureStore";
import Root from "./Root";
import Loader from "./components/Progress/CustomCircularProgress";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { CookiesProvider } from "react-cookie";
import { history } from "./store/historyConfig";
import "./assets/styles/css/bootstrap.css";

const { store, persistor } = configureStore();
// assign the target node or Create a div element for testing purpose
const domTarget =
  document.getElementById("root") || document.createElement("div");

render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <Root />
        </CookiesProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  domTarget
);
