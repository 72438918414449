import * as types from "../actionTypes/ajaxActionTypes";

/**
 * Begin Ajax call action creator dispatched to update the store that the application is beginning
 * a new ajax call
 * */
export function ajaxRequestAction() {
  return { type: types.AJAX_CALL_BEGIN };
}

/**
 * Ajax Call Error action creator. This will be dispatched to the store to update the state of the
 * ajax calls in the store
 * @param {Object} error error retrieved from the ajax call
 * @param {String} id Error Id of the ajax call with the error
 * */
export function ajaxErrorAction(error, id) {
  return { type: types.AJAX_CALL_FAILURE, error, id };
}

/**
 * Ajax call success action creator that is dispatched to the redux store on successfull completion
 * of a ajax call
 * */
export function ajaxSuccessAction() {
  return { type: types.AJAX_CALL_SUCCESS };
}

/**
 * Action creator stopping the auto-disconnect
 * @return {Object}
 * @returns {Object} Action object
 */
export const stopAutoDisconnectionAction = () => ({
  type: types.STOP_AUTO_DISCONNECT
});
