/**
 * @notes: Action creator for User Management container component. These create actions to be dispatched
 * to the redux store. These dispatched actions are objects with the type of action and the payload
 * These are pure functions that return the same expected output for the same number of inputs.
 * They simply return the action type and the object payload
 */
import * as types from "../actionTypes/webUserActionTypes";
import { IBeginAction, IActionError, IAction } from "store/model";
import { IWebUser } from "model/entities/User";
import { IWebUser as IBEWebUser } from "smala-tools/dist/src/types/users";

/**
 * Add web users Request begin Action
 * This will create an action of type CREATE_WEB_USERS_BEGIN that will be used to trigger a loading progress on the
 * view and signal that an AJAX request is being made
 * @returns {Object} Object Payload with the type of action to update the state of the Store
 * */
export function createWebUsersBeginActionCreator(): IBeginAction {
  return {
    type: types.CREATE_WEB_USERS_BEGIN,
  };
}

/**
 * Add web users Request failure action. This is dispatched to the Redux Store with the information
 * of the error raised during the Adding an app user process
 * @param {Object} error Error Object received from API
 * @returns {Object} Payload object with the type of action and the Payload information
 * */
export function createWebUsersFailureActionCreator(error: any): IActionError {
  return {
    type: types.CREATE_WEB_USERS_FAILURE,
    error,
  };
}

interface ICreateWebUsersSuccessActionCreator extends IAction {
  users: IWebUser[];
}

/**
 * Add web users request success action is dispatched to the redux store when we have successfully
 * added new app users. This will be used to update the state of the store with the payload
 * information received from the API. the object received is a data object with the id of the newly created user
 * @param {Object} users newly created User Object
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function createWebUsersSuccessActionCreator(
  users: IWebUser[]
): ICreateWebUsersSuccessActionCreator {
  return {
    type: types.CREATE_WEB_USERS_SUCCESS,
    users,
  };
}

/**
 * Fetch web users action triggered when fetching multiple users by client
 * @return {Object} Object with type of action
 * */
export function fetchWebUsersBeginActionCreator(): IBeginAction {
  return {
    type: types.FETCH_WEB_USERS_BY_CLIENT_BEGIN,
  };
}

interface IFetchWebUsersSuccessActionCreator extends IAction {
  users: IBEWebUser[];
  currentRight: any;
}

/**
 * Fetch web users by client success action creator
 * @param {Array} users Array with web user Objects
 * @return {Object} Object with type of action and array of web user objects
 * */
export function fetchWebUsersSuccessActionCreator(
  users: IBEWebUser[],
  currentRight: any
): IFetchWebUsersSuccessActionCreator {
  return {
    type: types.FETCH_WEB_USERS_BY_CLIENT_SUCCESS,
    users,
    currentRight,
  };
}

/**
 * Fetch web users by client failure action
 * @param {Object} error
 * @return {Object} type of action and error object
 * */
export function fetchWebUsersFailureActionCreator(error: any): IActionError {
  return {
    type: types.FETCH_WEB_USERS_BY_CLIENT_FAILURE,
    error,
  };
}

/**
 * Update web user action creator. dispatched when a new web user is being created
 * @return {Object} type of action
 * */
export function updateWebUsersBeginActionCreator(): IBeginAction {
  return {
    type: types.UPDATE_WEB_USERS_BEGIN,
  };
}

interface IUpdateWebUsersSuccessActionCreator extends IAction {
  users: Omit<IWebUser, "created_at" | "created_by">[];
}

/**
 * Updated success web user
 * @param {Object} user user with his new values
 * @returns {Object} type of action*/
export function updateWebUsersSuccessActionCreator(
  users: Omit<IWebUser, "created_at" | "created_by">[]
): IUpdateWebUsersSuccessActionCreator {
  return {
    type: types.UPDATE_WEB_USERS_SUCCESS,
    users,
  };
}

/**
 * Update web users failure action creator
 * @param {Object} error Information of the error encountered while updating user
 * @return {Object} Type of action and error object
 * */
export function updateWebUsersFailureActionCreator(error: any): IActionError {
  return {
    type: types.UPDATE_WEB_USERS_FAILURE,
    error,
  };
}

/**
 * Delete web user begin action creator dispatched when we begin the process to delete a user
 * @return {Object} type of action
 * */
export function deleteWebUserBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_WEB_USER_BEGIN,
  };
}

interface IDeleteWebUserSuccessActionCreator extends IAction {
  id: string;
}

/**
 * Delete web user success action
 * @param {String} id User id
 * @return {Object} Type of action and Boolean flag
 * */
export function deleteWebUserSuccessActionCreator(
  id: string
): IDeleteWebUserSuccessActionCreator {
  return {
    type: types.DELETE_WEB_USER_SUCCESS,
    id,
  };
}

/**
 * Delete web user failure action. Action creator dispatched when there is a failure in deleting a web user
 * @param {Object} error Error object information received in deletion
 * @return {Object} Type of action and error object
 * */
export function deleteWebUserFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_WEB_USER_FAILURE,
    error,
  };
}

/**
 * Delete web users begin action creator dispatched when we begin the process to delete users
 * @return {Object} type of action
 * */
export function deleteWebUsersBeginActionCreator(): IBeginAction {
  return {
    type: types.DELETE_WEB_USERS_BEGIN,
  };
}

interface IDeleteWebUsersSuccessActionCreator extends IAction {
  ids: string[];
}

/**
 * Delete web users success action
 * @param {String} ids User ids
 * @return {Object} Type of action and Boolean flag
 * */
export function deleteWebUsersSuccessActionCreator(
  ids: string[]
): IDeleteWebUsersSuccessActionCreator {
  return {
    type: types.DELETE_WEB_USERS_SUCCESS,
    ids,
  };
}

/**
 * Delete web users failure action. Action creator dispatched when there is a failure in deleting web users
 * @param {Object} error Error object information received in deletion
 * @return {Object} Type of action and error object
 * */
export function deleteWebUsersFailureActionCreator(error: any): IActionError {
  return {
    type: types.DELETE_WEB_USERS_FAILURE,
    error,
  };
}

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearWebUserDataActionCreator(): IAction {
  return {
    type: types.CLEAR_DATA,
  };
}

/**
 * Reset password failure action. Action creator dispatched when there is a failure in deleting a web user
 * @param {Object} error Error object information received in deletion
 * @return {Object} Type of action and error object
 * */
export function resetPasswordFailureActionCreator(error: any): IActionError {
  return {
    type: types.RESET_PASSWORD_FAILURE,
    error,
  };
}

export interface ICreateWebUsersActionSuccess extends IAction {
  users: IWebUser[];
}
export interface IFetchWebUsersActionSuccess extends IAction {
  users: IWebUser[];
  currentRight: string;
}
export interface IUpdateWebUsersActionSuccess extends IAction {
  users: IWebUser[];
}
export interface IDeleteWebUserActionSuccess extends IAction {
  id: string;
}
export interface IDeleteWebUsersActionSuccess extends IAction {
  ids: string[];
}
