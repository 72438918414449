import {
  // BusinessError,
  AlreadyExistsBusinessError,
  AuthentificationBusinessError,
  NotFoundBusinessError,
  NotUpToDateBusinessError,
  NotAllowedBusinessError,
  QuotaExceededBusinessError,
  PartialFailureBusinessError,
  PayloadTooLargeBusinessError,
  RunInBackgroundBusinessError,
} from "./actionErrors";
import { ajaxErrorAction } from "../actionCreators/ajaxActionCreator";
import * as notificationTypes from "../containers/notifications/actionTypes";
import * as levels from "../containers/notifications/constants";
import { createNotificationActionCreator } from "../containers/notifications/actionCreator";
import { logoutAction } from "containers/authentication/redux/actions";
import { Dispatch } from "redux";
import { BUSINESS_ERROR } from "../model/application/Api";
import IRootState from "store/model";
import {
  openWarningModalActionCreator,
  closeWarningModalActionCreator,
} from "actionCreators/appActionCreator";
import TLang from "model/application/Lang";

interface IResponse {
  status: number;
  data: {
    data: {
      error: {
        message: string;
      };
    };
  };
}

interface IError {
  errorStatus: BUSINESS_ERROR;
  message: string;
  response?: IResponse;
}

export type TreatErrorNotificationFunc = (
  dispatch: Dispatch,
  actionName: string,
  error: IError,
  failureActionCreatorFunction: (error: IError) => any,
  lang: TLang
) => void;

export const treatErrorNotification: TreatErrorNotificationFunc = (
  dispatch,
  actionName,
  error,
  failureActionCreatorFunction,
  lang
) => {
  let errorMessage;
  // error can be an applicative or a business error.
  const isBusiness = error.hasOwnProperty("errorStatus");
  if (isBusiness) {
    if (
      error.errorStatus === BUSINESS_ERROR.AUTHENTICATION ||
      error.message.includes("client with id")
    ) {
      logoutAction()(dispatch);
    }
    errorMessage =
      error.errorStatus === BUSINESS_ERROR.RUN_IN_BACKGROUND
        ? lang.actions.runInBackgroundMessage
        : error.message;
  } else {
    if (!error.response) {
      errorMessage = lang.notifications.errorNotifications.networkError;
    } else if (error.response.status === 422) {
      errorMessage = lang.notifications.errorNotifications.validationError;
    } else {
      errorMessage = lang.notifications.errorNotifications.internalError;
    }
  }
  let detailedMessage;
  if (error.message) {
    detailedMessage = `${error.message}${
      error.response &&
      error.response.data &&
      error.response.data.data &&
      error.response.data.data.error &&
      error.response.data.data.error.message
        ? `: ${error.response.data.data.error.message}`
        : ""
    }`;
  }
  dispatch(ajaxErrorAction(error, actionName));
  dispatch(failureActionCreatorFunction(error));
  dispatch(
    createNotificationActionCreator(
      error.errorStatus === BUSINESS_ERROR.RUN_IN_BACKGROUND
        ? notificationTypes.NOTIFICATION_INFO
        : notificationTypes.NOTIFICATION_ERROR,
      levels.NOTIFICATION_LEVEL_ERROR,
      errorMessage,
      detailedMessage
    )
  );
};

interface IData<T> {
  code_response: BUSINESS_ERROR;
  data: T;
  error_message: string;
}

export interface IServerResponse<T> {
  data: IData<T>;
}

export interface IOpenWarningModalParams {
  title: string;
  description: string;
  onConfirm?: any;
}

type TOpenWarningModalFunc = (params: IOpenWarningModalParams) => any;
export const openWarningModal: TOpenWarningModalFunc = (params) => {
  return (dispatch: Dispatch, getState: IRootState) => {
    dispatch(openWarningModalActionCreator({ ...params }));
  };
};

export const closeWarningModal = () => {
  return (dispatch: Dispatch, getState: IRootState) => {
    dispatch(closeWarningModalActionCreator());
  };
};

export const extractDataAndCheckErrorStatus = <T>(
  serverResponse: IServerResponse<T>
): T => {
  // const data = serverResponse && serverResponse.data ? serverResponse.data : serverResponse
  const { data } = serverResponse;
  if (!data.code_response) {
    throw new Error("Server response do not have any 'code_response'");
  } else {
    switch (data.code_response) {
      case BUSINESS_ERROR.SUCCESS: {
        return data.data;
      }
      case BUSINESS_ERROR.RUN_IN_BACKGROUND: {
        throw new RunInBackgroundBusinessError();
      }
      case BUSINESS_ERROR.ALREADY_EXISTS: {
        throw new AlreadyExistsBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.AUTHENTICATION: {
        throw new AuthentificationBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.NOT_ALLOWED: {
        throw new NotAllowedBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.NOT_FOUND: {
        throw new NotFoundBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.NOT_UP_TO_DATE: {
        throw new NotUpToDateBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.PARTIAL_SUCCESS: {
        throw new PartialFailureBusinessError(data.error_message, data.data);
      }
      case BUSINESS_ERROR.QUOTA_EXCEEDED: {
        throw new QuotaExceededBusinessError(data.data);
      }
      case BUSINESS_ERROR.PAYLOAD_TOO_LARGE: {
        throw new PayloadTooLargeBusinessError(data.data);
      }
      default: {
        throw new Error(
          "Server response do not have any 'code_response' recognized"
        );
      }
    }
  }
};
