import * as types from "../actionTypes/appActionTypes";
import initialState from "./initialState";

/**
 * appReducer reducer takes current state and action and
 * returns a new state for the application object in the store
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case types.APP_FIRST_LOGIN: {
      const { isFirstLogin } = action;

      return {
        ...state,
        isFirstLogin,
      };
    }

    case types.APP_ADD_NOTIFICATION:
      const { notification } = action;
      if (
        state.notifications.find(
          (n) => n.type !== action.notification && n.id === notification.id
        )
      ) {
        return state;
      }
      return {
        ...state,
        notifications: [...state.notifications, notification],
      };

    case types.APP_REMOVE_NOTIFICATION: {
      const { typeNotification, id } = action;
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => n.type !== typeNotification || n.id !== id
        ),
      };
    }

    case types.APP_CLEAN_NOTIFICATION:
      return {
        ...state,
        notifications: [],
      };

    case types.OPEN_WARNING_MODAL:
      return {
        ...state,
        warningModal: {
          ...action.params,
          isOpen: true
        }
      };

    case types.CLOSE_WARNING_MODAL:
      return {
        ...state,
        warningModal: {
          ...state.warningModal,
          isOpen: false
        }
      };

    default:
      return state;
  }
}
