import { IOption } from "model/application/components/index";
import {
  IKPI as IBEKPI,
  AXIS_TYPE,
  DASHBOARD_TYPE,
  IDashboard as IBEDashboard,
  IEmailGeneration as IBEEmailGeneration,
  ISubQuery as IBESubQuery,
  IPreComputeFilter as IBEPreComputeFilter,
  IKPIValue as IBEKPIValue,
  IKPIRowColumnBreakdownLevel as IBEKPIRowColumnBreakdownLevel,
  IStreamColumn as IBEStreamColumn,
  ITransformation as IBETransformation,
  IStream as IBEStream,
} from "smala-tools/dist/src/types";
import { ITableRowContent } from "model/application/components";
export {
  DASHBOARD_TYPE,
  DASHBOARD_SCOPE,
  KPI_TYPE,
  DB_TYPE,
  AXIS_TYPE,
  AGGREGATOR,
  AGGREGATOR_DIMENSION,
  QUERY_TYPE,
  PIVOT_TYPE,
  DEFAULT_DASHBOARD,
  TRANSFORMATION_AGGREGATION_PERIOD,
  EMAIL_GENERATION_DATE_RANGE,
  EMAIL_GENERATION_FREQUENCY,
  EMAIL_GENERATION_TYPE,
  FILTER_OPERATOR,
  ADD_DATA_STRATEGY,
  TRANSFORMATION_STATUS,
} from "smala-tools/dist/src/types";

export interface ILabelAliasMapping {
  label: string;
  alias: string;
}

export interface IStream extends IBEStream {
  indexes: string;
}

export interface ITransformation
  extends Omit<IBETransformation, "last_update"> {
  last_update?: string;
  freeze?: boolean;
  indexes: string;
}

export interface ISubQuery extends IBESubQuery {
  index?: number;
}

export interface IPreComputeFilter extends IBEPreComputeFilter {}

export interface IKPIValue extends IBEKPIValue {}

export interface IKPIRowColumnBreakdownLevel
  extends IBEKPIRowColumnBreakdownLevel {}
export interface IStreamColumn extends IBEStreamColumn {
  index?: number;
}

export interface IDashboardInTable extends ITableRowContent {
  actif: boolean;
  title: string;
  description: string;
  number_of_charts: number;
  type: string;
  source: "standard" | "custom";
}

export interface IStreamInTable extends ITableRowContent {
  title: string;
  destination_table: string;
  primary_key: string;
}

export interface IDashboard
  extends Omit<
    IBEDashboard,
    | "kpis"
    | "name"
    | "actif"
    | "type"
    | "host"
    | "port"
    | "db_name"
    | "user"
    | "password"
    | "list_id"
    | "alias_mapping"
  > {
  teams?: string[];
  kpis: IKPI[];
  name: string;
  actif: boolean;
  type: string;
  host: string;
  port: string;
  db_name: string;
  user: string;
  password: string;
  list_id: string;
  alias_mapping?: ILabelAliasMapping[];
  query: any;
  sensible_data_password?: string;
  is_default_dashboard?: boolean;
}
export interface IEmailGeneration
  extends Omit<IBEEmailGeneration, "web_users" | "mobile_user_roles"> {
  web_users: IOption[];
  mobile_user_roles: IOption[];
}
export interface IGpsRecord {
  acc: number;
  lat: number;
  lng: number;
  timestamp: string;
  user_id: string;
}

export interface ITimelineActionsForGPSTracking {
  place_id: string;
  workflow_id: string;
  submission_id: string;
  timestamp: string;
  user_id: string;
  action_code: TIMELINE_EVENT_TYPE;
}

export interface IPlaceDetailForGPSTracking {
  place_id: string;
  lat: number;
  lng: number;
  name: string;
  actif: boolean;
}

export interface IKPIData {
  id?: string;
  label: string;
  data: IGpsRecord[] | ITimelineActionsForGPSTracking[] | any[];
}

export enum TIMELINE_EVENT_TYPE {
  CHECK_IN = "CHECK_IN",
  CHECK_OUT = "CHECK_OUT",
  CREATE_SUBMISSION = "CREATE_SUBMISSION",
}

export type TTimelineData = {
  date: Date;
  action: TIMELINE_EVENT_TYPE;
  workflow_name: string;
  user_name?: string;
  user_phone?: string;
  place_name: string;
  place_id: string;
}[];

export interface IKPI<T = any> extends Omit<IBEKPI, "data" | "sub_queries"> {
  data?: T;
  sub_queries?: ISubQuery[];
  _error?: any;
}

export interface INivoConfiguration {
  colors: string[];
  curve: "monotoneX";
  enableArea: boolean;
  direction: "horizontal" | "vertical";
  axeXType: AXIS_TYPE;
  xFormat: string;
  fullWidth: boolean;
  forceSquare: boolean;
  labelTextColor: string;
  withHeaders: boolean;
  tickValues?: any[];
  enableYAxis?: boolean;
}

export interface DashboardTable {
  id: string;
  data: string;
  type: DASHBOARD_TYPE;
  actif: boolean;
}

export interface ITransformationInTable extends ITableRowContent {
  title: string;
  primary_key: string;
  delta_time: number;
  transformation_aggregation_period: string;
  historical_load_days: number;
  status: string;
  indexes: string;
}
export interface IEmailGenerationInTable extends ITableRowContent {
  id: string;
  title: string;
  type: string;
  frequency: string;
  date_range: string;
  start_date: Date;
  start_time: Date;
}

export interface IScale {
  minValue: number;
  maxValue: number;
}
