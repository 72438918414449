import { createSelector } from "reselect";
import { customSelectorCreator } from "../../../selectors/appSelector";
import {
  getSelectedClient,
  getIsFetchingEntities,
} from "../../clients/redux/selectors";
import { clone } from "../../../utils/utils";
import IRootState from "store/model";
import IWorkflow, {
  IWorkflowGroup,
  IGeneratorToTask,
  ISubmission,
} from "model/entities/Workflow";
import { IClient } from "model/entities/Client";
import { IList } from "model/entities/List";

/**
 * Selected sub-category
 * workflows
 */
export const getSelectedSubCategory = (state: IRootState) =>
  state.workflows.subCategorySelected;

/**
 * gets state of all workflows
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingAllWorkflows = (state: IRootState) =>
  state.workflows.isFetchingAll;

/**
 * get state of deleting a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingWorkflow = (state: IRootState) =>
  state.workflows.isDeleting;

/**
 * get state of archiving a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsArchivingWorkflow = (state: IRootState) =>
  state.workflows.isArchiving;

/**
 * get state of restoring a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsRestoringWorkflow = (state: IRootState) =>
  state.workflows.isRestoring;

/**
 * Gets the last updated value of workflows This should return a Date object or null if it has not been update
 * @param {Object} state
 * @returns {Object}
 */
export const getWorkflowsLastUpdated = (state: IRootState) =>
  state.workflows.lastUpdated;

/**
 * get state of creating a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 *  */
export const getIsCreatingWorkflow = (state: IRootState) =>
  state.workflows.isCreating;

/**
 * gets state of updating a worfklow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingWorkflow = (state: IRootState) =>
  state.workflows.isUpdating;

/**
 * get state of fetching the versions of a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingWorkflowVersions = (state: IRootState) =>
  state.workflows.isFetchingWorkflowVersions;

/**
 * get state of restoring the version of a workflow
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsRestoringWorkflowVersion = (state: IRootState) =>
  state.workflows.isRestoringWorkflowVersion;

/**
 * Gets all workflows from redux store
 * @param {Object} state
 * @returns {Array}
 */
export const getAllWorkflows = (state: IRootState): IWorkflow[] =>
  state.workflows.allWorkflows;

  export const getSelectedSubmission = (state: IRootState): ISubmission | undefined =>
  state.workflows.selectedSubmission;

/**
 * Gets all workflow versions from redux store
 * @param {Object} state
 * @returns {Array}
 */
export const getWorkflowVersions = (state: IRootState) =>
  state.workflows.workflowVersions;

/**
 * Gets all lists from redux store
 * @param {Object} state
 * @returns {Array}
 */
const getAllLists = (state: IRootState) => state.lists.allLists;

/**
 * Get workflows by id
 * @param {Object} state Redux store state
 * @returns {Object}
 */
const getWorkflowsById = (state: IRootState) => state.workflows.byId;

/**
 * Gets the most recent selected Workflo
 * @param {Object} state Redux store state
 * @returns {Object}
 */
const getSelectedWorkflow = (state: IRootState) => state.workflows.workflow;

/**
 * Selector to get all workflows
 * @param {Object} state Redux store state
 * @returns {Array} array of workflows
 */
export const allWorkflowsSelector = customSelectorCreator(
  getAllWorkflows,
  (allWorkflows: IWorkflow[]) => allWorkflows
);

/**
 * Selector to get all workflows
 * @param {Object} state Redux store state
 * @returns {Array} array of workflows
 */
export const allActiveWorkflowsSelector = customSelectorCreator(
  getAllWorkflows,
  (allWorkflows: IWorkflow[]) => allWorkflows.filter((w) => w.actif)
);

/**
 * Selector to get workflows by id
 * @param {Object} state
 * @returns {Object}
 */
export const workflowsByIdSelector = createSelector(
  getWorkflowsById,
  (workflowsById) => workflowsById
);

/**
 * Select to get the id of all actif workflows for a given client
 * @param {Object} state
 * @returns {Array}
 */
export const workflowSimplifiedSelector = customSelectorCreator(
  [getSelectedClient, getAllWorkflows, getIsFetchingEntities],
  (
    client: IClient,
    allWorkflows: IWorkflow[],
    isFetchingAllEntities: boolean
  ): { id: string; name: string }[] => {
    if (isFetchingAllEntities) return [];
    if (client) {
      let workflows: IWorkflow[] = clone(allWorkflows);
      return workflows
        .filter((w) => w.actif)
        .map((w) => ({
          id: w.id,
          name: w.name,
        }));
    } else return [];
  }
);

/**
 * Select to get the workflows for a given client
 * @param {Object} state
 * @returns {Array}
 */
export const workflowsComposedSelector = customSelectorCreator(
  [getSelectedClient, getAllWorkflows, getAllLists, getIsFetchingEntities],
  (
    client: IClient,
    allWorkflows: IWorkflow[],
    lists: IList[],
    isFetchingAllEntities: boolean
  ): IWorkflowGroup[] => {
    if (isFetchingAllEntities) return [];
    if (client) {
      let workflows: IWorkflow[] = clone(allWorkflows);
      if (!workflows) {
        return [];
      }
      const preResult = workflows.reduce(
        (acc: Partial<IWorkflowGroup>[], curr) => {
          if (curr.workflow_type === "TASK") {
            // search in acc if there is the generator we want
            if (
              acc.filter(
                (w) => w.primary && w.primary.workflow_task_id === curr.id
              ).length === 1
            ) {
              acc = acc.map((w) => {
                if (w.primary && w.primary.workflow_task_id === curr.id) {
                  w.secondary = curr;
                }
                return w;
              });
            } else {
              // the generator does not exist yet in the list => we create a new "super workflow" with the secondary one initialized
              acc.push({
                secondary: curr,
                actif: curr.hasOwnProperty("actif") ? curr.actif : true,
              });
            }
          } else if (curr.workflow_type === "TASK_GENERATOR") {
            // search in acc if there is the task we want
            if (
              acc.filter(
                (w) => w.secondary && w.secondary.id === curr.workflow_task_id
              ).length === 1
            ) {
              acc = acc.map((w) => {
                if (w.secondary && w.secondary.id === curr.workflow_task_id) {
                  w.primary = curr;
                  const link = formatLinkBetweenGeneratorAndTask(lists, curr);
                  w.link = link;
                }
                return w;
              });
            } else {
              const link = formatLinkBetweenGeneratorAndTask(lists, curr);
              // the task does not exist yet in the list => we create a new "super workflow" with the primary one initialized
              acc.push({
                primary: curr,
                link,
                actif: curr.hasOwnProperty("actif") ? curr.actif : true,
              });
            }
          } else {
            // case of "default" workflow. We just initialize the primary workflow
            acc.push({
              primary: curr,
              actif: curr.hasOwnProperty("actif") ? curr.actif : true,
            });
          }
          return acc;
        },
        []
      );
      return preResult.filter((r) => r.primary) as IWorkflowGroup[];
    } else {
      return [];
    }
  }
);

/**
 * This function returns the link in the format expected by the workflow builder:
 * - extracting the "task_meta" from the list task
 * - split the matrix_binding into n links
 */
const formatLinkBetweenGeneratorAndTask = (
  lists: IList[],
  generator: IWorkflow
) => {
  const taskList = lists.filter((l) => l.id === generator.list_task_id)[0];
  const link = taskList ? taskList.task_meta : undefined;
  if (link && link.tags_generator_to_task) {
    link.tags_generator_to_task = link.tags_generator_to_task.reduce(
      (acc: IGeneratorToTask[], curr) => {
        if (!curr.matrix_binding) {
          acc.push(curr);
        } else {
          curr.matrix_binding.forEach((l) => {
            acc.push({
              matrix_binding: [
                {
                  matrix_wf_generator_tag: l.matrix_wf_generator_tag,
                  matrix_wf_task_tag: l.matrix_wf_task_tag,
                },
              ],
              wf_generator_tag: curr.wf_generator_tag,
              wf_task_tag: curr.wf_task_tag,
            });
          });
        }
        return acc;
      },
      []
    );
  }
  return link;
};

/**
 * Selector to get the most recently selected workflow
 * @param {Object}
 * @returns {Object}
 */
export const selectedWorkflowSelector = createSelector(
  getSelectedWorkflow,
  (workflow) => workflow
);

/**
 * gets state of fetching a submission
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingSubmission = (state: IRootState) =>
  state.workflows.isFetchingSubmissionDetail;

/**
 * gets state of fetching a submission
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingSubmission = (state: IRootState) =>
  state.workflows.isDeletingSubmission;

/**
 * gets state of fetching a submission
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingSubmissions = (state: IRootState) =>
  state.workflows.isFetchingSubmissions;

/**
 * gets state of updating a submission
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingSubmission = (state: IRootState) =>
  state.workflows.isUpdatingSubmission;

/**
 * Gets the submissions from Redux Store
 * @param {Object} state Redux Store state for Clients
 * @returns {Array} All clients
 */
const getAllSubmissions = (state: IRootState) => state.workflows.allSubmissions;

/**
 * Submissions selector
 * @returns {Array}
 */
export const allSubmissionsSelector = createSelector(
  getAllSubmissions,
  (allSubmissions) => allSubmissions
);
