import apiService from "../../../api/api";
import { CLIENT_ENDPOINT } from "../../clients/redux/endpoints";
import { prepareSubmissionForBackend, prepareWorkflowGroupForBackend } from "./utils";
import { IWorkflowGroup, ISubmission } from "model/entities/Workflow";
import { IServerResponse } from "actions/appActions";
import {
  IList,
  IMetaHierarchyDependency,
  IWorkflow,
} from "smala-tools/dist/src/types";
import { getBackendQuery } from "components/Filter/Filter.utils";

export const WORKFLOWS_ENDPOINT = "/workflows";
export const SUBMISSIONS_ENDPOINT = "/submissions";
const CLIENTS_ENDPOINT = `/clients`;

interface ICreateWorkflowResponse {
  primary_id: string;
  primary_integrations: any[];
  secondary_id: string;
  secondary_integrations: any[];
  task_list: IList;
}

export const createWorkflowApiCall = (
  workflowGroup: IWorkflowGroup,
  clientId: string
): Promise<IServerResponse<ICreateWorkflowResponse>> => {
  return apiService.post(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}`,
    prepareWorkflowGroupForBackend(workflowGroup)
  );
};

interface IFetchAllWorkflowsResponse {
  workflows: IWorkflow[];
}

/**
 * Gets all workflows from api call
 * @param {String} clientId Client id to attach a workflow to
 * @returns {Promise}
 */
export const fetchAllWorkflowsApiCall = (
  clientId: string
): Promise<IServerResponse<IFetchAllWorkflowsResponse>> => {
  return apiService.get(`${CLIENT_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}`);
};

interface IFetchSubmissionDetailResponse {
  submission: any;
}

export const fetchSubmissionDetailApiCall = (
  workflowId: string,
  submissionId: string,
  clientId: string
): Promise<IServerResponse<IFetchSubmissionDetailResponse>> => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}/submissions/${submissionId}`
  );
};

interface IDeleteSubmissionResponse {}

export const deleteSubmissionApiCall = (
  workflowId: string,
  submissionId: string,
  clientId: string
): Promise<IServerResponse<IDeleteSubmissionResponse>> => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}/submissions/${submissionId}`,
    {}
  );
};

interface IFetchSubmissionsResponse {
  submissions: any[];
}

export const fetchSubmissionsApiCall = (
  workflowId: string,
  filters: any,
  metaHierarchy: IMetaHierarchyDependency,
  clientId: string
): Promise<IServerResponse<IFetchSubmissionsResponse>> => {
  let finalFilter = {};
  const backendQuery = getBackendQuery(filters, metaHierarchy);
  Object.keys(backendQuery).forEach((att) => {
    if (att === "start_date") {
      finalFilter["start_at"] = backendQuery[att];
    } else if (att === "end_date") {
      finalFilter["end_at"] = backendQuery[att];
    } else if (backendQuery[att]) {
      finalFilter[att] = backendQuery[att];
    }
  });
  return apiService.get(
    `${CLIENT_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}/submissions`,
    {
      param1: workflowId.startsWith("w_") ? workflowId : `w_${workflowId}`,
      ...finalFilter,
    } as any
  );
};

interface IUpdateWorkflowResponse {
  task_list: IList;
  secondary_id: string;
  primary_integrations: any[];
  secondary_integrations: any[];
}

export const updateWorkflowApiCall = (
  workflowGroup: IWorkflowGroup,
  clientId: string
): Promise<IServerResponse<IUpdateWorkflowResponse>> => {
  const body = prepareWorkflowGroupForBackend(workflowGroup);
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${body.primary.id}`,
    body
  );
};

interface IUpdateSubmissionResponse {}

export const updateSubmissionApiCall = (
  submission: ISubmission,
  workflowId: string,
  clientId: string
): Promise<IServerResponse<IUpdateSubmissionResponse>> => {
  const body = prepareSubmissionForBackend(submission);
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}${SUBMISSIONS_ENDPOINT}/${submission._id}`,
    body
  );
};

interface IArchiveWorkflowResponse {}

export const archiveWorkflowApiCall = (
  workflowId: string,
  clientId: string
): Promise<IServerResponse<IArchiveWorkflowResponse>> => {
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}/archive`,
    {}
  );
};

interface IRestoreWorkflowResponse {}

export const restoreWorkflowApiCall = (
  workflowId: string,
  clientId: string
): Promise<IServerResponse<IRestoreWorkflowResponse>> => {
  return apiService.patch(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}/restore`,
    {}
  );
};

interface IDeleteWorkflowResponse {}

export const deleteWorkflowApiCall = (
  workflowId: string,
  clientId: string
): Promise<IServerResponse<IDeleteWorkflowResponse>> => {
  return apiService.delete(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}`
  );
};

export interface IUploadFileForWorkflowResponse {
  uploaded: boolean;
  urls: string[];
}

export const uploadFileApiCall = (
  files: any[]
): Promise<IServerResponse<IUploadFileForWorkflowResponse>> => {
  return apiService.multipartUpdate(`/upload/workflows`, files);
};

interface IFetchWorkflowVersionsResponse {
  versions: { id: string; created_at: string; created_by: string }[];
}

export const fetchWorkflowVersionsApiCall = (
  workflowId: string,
  clientId: string
): Promise<IServerResponse<IFetchWorkflowVersionsResponse>> => {
  return apiService.get(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}/versions`
  );
};

interface IRestoreWorkflowVersionResponse {
  primary: IWorkflow;
  secondary?: IWorkflow;
  link?: any;
}

export const restoreOldWorkflowApiCall = (
  clientId: string,
  workflowId: string,
  versionId: string
): Promise<IServerResponse<IRestoreWorkflowVersionResponse>> => {
  return apiService.post(
    `${CLIENTS_ENDPOINT}/${clientId}${WORKFLOWS_ENDPOINT}/${workflowId}/versions/${versionId}/restore`
  );
};
