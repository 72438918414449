/**
 * @notes: Api wrapper to communicate with ApiService to handle api calls for Client account management
 */
import apiService from "../../../api/api";
import * as urls from "./endpoints";
import { prepareClientForBackend } from "./utils";
import { IClient } from "model/entities/Client";
import { IServerResponse } from "actions/appActions";
import { IClient as IBEClient } from "smala-tools/dist/src/types";
import { IReplicateClientAttributes } from "../components/modals/ReplicateClientModal";

/**
 * Creates a Client account by making a POST request to api with the given parameters
 * @param {Object} body details of the new created client
 * @return {Promise}
 * */
export type TCreateClientResponse = { id: string; dbname: string };
export type TCreateClientFunc = (
  body: IClient
) => Promise<IServerResponse<TCreateClientResponse>>;
export const createClientApiCall: TCreateClientFunc = (body) => {
  return apiService.post(urls.CLIENT_ENDPOINT, prepareClientForBackend(body));
};

/**
 * Fetch a single client api call
 * @param {String} id If of the client to fetch from API
 * @return {Promise}
 * */
export type TFetchClientResponse = { client: IBEClient };
export type TFetchClientFunc = (
  id: string
) => Promise<IServerResponse<TFetchClientResponse>>;
export const fetchClientApiCall: TFetchClientFunc = (id) => {
  return apiService.get(`${urls.CLIENT_ENDPOINT}/${id}`);
};
/**
 * Replicates a Client account by making a POST request to api with the given parameters
 * @param {Object} body details of the new created client
 * @return {Promise}
 * */
export type TReplicateClientResponse = {
  id: string;
  dbname: string;
};
export type TReplicateClientFunc = (
  old_client_id: string,
  attributes: IReplicateClientAttributes
) => Promise<IServerResponse<TReplicateClientResponse>>;
export const replicateClientApiCall: TReplicateClientFunc = (
  old_client_id,
  attributes
) => {
  return apiService.post(`${urls.CLIENT_ENDPOINT}/${old_client_id}/replicate`, {
    ...attributes,
    old_client_id,
  });
};

/**
 * Fetch all clients api call
 * @return {Promise}
 * */
export type TFetchAllClientsResponse = { clients: IBEClient[] };
export type TFetchAllClientsFunc = () => Promise<
  IServerResponse<TFetchAllClientsResponse>
>;
export const fetchAllClientsApiCall: TFetchAllClientsFunc = () => {
  return apiService.get(urls.CLIENT_ENDPOINT);
};

/**
 * Updates the client
 * @param {String} id Id of client to update
 * @param {Object} body Detail of the client updated
 * @return {Promise}
 * */
export type TUpdateClientFunc = (
  id: string,
  body: IClient
) => Promise<IServerResponse<{ write_time: string }>>;
export const updateClientApiCall: TUpdateClientFunc = (id, body) => {
  return apiService.patch(
    `${urls.CLIENT_ENDPOINT}/${id}`,
    prepareClientForBackend(body)
  );
};

/**
 * Set the client to live mode
 * @param {String} id Id of client to set live
 * @return {Promise}
 * */
export type TSetClientLiveFunc = (
  id: string
) => Promise<IServerResponse<{ write_time: string }>>;
export const setClientLiveApiCall: TSetClientLiveFunc = (id) => {
  return apiService.post(`${urls.CLIENT_ENDPOINT}/${id}/go_live`, {});
};

/**
 * Delete Client API call makes a POST request to delete a single Client
 * @param {String} id Id of the Client to delete
 * @return {Promise}
 * */
export type TDeleteClientFunc = (id: string) => Promise<IServerResponse<{}>>;
export const deleteClientApiCall: TDeleteClientFunc = (id) => {
  return apiService.delete(`${urls.CLIENT_ENDPOINT}/${id}`, {});
};

/**
 * Archive client api call
 * @param {String} clientId Client id
 */
export type TArchiveClientFunc = (
  clientId: string
) => Promise<IServerResponse<{}>>;
export const archiveClientApiCall: TArchiveClientFunc = (clientId) => {
  return apiService.patch(`${urls.CLIENT_ENDPOINT}/${clientId}/archive`, {});
};

/**
 * Restore client api call
 * @param {String} clientId Client id
 */
export type TRestoreClientFunc = (
  clientId: string
) => Promise<IServerResponse<{}>>;
export const restoreClientApiCall: TRestoreClientFunc = (clientId) => {
  return apiService.patch(`${urls.CLIENT_ENDPOINT}/${clientId}/restore`, {});
};

/**
 * Upload image
 * @param {}
 * @returns {Promise}
 */
export type TUploadFileResponse = {
  uploaded: boolean;
  urls: { filename: string; url: string }[];
};
export type TUploadFileFunc = (
  file: any,
  fileName: string
) => Promise<IServerResponse<TUploadFileResponse>>;
export const uploadFileApiCall: TUploadFileFunc = (file, fileName) => {
  return apiService.multipartUpdate(`/upload/client`, [{ file, fileName }]);
};

/**
 * Get the readonly postgres db user
 * @param {String} clientId client id for which we want to get the readonly pg db user
 * @return {Promise}
 * */
export type TGetDBAccFunc = (clientId: string) => Promise<IServerResponse<any>>;
export const getDBAccessCall: TGetDBAccFunc = (clientId) => {
  return apiService.get(`${urls.CLIENT_ENDPOINT}/${clientId}/dbuser`);
};
/**
 * get the client stripe session link
 * @param {String} clientId Id of client get session link
 * @return {Promise}
 * */
export type TGetStripeSessionLinkFunc = (
  clientId: string
) => Promise<IServerResponse<any>>;
export const getStripeSessionLinkApiCall: TGetStripeSessionLinkFunc = (
  clientId
) => {
  return apiService.get(`${urls.CLIENT_ENDPOINT}/${clientId}/get_session_link`);
};

/**
 * get the sales/operations owners
 * @param {String} clientId id of the client to fetch from API
 * @return {Promise}
 * */
export type TFetchAdditionalInfosFunc = (
  clientId: string
) => Promise<IServerResponse<any>>;
export const fetchAdditionalInfosApiCall: TFetchAdditionalInfosFunc = (
  clientId
) => {
  return apiService.get(`${urls.ADDITIONAL_INFOS_ENDPOINT}/${clientId}`);
};
