/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import apiService from "../../../../api/api";
import { prepareStreamForBackend } from "./utils";
import { IStream } from "model/entities/Dashboard";
import { IServerResponse } from "actions/appActions";

export const STREAMS_ENDPOINT = "streams";

// STREAMS

export type TCreateStreamFunc = (
  stream: IStream
) => Promise<IServerResponse<ICreateStreamResponse>>;
export interface ICreateStreamResponse {
  id: string;
}

export type TUpdateStreamFunc = (
  stream: IStream
) => Promise<IServerResponse<IUpdateStreamResponse>>;
export interface IUpdateStreamResponse {}

export type TDeleteStreamFunc = (
  stream_id: string
) => Promise<IServerResponse<IDeleteStreamResponse>>;
export interface IDeleteStreamResponse {}

export type TFetchStreamsFunc = () => Promise<
  IServerResponse<IFetchStreamsResponse>
>;
export interface IFetchStreamsResponse {
  streams: IStream[];
}

export type TUseStreamFunc = (
  id: string,
  table?: string,
  zip?: any
) => Promise<IServerResponse<IUseStreamResponse>>;
export interface IUseStreamResponse {}

/**
 * Api call to fetch all
 */
export const fetchStreamsApiCall: TFetchStreamsFunc = () => {
  return apiService.get(`${STREAMS_ENDPOINT}`);
};

/**
 * Creates a new stream
 * @param {Object} stream stream details
 */
export const createStreamApiCall: TCreateStreamFunc = (stream) => {
  const payload = prepareStreamForBackend(stream);
  return apiService.post(`${STREAMS_ENDPOINT}`, payload);
};

/**
 * Update a targetted stream
 * @param {Object} stream Detail of the new stream
 */
export const updateStreamApiCall: TUpdateStreamFunc = (stream) => {
  const payload = prepareStreamForBackend(stream);
  return apiService.patch(`${STREAMS_ENDPOINT}/${stream.id}`, payload);
};

/**
 * Delete stream API call makes a POST request to delete a single stream
 * @param {String} stream Id of the stream to delete
 * @return {Promise}
 * */
export const deleteStreamApiCall: TDeleteStreamFunc = (stream_id) => {
  return apiService.delete(`${STREAMS_ENDPOINT}/${stream_id}`, {});
};

/**
 * Use stream API call makes a POST request to use a stream
 * @param {String} id Id of the stream to use
 * @param {String} table Name of the origin table
 * @param {String} zip Information of the zip
 * @return {Promise}
 * */
export const runStreamApiCall: TUseStreamFunc = (id, table, zip) => {
  if (table) {
    return apiService.post(`${STREAMS_ENDPOINT}/${id}/use`, {
      source_table: table,
    });
  } else {
    return apiService.multipartUpdate(`${STREAMS_ENDPOINT}/${id}/use`, [
      { file: zip.file, fileName: zip.fileName },
    ]);
  }
};
