import React, { Component } from "react";
import { connect } from "react-redux";
import { object, objectOf, func, string, instanceOf, bool } from "prop-types";
import { routerActions } from "react-router-redux";
import * as authActions from "containers/authentication/redux/actions";
import * as appActions from "../../actions/appActions";
import * as ajaxActions from "../../actions/ajaxActions";
import { bindActionCreators } from "redux";
import { Route, withRouter, Switch } from "react-router-dom";
import PrivateRoute from "../../components/routes/PrivateRoute";
import {
  fetchAllClientsAction,
  reloadIfNeededAction,
} from "../clients/redux/actions";
import { setActionsAvailableAction } from "../authentication/redux/actions";
import MainLayout from "../../layouts/MainLayout";
import { WarningModalContext } from "containers/app/AppProviders";
import {
  HOME_ROUTE,
  CLIENTS_ROUTE,
  TEAMS_ROUTE,
  USERS_ROUTE,
  LISTS_ROUTE,
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  WORKFLOWS_ROUTE,
  PLACES_ROUTE,
  PICTURES_ROUTE,
  DOCUMENTS_ROUTE,
  ENVIRONMENT_ROUTE,
} from "../../config/constants";
import { Cookies, withCookies } from "react-cookie";
import {
  tokenSelector,
  getSignedInUser,
  getLang,
} from "../authentication/redux/selector";
import AppError from "./AppErrorBoundary";
import {
  HomePage,
  DashboardPage,
  LoginPage,
  ListsPage,
  UserMgtPage,
  ClientsPage,
  TeamsPage,
  WorkflowsPage,
  PlacesPage,
  PicturesPage,
  DocumentsPage,
  EnvironmentPage,
} from "./pages";
import { AppProviders, AppConsumer } from "./AppProviders";
import {
  getSelectedClient,
  getIsFetchingAllClients,
} from "../clients/redux/selectors";
import { MuiThemeProvider } from "@material-ui/core";
import { getActionsAvailable } from "model/application/ActionCode";
import apiService from "../../api/api";
import FullScreenLoader from "../../components/Progress/FullScreenLoader";
import { AppTheme } from "./AppTheme";
import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import * as langs from "../../lang";

/**
 * Application container component. This is the Root Component of the application.
 * */
class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      signedInUser: {},
      actionsAvailable: null,
      lastUpdate: Date.now(),
    };
  }

  getUrlVars() {
    var vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }

  /**
   * Self executing function that updates the token from the store to be used by the ApiService
   * */
  updateToken = () => {
    const { token } = this.props;
    (function () {
      if (token) {
        apiService.setToken(token);
      } else {
        apiService.setToken(null);
      }
    })();
  };

  /**
   * Self executing function that updates the client id from the store to be used by the ApiService
   * */
  updateClientId = () => {
    const { selectedClient } = this.props;
    (function () {
      if (selectedClient) {
        apiService.setClientId(selectedClient.id);
      } else {
        apiService.setClientId("");
      }
    })();
  };

  /**
   * Component is about to receive props. These props will come from the store. Since this is the root application
   * container. Extract the data needed from these props and update the state of the application container
   * */
  static getDerivedStateFromProps(nextProps, prevState) {
    const { cookies, token, signedInUser, redirectActions, ajaxActions } =
      nextProps;

    if (!prevState) return null;

    if (
      token !== prevState.token ||
      signedInUser !== prevState.signedInUser ||
      cookies !== prevState.cookies
    ) {
      // set the token in cookies
      if (token) {
        cookies.set("token", token);
      } else {
        redirectActions.push(LOGIN_ROUTE);
        ajaxActions.stopAutoDisconnection();
      }

      return {
        ...prevState,
        signedInUser,
        cookies,
        token,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const { redirectActions, signedInUser } = this.props;
    if (signedInUser.isAuthenticated) {
      redirectActions.push(HOME_ROUTE);
    } else {
      redirectActions.push(LOGIN_ROUTE);
    }
  }

  forceUpdate = () => {
    const { redirectActions, authActions } = this.props;
    // check if the app is up to date (only if REACT_APP_SMALA_VERSION is defined)
    if (process.env.REACT_APP_SMALA_VERSION) {
      authActions
        .checkUpdateAction(process.env.REACT_APP_SMALA_VERSION)
        .then((hasUpdate) => {
          if (hasUpdate) {
            this.context.openWarningModal({
              title: "New updated available",
              description:
                "There was an update on the application. The page will reload for you to have the latest version",
            });
            redirectActions.push("/");
            window.location.reload(true);
          }
        });
    }
  };

  render() {
    const {
      selectedClient,
      fetchAllClients,
      reloadIfNeeded,
      setActionsAvailable,
      isFetchingClients,
      lang,
    } = this.props;
    const { actionsAvailable, lastUpdate, signedInUser } = this.state;

    // special case: we log out + log in. FIXME: there should be a better way to do it... A bit dirty here.
    let realSelectedClient = signedInUser.isAuthenticated
      ? selectedClient && selectedClient.access_right_profiles
        ? selectedClient
        : null
      : null;
    const realActionsAvailable = signedInUser.isAuthenticated
      ? actionsAvailable
      : null;
    if (!signedInUser.isAuthenticated && actionsAvailable)
      this.setState({ actionsAvailable: null });

    this.updateToken();

    if (!realActionsAvailable && realSelectedClient) {
      const profiles = realSelectedClient.access_right_profiles;
      this.updateClientId();
      const actions = getActionsAvailable(profiles, realSelectedClient.profile);
      setActionsAvailable(actions);
      this.setState({
        actionsAvailable: actions,
      });
    }

    setTimeout(() => {
      reloadIfNeeded();
    }, 3000);

    const route = this.props.location.pathname;

    return (
      <AppError
        client_id={selectedClient ? selectedClient.id : null}
        user_id={signedInUser ? signedInUser.id : null}
      >
        <MuiThemeProvider theme={AppTheme}>
          <AppProviders
            client={realSelectedClient}
            user={signedInUser}
            lang={langs[lang]}
            warningModal={{
              openWarningModal: this.props.appActions.openWarningModal,
              closeWarningModal: this.props.appActions.closeWarningModal,
            }}
          >
            <AppConsumer>
              {({ client }) => (
                <>
                  <Route path={LOGIN_ROUTE} component={LoginPage} />

                  <Route
                    render={() => {
                      if (
                        !realSelectedClient ||
                        Date.now() - lastUpdate > 3600000
                      ) {
                        // every 1 hour, fetch all clients again
                        if (
                          signedInUser.isAuthenticated &&
                          !isFetchingClients
                        ) {
                          this.setState({ lastUpdate: Date.now() });
                          // fetch all clients
                          fetchAllClients(
                            realSelectedClient ? realSelectedClient.id : null
                          );
                        }
                      }
                      if (
                        realActionsAvailable &&
                        realSelectedClient &&
                        !isFetchingClients
                      ) {
                        return (
                          <MainLayout
                            selectedClient={client}
                            actionsAvailable={actionsAvailable}
                            forceUpdate={this.forceUpdate}
                            refreshClient={() =>
                              this.setState({ lastUpdate: 0 })
                            }
                            route={route}
                            {...this.props}
                          >
                            <Switch>
                              <PrivateRoute
                                exact
                                path={HOME_ROUTE}
                                component={HomePage}
                                isAuthenticated={true}
                              />
                              <PrivateRoute
                                exact
                                path={ENVIRONMENT_ROUTE}
                                component={EnvironmentPage}
                                isAuthenticated={
                                  actionsAvailable["FETCH_TRANSFORMATIONS"]
                                }
                              />
                              <PrivateRoute
                                exact
                                path={DASHBOARD_ROUTE}
                                component={DashboardPage}
                                isAuthenticated={
                                  actionsAvailable["FETCH_DASHBOARDS"]
                                }
                              />

                              <PrivateRoute
                                exact
                                path={USERS_ROUTE}
                                isAuthenticated={
                                  actionsAvailable["FETCH_WEB_USERS"] ||
                                  actionsAvailable["FETCH_MOBILE_USERS"]
                                }
                                component={UserMgtPage}
                              />

                              <PrivateRoute
                                exact
                                path={CLIENTS_ROUTE}
                                component={ClientsPage}
                                isAuthenticated={
                                  actionsAvailable["CREATE_CLIENT"]
                                }
                              />

                              <PrivateRoute
                                exact
                                path={TEAMS_ROUTE}
                                component={TeamsPage}
                                isAuthenticated={
                                  actionsAvailable["FETCH_TEAMS"]
                                }
                              />

                              <PrivateRoute
                                exact
                                path={WORKFLOWS_ROUTE}
                                component={WorkflowsPage}
                                isAuthenticated={
                                  actionsAvailable["FETCH_WORKFLOWS"]
                                }
                              />

                              <PrivateRoute
                                exact
                                path={LISTS_ROUTE}
                                component={ListsPage}
                                isAuthenticated={
                                  actionsAvailable["FETCH_LISTS"]
                                }
                              />

                              <PrivateRoute
                                exact
                                path={PLACES_ROUTE}
                                component={PlacesPage}
                                isAuthenticated={true}
                              />

                              <PrivateRoute
                                exact
                                path={PICTURES_ROUTE}
                                component={PicturesPage}
                                isAuthenticated={
                                  actionsAvailable["FETCH_CLIENT_PICTURES"]
                                }
                              />

                              <PrivateRoute
                                exact
                                path={DOCUMENTS_ROUTE}
                                component={DocumentsPage}
                                isAuthenticated={true}
                              />
                            </Switch>
                          </MainLayout>
                        );
                      } else {
                        return (
                          <div style={{ height: "calc(100vh - 20px)" }}>
                            <div
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "20px",
                              }}
                            >
                              <CustomButton
                                type={BUTTON_TYPES_OUTSIDE_TABLE.WARNING}
                                onClick={() =>
                                  this.props.authActions.logoutAction()
                                }
                              >
                                Log out
                              </CustomButton>
                            </div>
                            <FullScreenLoader />
                          </div>
                        );
                      }
                    }}
                  />
                </>
              )}
            </AppConsumer>
          </AppProviders>
        </MuiThemeProvider>
      </AppError>
    );
  }
}

/**
 * Prop validation for this App Container
 * */
App.propTypes = {
  appActions: objectOf(func).isRequired,
  ajaxActions: objectOf(func).isRequired,
  redirectActions: object.isRequired,
  selectedClient: object,
  history: object,
  signedInUser: object,
  router: object,
  cookies: instanceOf(Cookies),
  token: string,
  fetchAllClients: func,
  reloadIfNeeded: func,
  setActionsAvailable: func,
  isFetchingClients: bool,
  lang: string,
  authActions: object,
  userActions: object,
  place: object,
};

/**
 * Maps the state of the Redux Store to This components props
 * @param {Object} state The Redux Store state
 * @param {Object} ownProps App's own props
 * @returns {Object} Returns an object which is the redux store mapping to this Application props
 * */
function mapStateToProps(state, ownProps) {
  return {
    token: tokenSelector(state),
    selectedClient: getSelectedClient(state),
    signedInUser: getSignedInUser(state),
    isFetchingClients: getIsFetchingAllClients(state),
    router: state.routing,
    lang: getLang(state),
  };
}

/**
 * Map dispatch actions to the redux tore and bind these actions to be dispatched by a middleware
 * In this case, we are using redux thunk for dispatching these actions
 * @param {Function} dispatch Dispatch actions from Redux Thunk middleware
 * @returns {Object} */
function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    ajaxActions: bindActionCreators(ajaxActions, dispatch),
    redirectActions: bindActionCreators(routerActions, dispatch),
    fetchAllClients: bindActionCreators(fetchAllClientsAction, dispatch),
    reloadIfNeeded: bindActionCreators(reloadIfNeededAction, dispatch),
    setActionsAvailable: bindActionCreators(
      setActionsAvailableAction,
      dispatch
    ),
    authActions: bindActionCreators(authActions, dispatch),
  };
}

App.contextType = WarningModalContext;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(App))
);
