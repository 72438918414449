import {
  IWorkflow as IBEWorkflow,
  IQuestion as IBEQuestion,
  IQuestionGroup as IBEQuestionGroup,
  IOperation as IBEOperation,
  ICondition as IBECondition,
  IMatrix as IBEMatrix,
  IQuestionnaire as IBEQuestionnaire,
  IConditionOperand as IBEConditionOperand,
  IOperationOperand as IBEOperationOperand,
  ISubmission as IBESubmission,
  ITaskDescriptiveText,
  IGeneratorToTask,
  TASK_TYPE,
  ITaskMeta,
} from "smala-tools/dist/src/types";
import { IOption, ITableRowContent } from "model/application/components";
export {
  TASK_TYPE,
  WORKFLOW_TYPE,
  QUESTION_TYPE,
  CONDITION_OPERATOR,
  OPERATION_OPERATOR,
  COLUMN_TYPE,
  INTEGRATION_TYPE,
  BEYONIC_WEBHOOKS_EVENT_TYPE,
  BEYONOC_CURRENCIES,
} from "smala-tools/dist/src/types";
export type {
  ITaskDescriptiveText,
  IGeneratorToTask,
} from "smala-tools/dist/src/types/lists";

export interface IEditingWorkflowPrimary {
  id: string;
  name: string;
  picture_url: string;
  is_submission_editable: boolean;
  edit_submission_offset: number;
  submission_title_template: string;
  error: any;
}

export interface ISubmission extends IBESubmission {
  [attrname: string]: any;
}

export interface IWorkflowVersion {
  id: string;
  created_at: string;
  created_by: string;
}

export interface IWorkflowGroup {
  primary: IWorkflow;
  secondary?: IWorkflow;
  link?: ITaskMeta;
  [key: string]: any;
}

export interface IWorkflowInTable extends ITableRowContent {
  id: string;
  actif: boolean;
  workflow_id: string;
  listnb: number;
  name: string;
}

export interface ISubmissionInTable extends ITableRowContent {
  _id: string;
  _completed_at: Date;
  [att: string]: any;
}

export interface IPredefinedWorkflow extends IWorkflowGroup {
  language: string;
  sector: string;
  template_name: string;
  description: string;
}

export interface IPredefinedWorkflowWithId extends IPredefinedWorkflow {
  id: number;
}

export interface IOperationOperand extends IBEOperationOperand {
  matrix_question_tag?: string;
}

export interface IConditionOperand extends IBEConditionOperand {}

export interface IOperation extends IBEOperation {
  matrix_question_tag?: string;
}

export interface ICondition extends IBECondition {
  matrix_question_tag?: string;
}

export interface IMatrix extends Omit<IBEMatrix, "typed_questions"> {
  typed_questions: Array<IQuestion>;
}

export interface IQuestion
  extends Omit<
    IBEQuestion,
    "options" | "answer_text" | "matrix" | "conditions" | "index" | "operations"
  > {
  _locked?: boolean;
  _error?: any;
  _viewdetail?: boolean;
  options?: Array<IOption>;
  matrix?: IMatrix;
  question_group: string;
  question?: string; // for sub-questions
  conditions?: ICondition[];
  operations?: IOperation[];
  answer_text?: string;
  index: number;
}

export interface IQuestionGroup extends Omit<IBEQuestionGroup, "questions"> {
  questions: IQuestion[];
  _viewdetail?: boolean;
  _error?: any;
}

export interface IQuestionnaire
  extends Omit<IBEQuestionnaire, "question_groups"> {
  question_groups: Array<IQuestionGroup>;
}

export interface IWorkflow
  extends Omit<IBEWorkflow, "questionnaire" | "smala_list_ids"> {
  questionnaire: IQuestionnaire;
  picture?: any;
  _error?: any;
  deprecated_questions: IQuestion[];
  due_date_offset: number;
  expiration_date_offset: number;
  priority_level: number;
  scope_value_tag?: string;
  type: TASK_TYPE;
  descriptive_text: ITaskDescriptiveText;
  tags_generator_to_task: Array<IGeneratorToTask>;
  smala_list_ids: string[];
}

export interface IEditingWorkflowPrimary {
  id: string;
  name: string;
  picture_url: string;
  is_submission_editable: boolean;
  edit_submission_offset: number;
  submission_title_template: string;
  error: any;
}

export default IWorkflow;
