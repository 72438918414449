export type TColumnType = {
  name: string;
  type?: TABLE_COLUMN_TYPE;
  label: string;
  aggregator?: TABLE_AGGREGATOR;
};

export enum TABLE_AGGREGATOR {
  SUM = "SUM",
  MEAN = "MEAN",
  MIN = "MIN",
  MAX = "MAX",
  NONE = "NONE",
  BOOLEAN = "BOOLEAN",
}

export enum TABLE_COLUMN_TYPE {
  SINGLE_CHOICE = "SINGLE_CHOICE",
  SINGLE_CHOICE_ON_LIST = "SINGLE_CHOICE_ON_LIST",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  GPS = "GPS",
  PICTURE = "PICTURE",
  SWITCH = "SWITCH",
  NONE = "NONE",
  LINK = "LINK",
  PERCENTAGE = "PERCENTAGE",
  PROGRESS = "PROGRESS",
}

export type TRowAction = (i: string) => void;
