import * as types from "./actionTypes";
import initialState from "./initialState";
import {
  insertNewItemToArr,
  updateErrorsList,
  convertArrayToObject,
  removeObjectFromHash,
  removeObjectFromArray,
  extractIdsFromArray,
  updateObjectInArray,
} from "../../../utils/reducerUtils2";
import uniq from "lodash/uniq";
import moment from "moment";
import find from "lodash/find";
import { DATE_FORMAT_FULL_DATE } from "../../../utils/constants";
import { prepareItemsForFrontend } from "./utils";
import { IList } from "model/entities/List";
import { IAction } from "store/model";
import { IListItem } from "model/entities/ListItem";
import {
  IFetchItemsForListSuccessAction,
  IArchiveListSuccessAction,
  IRestoreListSuccessAction,
  ICreateItemsSuccessAction,
  IDeleteItemsSuccessAction,
  IUpdateItemsSuccessAction,
  IArchiveItemsSuccessAction,
  IRestoreItemsSuccessAction,
  IUnassignItemsSuccessAction,
  IAssignItemsSuccessAction,
  IDeleteListSuccessAction,
  IUpdateListSuccessAction,
  ICreateListSuccessAction,
  IFetchListByIdSuccessAction,
  IFetchListsForClientSuccessAction,
  IChangeSubcategorySuccessActionCreator,
  ISelectListActionCreator,
} from "./actionCreators";

export interface ListsState {
  subCategorySelected: string;
  fetchingListsForClient: boolean;
  fetchingObjectsForList: boolean;
  isFetchingList: boolean;
  isUpdatingList: boolean;
  isDeletingList: boolean;
  isArchivingList: boolean;
  isRestoringList: boolean;
  isCreatingList: boolean;
  isFetchingItems: boolean;
  isFetchingOneItem: boolean;
  isFetchingOneItemActivity: boolean;
  isFetchListItemSubmission: boolean;
  isUpdatingItems: boolean;
  isArchivingItems: boolean;
  isRestoringItems: boolean;
  isDeletingItems: boolean;
  isCreatingItems: boolean;
  isUpdatingItem: boolean;
  isArchivingItem: boolean;
  isRestoringItem: boolean;
  isDeletingItem: boolean;
  isCreatingItem: boolean;
  isUploadingFile: boolean;
  isAssigningItems: boolean;
  isUnassigningItems: boolean;
  isFindDuplicatesItems: boolean;
  isDownloadingFile: boolean;
  lastUpdated?: IList;
  allLists: IList[];
  selectedList?: IList;
  byId: { [id: string]: IList };
  allIds: string[];
  errors: any[];
}

/**
 * reducer reducer takes current state and action and
 * returns a new state
 * @param state initial state of the application store
 * @param action function to dispatch to store
 * @return {Object} new state or initial state*/
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CREATE_LIST_BEGIN:
      return {
        ...state,
        isCreatingList: true,
      };

    case types.CREATE_LIST_SUCCESS: {
      const { list } = action as ICreateListSuccessAction;
      // if "items" attribute is not defined, add the "items" attribute with an empty array
      if (!list.items) {
        list["items"] = [];
      }

      return {
        ...state,
        isCreatingList: false,
        allLists: insertNewItemToArr(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
      };
    }

    case types.CREATE_LIST_FAILURE:
      return {
        ...state,
        isCreatingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_LIST_BY_ID_BEGIN:
      return {
        ...state,
        isFetchingList: true,
      };

    case types.FETCH_LIST_BY_ID_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_LIST_BY_ID_SUCCESS: {
      let listOfItems = (action as IFetchListByIdSuccessAction).list;
      if (
        !(action as IFetchListByIdSuccessAction).list.hasOwnProperty("items")
      ) {
        listOfItems["items"] = [];
      }
      return {
        ...state,
        isFetchingList: false,
        selectedList: listOfItems,
      };
    }

    case types.FETCH_LISTS_FOR_CLIENT_BEGIN:
      return Object.assign({}, state, {
        fetchingListsForClient: true,
        selectedList: null,
        isUpdatingList: false,
        isDeletingList: false,
        isArchivingList: false,
        isRestoringList: false,
        isCreatingList: false,
        isFetchingItems: false,
        isUpdatingItems: false,
        isArchivingItems: false,
        isRestoringItems: false,
        isDeletingItems: false,
        isCreatingItems: false,
      });

    case types.FETCH_LISTS_FOR_CLIENT_FAILURE: {
      return {
        ...state,
        fetchingListsForClient: false,
        errors: updateErrorsList(state, action),
      };
    }

    case types.FETCH_LISTS_FOR_CLIENT_SUCCESS: {
      let { lists } = action as IFetchListsForClientSuccessAction;
      const ids = extractIdsFromArray(lists);
      lists = lists.map((l: any) => {
        if (!l.item_count && l.items)
          l.item_count = l.items.filter(
            (i: any) => !i.hasOwnProperty("_actif") || i._actif
          ).length;
        // add query in the list object
        l.query = {};
        return l;
      });
      return {
        ...state,
        fetchingListsForClient: false,
        allLists: lists,
        byId: convertArrayToObject(state.byId, lists, "id"),
        allIds: uniq(state.allIds.concat(ids)),
        lastUpdated: moment().format(DATE_FORMAT_FULL_DATE),
      };
    }
    case types.FETCH_ITEM_ACTIVITY_BEGIN:
      return {
        ...state,
        isFetchingOneItemActivity: true,
      };
    case types.FETCH_ITEM_ACTIVITY_SUCCESS:
      return {
        ...state,
        isFetchingOneItemActivity: false,
      };
    case types.FETCH_ITEM_ACTIVITY_FAILURE:
      return {
        ...state,
        isFetchingOneItemActivity: false,
      };
    case types.FETCH_SUBMISSION_DETAIL_BEGIN:
      return {
        ...state,
        isFetchListItemSubmission: true,
      };
    case types.FETCH_SUBMISSION_DETAIL_FAILURE:
      return {
        ...state,
        isFetchListItemSubmission: false,
      };
    case types.FETCH_SUBMISSION_DETAIL_SUCCESS: {
      return {
        ...state,
        isFetchListItemSubmission: false,
      };
    }
    case types.UPDATE_LIST_BEGIN:
      return {
        ...state,
        isUpdatingList: true,
      };

    case types.UPDATE_LIST_FAILURE:
      return {
        ...state,
        isUpdatingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_LIST_SUCCESS: {
      const { list } = action as IUpdateListSuccessAction;
      return {
        ...state,
        isUpdatingList: false,
        allLists: updateObjectInArray(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
      };
    }

    case types.DELETE_LIST_BEGIN:
      return {
        ...state,
        isDeletingList: true,
      };

    case types.DELETE_LIST_FAILURE:
      return {
        ...state,
        isDeletingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.DELETE_LIST_SUCCESS: {
      const { id } = action as IDeleteListSuccessAction;

      return {
        ...state,
        isDeletingList: false,
        selectedList: null,
        allLists: removeObjectFromArray(state.allLists, id),
        byId: removeObjectFromHash(state.byId, id),
        allIds: state.allIds.filter((listId) => listId !== id),
      };
    }

    case types.ARCHIVE_LIST_BEGIN:
      return {
        ...state,
        isArchivingList: true,
      };

    case types.ARCHIVE_LIST_FAILURE:
      return {
        ...state,
        isArchivingList: false,
        errors: updateErrorsList(state, action),
      };

    case types.ARCHIVE_LIST_SUCCESS: {
      const { id } = action as IArchiveListSuccessAction;
      let list = state.byId[id];
      list.actif = false;

      return {
        ...state,
        isArchivingList: false,
        selectedList: undefined,
        allLists: updateObjectInArray(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
      };
    }

    case types.RESTORE_LIST_BEGIN:
      return {
        ...state,
        isRestoringList: true,
      };

    case types.RESTORE_LIST_FAILURE:
      return {
        ...state,
        isRestoringList: false,
        errors: updateErrorsList(state, action),
      };

    case types.RESTORE_LIST_SUCCESS: {
      const { id } = action as IRestoreListSuccessAction;
      let list = state.byId[id];
      list.actif = true;

      return {
        ...state,
        isRestoringList: false,
        allLists: updateObjectInArray(state.allLists, list),
        byId: {
          ...state.byId,
          [list.id]: list,
        },
      };
    }

    case types.CREATE_ITEMS_BEGIN:
      return {
        ...state,
        isCreatingItems: true,
      };

    case types.CREATE_ITEMS_SUCCESS: {
      const { listId, items, listSchema } = action as ICreateItemsSuccessAction;

      let updatedItems = state.byId[listId].items;
      if (!updatedItems) updatedItems = [];
      for (const item of items) {
        updatedItems.push(item);
      }
      const cleanedItems = prepareItemsForFrontend(updatedItems, listSchema);
      // update the list
      const list = {
        ...state.byId[listId],
        items: cleanedItems,
      };

      const selectedList = find(state.allLists, (list_) => {
        return list_.id === listId;
      })!;
      selectedList.items = cleanedItems;

      return {
        ...state,
        selectedList,
        isCreatingItems: false,
        allLists: updateObjectInArray(state.allLists, [list]),
        byId: {
          ...state.byId,
          [listId]: list,
        },
      };
    }

    case types.CREATE_ITEMS_FAILURE:
      return {
        ...state,
        isCreatingItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_ITEMS_BEGIN:
      return {
        ...state,
        isFetchingItems: true,
      };

    case types.FETCH_ITEMS_SUCCESS: {
      const {
        listId,
        items,
        offset,
        eraseExisting,
        listSchema,
        query,
      } = action as IFetchItemsForListSuccessAction;
      let cleanedItems = prepareItemsForFrontend(items, listSchema);
      const cleanedItemsIds = cleanedItems.map((item) => item._id) || [];

      if (eraseExisting) {
        //cleanItems will replace the existing items
      } else if (offset !== 0) {
        // if this fetch action is not the first one, we add the items in the existing items of the store
        cleanedItems = [...cleanedItems, ...state.byId[listId].items];
      } else {
        // ... otherwise we update the items fetched with the filter
        const itemsNotInArea = [
          ...(state.byId[listId].items.filter(
            (item) => !cleanedItemsIds.includes(item._id)
          ) || []),
        ];
        const excedent = itemsNotInArea.length + cleanedItemsIds.length - 1000;
        if (excedent > 0) {
          itemsNotInArea.splice(0, excedent);
        }
        cleanedItems = [...itemsNotInArea, ...cleanedItems];
      }

      const selectedList = find(
        state.allLists,
        (list_) => list_.id === listId
      )!;
      selectedList.items = cleanedItems;
      selectedList.partial_data = false;
      if (query) selectedList.query = query;

      return {
        ...state,
        selectedList,
        isFetchingItems: false,
        isFetchingOneItem: false,
        isFetchingOneItemActivity: false,
        isFetchListItemSubmission: false,
        allLists: updateObjectInArray(state.allLists, [selectedList]),
        byId: {
          ...state.byId,
          [listId]: selectedList,
        },
      };
    }

    case types.FETCH_ITEMS_FAILURE:
      return {
        ...state,
        isFetchingItems: false,
        isFetchingOneItem: false,
        errors: updateErrorsList(state, action),
      };

    case types.FETCH_ITEM_BEGIN:
      return {
        ...state,
        isFetchingOneItem: true,
      };
    case types.DELETE_ITEMS_BEGIN:
      return {
        ...state,
        isDeletingItems: true,
      };

    case types.DELETE_ITEMS_SUCCESS: {
      const { ids, listId } = action as IDeleteItemsSuccessAction;
      // if the items are not loaded locally, return the store as it is
      if (!state.selectedList) {
        return {
          ...state,
          isDeletingItems: false,
        };
      }
      const list = state.byId[listId];
      const newItemArray = list.items.filter((item) => !ids.includes(item._id));

      const selectedList = { ...state.selectedList! };
      // update the items of the list
      selectedList.items = newItemArray;

      return {
        ...state,
        isDeletingItems: false,
        allLists: updateObjectInArray(state.allLists, {
          ...selectedList,
        }),
        selectedList,
        byId: {
          ...state.byId,
          [selectedList!.id]: selectedList,
        },
      };
    }

    case types.DELETE_ITEMS_FAILURE:
      return {
        ...state,
        isDeletingItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPDATE_ITEMS_BEGIN:
      return {
        ...state,
        isUpdatingItems: true,
      };
    // TODO:  ADD CASE for UPDATE_ITEM_SUCCESS
    case types.UPDATE_ITEMS_SUCCESS: {
      const { listId, items } = action as IUpdateItemsSuccessAction;
      // find the selected list
      let selectedList = find(state.allLists, (list_) => {
        return list_.id === listId;
      })!;
      // if the items are not loaded locally, return the store as it is
      if (!state.selectedList) {
        return {
          ...state,
          isUpdatingItems: false,
        };
      }
      // update the items of the list
      const itemsInSelectedList = selectedList.items.map((i) => {
        const newProps = (items as IListItem[]).filter(
          (it) => it._id === i._id
        )[0];
        if (newProps) {
          return {
            ...i,
            ...newProps,
          };
        } else {
          return i;
        }
      });
      selectedList.items = prepareItemsForFrontend(
        itemsInSelectedList,
        selectedList.schema
      );

      return {
        ...state,
        isUpdatingItems: false,
        selectedList,
        allLists: updateObjectInArray(state.allLists, {
          ...selectedList,
        }),
        byId: {
          ...state.byId,
          [selectedList.id]: selectedList,
        },
      };
    }
    case types.UPDATE_ITEMS_FAILURE:
      return {
        ...state,
        isUpdatingItems: false,
        errors: updateErrorsList(state, action),
      };
    case types.ARCHIVE_ITEMS_BEGIN:
      return {
        ...state,
        isArchivingItems: true,
      };
    case types.ARCHIVE_ITEMS_FAILURE:
      return {
        ...state,
        isArchivingItems: false,
        errors: updateErrorsList(state, action),
      };
    case types.ARCHIVE_ITEMS_SUCCESS: {
      const { ids } = action as IArchiveItemsSuccessAction;
      // if the items are not loaded locally, return the store as it is
      if (!state.selectedList) {
        return {
          ...state,
          isArchivingItems: false,
        };
      }
      // find the full items
      let fullItems = state.selectedList!.items.filter((item) =>
        ids.includes(item._id)
      );
      fullItems = fullItems.map((it) => {
        it._actif = false;
        return it;
      });

      const selectedList = { ...state.selectedList! };
      // update the items of the list
      selectedList.items = selectedList.items.filter((obj) => {
        return !ids.includes(obj._id);
      });
      selectedList.items = selectedList.items.slice().concat(fullItems);

      return {
        ...state,
        isArchivingItems: false,
        selectedList,
        allLists: updateObjectInArray(state.allLists, {
          ...selectedList,
        }),
        byId: {
          ...state.byId,
          [selectedList.id]: selectedList,
        },
      };
    }

    case types.RESTORE_ITEMS_BEGIN:
      return {
        ...state,
        isRestoringItems: true,
      };

    case types.RESTORE_ITEMS_FAILURE:
      return {
        ...state,
        isRestoringItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.RESTORE_ITEMS_SUCCESS: {
      const { ids } = action as IRestoreItemsSuccessAction;
      // if the items are not loaded locally, return the store as it is
      if (!state.selectedList) {
        return {
          ...state,
          isRestoringItems: false,
        };
      }
      // find the full items
      let fullItems = state.selectedList!.items.filter((item) =>
        ids.includes(item._id)
      );
      fullItems = fullItems.map((it) => {
        it._actif = true;
        return it;
      });
      const selectedList = { ...state.selectedList! };
      // update the items of the list
      selectedList.items = selectedList.items.filter((obj) => {
        return !ids.includes(obj._id);
      });
      selectedList.items = selectedList.items.slice().concat(fullItems);

      return {
        ...state,
        isRestoringItems: false,
        selectedList,
        allLists: updateObjectInArray(state.allLists, {
          ...selectedList,
        }),
        byId: {
          ...state.byId,
          [selectedList.id]: selectedList,
        },
      };
    }

    case types.ASSIGN_ITEMS_BEGIN:
      return {
        ...state,
        isAssigningItems: true,
      };

    case types.UNASSIGN_ITEMS_BEGIN:
      return {
        ...state,
        isUnassigningItems: true,
      };

    case types.UNASSIGN_ITEMS_FAILURE:
      return {
        ...state,
        isUnassigningItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.ASSIGN_ITEMS_FAILURE:
      return {
        ...state,
        isAssigningItems: false,
        errors: updateErrorsList(state, action),
      };

    case types.UNASSIGN_ITEMS_SUCCESS: {
      const { links } = action as IUnassignItemsSuccessAction;
      // if the items are not loaded locally, return the store as it is
      if (!state.selectedList) {
        return {
          ...state,
          isUnassigningItems: false,
        };
      }
      // find the full items
      let fullItems = state.selectedList.items.map((item) => {
        if (links.includes(item._id)) {
          item._owners = [];
        }
        return item;
      });
      const selectedList = { ...state.selectedList! };
      selectedList.items = fullItems;

      return {
        ...state,
        isUnassigningItems: false,
        selectedList,
        allLists: updateObjectInArray(state.allLists, selectedList),
        byId: {
          ...state.byId,
          [selectedList.id]: selectedList,
        },
      };
    }

    case types.ASSIGN_ITEMS_SUCCESS: {
      const { links } = action as IAssignItemsSuccessAction;
      // if the items are not loaded locally, return the store as it is
      if (!state.selectedList) {
        return {
          ...state,
          isAssigningItems: false,
        };
      }
      // find the full items
      let fullItems = state.selectedList!.items.map((item) => {
        const owners = (links as { item_id: string; team_id: string }[])
          .filter((l) => l.item_id === item._id)
          .map((l) => l.team_id);
        if (owners.length > 0) {
          return {
            ...item,
            _owners: owners,
          };
        } else return item;
      });
      const selectedList = { ...state.selectedList! };
      selectedList.items = selectedList.items = fullItems;

      return {
        ...state,
        isAssigningItems: false,
        selectedList,
        allLists: updateObjectInArray(state.allLists, {
          ...selectedList,
        }),
        byId: {
          ...state.byId,
          [selectedList.id]: selectedList,
        },
      };
    }

    case types.SELECT_LIST: {
      const { selectedList } = action as ISelectListActionCreator;
      return {
        ...state,
        selectedList,
      };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
      };

    case types.UPLOAD_FILE_BEGIN:
      return {
        ...state,
        isUploadingFile: true,
      };

    case types.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploadingFile: false,
        errors: updateErrorsList(state, action),
      };

    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isUploadingFile: false,
      };
    }

    case types.DOWNLOAD_LIST_ITEM_BEGIN:
      return {
        ...state,
        isDownloadingFile: true,
      };

    case types.DOWNLOAD_LIST_ITEM_FAILURE:
      return {
        ...state,
        isDownloadingFile: false,
        errors: updateErrorsList(state, action),
      };

    case types.DOWNLOAD_LIST_ITEM_SUCCESS: {
      return {
        ...state,
        isDownloadingFile: false,
      };
    }

    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as IChangeSubcategorySuccessActionCreator;
      return {
        ...state,
        subCategorySelected: subcategory,
      };
    }

    default:
      return state;
  }
}
