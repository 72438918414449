import { IClientsStates } from "./reducer";
import { IClient } from "model/entities/Client";

const initialState: IClientsStates = {
  allClients: [],
  allIds: [],
  byId: {},
  errors: [],
  recentlyFetchedClient: undefined,
  selectedClient: {} as IClient,
  isFetchingClient: false,
  isFetchingAllClients: false,
  isUpdatingClient: false,
  isDeletingClient: false,
  isReplicatingClient: false,
  isCreatingClient: false,
  isArchivingClient: false,
  isRestoringClient: false,
  lastUpdated: undefined,
  invalidateCache: false,
  owners: [],
};

export default initialState;
