/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import apiService from "../../../../api/api";
import { CLIENT_ENDPOINT } from "../../../clients/redux/endpoints";
import { prepareEmailGenerationForBackend } from "./utils";
import { IEmailGeneration } from "model/entities/Dashboard";
import { IServerResponse } from "actions/appActions";
import { IEmailGeneration as IBEEmailGeneration } from "smala-tools/dist/src/types";

export const EMAILGENERATIONS_ENDPOINT = `emailgenerations`; // EMAILGENERATIONS

export type TCreateEmailGenerationFunc = (
  emailGeneration: IEmailGeneration,
  client_id: string
) => Promise<IServerResponse<ICreateEmailGenerationResponse>>;
export interface ICreateEmailGenerationResponse {
  id: string;
}

export type TUpdateEmailGenerationFunc = (
  emailGeneration: IEmailGeneration,
  client_id: string
) => Promise<IServerResponse<IUpdateEmailGenerationResponse>>;
export interface IUpdateEmailGenerationResponse {}

export type TDeleteEmailGenerationFunc = (
  emailGeneration_id: string,
  client_id: string
) => Promise<IServerResponse<IDeleteEmailGenerationResponse>>;
export interface IDeleteEmailGenerationResponse {}

export type TFetchEmailGenerationsFunc = (
  client_id: string
) => Promise<IServerResponse<IFetchEmailGenerationsResponse>>;
export interface IFetchEmailGenerationsResponse {
  email_generations: IBEEmailGeneration[];
}

//api calls for emailGenerations
/**
 * Creates a new emailGeneration
 * @param {Object} emailGeneration EmailGeneration details
 * @param {string} client_id clientId
 */
export const createEmailGenerationApiCall: TCreateEmailGenerationFunc = (
  emailGeneration,
  client_id
) => {
  const payload = prepareEmailGenerationForBackend(emailGeneration);
  return apiService.post(
    `${CLIENT_ENDPOINT}/${client_id}/${EMAILGENERATIONS_ENDPOINT}`,
    payload
  );
};

/**
 * Update a targetted emailGeneration
 * @param {Object} emailGeneration Detail of the new emailGeneration
 * @param {string} client_id clientId
 */
export const updateEmailGenerationApiCall: TUpdateEmailGenerationFunc = (
  emailGeneration,
  client_id
) => {
  const payload = prepareEmailGenerationForBackend(emailGeneration, false);
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${client_id}/${EMAILGENERATIONS_ENDPOINT}/${emailGeneration.id}`,
    payload
  );
};

/**
 * Delete emailGeneration API call makes a POST request to delete a single emailGeneration
 * @param {String} emailGeneration Id of the emailGeneration to delete
 * @param {string} client_id clientId
 * @return {Promise}
 * */
export const deleteEmailGenerationApiCall: TDeleteEmailGenerationFunc = (
  emailGeneration_id,
  client_id
) => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${client_id}/${EMAILGENERATIONS_ENDPOINT}/${emailGeneration_id}`,
    {}
  );
};

/**
 * Api call to fetch all
 */
export const fetchEmailGenerationsApiCall: TFetchEmailGenerationsFunc = (
  client_id
) => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${client_id}/${EMAILGENERATIONS_ENDPOINT}`
  );
};
