/**
 * @notes: Route constants
 */

export const BASE_ROUTE = "/admin";
export const LOGIN_ROUTE = "/login";
export const HOME_ROUTE = `/home`;
export const DASHBOARD_ROUTE = `/dashboard`;
export const USERS_ROUTE = `/users`;
export const TEAMS_ROUTE = `/teams`;
export const LICENSES_ROUTE = `/licenses`;
export const CLIENTS_ROUTE = `/clients`;
export const WORKFLOWS_ROUTE = `/workflows`;
export const LISTS_ROUTE = `/lists`;
export const PLACES_ROUTE = `/places`;
export const PICTURES_ROUTE = "/pictures";
export const DOCUMENTS_ROUTE = "/documents";
export const ENVIRONMENT_ROUTE = `/environment`;
