import React from "react";
import { withSnackbar } from "notistack";
import { shape, number, string, object, bool, func } from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { CustomDialogWarning } from "../Dialog/CustomDialogWarning";

export const NotificationItem = (props) => {
  const {
    notification,
    closeSnackbar,
    onShowDetail,
    open,
    enqueueSnackbar,
    ...rest
  } = props;
  const isLoading = notification.level === "loading";

  let action = (key) => [
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon />
    </IconButton>,
  ];

  if (notification.detailedMessage) {
    action = (key) => [
      <IconButton
        key="more"
        aria-label="more"
        color="inherit"
        onClick={() => onShowDetail(notification.detailedMessage)}
      >
        <InfoIcon />
      </IconButton>,
      <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon />
      </IconButton>,
    ];
  }

  const options = {
    ...rest,
    action,
    open,
    preventDuplicate: isLoading,
    variant: !isLoading ? notification.level : "default",
  };

  const addToQueue = () => {
    enqueueSnackbar(notification.message, options);
    return null;
  };
  return notification ? addToQueue() : null;
};

NotificationItem.defaultProps = {
  anchorOrigin: { vertical: "top", horizontal: "right" },
};

NotificationItem.propTypes = {
  closeSnackbar: func,
  onShowDetail: func,
  notification: shape({
    message: string,
    level: string,
  }),
  anchorOrigin: object,
};

export class SmalaNotistack extends React.Component {
  state = {
    open: true,
    errorMessage: "",
    isDisplayingDetail: false,
  };

  handleClose = (key) => {
    const { onClose, closeSnackbar } = this.props;
    closeSnackbar(key);
    onClose();
  };

  render() {
    const { open, errorMessage, isDisplayingDetail } = this.state;
    const { notification, lang, ...rest } = this.props;
    return (
      <div>
        <CustomDialogWarning
          singleButton={true}
          confirmBtnText={lang.modal.proceed}
          isCriticalWarning={false}
          title={lang.notifications.errorNotifications.errorDetailModal.title}
          isOpen={isDisplayingDetail}
          onClose={() => this.setState({ isDisplayingDetail: false })}
          rootLang={lang}
          lang={{
            title: notification.message,
            description: errorMessage,
          }}
        />
        {isDisplayingDetail ? null : (
          <NotificationItem
            {...rest}
            closeSnackbar={this.handleClose}
            onShowDetail={(errorMessage) =>
              this.setState({
                isDisplayingDetail: true,
                errorMessage,
              })
            }
            notification={notification}
            open={open}
          />
        )}
      </div>
    );
  }
}

SmalaNotistack.defaultProps = {
  maxSnack: 3,
  hideIconVariant: false,
  onClose: () => {},
};

SmalaNotistack.propTypes = {
  ...NotificationItem.propTypes.notification,
  maxSnack: number,
  lang: object,
  anchorOrigin: object,
  hideIconVariant: bool,
  onClose: func,
};

export default withSnackbar(SmalaNotistack);
