import { ITransformationState } from "./reducer";

const initialState: ITransformationState = {
  isFetchingAll: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  isComputing: false,
  selectedTransformation: null,
  transformations: [],
  errors: [],
};

export default initialState;
