import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { bindActionCreators, Dispatch } from "redux";
import {
  allNotificationsSelector,
  recentNotificationSelector,
} from "./selectors";
import { getIsFirstLoginSelector } from "../../selectors/appSelector";
import { firstAppLoginAction } from "../../actionCreators/appActionCreator";
import SmalaNotistack from "../../components/Snackbars/SmalaNotistack";
import IRootState from "store/model";
import TLang from "model/application/Lang";

interface INotificationsContainerProps {
  allNotifications: any[];
  recentNotification: any;
  isFirstLogin: boolean;
  lang: TLang;
}
interface INotificationsContainerState {
  allNotifications: any[];
  recentNotification: any;
  isFirstLogin: boolean;
}

export class NotificationsContainer extends Component<
  INotificationsContainerProps,
  INotificationsContainerState
> {
  constructor(props: INotificationsContainerProps) {
    super(props);
    this.state = {
      allNotifications: [],
      recentNotification: null,
      isFirstLogin: false,
    };
  }

  /**
   * Make any necessary updates when we receive props. These props will ideally come from the Redux Store
   * @param {Object} nextProps Next Properties received from the Redux Store state using the mapStateToProps function
   * @param {Object} prevState Previous State of the component
   * */
  static getDerivedStateFromProps(
    nextProps: INotificationsContainerProps,
    prevState: INotificationsContainerState
  ) {
    const { recentNotification, allNotifications, isFirstLogin } = nextProps;
    if (
      allNotifications !== prevState.allNotifications ||
      recentNotification !== prevState.recentNotification
    ) {
      return {
        allNotifications,
        recentNotification,
        isFirstLogin,
      };
    } else {
      return null;
    }
  }

  render() {
    const { lang } = this.props;
    const { recentNotification } = this.state;

    return (
      !isEmpty(recentNotification) && (
        <SmalaNotistack lang={lang} notification={recentNotification} />
      )
    );
  }
}

/**
 * Maps state of redux store to props in this component
 * @param {Object} state Redux store state
 */
function mapStateToProps(state: IRootState) {
  return {
    allNotifications: allNotificationsSelector(state),
    recentNotification: recentNotificationSelector(state),
    isFirstLogin: getIsFirstLoginSelector(state),
  };
}

/**
 * Maps the actions to props to be used in this container component
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    firstAppLogin: bindActionCreators(firstAppLoginAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);
