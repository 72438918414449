import React, { createContext } from "react";
import { oneOfType, array, node, element, func, object } from "prop-types";
import * as appActions from "actions/appActions";

/**
 * Signed in User context, default value is the user object expected from the Redux Store
 */
export const UserContext = createContext({
  id: "",
  name: "",
  email: "",
  isAuthenticated: false,
  isSaAdmin: false,
  isAdmin: false,
  roles: [],
  created_at: "",
});

/**
 * Client context. This will be used in the case when switching client contexts
 */
export const ClientContext = createContext({
  description: "",
  id: "",
  name: "",
  go_back_days_filter: 180,
});

export const LangContext = createContext({});

export const WarningModalContext = createContext({
  openWarningModal: appActions.openWarningModal,
  closeWarningModal: appActions.closeWarningModal,
});

/**
 * AppProviders, These will be used to pass down props through context from the various Providers in the application
 * @param {Object} props destructured props
 */
export const AppProviders = ({
  children,
  client,
  user,
  warningModal,
  lang,
}) => {
  // TODO: dirty fix to avoid crash. Would be better to solve this problem otherwise
  if (!client) {
    client = { name: "clientName", description: "clientDescription" };
  }
  return (
    <UserContext.Provider value={user}>
      <ClientContext.Provider value={client}>
        <LangContext.Provider value={lang}>
          <WarningModalContext.Provider value={warningModal}>
            {children}
          </WarningModalContext.Provider>
        </LangContext.Provider>
      </ClientContext.Provider>
    </UserContext.Provider>
  );
};

/**
 * Application consumer
 */
export const AppConsumer = ({ children }) => {
  return (
    <UserContext.Consumer>
      {(user) => (
        <WarningModalContext.Consumer>
          {(warningModal) => (
            <LangContext.Consumer>
              {(lang) => (
                <ClientContext.Consumer>
                  {(client) => children(user, client, lang, warningModal)}
                </ClientContext.Consumer>
              )}
            </LangContext.Consumer>
          )}
        </WarningModalContext.Consumer>
      )}
    </UserContext.Consumer>
  );
};

AppProviders.propTypes = {
  children: oneOfType([array, node, element]),
  user: object,
  client: object,
  warningModal: object,
  lang: object,
};

AppConsumer.propTypes = {
  children: func,
};
