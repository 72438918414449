import Loadable from "react-loadable";
import ContentLoader from "components/Progress/ContentLoader";

// Reference : https://github.com/jamiebuilds/react-loadable
/**
 * Home
 */
export const HomePage = Loadable({
  loader: () => import("../home/HomeContainer"),
  loading: ContentLoader,
});

/**
 * Dashboards Loadable Component
 */
export const DashboardPage = Loadable({
  loader: () => import("../dashboards/DashboardContainer"),
  loading: ContentLoader,
});

/**
 *
 * */
export const LoginPage = Loadable({
  loader: () => import("../authentication/LoginContainer"),
  loading: ContentLoader,
});

/**
 *
 * */
export const UserMgtPage = Loadable({
  loader: () => import("../usermgt/UserMgtContainer"),
  loading: ContentLoader,
});

/**
 *
 * */
export const ClientsPage = Loadable({
  loader: () => import("../clients/ClientsContainer"),
  loading: ContentLoader,
});

/**
 *
 * */
export const TeamsPage = Loadable({
  loader: () => import("../teams/TeamsContainer"),
  loading: ContentLoader,
});

/**
 *
 * */
export const WorkflowsPage = Loadable({
  loader: () => import("../workflows/WorkflowsContainer"),
  loading: ContentLoader,
});

/**
 *
 * */
export const ListsPage = Loadable({
  loader: () => import("../lists/ListsContainer"),
  loading: ContentLoader,
});

/**
 *
 * */
export const PlacesPage = Loadable({
  loader: () => import("../places/PlacesContainer"),
  loading: ContentLoader,
});

export const PicturesPage = Loadable({
  loader: () => import("../pictures/PicturesContainer"),
  loading: ContentLoader,
});
export const DocumentsPage = Loadable({
  loader: () => import("../documents/DocumentsContainer"),
  loading: ContentLoader,
});
/**
 * Environments Loadable Component
 */
export const EnvironmentPage = Loadable({
  loader: () => import("../environments/EnvironmentContainer"),
  loading: ContentLoader,
});
