/**
 * @notes: EmailGeneration action types
 */
export const CREATE_EMAILGENERATION_BEGIN = "CREATE_EMAILGENERATION_BEGIN";
export const CREATE_EMAILGENERATION_SUCCESS = "CREATE_EMAILGENERATION_SUCCESS";
export const CREATE_EMAILGENERATION_FAILURE = "CREATE_EMAILGENERATION_FAILURE";

export const UPDATE_EMAILGENERATION_BEGIN = "UPDATE_EMAILGENERATION_BEGIN";
export const UPDATE_EMAILGENERATION_SUCCESS = "UPDATE_EMAILGENERATION_SUCCESS";
export const UPDATE_EMAILGENERATION_FAILURE = "UPDATE_EMAILGENERATION_FAILURE";

export const FETCH_ALL_EMAILGENERATIONS_BEGIN =
  "FETCH_ALL_EMAILGENERATIONS_BEGIN";
export const FETCH_ALL_EMAILGENERATIONS_SUCCESS =
  "FETCH_ALL_EMAILGENERATIONS_SUCCESS";
export const FETCH_ALL_EMAILGENERATIONS_FAILURE =
  "FETCH_ALL_EMAILGENERATIONS_FAILURE";

export const DELETE_EMAILGENERATION_BEGIN = "DELETE_EMAILGENERATION_BEGIN";
export const DELETE_EMAILGENERATION_SUCCESS = "DELETE_EMAILGENERATION_SUCCESS";
export const DELETE_EMAILGENERATION_FAILURE = "DELETE_EMAILGENERATION_FAILURE";
