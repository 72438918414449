/**
 * @notes: Action Types for CLIENTS management
 */

export const CREATE_CLIENT_BEGIN = "CREATE_CLIENTS_BEGIN";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENTS_SUCCESS";
export const CREATE_CLIENT_FAILURE = "CREATE_CLIENTS_FAILURE";

export const FETCH_CLIENT_BEGIN = "FETCH_CLIENTS_BEGIN";
export const FETCH_ALL_CLIENTS_BEGIN = "FETCH_ALL_CLIENTS_BEGIN";

export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_ALL_CLIENTS_SUCCESS = "FETCH_ALL_CLIENTS_SUCCESS";

export const FETCH_CLIENT_FAILURE = "FETCH_CLIENTS_FAILURE";
export const FETCH_ALL_CLIENTS_FAILURE = "FETCH_ALL_CLIENTS_FAILURE";

export const UPDATE_CLIENT_BEGIN = "UPDATE_CLIENTS_BEGIN";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENTS_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENTS_FAILURE";

export const SET_CLIENT_LIVE_BEGIN = "SET_CLIENTS_LIVE_BEGIN";
export const SET_CLIENT_LIVE_SUCCESS = "SET_CLIENTS_LIVE_SUCCESS";
export const SET_CLIENT_LIVE_FAILURE = "SET_CLIENTS_LIVE_FAILURE";

export const DELETE_CLIENT_BEGIN = "DELETE_CLIENTS_BEGIN";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENTS_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENTS_FAILURE";

export const FETCH_TEAMS_BY_CLIENT_BEGIN = "FETCH_TEAMS_BY_CLIENTS_BEGIN";
export const FETCH_TEAMS_BY_CLIENT_SUCCESS = "FETCH_TEAMS_BY_CLIENTS_SUCCESS";
export const FETCH_TEAMS_BY_CLIENT_FAILURE = "FETCH_TEAMS_BY_CLIENTS_FAILURE";

export const FETCH_TRANSFORMATIONS_BY_CLIENT_BEGIN =
  "FETCH_TRANSFORMATIONS_BY_CLIENTS_BEGIN";
export const FETCH_TRANSFORMATIONS_BY_CLIENT_SUCCESS =
  "FETCH_TRANSFORMATIONS_BY_CLIENTS_SUCCESS";
export const FETCH_TRANSFORMATIONS_BY_CLIENT_FAILURE =
  "FETCH_TRANSFORMATIONS_BY_CLIENTS_FAILURE";

export const ARCHIVE_CLIENT_BEGIN = "ARCHIVE_CLIENT_BEGIN";
export const ARCHIVE_CLIENT_SUCCESS = "ARCHIVE_CLIENT_SUCCESS";
export const ARCHIVE_CLIENT_FAILURE = "ARCHIVE_CLIENT_FAILURE";

export const RESTORE_CLIENT_BEGIN = "RESTORE_CLIENT_BEGIN";
export const RESTORE_CLIENT_SUCCESS = "RESTORE_CLIENT_SUCCESS";
export const RESTORE_CLIENT_FAILURE = "RESTORE_CLIENT_FAILURE";

export const UPLOAD_FILE_BEGIN = "UPLOAD_FILE_BEGIN";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const FETCH_STRIPE_SESSION_BEGIN = "FETCH_STRIPE_SESSION_BEGIN";
export const FETCH_STRIPE_SESSION_SUCCESS = "FETCH_STRIPE_SESSION_SUCCESS";
export const FETCH_STRIPE_SESSION_FAILURE = "FETCH_STRIPE_SESSION_FAILURE";

export const REPLICATE_CLIENT_BEGIN = "REPLICATE_CLIENTS_BEGIN";
export const REPLICATE_CLIENT_SUCCESS = "REPLICATE_CLIENTS_SUCCESS";
export const REPLICATE_CLIENT_FAILURE = "REPLICATE_CLIENTS_FAILURE";

export const FETCH_ADDITIONAL_INFOS_BEGIN = "FETCH_ADDITIONAL_INFOS_BEGIN";
export const FETCH_ADDITIONAL_INFOS_SUCCESS = "FETCH_ADDITIONAL_INFOS_SUCCESS";
export const FETCH_ADDITIONAL_INFOS_FAILURE = "FETCH_ADDITIONAL_INFOS_FAILURE";

// select CLIENTS action type for selecting a CLIENTS from CLIENTSs Table
export const SELECT_CLIENT = "SELECT_CLIENT";

export { LOGOUT_REQUEST_SUCCESS } from "../../authentication/redux/actionTypes";
