import { TValidateFunction } from "components/Dialog/CustomDialogCreateEdit";
import { IChangePasswordModalState } from "./ChangePasswordModal";

export const getErrorMessagesForPassword: TValidateFunction<IChangePasswordModalState> = ({
  attributes,
  lang,
}) => {
  const { oldPassword, newPassword, confirmPassword } = attributes;
  const result: any = {};
  if (!oldPassword || attributes.oldPassword.length === 0)
    result["oldPassword"] = lang.components.inputErrors.empty;
  if (
    !newPassword ||
    !new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[1-9]).{8,}$").test(
      attributes.newPassword
    )
  )
    result["newPassword"] = lang.components.inputErrors.empty;
  if (!confirmPassword || attributes.confirmPassword !== attributes.newPassword)
    result["confirmPassword"] = lang.components.inputErrors.empty;
  if (Object.keys(result).length === 0) return {};
  return result;
};
