import ITable from "model/entities/Database";
import { customSelectorCreator } from "selectors/appSelector";
import IRootState from "store/model";

/**
 * Gets the state of fetching tables for a given client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 * */
export const getIsFetchingTablesForClient = (state: IRootState) =>
  state.environments.dbSchema.isFetchingTablesForClient;

/**
 * Get all tables from redux store
 * @param {Object} state
 * @returns {Array} Array of group objects
 */
export const getDbSchema = (state: IRootState) =>
  state.environments.dbSchema.allTables;

/**
 * Selector to get all Tables from redux store
 * @param {Object} state Redux store state
 * @returns {Array}
 */
export const allTablesSelector = customSelectorCreator(
  [getDbSchema],
  (allTables: ITable[]) => {
    // map all the tables
    return allTables;
  }
);
