/**
 * @notes: Api wrapper to handle calls to access dashboard data
 */
import apiService from "../../../api/api";
import { CLIENT_ENDPOINT } from "../../clients/redux/endpoints";
import { prepareDashboardForBackend } from "./utils";
import { IDashboard } from "model/entities/Dashboard";
import { IServerResponse } from "actions/appActions";
import { IDashboard as IBEDashboard } from "smala-tools/dist/src/types/dashboards";
import { getBackendQuery } from "components/Filter/Filter.utils";
import { IMetaHierarchyDependency } from "model/entities/HierarchyDependency";

export const DASHBOARDS_ENDPOINT = `dashboards`;

export type CreateDashboardFunc = (
  dashboard: IDashboard,
  client_id: string
) => Promise<IServerResponse<ICreateDashboardResponse>>;
export interface ICreateDashboardResponse {
  dashboard_id: string;
  kpis: { id: string; iid: string }[];
}

export type UpdateDashboardFunc = (
  dashboard: IDashboard,
  client_id: string
) => Promise<IServerResponse<IUpdateDashboardResponse>>;
export interface IUpdateDashboardResponse {
  kpis: { id: string; iid: string }[];
}

export type DeleteDashboardFunc = (
  dashboard_id: string,
  client_id: string
) => Promise<IServerResponse<IDeleteDashboardResponse>>;
export interface IDeleteDashboardResponse {}

export type ArchiveDashboardsFunc = (
  ids: string[],
  client_id: string
) => Promise<IServerResponse<IArchiveDashboardsResponse>>;
export interface IArchiveDashboardsResponse {}

export type RestoreDashboardsFunc = (
  ids: string[],
  client_id: string
) => Promise<IServerResponse<IRestoreDashboardsResponse>>;
export interface IRestoreDashboardsResponse {}

export type FetchDashboardsFunc = (
  client_id: string
) => Promise<IServerResponse<IFetchDashboardsResponse>>;
export interface IFetchDashboardsResponse {
  dashboards: IBEDashboard[];
}

// FIXME: change the "any" type for query to a more specific one
export type ComputeDashboardFunc = (
  client_id: string,
  dashboard_id: string,
  query: any,
  metaHierarchy: IMetaHierarchyDependency,
  degradedModeKpis?: string[]
) => Promise<IServerResponse<IComputeDashboardResponse>>;
export interface IComputeDashboardResponse {
  kpis: { id: string; data: string | any[] | { error: any } }[]; // FIXME: change the "any" type for query to a more specific one
}

/**
 * Creates a new dashboard
 * @param {Object} dashboard Dashboard details
 * @param {String} client_id Client id
 */
export const createDashboardApiCall: CreateDashboardFunc = (
  dashboard,
  client_id
) => {
  const payload = prepareDashboardForBackend(dashboard);
  return apiService.post(
    `${CLIENT_ENDPOINT}/${client_id}/${DASHBOARDS_ENDPOINT}`,
    payload
  );
};

/**
 * Update a targetted dashboard
 * @param {Object} dashboard Detail of the new dashboard
 * @param {String} client_id Client id
 */
export const updateDashboardApiCall: UpdateDashboardFunc = (
  dashboard,
  client_id
) => {
  const payload = prepareDashboardForBackend(dashboard);
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${client_id}/${DASHBOARDS_ENDPOINT}/${dashboard.id}`,
    payload
  );
};

/**
 * Delete dashboard API call makes a POST request to delete a single dashboard
 * @param {String} dashboard_id Id of the dashboard to delete
 * @param {String} clientId Id of the client concerned
 * @return {Promise}
 * */
export const deleteDashboardApiCall: DeleteDashboardFunc = (
  dashboard_id,
  clientId
) => {
  return apiService.delete(
    `${CLIENT_ENDPOINT}/${clientId}/${DASHBOARDS_ENDPOINT}/${dashboard_id}`,
    {}
  );
};

/**
 * Archive dashboard API call makes a POST request to archive dashboards
 * @param {String} ids Ids of the dashboards to archive
 * @param {String} clientId Id of the client concerned
 * @return {Promise}
 * */
export const archiveDashboardsApiCall: ArchiveDashboardsFunc = (
  ids,
  clientId
) => {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${DASHBOARDS_ENDPOINT}/archive`,
    {
      ids,
    }
  );
};

/**
 * Restore dashboard API call makes a POST request to restore dashboards
 * @param {String} ids Ids of the dashboards to restore
 * @param {String} clientId Id of the client concerned
 * @return {Promise}
 * */
export const restoreDashboardsApiCall: RestoreDashboardsFunc = (
  ids,
  clientId
) => {
  return apiService.patch(
    `${CLIENT_ENDPOINT}/${clientId}/${DASHBOARDS_ENDPOINT}/restore`,
    {
      ids,
    }
  );
};

/**
 * Api call to fetch all ${DASHBOARDS_ENDPOINT}
 * @param {String} client_id Client id
 */
export const fetchDashboardsApiCall: FetchDashboardsFunc = (client_id) => {
  return apiService.get(
    `${CLIENT_ENDPOINT}/${client_id}/${DASHBOARDS_ENDPOINT}`
  );
};

/**
 * Compute dashboard with its parameters
 * @param {String} client_id Client id
 * @param {String} dashboard_id Dashboard id
 * @param {Object} query Query object, containing typically "start_date", "end_date", "teams", "users"
 * @param {Array} degradedModeKpis List of kpis to send by email (optionnal)
 * @returns {Promise}
 */
export const computeDashboardsApiCall: ComputeDashboardFunc = (
  client_id,
  dashboard_id,
  query,
  metaHierarchy,
  degradedModeKpis
) => {
  const body = getBackendQuery(query, metaHierarchy, dashboard_id);
  if (degradedModeKpis) body["degraded_mode_kpis"] = degradedModeKpis;
  return apiService.post(
    `${CLIENT_ENDPOINT}/${client_id}/${DASHBOARDS_ENDPOINT}/${dashboard_id}`,
    body,
    true
  );
};
