import { createSelector } from "reselect";
import capitalize from "lodash/capitalize";
import { customSelectorCreator } from "../../../selectors/appSelector";
import IRootState from "store/model";
import { IClient } from "model/entities/Client";

/**
 * Get the state of fetching all deploymensts
 * @param {Object} state Redux Store State
 * @returns {Boolean} value whether the application is fetching clients
 */
export const getIsFetchingAllClients = (state: IRootState) =>
  state.clients.isFetchingAllClients;

/**
 * Gets the state of fetching a single client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingClient = (state: IRootState) =>
  state.clients.isFetchingClient;

/**
 * Gets the state of fetching business objects
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsFetchingEntities = (state: IRootState) =>
  state.clients.isFetchingClient ||
  state.clients.isFetchingAllClients ||
  state.dashboards.isFetchingAll ||
  state.lists.fetchingListsForClient ||
  state.teams.isFetchingTeamsForClient ||
  state.users.mobileUsers.fetchingAllUsers ||
  state.users.webUsers.fetchingAllUsers ||
  state.workflows.isFetchingAll;

/**
 * Gets the state of creating a client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsCreatingClient = (state: IRootState) =>
  state.clients.isCreatingClient;

/**
 * Gets the state of updating a client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsUpdatingClient = (state: IRootState) => {
  return state.clients.isUpdatingClient;
};

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsDeletingClient = (state: IRootState) =>
  state.clients.isDeletingClient;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsArchivingClient = (state: IRootState) =>
  state.clients.isArchivingClient;

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsRestoringClient = (state: IRootState) =>
  state.clients.isRestoringClient;

/**
 * Gets the state of invalidation of cache for clients
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsClientCacheInvalidated = (state: IRootState) =>
  state.clients.invalidateCache;

/**
 * Gets the clients from Redux Store
 * @param {Object} state Redux Store state for Clients
 * @returns {Array} All clients
 */
const getAllClients = (state: IRootState) => state.clients.allClients;

/**
 * Gets the currently selected Client
 * @param {Object} state Redux store state
 * @returns {Object}
 */
export const getSelectedClient = (state: IRootState) =>
  state.clients.selectedClient;

/**
 * Gets the state of replicating a client
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsReplicatingClient = (state: IRootState) =>
  state.clients.isReplicatingClient;

/**
 * Gets all the clients as an object
 * @param {Object} state Redux Store state
 * @returns {Object} Clients by Id object
 */
const getClientsById = (state: IRootState) => state.clients.byId;

/**
 * Clients By Id selector. Gets the clients by Id object
 */
export const clientsByIdSelector = customSelectorCreator(
  getClientsById,
  (byId: { [id: string]: IClient }) => byId
);

/**
 * Clients selector
 * @returns {Array}
 */
export const allClientsSelector = createSelector(
  getAllClients,
  (allClients) => allClients
);

/**
 * Creates a selector for creating Select options for the Navbar component
 * This will return an array of objects with the label and value properties
 * @param {Object} state Redux state
 * @returns {Array}
 */
export const clientOptionsSelector = createSelector(
  allClientsSelector,
  (allClients: IClient[]) => {
    allClients = allClients.filter(
      (client) => !client.hasOwnProperty("actif") || client.actif
    );
    return allClients.map(({ id, name }) => {
      return {
        label: name,
        key: id,
      };
    });
  }
);

/**
 * Clients composes Selector Gets all the clients
 */
export const allClientsComposedSelector = customSelectorCreator(
  allClientsSelector,
  (clients: IClient[]) => {
    clients = clients.filter(
      (client) => !client.hasOwnProperty("actif") || client.actif
    );
    return clients;
  }
);

/**
 * Clients composes Selector Gets all the clients with archived clients
 */
export const allClientsComposedWithArchiveSelector = customSelectorCreator(
  allClientsSelector,
  (clients: IClient[]) => {
    return clients;
  }
);

export const getLevelViewOptions = (
  state: IRootState
): {
  key: string;
  label: string;
  options?: { key: string; value: string }[];
}[] => {
  const client: IClient = state.clients.selectedClient;
  const options: {
    key: string;
    label: string;
    options?: { key: string; value: string }[];
  }[] = [{ key: "team", label: "Team" }];
  Object.keys(client.meta_hierarchy_dependencies).forEach((levelIdx) => {
    options.push({
      key: levelIdx,
      label: capitalize(
        client.meta_hierarchy_dependencies[levelIdx].level_type_name
      ),
      options:
        client.meta_hierarchy_dependencies[levelIdx].level_type_number === -1
          ? undefined
          : client.hierarchy_dependencies
              .filter(
                (hd) =>
                  hd.level_type_number ==
                  client.meta_hierarchy_dependencies[levelIdx].level_type_number
              )
              .map((hd) => {
                return { key: hd.level_name, value: hd.level_name };
              }),
    });
  });
  return options;
};
