/**
 * @notes: Authentication API, handles authentication calls with the API
 */

import apiService from "../../../api/api";
import * as urls from "./endpoints";
import { IServerResponse } from "actions/appActions";

interface ILoginUserApiCall {
  id: string;
  token: string;
  created_at: string;
  client: string;
}

/**
 * Login user api function. This calls a helper function to handle api calls
 * This returns a Promise that will either be resolved or rejected
 * @param {String} email User email
 * @param {String} password Password that will posted to FIELDPRO API
 * @returns {Promise}
 * */
export function loginUserApiCall(
  email: string,
  password: string
): Promise<IServerResponse<ILoginUserApiCall>> {
  return apiService.post(urls.LOGIN_ENDPOINT, { email, password });
}

/**
 * Logs out a user from the API
 * @return {Promise}
 * */
export function logoutUserApiCall() {
  return apiService.post(urls.LOGOUT_ENDPOINT, { token: null });
}

/**
 * Recover the password
 * @param {String} email User email
 * @return {Promise}
 * */
export function recoverPasswordApiCall(email: string) {
  return apiService.post(urls.RECOVER_PASSWORD_ENDPOINT, { email });
}

/**
 * Change password
 * @param {String} password New password
 * @param {String} oldPassword Old password
 * @return {Promise}
 * */
export function changePasswordApiCall(password: string, oldPassword: string) {
  return apiService.post(urls.CHANGE_PASSWORD_ENDPOINT, {
    password,
    old_password: oldPassword,
  });
}

interface ICheckUpdateApiCall {
  has_update: boolean;
}
/**
 * Check update for the current version in display
 * @param {String} version Version of the app
 * @returns {Promise}
 * */
export function checkUpdateApiCall(
  version: string
): Promise<IServerResponse<ICheckUpdateApiCall>> {
  return apiService.post(urls.CHECK_UPDATE_ENDPOINT, { version });
}
