import { allWebUsersSelector } from "containers/usermgt/redux/selectors/webUsersSelector";
import {
  isOptimetriksRole,
  OPTIMETRIKS_ROLES,
} from "model/application/ActionCode";
import { createSelector } from "reselect";
import IRootState from "store/model";

/**
 * Gets the state of loggin out
 * @param {Object} state Redux Store state
 */
export const getIsLoggingOut = (state: IRootState) => state.auth.loggingOut;

/**
 * Gets the currently logged in user
 * @param {Object} state Redux store state
 * @return {Object}
 */
export const getSignedInUser = (state: IRootState) => state.auth.user;

/**
 * Gets the current language
 * @param {Object} state Redux store state
 * @return {String}
 */
export const getLang = (state: IRootState) => state.auth.lang || "en";

/**
 * Gets the date of the last check version endpoint
 * @param {Object} state Redux store state
 * @return {String}
 */
export const getLastCheckVersion = (state: IRootState) =>
  state.auth.lastCallToCheckVersion;

/**
 * Gets the roles less important than the logged user, store it in a list of
 * @param {Object} state Redux store state
 * @return {string[]}
 */
export const getLowerPrivileges = (state: IRootState) => {
  const lowerPrivileges: string[] = [];
  if (!state.clients.selectedClient) return [];
  const role = state.clients.selectedClient.profile;
  if (!role) return [];
  const profiles = state.clients.selectedClient.access_right_profiles;
  if (isOptimetriksRole(role)) {
    profiles.forEach((p) => lowerPrivileges.push(p.name));
    if (role === OPTIMETRIKS_ROLES.SUPERADMIN) {
      lowerPrivileges.push(OPTIMETRIKS_ROLES.ADMIN);
      lowerPrivileges.push(OPTIMETRIKS_ROLES.OPERATION);
    }
    if (role === OPTIMETRIKS_ROLES.ADMIN) {
      lowerPrivileges.push(OPTIMETRIKS_ROLES.OPERATION);
    }
    return lowerPrivileges;
  }
  const profileForUser = profiles.filter((profile) => profile.name === role)[0];
  let nbActionsAvailable = 0;
  for (let act in profileForUser.actions) {
    if (profileForUser.actions[act]) {
      nbActionsAvailable++;
    }
  }
  profiles.forEach((profile) => {
    if (profile.name !== role) {
      let actionCount = 0;
      for (let act in profile.actions) {
        if (profile.actions[act]) {
          actionCount++;
        }
      }
      if (nbActionsAvailable > actionCount) {
        lowerPrivileges.push(profile.name);
      }
    }
  });
  return lowerPrivileges;
};

export const getRolesWithRestrictedAccess = (state: IRootState) => {
  return state.clients.selectedClient.access_right_profiles
    .filter((p) => (p.access_all_resources ? false : true))
    .map((p) => p.name);
};

/**
 * Gets the role of the user
 * @param {Object} state Redux store state
 * @return {String}
 */
export const getPrivilege = (state: IRootState) => {
  const role = state.clients.selectedClient.profile;
  return role;
};
/** Reselect to compose selector to retrieve the authentication status*/
export const userBusinessRole = createSelector(
  [allWebUsersSelector, getSignedInUser],
  (allWebUsers, signedUser) => {
    const user = allWebUsers.find((u) => u.email === signedUser.id);
    return user?.business_role || "";
  }
);

/**
 * Gets the state of
 * @param {Object} state Redux store state
 * @returns {Boolean}
 */
export const getIsPasswordRecovering = (state: IRootState) =>
  state.auth.isPasswordRecovering;

/**
 * Selector to get the token from the Store
 * @param {Object} state State of store
 */
const getToken = (state: IRootState) => {
  /*if (state.ajax.mustLogOut) {
		return null;
	}*/
  return state.auth.smalaToken;
};

/**
 * Get the logging in state from Redux Store
 * @param {Object} state Redux store state
 * @returns {Boolean} returns the state of logging in
 */
const getLoginState = (state: IRootState) => state.auth.loggingIn;
/**
 * Selector to get the Authentication status of the User
 * @param {Object} state State of store
 */
const getAuthStatus = (state: IRootState) => state.auth.user.isAuthenticated;

/**
 * Reselect function that composes the selector function to get the token to use for authentication
 */
export const tokenSelector = createSelector([getToken], (token) => token);

/** Reselect to compose selector to retrieve the authentication status*/
export const authStatusSelector = createSelector(
  [getAuthStatus],
  (authenticated) => authenticated
);

/** Selector to get the state of the application on logging in*/
export const loginStateSelector = createSelector(
  getLoginState,
  (loggingIn) => loggingIn
);

/**
 * Get logg
 * @param {Object} state Redux store state
 * @returns {Array} Array of errors encountered on authentication
 */
const getAuthErrors = (state: IRootState) => state.auth.errors;

/** Get Auth errors selector */
export const authErrorsSelector = createSelector(
  getAuthErrors,
  (authErrors) => authErrors
);

/**
 * Get has login error
 * @param {Object} state Redux Store state
 * @returns {Boolean} True if there is a login error
 */
const getHasLoginError = (state: IRootState) => state.auth.loginError;

/** selector for the login errror */
export const hasLoginErrorSelector = createSelector(
  getHasLoginError,
  (hasLoginError) => hasLoginError
);

/**
 * Get login error message
 * @param {Object} state Redux Store state
 * @returns {String} True if there is a login error
 */
const getErrorMessage = (state: IRootState) => state.auth.errorMessage;

/** selector for the login errror */
export const errorMessageSelector = createSelector(
  getErrorMessage,
  (errorMessage) => errorMessage
);

const getActionsAvailable = (state: IRootState) => state.auth.availableActions;

export const getActionsAvailableSelector = createSelector(
  getActionsAvailable,
  (actionsAvailable) => actionsAvailable
);
