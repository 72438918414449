export const shouldRunInBackground = (payload: Object | undefined): boolean => {
  if (payload) {
    return Object.values(payload).some((value) => checkValue(value));
  }
  return false;
};

const checkValue = (value: any): boolean => {
  if (Array.isArray(value)) {
    if (value.length > 500) {
      return true;
    } else {
      if (value.some((e) => checkValue(e))) {
        return true;
      }
    }
  } else if (typeof value === "object" && value !== null) {
    return shouldRunInBackground(value);
  }
  return false;
};
