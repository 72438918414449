import {
  ajaxSuccessAction,
  ajaxRequestAction,
} from "actionCreators/ajaxActionCreator";
import { treatErrorNotification } from "actions/appActions";
import {
  uploadDocumentsBeginAction,
  uploadDocumentsFailureAction,
  uploadDocumentsuccessAction,
  fetchDocumentsBeginAction,
  fetchDocumentsFailureAction,
  fetchDocumentsSuccessAction,
  changeSubcategorySuccessActionCreator,
} from "./actionCreators";
import { IDispatchAndGetState } from "store/model";
import { getLang } from "containers/authentication/redux/selector";
import * as lang from "lang";
import { TLang } from "model/application/Lang";
import {
  uploadDocumentsRequest,
  fetchDocumentsForClientApiCall,
  deleteDocumentsRequest,
} from "./api";
import { IFileInformation } from "components/UploadFileInput/UploadFileInput";
import { DOCUMENT_SCOPE } from "model/entities/Document";

type IFetchDocumentsForClientActionFunc = (
  clientId: string
) => IDispatchAndGetState<void>;
export const fetchDocumentsForClientAction: IFetchDocumentsForClientActionFunc =
  (clientId) => {
    return async (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(fetchDocumentsBeginAction());

      try {
        const documents = await fetchDocumentsForClientApiCall(clientId);
        dispatch(ajaxSuccessAction());
        dispatch(fetchDocumentsSuccessAction(clientId, documents.data.data));
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "fetchDocumentsError",
          error as any,
          fetchDocumentsFailureAction,
          currLang
        );
      }
    };
  };

type IUploadDocumentsForClientActionFunc = (
  clientId: string,
  files: Blob[],
  fileInformations: IFileInformation[],
  teams: string[],
  scope: DOCUMENT_SCOPE,
  folder: string
) => IDispatchAndGetState<void>;
export const uploadDocumentsForClientAction: IUploadDocumentsForClientActionFunc =
  (clientId, files, fileInformations, teams, scope, folder) => {
    return async (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(uploadDocumentsBeginAction());

      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileInformation = fileInformations[i];
          await uploadDocumentsRequest(
            clientId,
            file,
            fileInformation.fileName,
            teams,
            scope,
            folder
          );
        }
        dispatch(ajaxSuccessAction());
        dispatch(uploadDocumentsuccessAction());
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "uploadDocumentsError",
          error as any,
          uploadDocumentsFailureAction,
          currLang
        );
      }
    };
  };

export type ChangeSubcategoryActionFunc = (
  subcategory: "test02" | "test02"
) => IDispatchAndGetState<void>;
export type IChangeSubcategoryActionFunc = (
  subcategory: string,
  folderSelected: string
) => IDispatchAndGetState<void>;

export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory,
  folderSelected?: string
) => {
  return (dispatch) => {
    return new Promise(() =>
      dispatch(
        changeSubcategorySuccessActionCreator(subcategory, folderSelected || "")
      )
    );
  };
};
type IDeleteDocumentsForClientActionFunc = (
  clientId: string,
  documentId: string
) => IDispatchAndGetState<void>;
export const deleteDocumentsForClientAction: IDeleteDocumentsForClientActionFunc =
  (clientId, documentId) => {
    return async (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(uploadDocumentsBeginAction());

      try {
        await deleteDocumentsRequest(clientId, documentId);
        dispatch(ajaxSuccessAction());
        dispatch(uploadDocumentsuccessAction());
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "uploadDocumentsError",
          error as any,
          uploadDocumentsFailureAction,
          currLang
        );
      }
    };
  };

export interface IDocumentsActions {
  fetchDocumentsForClientAction: IFetchDocumentsForClientActionFunc;
  uploadDocumentsForClientAction: IUploadDocumentsForClientActionFunc;
  deleteDocumentsForClientAction: IDeleteDocumentsForClientActionFunc;
  changeSubcategoryAction: IChangeSubcategoryActionFunc;
}

const actions: IDocumentsActions = {
  fetchDocumentsForClientAction,
  uploadDocumentsForClientAction,
  deleteDocumentsForClientAction,
  changeSubcategoryAction,
};

export default actions;
