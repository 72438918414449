import en from "./en.json";
import fr from "./fr.json";
import es from "./es.json";
import TLang from "model/application/Lang";

export const recursiveClean = (targetLang: any, defaultLang: any): TLang | string => {
  const res2 = {};
  for (let att in defaultLang) {
    const lang = targetLang[att] ? targetLang : defaultLang;
    if (typeof lang === "string") {
      return lang;
    } else {
      res2[att] = recursiveClean(lang[att], defaultLang[att]);
    }
  }
  return res2 as TLang;
};

const eng = recursiveClean(en, en) as TLang;
const fra = recursiveClean(fr, en) as TLang;
const esp = recursiveClean(es, en) as TLang;

export { eng as en, fra as fr, esp as es };
