import { INotificationState } from "./reducer";

/**
 * Initial State of notifications in Application
 * A notificaiton should have the following properties
 * 1. level, indicating the severity of the message to display
 * 	Levels available:
 * 	i. success Successful operations
 *  ii. warning Warning messages
 *  iii. error Error Messages
 *  iv. info Informative Messages
 * 2. message The message to display
 * @property {Date} lastUpdated When notifications werelast updated
 * @property {Array} notifications Array of all notificaitons
 * @property {Object} byLevel Notifications by level
 * @property {Object} recentNotification Most Recent Notification
 */
const initState: INotificationState = {
  lastUpdated: null,
  allNotifications: [],
  recentNotification: {},
  byLevel: {},
  lastSuccessNotification: -1,
};

export default initState;
