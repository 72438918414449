/**
 * @notes: AppErrorBoundary error Boundary. Wrap this around your component to granulise the application
 * this prevents the whole React Component Tree from being unmounted when an error occurs in
 * single component. if the wrapped component crashes, the rest of the application runs as
 * expected.
 * Refer to https://reactjs.org/docs/error-boundaries.html
 */

import React, { Component } from "react";
import { array, oneOfType, element, node, string } from "prop-types";
import { sendErrorApiCall } from "../../api/sendError";

/**
 * Application Error Boundary, wrapping the whole application in an Error Boundary
 * Ideally, this should not be the case, as it means the whole application tree is unmounted when an error occurs
 * Thus, every class component should be wrapped in an error boundary
 * */
export default class AppErrorBoundary extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hasError: false,
      error: {},
      info: {},
    };
  }

  /**
   * Catches errors thrown by children
   * @param {Object} error Error thrown
   * @param {Object} info Information of the error Thrown
   * */
  componentDidCatch(error, info) {
    const { client_id, user_id } = this.props;
    sendErrorApiCall(error.stack, error.message, client_id, user_id);
    this.setState({
      hasError: true,
      error,
      info,
    });
  }

  render() {
    if (this.state.hasError) {
      // An appropriate error message
      // render a custom fallback UI
      return <h1>Something went wrong :(</h1>;
    }

    // render children if there is no error
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: oneOfType([node, element, array]),
  client_id: string,
  user_id: string,
};
